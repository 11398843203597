import { ReactNode } from 'react'
import { uniqBy } from 'lodash-es'

import {
  COLORS_FOR_PORTION_DATE,
  GARNISH_CONTAINERS_OBJECT,
} from 'constants/meal'
import { ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import {
  Facility,
  FacilityNetwork,
  GarnishContainer,
  Meal,
  ProductionCycle,
  ProductionRecord,
  Tag,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { filterPortionFacilities } from 'utils/facility-networks'
import { getLocationBackgroundColor, getTheme } from 'utils/theme-helper'
import { getTitlesByCategories } from 'utils/tags'
import { isGarnishContainer } from 'utils/containers'
import { themes } from 'constants/themes'

import { useAppDispatch } from 'hooks'
import { useBulkUpdateProductionPartTags } from 'hooks/combinedAPI/productionParts'
import { useCachedTags } from 'hooks/tags'

const GARNISH_CONTAINER_OPTIONS: (GarnishContainer | 'N/A')[] = [
  'bag',
  '2 oz cup',
  '2 oz oval cup',
  '1 oz cup',
  'dry sachet',
  'sachet',
  'pre-portioned',
  'veggie bags',
  'mushroom bags',
  '9x14 bag',
  '14x16 bag',
  'unsealed body armor',
  'sealed body armor',
  'bagged meal extra',
  'N/A',
]

const PORTION_DATE_OPTIONS: {
  label: string
  value: TagCategories['portion_date']
}[] = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday 2', value: 'monday2' },
]

const GarnishTags = ({
  facilityNetwork,
  garnish,
  productionCycle,
  productionMealCycle2,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: {
  facilityNetwork: FacilityNetwork
  garnish: ComposedMealBOMItem
  productionCycle: ProductionCycle
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { mutateAsync: bulkUpdateTags } = useBulkUpdateProductionPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  const { onTagsChanged, tags } = useCachedTags({
    initialTags: garnish.tags,
    updateTagsFn: ({ tags }) => {
      return bulkUpdateTags({
        boms: [garnish],
        productionCycle,
        productionMealCycle2,
        productionRecord,
        productionTerm,
        tags,
      })
    },
  })

  async function editTag(tag: Tag) {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to update garnish tags.')
      )
    }

    onTagsChanged({ tags: uniqBy([tag, ...tags], 'category') })
  }

  const [container, portionDate, portionLocation] = getTitlesByCategories(
    tags,
    ['container', 'portion_date', 'portion_location']
  )

  const labelIDPrefix = `garnish-tags-${garnish.id}-`
  const labelIDs = {
    container: `${labelIDPrefix}-container`,
    portionDate: `${labelIDPrefix}-portion-date`,
    portionLocation: `${labelIDPrefix}-portion-location`,
  }

  const theme = getTheme(themes, facilityNetwork)

  return (
    <>
      <b>Portion Tags:</b>
      <div className="flex justify-between">
        <div>
          <TagLabel htmlFor={labelIDs.portionLocation}>
            Portion Location
          </TagLabel>
          <select
            className="text-[#212529]"
            data-testid="portion-location-select"
            disabled={readOnly}
            id={labelIDs.portionLocation}
            onChange={(e) => {
              editTag({
                category: 'portion_location',
                title: e.target.value as Facility['title'],
              })
            }}
            style={{
              backgroundColor: getLocationBackgroundColor(
                portionLocation,
                theme
              ),
            }}
            value={portionLocation}
          >
            {filterPortionFacilities(facilityNetwork).ofType.map((facility) => (
              <option key={facility.title} value={facility.title}>
                {facility.displayName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.portionDate}>Portion Date</TagLabel>
          <select
            className="text-[#212529]"
            disabled={readOnly}
            id={labelIDs.portionDate}
            onChange={(e) => {
              editTag({
                category: 'portion_date',
                title: e.target
                  .value as (typeof PORTION_DATE_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: portionDate
                ? COLORS_FOR_PORTION_DATE[portionDate]
                : undefined,
            }}
            value={portionDate}
          >
            {PORTION_DATE_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.container}>Container</TagLabel>
          <select
            className="text-[#212529]"
            data-testid="garnish-container-select"
            disabled={readOnly}
            id={labelIDs.container}
            onChange={(e) => {
              editTag({
                category: 'container',
                title: e.target
                  .value as (typeof GARNISH_CONTAINER_OPTIONS)[number],
              })
            }}
            style={{
              background:
                container && isGarnishContainer(container)
                  ? `rgb${GARNISH_CONTAINERS_OBJECT[container].colorForContainer}`
                  : '#17a2b8',
            }}
            value={container}
          >
            {GARNISH_CONTAINER_OPTIONS.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className="mt-2 flex justify-between">
        <div>
          <TagLabel>Portion Weight</TagLabel>
          <TagLabel>
            <b>{garnish.qtyGrams.toFixed(0)}g</b>
          </TagLabel>
        </div>

        <div>
          <TagLabel>Total Weight</TagLabel>
          <TagLabel>
            <b>{garnish.totalWeightRequiredPounds.toFixed(2)}lbs</b>
          </TagLabel>
        </div>
      </div>
    </>
  )
}

export default GarnishTags

const TagLabel = ({
  children,
  htmlFor,
}: {
  children: ReactNode
  htmlFor?: string
}) => {
  return (
    <label className="mb-1 block text-2xs leading-none" htmlFor={htmlFor}>
      {children}
    </label>
  )
}
