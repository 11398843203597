import { groupBy, keys } from 'lodash-es'

import {
  componentsForMeal,
  transferTagNumbersForComponent,
  transferTagNumbersForMeal,
} from 'utils/transfer-tags'
import {
  FacilityNetwork,
  Meal,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { getTagByCategory } from 'utils/tags'
import { getTransferPartialBinUnit } from 'utils/shipping'
import Table, { Td, Th, Thead, Tr } from 'components/common/Table'

const TagTable = ({
  toggleSelectAll,
  transferComponents,
  type,
  selectMeal,
  allSelected,
  destination,
  selectedMeals,
  facilityNetwork,
}: {
  allSelected: boolean
  destination: string
  facilityNetwork: FacilityNetwork
  selectMeal(meal: Meal): void
  selectedMeals: number[]
  toggleSelectAll(): void
  transferComponents: Meal[]
  type: 'component' | 'frozen' | 'meal'
}): JSX.Element => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <input
              checked={allSelected}
              onChange={() => toggleSelectAll()}
              type="checkbox"
            />
          </Th>
          <Th>{type === 'meal' ? 'Meal' : 'Component'}</Th>
          <Th>Total Quantity</Th>
          <Th>Total Bins</Th>
          <Th>Total Towers</Th>
          <Th>Partial Tower (bins)</Th>
          <Th>Partial Bin ({type === 'meal' ? 'meals' : 'units'})</Th>
        </Tr>
      </Thead>
      <tbody>
        {transferComponents.map((transferComponent, i) => {
          if (type === 'meal') {
            const transferTagNumbers =
              transferTagNumbersForMeal(transferComponent)
            const totalFullBins = transferTagNumbers.totalFullBins
            const totalBins = transferTagNumbers.totalBins
            const partialBinUnits = transferTagNumbers.partialBinUnits
            const allTowers = transferTagNumbers.allTowers
            const partialTowerBins = transferTagNumbers.partialTowerBins
            const fullTowers = transferTagNumbers.fullTowers

            return (
              <Tr key={i + 1}>
                <Td>
                  <input
                    checked={selectedMeals.includes(transferComponent.mealCode)}
                    onChange={() => selectMeal(transferComponent)}
                    type="checkbox"
                  />
                </Td>
                <Td>
                  <span data-testid="mealName">
                    #{transferComponent.mealCode}{' '}
                    {transferComponent.shortTitle ||
                      transferComponent.apiMealTitle}
                  </span>
                </Td>
                <Td>{transferComponent.totalMeals}</Td>
                <Td>
                  {totalBins} Bins
                  <br />({totalFullBins} full
                  {partialBinUnits > 0 ? `, 1 partial` : null})
                </Td>
                <Td>
                  {allTowers} towers
                  <br />({fullTowers} full
                  {partialTowerBins > 0 ? `, 1 partial` : null})
                </Td>
                <Td>
                  {partialTowerBins ? partialTowerBins : '0'} bins
                  <br />
                  {partialTowerBins && partialBinUnits
                    ? `(${partialTowerBins - 1} full, 1 partial)`
                    : ''}
                </Td>
                <Td>{partialBinUnits ? partialBinUnits : '0'} meals</Td>
              </Tr>
            )
          }

          const components = componentsForMeal(
            type,
            transferComponent,
            destination,
            facilityNetwork
          )
          const grouped = groupBy(components, (c) => {
            const containerTag = getTagByCategory(c.tags, 'container')

            return containerTag?.title
          })

          if (components) {
            return keys(grouped).map((container, i) => {
              const typedContainer = container as TagCategories['container']
              const component = grouped[container][0]
              const transferTagNumbers = transferTagNumbersForComponent(
                transferComponent,
                component
              )
              const totalFullBins = transferTagNumbers.totalFullBins
              const totalBins = transferTagNumbers.totalBins
              const partialBinUnits = transferTagNumbers.partialBinUnits
              const allTowers = transferTagNumbers.allTowers
              const fullTowers = transferTagNumbers.fullTowers
              const partialTowerBins = transferTagNumbers.partialTowerBins
              const unit = getTransferPartialBinUnit(typedContainer)

              if (
                container.includes('tray 1') ||
                container.includes('tray 2') ||
                container.includes('bag') ||
                container.includes('clamshell')
              ) {
                return (
                  <Tr key={i + 1}>
                    <Td>
                      <input
                        checked={selectedMeals.includes(
                          transferComponent.mealCode
                        )}
                        onChange={() => selectMeal(transferComponent)}
                        type="checkbox"
                      />
                    </Td>
                    <Td>
                      <span data-testid="mealName">
                        #{transferComponent.mealCode}{' '}
                        {grouped[container].map((g) => g.title).join(' / ')}
                      </span>
                    </Td>
                    <Td>{transferComponent.totalMeals}</Td>
                    <Td>
                      {totalBins} Bins
                      <br /> ({totalFullBins} full{' '}
                      {partialBinUnits > 0 ? `, 1 partial` : null})
                    </Td>
                    <Td>
                      {allTowers} towers
                      <br /> ({fullTowers} full{' '}
                      {partialTowerBins > 0 ? `, 1 partial` : null})
                    </Td>
                    <Td>
                      {partialTowerBins ? partialTowerBins : '0'} bins
                      <br />
                      {partialTowerBins && partialBinUnits
                        ? `(${partialTowerBins - 1} full, 1 partial)`
                        : ''}
                    </Td>
                    <Td>
                      {partialBinUnits ? partialBinUnits : '0'} {unit}
                    </Td>
                  </Tr>
                )
              }

              return grouped[container].map((g, x) => (
                <Tr key={x + 1}>
                  <Td>
                    <input
                      checked={selectedMeals.includes(
                        transferComponent.mealCode
                      )}
                      onChange={() => selectMeal(transferComponent)}
                      type="checkbox"
                    />
                  </Td>
                  <Td>
                    <span data-testid="mealName">
                      #{transferComponent.mealCode} {g.title}
                    </span>
                  </Td>
                  <Td>{transferComponent.totalMeals}</Td>
                  <Td>
                    {totalBins} Bins
                    <br /> ({totalFullBins} full{' '}
                    {partialBinUnits > 0 ? `, 1 partial` : null})
                  </Td>
                  <Td>
                    {allTowers} towers
                    <br /> ({fullTowers} full{' '}
                    {partialTowerBins > 0 ? `, 1 partial` : null})
                  </Td>
                  <Td>
                    {partialTowerBins ? partialTowerBins : '0'} bins
                    <br />
                    {partialTowerBins && partialBinUnits
                      ? `(${partialTowerBins - 1} full, 1 partial)`
                      : ''}
                  </Td>
                  <Td>
                    {partialBinUnits ? partialBinUnits : '0'} {unit}
                  </Td>
                </Tr>
              ))
            })
          }

          return null
        })}
      </tbody>
    </Table>
  )
}

export default TagTable
