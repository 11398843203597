import { forEach, reject, sortBy, sumBy } from 'lodash-es'

import { capitalize } from './string'
import {
  FacilityNetwork,
  Meal,
  PrepLabel,
  ProductionCycle,
  ProductionPart,
  ProductionRecord,
  Tag,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { filterFulfillmentFacilities } from 'utils/facility-networks'
import { getTagsByCategories } from './tags'
import {
  MealWithCycle,
  PrepLabelExtended,
  ProductionPartExtended,
  ProductionRecordBetter,
} from 'types/internal'

export function componentsForTransfer(
  productionRecord: ProductionRecord,
  facilityNetwork: FacilityNetwork
): {
  fulfillmentToNonFulfillmentMealsWithTransferComponents: MealWithCycle[]
  nonFulfillmentSleevedMeals: MealWithCycle[]
  nonFulfillmentToFulfillmentMealsWithFrozenComponents: MealWithCycle[]
  nonFulfillmentToFulfillmentMealsWithTransferComponents: MealWithCycle[]
} {
  const cycle1Meals: MealWithCycle[] = productionRecord.cycles['1'].meals.map(
    (m) => {
      return { ...m, cycle: '1' }
    }
  )
  const cycle2Meals: MealWithCycle[] = productionRecord.cycles['2'].meals.map(
    (m) => {
      return { ...m, cycle: '2' }
    }
  )
  const allMeals = sortBy([...cycle1Meals, ...cycle2Meals], (m) => m.mealCode)

  const fulfillmentFacilities = filterFulfillmentFacilities(facilityNetwork)

  const nonFulfillmentSleevedMeals = allMeals.filter((m) =>
    hasTagValue(
      m.tags,
      'sleeving_location',
      fulfillmentFacilities.notOfType?.title ?? ''
    )
  )
  const fulfillmentSleevedMeals = allMeals.filter((m) =>
    hasTagValue(
      m.tags,
      'sleeving_location',
      fulfillmentFacilities.ofType?.title ?? ''
    )
  )

  const nonFulfillmentToFulfillmentMealsWithFrozenComponents = allMeals.filter(
    (meal) => {
      return !!meal.billOfMaterials.find((bom) => {
        return (
          hasTagValue(bom.tags, 'frozen', 'yes') &&
          hasTagValue(
            bom.tags,
            'portion_location',
            fulfillmentFacilities.notOfType?.title ?? ''
          )
        )
      })
    }
  )

  const nonFulfillmentToFulfillmentMealsWithTransferComponents =
    fulfillmentSleevedMeals.filter((meal) =>
      mealHasNonFrozenBomItemPortionedAt(
        fulfillmentFacilities.notOfType?.title ?? '',
        meal
      )
    )

  const fulfillmentToNonFulfillmentMealsWithTransferComponents =
    nonFulfillmentSleevedMeals.filter((meal) =>
      mealHasNonFrozenBomItemPortionedAt(
        fulfillmentFacilities.ofType?.title ?? '',
        meal
      )
    )

  return {
    fulfillmentToNonFulfillmentMealsWithTransferComponents,
    nonFulfillmentSleevedMeals,
    nonFulfillmentToFulfillmentMealsWithFrozenComponents,
    nonFulfillmentToFulfillmentMealsWithTransferComponents,
  }
}

export function getCycle2Part(
  productionRecord: ProductionRecord,
  cycle1Part: ProductionPart
): ProductionPart | undefined {
  const cycle2PartMatch = productionRecord.cyclePartMatches.find(
    (p) => cycle1Part.id === p.id
  )

  if (cycle2PartMatch) {
    return productionRecord.cycles['2'].parts.find(
      (p) => cycle2PartMatch.c2ID === p.id
    )
  }
}

export function getPartsFromProductionRecord({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): ProductionPart[] {
  return productionRecord.cycles[productionCycle].parts.map((part) => {
    return part.combined ? productionRecord.combinedParts[part.id] : part
  })
}

export function getProductionPartWeights(productionPart: ProductionPart): {
  batchWt: string
  totalWt: string
  useYieldWeight: boolean
  yieldWt: string
} {
  const totalWt = sumBy(productionPart.billOfMaterials, (bom) => {
    return bom.totalWeightRequiredPounds
  })
  const useYieldWeight = !!productionPart.tags.find(
    (t) => t.category === 'canned' || t.category === 'manual'
  )
  const yieldWt = sumBy(productionPart.billOfMaterials, (bom) => {
    return bom.finalYieldPounds
  })

  return {
    batchWt: (
      (useYieldWeight ? yieldWt : totalWt) / productionPart.numBatches
    ).toFixed(2),
    totalWt: totalWt.toFixed(2),
    useYieldWeight,
    yieldWt: yieldWt.toFixed(2),
  }
}

function hasTagValue(tags: Tag[], category: string, value: string): boolean {
  return !!tags.find((t) => t.category === category && t.title === value)
}

export function makeBetterProductionRecord(
  productionRecord: ProductionRecord
): ProductionRecordBetter {
  const betterProductionRecord: ProductionRecordBetter = {
    cycles: {
      '1': {
        meals: productionRecord.cycles['1'].meals,
        parts: [],
        prepLabels: [],
      },
      '2': {
        meals: productionRecord.cycles['2'].meals,
        parts: [],
        prepLabels: [],
      },
    },
  }

  forEach(['1', '2'] as const, (cycle) => {
    forEach(productionRecord.cycles[cycle].parts, (part) => {
      let newPart: ProductionPartExtended
      const useYieldWeight = !!part.tags.find(
        (t) => t.category === 'canned' || t.category === 'manual'
      )

      if (part.combined) {
        const combinedPart = productionRecord.combinedParts[part.id]

        // here we can assign a new object with additional values
        let totalWeightPoundsPerBatch =
          combinedPart.billOfMaterials.length &&
          (
            sumBy(combinedPart.billOfMaterials, (bom) => {
              return useYieldWeight
                ? bom.finalYieldPounds
                : bom.totalWeightRequiredPounds
            }) / combinedPart.numBatches
          ).toFixed(2)

        if (totalWeightPoundsPerBatch === 'NaN') {
          totalWeightPoundsPerBatch = 0
        }

        newPart = { ...combinedPart, totalWeightPoundsPerBatch, useYieldWeight }
      } else {
        let totalWeightPoundsPerBatch =
          part.billOfMaterials.length &&
          (
            sumBy(part.billOfMaterials, (bom) => {
              return useYieldWeight
                ? bom.finalYieldPounds
                : bom.totalWeightRequiredPounds
            }) / part.numBatches
          ).toFixed(2)

        if (totalWeightPoundsPerBatch === 'NaN') {
          totalWeightPoundsPerBatch = 0
        }

        newPart = { ...part, totalWeightPoundsPerBatch, useYieldWeight }
      }

      betterProductionRecord.cycles[cycle].parts.push(newPart)
    })

    forEach(productionRecord.cycles[cycle].prepLabels, (label) => {
      let newLabel: PrepLabelExtended | undefined

      if (label.parentPartCombined) {
        const combinedLabel = productionRecord.combinedLabels[label.id]
        if (combinedLabel) {
          let totalWeightPoundsPerBatch: number | string = (
            combinedLabel.totalWeightPounds / combinedLabel.numBatches
          ).toFixed(2)

          if (totalWeightPoundsPerBatch === 'NaN') {
            totalWeightPoundsPerBatch = 0
          }

          newLabel = { ...combinedLabel, totalWeightPoundsPerBatch }
        }
      } else {
        let totalWeightPoundsPerBatch: number | string = (
          label.totalWeightPounds / label.numBatches
        ).toFixed(2)

        if (totalWeightPoundsPerBatch === 'NaN') {
          totalWeightPoundsPerBatch = 0
        }

        newLabel = { ...label, totalWeightPoundsPerBatch }
      }

      if (newLabel) {
        betterProductionRecord.cycles[cycle].prepLabels.push(newLabel)
      }
    })
  })

  return betterProductionRecord
}

function mealHasNonFrozenBomItemPortionedAt(
  portioningLocation: TagCategories['portion_location'] | '',
  meal: Meal
): boolean {
  const acceptableContainers: TagCategories['container'][] = [
    'tray 1',
    'tray 1 bag',
    'tray 1 clamshell',
    'tray 2',
    'tray 2 bag',
    'tray 2 clamshell',
    'dry sachet',
    'sachet',
    '1 oz cup',
    '2 oz cup',
    '2 oz oval cup',
  ]

  return !!meal.billOfMaterials.find((bom) => {
    const [portionLocationTag, frozenTag, containerTag] = getTagsByCategories(
      bom.tags,
      ['portion_location', 'frozen', 'container']
    )

    const validContainer =
      containerTag && acceptableContainers.includes(containerTag.title)

    return (
      validContainer &&
      portionLocationTag?.title === portioningLocation &&
      (!frozenTag || frozenTag.title === 'no')
    )
  })
}

export function parsePrepLabels(
  productionRecord: ProductionRecord | ProductionRecordBetter,
  location: string | null,
  productionCycle: ProductionCycle,
  hideLabels: string[]
): PrepLabel[] {
  if (location !== null && productionRecord) {
    // filter out labels that have no batch set, or no weight
    const filtered = productionRecord.cycles[productionCycle].prepLabels.filter(
      (label) => {
        let batchedAndWeighed = label.numBatches > 0 && label.totalWeightPounds
        if (label.parentPartCombined) {
          batchedAndWeighed =
            label.combinedNumBatches > 0 && label.totalWeightPounds
        }

        return !!(
          label.location.toLowerCase() === location.toLowerCase() &&
          batchedAndWeighed
        )
      }
    )
    const hidden = reject(filtered, (f) => {
      if (
        hideLabels.includes(f.id) ||
        (f.parentPartCombined && productionCycle !== '1')
      ) {
        return true
      }
      return false
    })

    return sortBy(hidden, (p) => p.partTitle)
  }

  return []
}

export function workOrderDays(parts: ProductionPart[]): {
  day: string
  parts: ProductionPart[]
}[] {
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  const workOrderDays: {
    day: string
    parts: ProductionPart[]
  }[] = []

  days.map((day) => {
    let workOrdersForDay = parts.filter((part) => {
      return !!part.tags.find(
        (t) => t.category === 'day_of_week' && t.title === day
      )
    })

    workOrdersForDay = sortBy(workOrdersForDay, (p) => {
      const relevantTag = p.tags.find(
        (t) => t.category !== 'day_of_week' && t.category !== 'prep_location'
      )
      if (relevantTag) {
        return [relevantTag.category, relevantTag.title]
      }

      return p.title
    })

    return workOrderDays.push({
      day: capitalize(day),
      parts: workOrdersForDay,
    })
  })

  return workOrderDays
}
