import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'

import notifications from './notifications'
import production from './production'

export default combineReducers({
  production,
  loading: loadingBarReducer,
  notifications,
})
