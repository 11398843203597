import { FieldArray, Form, Formik, useField } from 'formik'
import { flatten, orderBy, sortBy, round, cloneDeep } from 'lodash-es'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  getFacilityOption,
  getInitialFormData,
  getLineItemInfo,
  getLineItemsTotal,
  getVendorOption,
  getVendorSKUOption,
  SavePurchaseOrderFormData,
  validateFormData,
} from './helpers'
import { filterVendorSKUsWithPurchasedGoods } from 'utils/selects'
import { formatCommas } from 'utils/general'
import { formatDollars, percentDifference } from 'utils/currency'
import { ReactSelectValue, VendorSKUWithPurchasedGood } from 'types/internal'
import { Vendor } from 'types/combinedAPI/domainModels'

import {
  useFacilityNetworks,
  useSelectFacilityNetwork,
} from 'hooks/combinedAPI/facilityNetworks'
import { useFullPurchaseOrder } from 'hooks/combinedAPI/purchaseOrders'
import { useIsReadOnly } from 'contexts/auth'
import { usePurchasedGoods } from 'hooks/combinedAPI/purchasedGoods'
import { useSubmitValidation } from 'hooks/forms'
import { useVendors } from 'hooks/combinedAPI/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Breadcrumbs from 'components/common/Breadcrumbs'
import Button from 'components/common/Button'
import ConfirmPOSaveModal from './ConfirmPOSaveModal'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormDateInput from 'components/common/FormDateInput'
import FormInput from 'components/common/FormInput'
import FormSelect from 'components/common/FormSelect'
import LineItemsTableCols from './LineItemTableCols'
import MinusCircleIcon from 'components/common/icons/MinusCircleIcon'
import PageLayout from './PageLayout'
import PurchaseOrderPageLoading from './PurchaseOrderPageLoading'
import UploadFromCSV from 'components/purchaseOrders/UploadFromCSV'

const SavePurchaseOrderPage = (): JSX.Element => {
  const { id: purchaseOrderID } = useParams()

  const isEdit = !!purchaseOrderID

  const { search: locationSearch } = useLocation()
  const selectedFacilityNetworkID = new URLSearchParams(locationSearch).get(
    'facilityNetworkID'
  )

  useSelectFacilityNetwork(selectedFacilityNetworkID)

  return isEdit ? (
    <EditPurchaseOrder
      purchaseOrderID={purchaseOrderID}
      selectedFacilityNetworkID={selectedFacilityNetworkID}
    />
  ) : (
    <PageLayout>
      <Breadcrumbs
        breadcrumbs={[
          {
            link: `/purchaseOrders?facilityNetworkID=${selectedFacilityNetworkID}`,
            text: 'Purchase Orders',
          },
          { text: 'Create' },
        ]}
      />

      <SavePurchaseOrderFormWrapper
        initialFormData={getInitialFormData()}
        isEdit={false}
        selectedFacilityNetworkID={selectedFacilityNetworkID}
      />
    </PageLayout>
  )
}

export default SavePurchaseOrderPage

const EditPurchaseOrder = ({
  purchaseOrderID,
  selectedFacilityNetworkID,
}: {
  purchaseOrderID: string
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const { error, isLoading, purchaseOrder } = useFullPurchaseOrder({
    purchaseOrderID,
    selectedFacilityNetworkID,
  })

  if (isLoading) {
    return <PurchaseOrderPageLoading />
  }

  let pageContent: ReactNode
  if (error || !purchaseOrder) {
    pageContent = (
      <PageLayout>
        <APIErrorDisplay error={error} />
      </PageLayout>
    )
  } else {
    pageContent = (
      <SavePurchaseOrderFormWrapper
        initialFormData={getInitialFormData({ purchaseOrder })}
        isEdit={true}
        purchaseOrderID={purchaseOrderID}
        selectedFacilityNetworkID={selectedFacilityNetworkID}
      />
    )
  }

  return (
    <PageLayout>
      <Breadcrumbs
        breadcrumbs={[
          {
            link: `/purchaseOrders?facilityNetworkID=${selectedFacilityNetworkID}`,
            text: 'Purchase Orders',
          },
          {
            link: `/purchaseOrders/${purchaseOrderID}?facilityNetworkID=${selectedFacilityNetworkID}`,
            text: purchaseOrder?.snapReceiptID ?? purchaseOrderID,
          },
          { text: 'Edit' },
        ]}
      />

      {pageContent}
    </PageLayout>
  )
}

const SavePurchaseOrderFormWrapper = ({
  initialFormData,
  isEdit,
  purchaseOrderID,
  selectedFacilityNetworkID,
}: {
  initialFormData: SavePurchaseOrderFormData
  isEdit: boolean
  purchaseOrderID?: string
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const isReadOnly = useIsReadOnly('procurement')

  const [showSavePOConfirmation, setShowSavePOConfirmation] = useState(false)

  return (
    <Formik<SavePurchaseOrderFormData>
      initialValues={initialFormData}
      onSubmit={() => {
        setShowSavePOConfirmation(true)
      }}
      validate={(formData) => {
        return validateFormData({ formData, selectedFacilityNetworkID })
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form>
        <div className="mb-4 flex items-center space-x-4">
          <h1 className="text-2xl">
            {isEdit ? 'Edit' : 'Create'} Purchase Order
          </h1>
          {!isReadOnly && (
            <div className="sm:hidden">
              <UploadFromCSV />
            </div>
          )}
        </div>

        {isReadOnly ? (
          <ErrorDisplay>
            <p>
              You do not have permission to view this page.{' '}
              <span className="font-semibold">MISEVALA_PROCUREMENT</span> scope
              is required.
            </p>
          </ErrorDisplay>
        ) : (
          <>
            <SavePurchaseOrderForm
              isEdit={isEdit}
              selectedFacilityNetworkID={selectedFacilityNetworkID}
            />

            {showSavePOConfirmation && (
              <ConfirmPOSaveModal
                isEdit={isEdit}
                onClosePOConfirmation={() => {
                  setShowSavePOConfirmation(false)
                }}
                purchaseOrderID={purchaseOrderID}
                selectedFacilityNetworkID={selectedFacilityNetworkID}
              />
            )}
          </>
        )}
      </Form>
    </Formik>
  )
}

const SavePurchaseOrderForm = ({
  isEdit,
  selectedFacilityNetworkID,
}: {
  isEdit: boolean
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const { errors, onClickSubmit } =
    useSubmitValidation<SavePurchaseOrderFormData>({
      initialShouldValidate: isEdit,
      isSaving: false,
    })

  return (
    <>
      <div className="mb-4 grid grid-cols-4 gap-4 sm:grid-cols-2">
        <VendorSelect isDisabled={isEdit} />
        <FacilitySelect
          isDisabled={isEdit}
          selectedFacilityNetworkID={selectedFacilityNetworkID}
        />
        <FormDateInput
          label="Requested Delivery Date"
          labelFor="requestedDeliveryDate"
          name="requestedDeliveryDate"
        />
        <FormInput
          id="supplierReference"
          label="Supplier Reference"
          labelFor="supplierReference"
          name="supplierReference"
          type="text"
        />
      </div>
      <LineItemsTable selectedFacilityNetworkID={selectedFacilityNetworkID} />

      <div className="mt-8">
        <div className="flex items-center justify-end space-x-4">
          {!!errors && (
            <ErrorDisplay>Please fix form errors and try again</ErrorDisplay>
          )}
          <div className="w-24">
            <Button onClick={onClickSubmit} type="submit">
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const LineItemsTable = ({
  selectedFacilityNetworkID,
}: {
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const [{ value: lineItems }] =
    useField<SavePurchaseOrderFormData['lineItems']>('lineItems')
  const [{ value: freightCost }] =
    useField<SavePurchaseOrderFormData['freightCost']>('freightCost')

  const totalCostForLineItems = getLineItemsTotal({
    lineItems,
    selectedFacilityNetworkID,
  })

  const totalCost = totalCostForLineItems + Number(freightCost)

  const gridClasses =
    'grid grid-cols-purchase-order-table-save sm:grid-cols-purchase-order-table-save-mobile gap-4 items-center justify-items-center'

  return (
    <div>
      <div
        className={`sticky top-[59px] z-10 bg-white ${gridClasses} mb-4 border-b border-light-grey pb-2 pt-4 text-sm font-normal uppercase text-grey`}
      >
        <LineItemsTableCols
          actionCol=""
          purchasedGoodCol="Purchased Good"
          qtyCol="QTY"
          totalPriceCol="Total"
          totalWtCol="Total Wt (lbs)"
          unitPriceCol="Unit Price"
          unitWtCol="Unit Wt (lbs)"
        />
      </div>
      <FieldArray
        name="lineItems"
        render={(lineItemsHelpers) => {
          return (
            <LineItemWrapper
              gridClasses={gridClasses}
              onAddLineItem={() => {
                lineItemsHelpers.push({
                  overrideCasePrice: '',
                  qty: '',
                  unitPrice: null,
                  vendorSKU: null,
                })
              }}
              onChangePrice={(index, overrideCasePrice, overridedSku) => {
                const clonedLineItem = cloneDeep(lineItems[index])
                clonedLineItem.overrideCasePrice = overrideCasePrice
                clonedLineItem.overridedSku = overridedSku
                lineItemsHelpers.replace(index, clonedLineItem)
              }}
              onRemoveLineItem={(index) => {
                if (lineItems.length !== 1) {
                  lineItemsHelpers.remove(index)
                }
              }}
              selectedFacilityNetworkID={selectedFacilityNetworkID}
            />
          )
        }}
      />

      <div
        className={`${gridClasses} mt-4 border-t border-light-grey pt-4 text-sm uppercase`}
      >
        <FreightCost />
      </div>

      <div
        className={`${gridClasses} mt-4 border-t border-light-grey pt-2 text-sm font-bold uppercase`}
      >
        <LineItemsTableCols
          purchasedGoodCol="Total"
          totalPriceCol={formatDollars(totalCost)}
        />
      </div>
    </div>
  )
}

const LineItemWrapper = ({
  gridClasses,
  onAddLineItem,
  onRemoveLineItem,
  onChangePrice,
  selectedFacilityNetworkID,
}: {
  gridClasses: string
  onAddLineItem(): void
  onRemoveLineItem(index: number): void
  onChangePrice(
    index: number,
    overrideCasePrice: number,
    overridedSku: number
  ): void
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const [{ value: lineItems }] =
    useField<SavePurchaseOrderFormData['lineItems']>('lineItems')

  const hasEmptyLineItem = !lineItems.every((lineItem) => {
    return lineItem.vendorSKU || lineItem.qty
  })

  useEffect(() => {
    if (!hasEmptyLineItem) {
      onAddLineItem()
    }

    // We disable "exhaustive-deps" here because there's no way for us to wrap the onAddLineItem function prop in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEmptyLineItem])

  return (
    <div className="space-y-4 sm:text-sm">
      {lineItems.map((_lineItem, i) => {
        return (
          <LineItemTableRow
            key={i}
            gridClasses={gridClasses}
            onChangePrice={onChangePrice}
            onRemoveLineItem={onRemoveLineItem}
            rowIndex={i}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          />
        )
      })}
    </div>
  )
}

const LineItemTableRow = ({
  gridClasses,
  onRemoveLineItem,
  onChangePrice,
  rowIndex,
  selectedFacilityNetworkID,
}: {
  gridClasses: string
  onRemoveLineItem(index: number): void
  onChangePrice(
    index: number,
    overrideCasePrice: number,
    overridedSku: number
  ): void
  rowIndex: number
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const [{ value: vendor }] =
    useField<SavePurchaseOrderFormData['vendor']>('vendor')
  const [{ value: lineItem }] = useField<
    SavePurchaseOrderFormData['lineItems'][number]
  >(`lineItems.${rowIndex}`)

  const hasVendorSKU = !!lineItem.vendorSKU
  const {
    orderUnit,
    orderUnitSingle,
    total,
    totalWt,
    unitPrice,
    originalUnitPrice,
    unitWt,
  } = getLineItemInfo({
    lineItem,
    selectedFacilityNetworkID,
  })

  // If the unit price returned is not what's in the field, we need to change it
  const isEqualPrice = unitPrice === lineItem.overrideCasePrice
  const vendorSKU = lineItem.vendorSKU?.value.id ?? 0

  useEffect(() => {
    if (!isEqualPrice) {
      onChangePrice(rowIndex, unitPrice, vendorSKU)
    }
    // We disable "exhaustive-deps" here because there's no way for us to wrap the onChangePrice function prop in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEqualPrice, rowIndex, unitPrice, vendorSKU])

  const adjustmentPercent = percentDifference(originalUnitPrice, unitPrice)

  // Request: Don't change the text color for a $0.001 variance
  const varianceAllowed = 0.001
  const priceDifference = round(
    Math.abs(originalUnitPrice - (lineItem.overrideCasePrice ?? 0)),
    3
  )
  const withinVarianceAllowed = priceDifference <= varianceAllowed

  return (
    <div className={gridClasses}>
      <LineItemsTableCols
        actionCol={
          <div
            className="h-4 w-4 cursor-pointer text-grey"
            onClick={() => {
              onRemoveLineItem(rowIndex)
            }}
          >
            <MinusCircleIcon />
          </div>
        }
        purchasedGoodCol={
          <PurchasedGoodSelect
            fieldName={`lineItems.${rowIndex}.vendorSKU`}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
            selectedVendor={vendor}
          />
        }
        qtyCol={
          <div className="flex items-center space-x-2">
            <div className="w-20">
              <FormInput
                min="0"
                name={`lineItems.${rowIndex}.qty`}
                type="number"
              />
            </div>
            <span>{orderUnit}</span>
          </div>
        }
        totalPriceCol={hasVendorSKU ? formatDollars(total) : ''}
        totalWtCol={hasVendorSKU ? formatCommas(totalWt) : ''}
        unitPriceCol={
          hasVendorSKU ? (
            <div className="flex items-center space-x-2">
              <span>$</span>
              <div className="w-20">
                <FormInput
                  min="0"
                  name={`lineItems.${rowIndex}.overrideCasePrice`}
                  step={0.001}
                  // Change to Tovala blue if the price has been adjusted; change to Tovala Orange + bold if greater than 10% difference.
                  style={{
                    color:
                      unitPrice !== originalUnitPrice && !withinVarianceAllowed
                        ? adjustmentPercent >= 10
                          ? '#ff5600'
                          : '#0069d9'
                        : 'black',
                    fontWeight: adjustmentPercent >= 10 ? 'bold' : 'normal',
                  }}
                  type="number"
                />
              </div>
              <span>/{orderUnitSingle}</span>
            </div>
          ) : (
            ''
          )
        }
        unitWtCol={hasVendorSKU ? unitWt : ''}
      />
    </div>
  )
}

const VendorSelect = ({
  isDisabled = false,
}: {
  isDisabled?: boolean
}): JSX.Element => {
  const { data: vendors = [], error, isLoading } = useVendors()

  const orderedVendors = sortBy(vendors, 'name')

  return (
    <FormSelect
      isDisabled={isDisabled}
      isLoading={isLoading}
      label="Vendor"
      labelFor="vendor"
      loadError={
        error
          ? `There was an error loading vendors. Error: ${error?.message}`
          : undefined
      }
      name="vendor"
      options={orderedVendors.map((vendor) => {
        return getVendorOption(vendor)
      })}
    />
  )
}

const FacilitySelect = ({
  isDisabled = false,
  selectedFacilityNetworkID,
}: {
  isDisabled?: boolean
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const {
    data: facilityNetworks = [],
    error,
    isLoading,
  } = useFacilityNetworks()

  const selectedFacilityNetwork = facilityNetworks.find(
    ({ id }) => id === selectedFacilityNetworkID
  )
  const availableFacilities = selectedFacilityNetwork?.facilities ?? []

  return (
    <FormSelect
      isDisabled={isDisabled}
      isLoading={isLoading}
      label="Facility"
      labelFor="facility"
      loadError={
        error
          ? `There was an error loading facility networks. Error: ${error.message}`
          : undefined
      }
      name="facility"
      options={availableFacilities.map((facility) => {
        return getFacilityOption(facility)
      })}
    />
  )
}

export const PurchasedGoodSelect = ({
  fieldName,
  selectedFacilityNetworkID,
  selectedVendor,
}: {
  fieldName: string
  selectedFacilityNetworkID: string | null
  selectedVendor: ReactSelectValue<Vendor> | null
}): JSX.Element => {
  const selectedVendorId = selectedVendor?.value.id ?? ''
  const selectedVendorName = (selectedVendor?.value.name ?? '').toLowerCase()

  const {
    data: purchasedGoodsForVendor = [],
    error,
    isLoading,
  } = usePurchasedGoods({ vendorID: selectedVendorId })

  const flattenedVendorSKUs: VendorSKUWithPurchasedGood[] = flatten(
    purchasedGoodsForVendor.map(({ vendorSkus, ...rest }) => {
      return vendorSkus.map((vendorSKU) => {
        return { ...vendorSKU, purchasedGood: rest }
      })
    })
  )

  const availableVendorSKUs = flattenedVendorSKUs.filter(
    ({ facilityNetworkInfo, vendor }) => {
      return vendor.toLowerCase() === selectedVendorName &&
        selectedFacilityNetworkID
        ? facilityNetworkInfo[selectedFacilityNetworkID]?.isAvailable
        : false
    }
  )
  const orderedVendorSKUs = orderBy(availableVendorSKUs, (vendorSKU) => {
    return vendorSKU.purchasedGood.name.toLowerCase()
  })

  return (
    <FormSelect
      filterOption={filterVendorSKUsWithPurchasedGoods}
      formatOptionLabel={(option) => {
        return (
          <div data-testid="select-purchased-good-option">
            <div>{option.value.purchasedGood.name}</div>
            <div className="text-sm text-grey">
              Vendor SKU: {option.value.sku || 'None'}
            </div>
          </div>
        )
      }}
      isLoading={isLoading}
      loadError={
        error
          ? `There was an error loading purchased goods. Error: ${error.message}`
          : undefined
      }
      name={fieldName}
      options={orderedVendorSKUs.map((vendorSKU) => {
        return getVendorSKUOption(vendorSKU)
      })}
      selectHeight="3rem"
    />
  )
}

const FreightCost = (): JSX.Element => {
  return (
    <LineItemsTableCols
      purchasedGoodCol={<label htmlFor="freightCost">Freight Cost</label>}
      totalPriceCol={
        <div className="flex items-center space-x-2">
          <span>$</span>

          <div className="w-20">
            <FormInput
              id="freightCost"
              min="0"
              name="freightCost"
              step={0.01}
              type="number"
            />
          </div>
        </div>
      }
    />
  )
}
