import { ReactNode } from 'react'

import ExclamationCircleIcon from './icons/ExclamationCircleIcon'

const ErrorDisplay = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="flex flex-wrap items-start space-x-2 bg-faded-red p-4 text-sm">
      <div className="flex items-start space-x-1">
        <div className="mt-0.5 h-4 w-4 flex-shrink-0 text-red">
          <ExclamationCircleIcon />
        </div>
        <div className="font-semibold text-red">Error:</div>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default ErrorDisplay
