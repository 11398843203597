import { ReactNode } from 'react'
import { uniqBy } from 'lodash-es'

import { COLORS_FOR_PORTION_DATE } from 'constants/meal'
import { colorsForTrayContainer } from 'utils/meal'
import { ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import {
  Facility,
  FacilityNetwork,
  Meal,
  ProductionCycle,
  ProductionRecord,
  Tag,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { filterPortionFacilities } from 'utils/facility-networks'
import { getLocationBackgroundColor, getTheme } from 'utils/theme-helper'
import { getTitlesByCategories } from 'utils/tags'
import { themes } from 'constants/themes'

import { useAppDispatch } from 'hooks'
import { useBulkUpdateProductionPartTags } from 'hooks/combinedAPI/productionParts'
import { useCachedTags } from 'hooks/tags'

const FILM_TYPE_OPTIONS: {
  label: string
  value: TagCategories['film_type']
}[] = [
  { label: 'regular', value: 'regular' },
  { label: 'PERF', value: 'PERF' },
]

const FROZEN_OPTIONS: {
  label: string
  value: TagCategories['frozen']
}[] = [
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
]

const MAP_OPTIONS: {
  label: string
  value: TagCategories['MAP']
}[] = [
  { label: 'No MAP', value: 'no MAP' },
  { label: 'MAP', value: 'MAP' },
]

const PORTION_DATE_OPTIONS: {
  label: string
  value: TagCategories['portion_date']
}[] = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday 2', value: 'monday2' },
]

const SPARE_TRAY_OPTIONS: {
  label: string
  value: TagCategories['spare_tray']
}[] = [
  { label: '0 trays', value: '0 trays' },
  { label: '1 Tray', value: '1 tray' },
  { label: '2 trays', value: '2 trays' },
  { label: '3 trays', value: '3 trays' },
  { label: '4 trays', value: '4 trays' },
]

const ComponentTags = ({
  boms,
  facilityNetwork,
  productionCycle,
  productionMealCycle2,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
  trayNumber,
}: {
  boms: [ComposedMealBOMItem, ...ComposedMealBOMItem[]]
  facilityNetwork: FacilityNetwork
  productionCycle: ProductionCycle
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
  trayNumber: 1 | 2
}): JSX.Element | null => {
  const dispatch = useAppDispatch()

  // We grab the first BOM because all BOMs in a component should have the same tags. There may be more than 1 BOM
  // in a components if the BOMs are combined.
  const firstBOM = boms[0]

  const { mutateAsync: bulkUpdateTags } = useBulkUpdateProductionPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  const { onTagsChanged, tags } = useCachedTags({
    initialTags: firstBOM.tags,
    updateTagsFn: ({ tags }) => {
      return bulkUpdateTags({
        boms,
        productionCycle,
        productionMealCycle2,
        productionRecord,
        productionTerm,
        tags,
      })
    },
  })

  function editTag(tag: Tag) {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to update component tags.')
      )
    }

    onTagsChanged({ tags: uniqBy([tag, ...tags], 'category') })
  }

  const containerOptions: {
    label: string
    value: TagCategories['container']
  }[] = [
    { label: `tray ${trayNumber}`, value: `tray ${trayNumber}` },
    { label: 'bag', value: `tray ${trayNumber} bag` },
    { label: 'vac pack', value: `tray ${trayNumber} vac pack` },
    { label: 'clamshell', value: `tray ${trayNumber} clamshell` },
    { label: 'sachet', value: `tray ${trayNumber} sachet` },
    { label: 'dry sachet', value: `tray ${trayNumber} dry sachet` },
    {
      label: 'unsealed body armor',
      value: `tray ${trayNumber} unsealed body armor`,
    },
    {
      label: 'sealed body armor',
      value: `tray ${trayNumber} sealed body armor`,
    },
    {
      label: 'bagged body armor',
      value: `tray ${trayNumber} bagged meal extra`,
    },
    { label: 'veggie bags', value: `tray ${trayNumber} veggie bags` },
    { label: 'mushroom bags', value: `tray ${trayNumber} mushroom bags` },
    {
      label: '9x14 bag',
      value: `tray ${trayNumber} 9x14 bag`,
    },
    { label: '14x16 bag', value: `tray ${trayNumber} 14x16 bag` },
    { label: 'fish tray', value: `tray ${trayNumber} fish tray` },
  ]

  const [
    container,
    filmType,
    frozen,
    map,
    portionDate,
    portionLocation,
    spareTray,
  ] = getTitlesByCategories(tags, [
    'container',
    'film_type',
    'frozen',
    'MAP',
    'portion_date',
    'portion_location',
    'spare_tray',
  ])

  const labelIDPrefix = `component-tags-${firstBOM.id}-`
  const labelIDs = {
    container: `${labelIDPrefix}-container`,
    filmType: `${labelIDPrefix}-film-type`,
    frozen: `${labelIDPrefix}-frozen`,
    map: `${labelIDPrefix}-MAP`,
    portionDate: `${labelIDPrefix}-portion-date`,
    portionLocation: `${labelIDPrefix}-portion-location`,
    spareTray: `${labelIDPrefix}-spare-tray`,
  }

  const theme = getTheme(themes, facilityNetwork)

  return (
    <div className="flex flex-col items-start p-2">
      <b>Portion Tags:</b>
      <div className="flex w-full justify-between">
        <div>
          <TagLabel htmlFor={labelIDs.portionLocation}>Location</TagLabel>
          <select
            data-testid={facilityNetwork.title}
            disabled={readOnly}
            id={labelIDs.portionLocation}
            onChange={(e) => {
              editTag({
                category: 'portion_location',
                title: e.target.value as Facility['title'],
              })
            }}
            style={{
              backgroundColor: getLocationBackgroundColor(
                portionLocation,
                theme
              ),
              color: '#212529',
            }}
            value={portionLocation}
          >
            <option value="">Select Portion Location</option>
            {filterPortionFacilities(facilityNetwork).ofType.map((facility) => (
              <option key={facility.title} value={facility.title}>
                {facility.displayName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.portionDate}>Date</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.portionDate}
            onChange={(e) => {
              editTag({
                category: 'portion_date',
                title: e.target
                  .value as (typeof PORTION_DATE_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: portionDate
                ? COLORS_FOR_PORTION_DATE[portionDate]
                : undefined,
              color: '#212529',
            }}
            value={portionDate}
          >
            {PORTION_DATE_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.container}>Container</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.container}
            onChange={(e) => {
              editTag({
                category: 'container',
                title: e.target
                  .value as (typeof containerOptions)[number]['value'],
              })
            }}
            style={{
              backgroundColor: colorsForTrayContainer(container),
              color: '#212529',
            }}
            value={container}
          >
            {containerOptions.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div className="mt-2 flex w-full justify-between">
        <div>
          <TagLabel htmlFor={labelIDs.frozen}>Frozen Cmpnt</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.frozen}
            onChange={(e) => {
              editTag({
                category: 'frozen',
                title: e.target
                  .value as (typeof FROZEN_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: '#efefef',
            }}
            value={frozen}
          >
            {FROZEN_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.map}>MAP</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.map}
            onChange={(e) => {
              editTag({
                category: 'MAP',
                title: e.target.value as (typeof MAP_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: '#efefef',
            }}
            value={map}
          >
            {MAP_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.filmType}>Film Type</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.filmType}
            onChange={(e) => {
              editTag({
                category: 'film_type',
                title: e.target
                  .value as (typeof FILM_TYPE_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: '#efefef',
            }}
            value={filmType}
          >
            {FILM_TYPE_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>

        <div>
          <TagLabel htmlFor={labelIDs.spareTray}>Total Trays</TagLabel>
          <select
            disabled={readOnly}
            id={labelIDs.spareTray}
            onChange={(e) => {
              editTag({
                category: 'spare_tray',
                title: e.target
                  .value as (typeof SPARE_TRAY_OPTIONS)[number]['value'],
              })
            }}
            style={{
              backgroundColor: '#efefef',
            }}
            value={spareTray}
          >
            {SPARE_TRAY_OPTIONS.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            })}
          </select>
        </div>
      </div>
    </div>
  )
}

export default ComponentTags

const TagLabel = ({
  children,
  htmlFor,
}: {
  children: ReactNode
  htmlFor: string
}) => {
  return (
    <label className="mb-1 block text-2xs leading-none" htmlFor={htmlFor}>
      {children}
    </label>
  )
}
