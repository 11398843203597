import { reject } from 'lodash-es'

import { ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import {
  Meal,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { useBulkUpdateProductionPartTags } from 'hooks/combinedAPI/productionParts'

const NaBOMs = ({
  naBOMs,
  productionCycle,
  productionMealCycle2,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: {
  naBOMs: ComposedMealBOMItem[]
  productionCycle: ProductionCycle
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { mutateAsync: bulkUpdateTags } = useBulkUpdateProductionPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  async function removeNATag(bom: ComposedMealBOMItem) {
    if (readOnly) {
      return dispatch(error('You do not have permission to remove N/A tags.'))
    }

    bulkUpdateTags({
      boms: [bom],
      productionCycle,
      productionMealCycle2,
      productionRecord,
      productionTerm,
      tags: reject(
        bom.tags,
        (tag) => tag.category === 'container' && tag.title === 'N/A'
      ),
    })
  }

  return (
    <>
      {naBOMs.map((bom, i) => (
        <div key={i}>
          <span
            aria-label="Remove N/A tag"
            className="cursor-pointer"
            onClick={() => {
              removeNATag(bom)
            }}
            style={{ color: '#a6a6a6' }}
          >
            <i className="fas fa-minus-circle" />
          </span>{' '}
          <span style={{ color: '#a6a6a6' }}>{bom.title}</span>
        </div>
      ))}
    </>
  )
}

export default NaBOMs
