import { useEffect, useState } from 'react'

import { ComposedMeal } from 'types/internal'
import { error } from 'slices/notifications'
import { ProductionCycle } from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { useIsReadOnly } from 'contexts/auth'
import { useUpdateProductionMeal } from 'hooks/combinedAPI/productionMeals'
import CircleLoader from 'components/common/CircleLoader'
import FormFieldError from 'components/common/FormFieldError'
import InputAsync from 'components/common/InputAsync'

export interface Props {
  initialTotalMeals: number
  isFocused: boolean
  mealTotalAcrossCycles: number
  onChangeFocusedMealCountIndex(direction: 'down' | 'up'): void
  onMealCountFocused(): void
  productionCycle: ProductionCycle
  productionMeal: ComposedMeal
  productionTerm: string
  refetchProductionRecord(): void
}

const MealCount = ({
  initialTotalMeals,
  isFocused,
  mealTotalAcrossCycles,
  onChangeFocusedMealCountIndex,
  onMealCountFocused,
  productionCycle,
  productionMeal,
  productionTerm,
  refetchProductionRecord,
}: Props): JSX.Element => {
  const isReadOnly = useIsReadOnly()

  const dispatch = useAppDispatch()

  const [isEditing, setIsEditing] = useState(isFocused)
  const [totalMeals, setTotalMeals] = useState(`${initialTotalMeals}`)

  const {
    error: updateProdMealError,
    isError: hasUpdateProdMealError,
    isLoading: isUpdatingProdMeal,
    mutate: updateProductionMeal,
  } = useUpdateProductionMeal({
    onSettled: () => {
      setIsEditing(false)
      refetchProductionRecord()
    },
  })

  function checkForMealCountUpdate(newTotalMeals: string) {
    if (newTotalMeals === `${initialTotalMeals}`) {
      setIsEditing(false)
    } else {
      updateMeal(Number.parseInt(newTotalMeals, 10))
    }
  }

  async function updateMeal(newTotalMeals: number) {
    if (isReadOnly) {
      return dispatch(error('You do not have permission to edit meal counts.'))
    }

    updateProductionMeal({
      data: {
        id: productionMeal.id,
        mealCode: productionMeal.mealCode,
        shortTitle: productionMeal.shortTitle,
        totalMeals: newTotalMeals,
      },
      mealID: productionMeal.id,
      productionTerm,
    })
  }

  useEffect(() => {
    if (isFocused) {
      setIsEditing(isFocused)
    }
  }, [isFocused])

  return (
    <>
      <div>Cycle {productionCycle}:</div>
      {isEditing ? (
        <div className="px-1">
          <InputAsync
            autoFocus={true}
            isLoading={isUpdatingProdMeal}
            onBlur={(e) => {
              checkForMealCountUpdate(e.target.value)
            }}
            onChange={(e) => setTotalMeals(e.target.value)}
            onFocus={() => {
              onMealCountFocused()
            }}
            onKeyDown={(e) => {
              const isArrowDown = e.key === 'ArrowDown'
              const isArrowUp = e.key === 'ArrowUp'
              const isEnter = e.key === 'Enter'

              if (isArrowDown || isArrowUp || isEnter) {
                checkForMealCountUpdate(e.currentTarget.value)

                if (isArrowUp || isArrowDown) {
                  onChangeFocusedMealCountIndex(isArrowDown ? 'down' : 'up')
                }
              }
            }}
            type="text"
            value={totalMeals}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center space-x-2">
            <button
              className="text-base text-blue hover:underline disabled:text-dark-grey disabled:hover:no-underline"
              disabled={isReadOnly || isUpdatingProdMeal}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              {totalMeals}
            </button>
            {isUpdatingProdMeal && <CircleLoader loaderStyle="colored" />}
          </div>
          {hasUpdateProdMealError && (
            <FormFieldError error={`Error: ${updateProdMealError?.message}`} />
          )}
        </>
      )}
      <div className="mt-1">Total: {mealTotalAcrossCycles}</div>
    </>
  )
}

export default MealCount
