import { Th, Thead, Tr } from 'components/common/Table'

const WeightCheckTableHeader = () => {
  return (
    <Thead>
      <Tr>
        <Th width="10%">Part</Th>
        <Th>Cook Type</Th>
        <Th width="12%"></Th>
        <Th width="12%">Expected Weight (lbs)</Th>
        <Th width="12%">Actual Weight (lbs)</Th>
        <Th width="12%">Meal #</Th>
        <Th width="12%">Tasted/ Initials</Th>
        <Th width="6%">Date/ Time</Th>
        <Th width="12%">Notes</Th>
        <Th backgroundColor="bg-[#ddd] print:bg-inherit">True Yield</Th>
      </Tr>
    </Thead>
  )
}

export default WeightCheckTableHeader
