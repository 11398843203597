import { createFilter } from 'react-select'

import { BOMItem } from 'types/combinedAPI/domainModels'
import { ReactSelectValue, VendorSKUWithPurchasedGood } from 'types/internal'

export const filterVendorSKUsWithPurchasedGoods = createFilter<
  ReactSelectValue<VendorSKUWithPurchasedGood>
>({
  stringify: (option) =>
    `${option.data.value.purchasedGood.name} ${option.data.value.sku}`,
})

export const filterShortPurchasedGoods = createFilter<
  ReactSelectValue<BOMItem>
>({
  // The ID of a purchased good is its base SKU.
  stringify: (option) => `${option.data.label} ${option.data.value.baseSku}`,
})
