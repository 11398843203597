import { clsx } from 'clsx'
import { ButtonHTMLAttributes } from 'react'

type ButtonStyle = 'cancel' | 'danger' | 'primary' | 'primary-inverse'
export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonStyle?: ButtonStyle
  inheritHeight?: boolean
}

const COLOR_CLASS_MAP: { [key in ButtonStyle]: string } = {
  cancel: 'hover:bg-light-grey text-black',
  danger: 'bg-red text-white',
  primary: 'bg-orange text-white',
  'primary-inverse': 'border border-orange text-orange',
}

const Button = ({
  buttonStyle = 'primary',
  inheritHeight = false,
  type = 'button',
  ...rest
}: Props): JSX.Element => {
  const disabledClasses = buttonStyle.includes('-inverse')
    ? 'opacity-50 cursor-not-allowed'
    : 'opacity-75 cursor-not-allowed'

  const heightClasses = inheritHeight ? 'h-full' : 'h-8'

  return (
    <button
      className={clsx(
        'w-full rounded border-none text-xs uppercase',
        heightClasses,
        COLOR_CLASS_MAP[buttonStyle],
        {
          [disabledClasses]: rest.disabled,
          'font-bold': buttonStyle !== 'cancel',
        }
      )}
      type={type}
      {...rest}
    />
  )
}

export default Button
