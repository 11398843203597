import { Font, StyleSheet } from '@react-pdf/renderer'

export const commonPDFStyles = StyleSheet.create({
  page: { fontFamily: 'Roboto' },
  signature: { fontFamily: 'Allura' },
})

/**
 * Registers custom fonts for use with the react-pdf library.
 */
export function registerPDFFonts() {
  Font.register({
    family: 'Roboto',
    // This ttf URL was found by running the following cURL command:
    // curl -A "Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10_6_8; de-at) AppleWebKit/533.21.1 (KHTML, like Gecko) Version/5.0.5 Safari/533.21.1" https://fonts.googleapis.com/css?family=Roboto
    //
    // See https://gist.github.com/westonruter/d6cbb4d10cc34864577b946a4b8dd297.
    src: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf',
  })
}

export function registerPOVendorPDFFonts() {
  Font.register({
    family: 'Allura',
    src: 'https://fonts.gstatic.com/s/allura/v15/9oRPNYsQpS4zjuA_iwgTHNn7GQ.ttf',
  })
}
