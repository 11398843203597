export function capitalize(s: string): string {
  return s.slice(0, 1).toUpperCase() + s.slice(1)
}

export function titleize(s: string): string {
  return s.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function parameterize(s: string): string {
  return s
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '')
}
