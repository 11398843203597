import { ReactNode } from 'react'

import { MenuItem } from 'components/common/Menu'

const DropdownItem = ({
  children,
  onClick,
}: {
  children: ReactNode
  onClick(): void
}) => {
  return (
    <MenuItem>
      <button
        className="flex w-full p-2 ui-active:bg-lightest-grey"
        onClick={onClick}
      >
        {children}
      </button>
    </MenuItem>
  )
}

export default DropdownItem
