import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating as useFloatingUI,
} from '@floating-ui/react'

export function useFloating() {
  return useFloatingUI({
    placement: 'bottom-start',
    middleware: [
      offset(({ placement }) => ({
        // The value of "8" takes into account the space for the arrow and then a little
        // extra to separate the tooltip from its trigger element.
        mainAxis: placement.includes('top') ? 8 : -8,
      })),
      flip(),
      shift({ padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  })
}
