import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'

import rootReducer from 'slices'

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // We disable the immutability middleware because our production record state is HUGE and keeping this on
      // causes a slowdown in development. Once we've broken apart the production record state, we could re-enable
      // this middleware (if we're still even using Redux at that point).
      immutableCheck: false,
    }),
  reducer: rootReducer,
})

export default store

export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
export type RootState = ReturnType<typeof store.getState>
