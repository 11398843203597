import { Th, Thead, Tr } from 'components/common/Table'

const SensoryTableHeader = () => {
  return (
    <Thead>
      <Tr>
        <Th width="11%">Part</Th>
        <Th>Meal #</Th>
        <Th width="12%">Ingredients and Method reviewed with cook</Th>
        <Th width="12%">Sample Delivered</Th>
        <Th width="12%">Sample Tasted with Cook</Th>
        <Th width="12%">Completed Project Tasted</Th>
        <Th width="12%">Initials of MOD (both if across both shifts)</Th>
        <Th width="6%">Date/Time</Th>
        <Th width="12%">Tasting Notes/Comments</Th>
      </Tr>
    </Thead>
  )
}

export default SensoryTableHeader
