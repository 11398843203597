import { apiDelete, put } from './baseAPI'
import { ProductionRecord, Tag } from 'types/combinedAPI/domainModels'

export interface DeleteProductionMeal {
  mealID: string
  productionTerm: string
}

export type DeleteProductionMealResponse = ProductionRecord

export interface UpdateProductionMeal {
  data: { id: string; mealCode: number; shortTitle: string; totalMeals: number }
  mealID: string
  productionTerm: string
}

export type UpdateProductionMealResponse = Record<string, unknown>

export interface UpdateProductionMealTags {
  data: { tags: Tag[] }
  mealID: string
  productionTerm: string
}

export type UpdateProductionMealTagsResponse = 'okay'

export const ENDPOINTS = {
  DELETE_PRODUCTION_MEAL: ({
    mealID,
    productionTerm,
  }: DeleteProductionMeal) => ({
    path: `/productionRecords/${productionTerm}/productionMeals/${mealID}`,
    version: 'v0' as const,
  }),
  UPDATE_PRODUCTION_MEAL: ({
    mealID,
    productionTerm,
  }: Omit<UpdateProductionMeal, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/productionMeals/${mealID}`,
    version: 'v0' as const,
  }),
  UPDATE_PRODUCTION_MEAL_TAGS: ({
    mealID,
    productionTerm,
  }: Omit<UpdateProductionMealTags, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/productionMeals/${mealID}/tags`,
    version: 'v0' as const,
  }),
}

export function deleteProductionMeal(
  opts: DeleteProductionMeal
): Promise<DeleteProductionMealResponse> {
  return apiDelete(ENDPOINTS.DELETE_PRODUCTION_MEAL(opts))
}

export async function updateProductionMeal({
  data,
  mealID,
  productionTerm,
}: UpdateProductionMeal): Promise<UpdateProductionMealResponse> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_PRODUCTION_MEAL({ mealID, productionTerm }),
  })
}

export async function updateProductionMealTags({
  data,
  mealID,
  productionTerm,
}: UpdateProductionMealTags): Promise<UpdateProductionMealTagsResponse> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_PRODUCTION_MEAL_TAGS({ mealID, productionTerm }),
  })
}
