import { ReactNode } from 'react'
import { useField } from 'formik'

import FormGroup from './FormGroup'
import Textarea, { Props as TextareaProps } from './Textarea'

const FormTextarea = ({
  label,
  labelFor,
  ...props
}: Omit<TextareaProps, 'name'> & {
  label?: ReactNode
  labelFor?: string
  name: string
}): JSX.Element => {
  const [field, meta] = useField(props)
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <Textarea
        {...field}
        {...props}
        hasError={hasError}
        value={field.value ?? ''}
      />
    </FormGroup>
  )
}

export default FormTextarea
