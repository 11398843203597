import { post } from './baseAPI'

export interface Login {
  data: { email: string; password: string }
}

export interface LoginResponse {
  token: string
}

export const ENDPOINTS = {
  LOGIN: () => ({
    path: '/login',
    version: '' as const,
  }),
}

export async function login({ data }: Login): Promise<LoginResponse> {
  return post({ data, isPublic: true, ...ENDPOINTS.LOGIN() })
}
