import { isEmpty } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { usePDF } from '@react-pdf/renderer'
import { useFormikContext } from 'formik'

import { error } from 'slices/notifications'
import { PurchaseOrderFull } from 'types/internal'

import { useAppDispatch } from 'hooks'
import { useFullPurchaseOrder } from 'hooks/combinedAPI/purchaseOrders'

import VendorPDF from './VendorPDF'
import { VendorPurchaseOrderFormData } from './helpers'

const PrintVendorPDF = ({
  children,
  purchaseOrderID,
  selectedFacilityNetworkID,
}: {
  children(opts: { isLoading: boolean }): JSX.Element
  purchaseOrderID: string | undefined
  selectedFacilityNetworkID: string | null
}): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [hasRequestedPrint, setHasRequestedPrint] = useState(false)

  const { purchaseOrder } = useFullPurchaseOrder({
    enabled: hasRequestedPrint,
    onFetchPOError: () => {
      dispatch(error('Failed to load purchase order for printing vendor PDF.'))
      setHasRequestedPrint(false)
    },
    onFetchPurchasedGoodsError: () => {
      dispatch(error('Failed to load purchased goods for printing vendor PDF.'))
      setHasRequestedPrint(false)
    },
    purchaseOrderID,
    refetchOnWindowFocus: false,
    selectedFacilityNetworkID,
  })

  const { validateForm } = useFormikContext<VendorPurchaseOrderFormData>()

  const onFailed = useCallback(
    (errorMessage: string) => {
      dispatch(error(`Failed to generate vendor PDF. Error: ${errorMessage}`))

      setHasRequestedPrint(false)
    },
    [dispatch]
  )

  const onPrinted = useCallback(() => {
    setHasRequestedPrint(false)
  }, [])

  if (!hasRequestedPrint) {
    return (
      <div
        onClick={() => {
          validateForm().then((formErrors) => {
            if (isEmpty(formErrors)) {
              setHasRequestedPrint(true)
            }
          })
        }}
      >
        {children({ isLoading: false })}
      </div>
    )
  }

  if (purchaseOrder) {
    return (
      <AutoPrintVendorDoc
        onFailed={onFailed}
        onPrinted={onPrinted}
        purchaseOrder={purchaseOrder}
      >
        {children}
      </AutoPrintVendorDoc>
    )
  }

  return children({ isLoading: true })
}

export default PrintVendorPDF

const AutoPrintVendorDoc = ({
  children,
  onFailed,
  onPrinted,
  purchaseOrder,
}: {
  children(opts: { isLoading: boolean }): JSX.Element
  onFailed(errorMessage: string): void
  onPrinted(): void
  purchaseOrder: PurchaseOrderFull
}): JSX.Element | null => {
  const { values } = useFormikContext<VendorPurchaseOrderFormData>()

  const [instance] = usePDF({
    document: <VendorPDF formData={values} purchaseOrder={purchaseOrder} />,
  })

  useEffect(() => {
    if (instance.url) {
      const win = window.open(instance.url)
      win?.print()
      onPrinted()
    }
  }, [instance.url, onPrinted])

  useEffect(() => {
    if (instance.error) {
      onFailed(instance.error)
    }
  }, [instance.error, onFailed])

  return children({ isLoading: true })
}
