import { clsx } from 'clsx'
import { ReactNode } from 'react'

import { ComposedMeal, ComposedMealBOMItem } from 'types/internal'
import {
  FacilityNetwork,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { getCycle2Meal } from 'utils/productionMeals'

import { useIsReadOnly } from 'contexts/auth'
import ComponentTags from './ComponentTags'
import DeleteMeal from './DeleteMeal'
import GarnishTags from './GarnishTags'
import MealBOMSlot from './MealBOMSlot'
import MealCount from 'components/production/planning/meals/MealCount'
import MealSleeving from './MealSleeving'
import NaBOMs from './NaBOMs'
import UnassignedBOMsButton from './UnassignedBOMsButton'

function hasAtLeastOneBOM(
  boms: ComposedMealBOMItem[]
): boms is [ComposedMealBOMItem, ...ComposedMealBOMItem[]] {
  return boms.length > 0
}

export interface Props {
  facilityNetwork: FacilityNetwork
  handleMealClick(meal: ComposedMeal): void
  isMealCountFocused: boolean
  mealTotalAcrossCycles: number
  onChangeFocusedMealCountIndex(direction: 'down' | 'up'): void
  onMealCountFocused(): void
  productionCycle: ProductionCycle
  productionMeal: ComposedMeal
  productionRecord: ProductionRecord
  productionTerm: string
  refetchProductionRecord(): void
}

const PlanningMealsRow = ({
  facilityNetwork,
  handleMealClick,
  isMealCountFocused,
  mealTotalAcrossCycles,
  onChangeFocusedMealCountIndex,
  onMealCountFocused,
  productionCycle,
  productionMeal,
  productionRecord,
  productionTerm,
  refetchProductionRecord,
}: Props): JSX.Element => {
  const isReadOnly = useIsReadOnly()

  const productionMealCycle2 = getCycle2Meal({
    cycle1Meal: productionMeal,
    productionRecord,
  })

  return (
    <div className="flex border border-black">
      <Col width="w-[5%]">
        <button
          className="p-2 text-base text-blue hover:underline"
          onClick={() => handleMealClick(productionMeal)}
        >
          #{productionMeal.mealCode}
        </button>
        <DeleteMeal
          productionMeal={productionMeal}
          productionTerm={productionTerm}
          readOnly={isReadOnly}
          refetchProductionRecord={refetchProductionRecord}
        />
      </Col>
      <Col width="w-[5%]">
        <div className="p-2 text-center text-sm">
          <MealCount
            initialTotalMeals={productionMeal.totalMeals}
            isFocused={isMealCountFocused}
            mealTotalAcrossCycles={mealTotalAcrossCycles}
            onChangeFocusedMealCountIndex={onChangeFocusedMealCountIndex}
            onMealCountFocused={onMealCountFocused}
            productionCycle={productionCycle}
            productionMeal={productionMeal}
            productionTerm={productionTerm}
            refetchProductionRecord={refetchProductionRecord}
          />
        </div>
      </Col>
      <Col width="w-[10%]">
        <div className="p-2">{productionMeal.shortTitle}</div>
      </Col>
      <Col width="w-[7%]">
        <MealSleeving
          facilityNetwork={facilityNetwork}
          productionCycle={productionCycle}
          productionMeal={productionMeal}
          productionMealCycle2={productionMealCycle2}
          productionTerm={productionTerm}
          readOnly={isReadOnly}
          refetchProductionRecord={refetchProductionRecord}
        />
      </Col>
      <Col width="w-[25%]">
        <div className="relative pb-12">
          <div className="absolute right-0 top-0 z-10">
            <UnassignedBOMsButton
              productionCycle={productionCycle}
              productionMeal={productionMeal}
              productionMealCycle2={productionMealCycle2}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              readOnly={isReadOnly}
              refetchProductionRecord={refetchProductionRecord}
              slot="component1"
              unassignedBOMs={productionMeal.unassigned}
            />
          </div>

          {productionMeal.component1.map((bom) => {
            return (
              <MealBOMSlot
                key={bom.id}
                bom={bom}
                productionCycle={productionCycle}
                productionMealCycle2={productionMealCycle2}
                productionRecord={productionRecord}
                productionTerm={productionTerm}
                readOnly={isReadOnly}
                refetchProductionRecord={refetchProductionRecord}
              >
                <span>
                  {bom.qtyGrams.toFixed()}g per portion -{' '}
                  {bom.totalWeightRequiredPounds.toFixed(2)}lbs total
                </span>
              </MealBOMSlot>
            )
          })}
          {hasAtLeastOneBOM(productionMeal.component1) && (
            <ComponentTags
              boms={productionMeal.component1}
              facilityNetwork={facilityNetwork}
              productionCycle={productionCycle}
              productionMealCycle2={productionMealCycle2}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              readOnly={isReadOnly}
              refetchProductionRecord={refetchProductionRecord}
              trayNumber={1}
            />
          )}
        </div>
      </Col>
      <Col width="w-[25%]">
        <div className="relative pb-12">
          <div className="absolute right-0 top-0 z-10">
            <UnassignedBOMsButton
              productionCycle={productionCycle}
              productionMeal={productionMeal}
              productionMealCycle2={productionMealCycle2}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              readOnly={isReadOnly}
              refetchProductionRecord={refetchProductionRecord}
              slot="component2"
              unassignedBOMs={productionMeal.unassigned}
            />
          </div>

          {productionMeal.component2.map((bom) => {
            return (
              <MealBOMSlot
                key={bom.id}
                bom={bom}
                productionCycle={productionCycle}
                productionMealCycle2={productionMealCycle2}
                productionRecord={productionRecord}
                productionTerm={productionTerm}
                readOnly={isReadOnly}
                refetchProductionRecord={refetchProductionRecord}
              >
                <span>
                  {bom.qtyGrams.toFixed()}g per portion -{' '}
                  {bom.totalWeightRequiredPounds.toFixed(2)}lbs total
                </span>
              </MealBOMSlot>
            )
          })}
          {hasAtLeastOneBOM(productionMeal.component2) && (
            <ComponentTags
              boms={productionMeal.component2}
              facilityNetwork={facilityNetwork}
              productionCycle={productionCycle}
              productionMealCycle2={productionMealCycle2}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              readOnly={isReadOnly}
              refetchProductionRecord={refetchProductionRecord}
              trayNumber={2}
            />
          )}
        </div>
      </Col>

      <Col width="w-[25%] relative">
        <div className="absolute right-0 top-0 z-10">
          <UnassignedBOMsButton
            productionCycle={productionCycle}
            productionMeal={productionMeal}
            productionMealCycle2={productionMealCycle2}
            productionRecord={productionRecord}
            productionTerm={productionTerm}
            readOnly={isReadOnly}
            refetchProductionRecord={refetchProductionRecord}
            slot="garnish"
            unassignedBOMs={productionMeal.unassigned}
          />
        </div>

        {productionMeal.garnishes.map((garnish) => {
          const height = 100 / productionMeal.garnishes.length

          return (
            <div key={garnish.id} style={{ height: `${height}%` }}>
              <MealBOMSlot
                bom={garnish}
                productionCycle={productionCycle}
                productionMealCycle2={productionMealCycle2}
                productionRecord={productionRecord}
                productionTerm={productionTerm}
                readOnly={isReadOnly}
                refetchProductionRecord={refetchProductionRecord}
              >
                <GarnishTags
                  facilityNetwork={facilityNetwork}
                  garnish={garnish}
                  productionCycle={productionCycle}
                  productionMealCycle2={productionMealCycle2}
                  productionRecord={productionRecord}
                  productionTerm={productionTerm}
                  readOnly={isReadOnly}
                  refetchProductionRecord={refetchProductionRecord}
                />
              </MealBOMSlot>
            </div>
          )
        })}
        <NaBOMs
          naBOMs={productionMeal.naBoms}
          productionCycle={productionCycle}
          productionMealCycle2={productionMealCycle2}
          productionRecord={productionRecord}
          productionTerm={productionTerm}
          readOnly={isReadOnly}
          refetchProductionRecord={refetchProductionRecord}
        />
      </Col>
    </div>
  )
}

export default PlanningMealsRow

const Col = ({ children, width }: { children: ReactNode; width: string }) => {
  return (
    <div
      className={clsx(
        'relative flex flex-col border-l border-black text-xs',
        width
      )}
    >
      {children}
    </div>
  )
}
