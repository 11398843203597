import { clsx } from 'clsx'

import { useAppSelector } from 'hooks'

const NotificationBar = (): JSX.Element | null => {
  const notifications = useAppSelector((state) => state.notifications)

  if (notifications.message === '') {
    return null
  }

  return (
    <div
      className={clsx('fixed left-0 right-0 top-16 z-50 p-4', {
        'bg-[#d4edda]': notifications.level === 'success',
        'bg-[#f8d7da]': notifications.level === 'error',
      })}
    >
      {notifications.message || 'Success! This is a test message.'}
    </div>
  )
}

export default NotificationBar
