import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: {
    link?: string
    text: string
  }[]
}): JSX.Element => {
  return (
    <div className="space-x-2 text-xs uppercase text-grey">
      {breadcrumbs.map(({ link, text }, i) => {
        let breadcrumbEl = <span>{text}</span>

        if (link) {
          breadcrumbEl = (
            <Link className="text-grey hover:underline" to={link}>
              {text}
            </Link>
          )
        }

        return (
          <Fragment key={i}>
            {i > 0 && <span>{'>'}</span>}
            {breadcrumbEl}
          </Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
