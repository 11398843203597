import { get, post } from './baseAPI'
import { ProductionCycle } from 'types/combinedAPI/domainModels'

export interface CreatePickOrders {
  facilityNetworkID: string
  productionTerm: string
}

export type CreatePickOrdersResponse = null

export interface CreateShortPickOrderPurchasedGood {
  data: {
    baseSku: string
    cycle: ProductionCycle
    facilityID: string
    facilityNetworkID: string
    pounds: number
    termID: string
  }
}

export type CreateShortPickOrderPurchasedGoodResponse = string

export interface GetPickOrders {
  facilityNetworkID: string
  productionTerm: string
}

export interface GetPickOrdersResponse {
  [facilityID: string]: string[]
}

export const ENDPOINTS = {
  CREATE_PICK_ORDERS: ({
    facilityNetworkID,
    productionTerm,
  }: CreatePickOrders) => ({
    path: `/facilityNetworks/${facilityNetworkID}/terms/${productionTerm}/pickOrders`,
    version: 'v0' as const,
  }),
  CREATE_SHORT_PICK_ORDER_PURCHASED_GOOD: () => ({
    path: '/pickOrders/shortPurchasedGood',
    version: 'v0' as const,
  }),
  GET_PICK_ORDERS: ({ facilityNetworkID, productionTerm }: GetPickOrders) => ({
    path: `/facilityNetworks/${facilityNetworkID}/terms/${productionTerm}/pickOrders`,
    version: 'v0' as const,
  }),
}

export function createPickOrders(
  opts: CreatePickOrders
): Promise<CreatePickOrdersResponse> {
  return post(ENDPOINTS.CREATE_PICK_ORDERS(opts))
}

export async function createShortPickOrderPurchasedGood({
  data,
}: CreateShortPickOrderPurchasedGood): Promise<CreateShortPickOrderPurchasedGoodResponse> {
  return post({
    data: { ...data, cycle: Number(data.cycle), termID: Number(data.termID) },
    ...ENDPOINTS.CREATE_SHORT_PICK_ORDER_PURCHASED_GOOD(),
  })
}

export async function getPickOrders(
  opts: GetPickOrders
): Promise<GetPickOrdersResponse> {
  return get(ENDPOINTS.GET_PICK_ORDERS(opts))
}
