import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import jwtDecode from 'jwt-decode'

import { COOKIE_NAMES, getCookie, removeCookie, setCookie } from 'utils/storage'
import { JWTPayload } from 'types/combinedAPI/domainModels'
import { identifyUser } from 'utils/analytics'
import { isDateBefore } from 'utils/dates'
import { reset as notificationsReset } from 'slices/notifications'
import { reset as productionReset } from 'slices/production'

import { useAppDispatch } from 'hooks'

interface Auth {
  isLoggedIn: boolean
  onJWTChanged(newJWT: string | null): void
  userInfo: JWTPayload | null
}

const AuthContext = createContext<Auth | undefined>(undefined)

const UNAUTHORIZED_EVENT_NAME = 'misevala:unauthorized'

export function dispatchUnauthorizedEvent() {
  const unauthorizedEvent = new Event(UNAUTHORIZED_EVENT_NAME)
  document.dispatchEvent(unauthorizedEvent)
}

function getJWTExpirationDate(decodedJWT: JWTPayload) {
  return new Date(decodedJWT.exp * 1000)
}

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const dispatch = useAppDispatch()

  const [jwt, setJwt] = useState<string | null>(() => {
    const jwt = getCookie(COOKIE_NAMES.JWT_TOKEN) ?? null
    if (!jwt) {
      return null
    }

    const decodedJWT = jwtDecode<JWTPayload>(jwt)
    const isExpired = isDateBefore(getJWTExpirationDate(decodedJWT), new Date())

    return isExpired ? null : jwt
  })
  const [userInfo, setUserInfo] = useState<JWTPayload | null>(() => {
    return jwt ? jwtDecode<JWTPayload>(jwt) : null
  })

  useEffect(() => {
    if (jwt) {
      const decodedJWT = jwtDecode<JWTPayload>(jwt)

      setCookie({
        cookieName: COOKIE_NAMES.JWT_TOKEN,
        content: jwt,
        expires: getJWTExpirationDate(decodedJWT),
      })

      setUserInfo(decodedJWT)

      identifyUser({ decodedJWT })
    } else {
      removeCookie(COOKIE_NAMES.JWT_TOKEN)

      setUserInfo(null)

      dispatch(notificationsReset())
      dispatch(productionReset())
    }
  }, [jwt, dispatch])

  useEffect(() => {
    function handleUnauthorizedEvent() {
      setJwt(null)
    }

    document.addEventListener(UNAUTHORIZED_EVENT_NAME, handleUnauthorizedEvent)

    return () => {
      document.removeEventListener(
        UNAUTHORIZED_EVENT_NAME,
        handleUnauthorizedEvent
      )
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!jwt,
        onJWTChanged: (newJWT: string | null) => {
          setJwt(newJWT)
        },
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used in an AuthProvider')
  }

  return context
}

function useIsReadOnly(scope?: 'beta' | 'mod' | 'procurement' | 'qa'): boolean {
  const { userInfo } = useAuth()

  if (!userInfo) {
    return true
  }

  const { adminScope } = userInfo
  if (!adminScope || adminScope.length === 0) {
    return true
  }

  if (adminScope.includes('MISEVALA_PLANTMANAGER')) {
    return false
  }

  if (scope === 'beta' && adminScope.includes('MISEVALA_BETA')) {
    return false
  }

  if (scope === 'qa' && adminScope.includes('MISEVALA_QA')) {
    return false
  }

  if (scope === 'mod' && adminScope.includes('MISEVALA_MOD')) {
    return false
  }

  if (scope === 'procurement' && adminScope.includes('MISEVALA_PROCUREMENT')) {
    return false
  }

  return true
}

export { AuthProvider, useAuth, useIsReadOnly }
