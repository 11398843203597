import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  GetLineHauls,
  getLineHauls,
  GetLineHaulsResponse,
} from 'services/combinedAPI/lineHauls'
import { setLineHauls } from 'slices/production'

import { useAppDispatch } from 'hooks'

export function useInvalidateLineHauls() {
  const queryClient = useQueryClient()

  return {
    invalidateLineHauls: ({
      facilityNetworkID,
      productionTerm,
    }: {
      facilityNetworkID: string
      productionTerm: string
    }) => {
      queryClient.invalidateQueries([
        'line-hauls',
        facilityNetworkID,
        productionTerm,
      ])
    },
  }
}

export function useLineHauls({
  facilityNetworkID,
  productionTerm,
  ...rest
}: GetLineHauls &
  Omit<
    UseQueryOptions<GetLineHaulsResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'refetchOnWindowFocus'
  >) {
  const dispatch = useAppDispatch()

  return useQuery<GetLineHaulsResponse, Error>({
    ...rest,
    enabled: !!(facilityNetworkID && productionTerm),
    onError: (...args) => {
      console.log(args[0])

      rest?.onError?.(...args)
    },
    onSuccess: (...args) => {
      dispatch(setLineHauls(args[0]))

      rest?.onSuccess?.(...args)
    },
    queryFn: () => {
      return getLineHauls({ facilityNetworkID, productionTerm })
    },
    queryKey: ['line-hauls', facilityNetworkID, productionTerm],
    refetchOnWindowFocus: false,
  })
}
