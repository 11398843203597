import { find } from 'lodash-es'

import {
  Tag,
  TagCategories,
  TagCategoriesToTag,
} from 'types/combinedAPI/domainModels'

type TagCategoriesToTagKeys = keyof TagCategoriesToTag
type CategoryTag<Category> = Category extends TagCategoriesToTagKeys
  ? TagCategoriesToTag[Category] | undefined
  : never
type CategoryTagTitle<Category> = Category extends TagCategoriesToTagKeys
  ? TagCategoriesToTag[Category]['title'] | ''
  : never
type ArrayOfTags<Categories extends TagCategoriesToTagKeys[]> = {
  [Index in keyof Categories]: CategoryTag<Categories[Index]>
}
type ArrayOfTitles<Categories extends TagCategoriesToTagKeys[]> = {
  [Index in keyof Categories]: CategoryTagTitle<Categories[Index]>
}

export function getTagByCategory<Category extends keyof TagCategories>(
  tags: Tag[],
  category: Category
): TagCategoriesToTag[Category] | undefined {
  return find(tags, { category }) as TagCategoriesToTag[Category] | undefined
}

export function getTagsByCategories<
  Categories extends TagCategoriesToTagKeys[]
>(tags: Tag[], categories: [...Categories]): ArrayOfTags<Categories> {
  const tagsByCategory: { [key: string]: Tag } = {}
  tags.forEach((tag) => {
    tagsByCategory[tag.category] = tag
  })

  return categories.map((category) => {
    return tagsByCategory[category]
  }) as ArrayOfTags<Categories>
}

export function getTitlesByCategories<
  Categories extends TagCategoriesToTagKeys[]
>(tags: Tag[], categories: [...Categories]): ArrayOfTitles<Categories> {
  const tagsByCategory: { [key: string]: Tag } = {}
  tags.forEach((tag) => {
    tagsByCategory[tag.category] = tag
  })

  return categories.map((category) => {
    return tagsByCategory[category]?.title ?? ''
  }) as ArrayOfTitles<Categories>
}
