import { ComposedMealBOMItem, ComposedMealWithCycle } from 'types/internal'
import {
  FacilityNetwork,
  Meal,
  MealBOMItem,
} from 'types/combinedAPI/domainModels'
import { filterFulfillmentFacilities } from './facility-networks'

export const componentsForMeal = (
  type: 'component' | 'frozen',
  transferComponent: Meal,
  destination: string,
  facilityNetwork: FacilityNetwork
): MealBOMItem[] => {
  const acceptableContainers = [
    'tray 1',
    'tray 1 bag',
    'tray 1 dry sachet',
    'tray 1 sachet',
    'tray 2',
    'tray 2 bag',
    'tray 2 dry sachet',
    'tray 2 sachet',
    'dry sachet',
    'sachet',
    '1 oz cup',
    '2 oz cup',
    '2 oz oval cup',
  ]

  if (type === 'frozen') {
    const fulfillmentFacility = filterFulfillmentFacilities(facilityNetwork)

    return transferComponent.billOfMaterials.filter((bom) => {
      const containerTag = bom.tags.find((t) => t.category === 'container')
      const validContainer =
        containerTag && acceptableContainers.includes(containerTag.title)

      return !!(
        validContainer &&
        bom.tags.find((t) => t.category === 'frozen' && t.title === 'yes') &&
        bom.tags.find(
          (t) =>
            t.category === 'portion_location' &&
            t.title === fulfillmentFacility.notOfType?.title
        )
      )
    })
  } else if (type === 'component') {
    return transferComponent.billOfMaterials.filter((bom) => {
      const frozenTag = bom.tags.find((t) => t.category === 'frozen')
      const containerTag = bom.tags.find((t) => t.category === 'container')
      const validContainer =
        containerTag && acceptableContainers.includes(containerTag.title)
      const portionedAtDiffLocation = bom.tags.find(
        (t) => t.category === 'portion_location' && t.title !== destination
      )

      return !!(
        (!frozenTag || frozenTag.title === 'no') &&
        portionedAtDiffLocation &&
        validContainer
      )
    })
  }

  return []
}

export function transferTagNumbersForComponent(
  meal: ComposedMealWithCycle | Meal,
  component: ComposedMealBOMItem | MealBOMItem
): {
  allTowers: number
  fullTowers: number
  maxBinsPerTower: number
  partialBinUnits: number
  partialTowerBins: number
  totalBins: number
  totalFullBins: number
} {
  let denominator = 32
  let maxBinsPerTower = 10
  const containerTag = component.tags.find((t) => t.category === 'container')
  if (containerTag && containerTag.title === 'sachet') {
    denominator = 400
    maxBinsPerTower = 5
  }

  if (containerTag && containerTag.title === 'dry sachet') {
    denominator = 300
    maxBinsPerTower = 5
  }

  if (containerTag && containerTag.title.includes('cup')) {
    denominator = 128
  }

  const totalBins = Math.ceil(meal.totalMeals / denominator)
  const totalFullBins = Math.floor(meal.totalMeals / denominator)
  const partialBinUnits = meal.totalMeals % denominator
  const allTowers = Math.ceil(totalBins / maxBinsPerTower)
  const partialTowerBins = totalBins % maxBinsPerTower
  const fullTowers = Math.floor(totalBins / maxBinsPerTower)

  return {
    allTowers,
    fullTowers,
    maxBinsPerTower,
    partialBinUnits,
    partialTowerBins,
    totalBins,
    totalFullBins,
  }
}

export function transferTagNumbersForMeal(meal: { totalMeals: number }): {
  allTowers: number
  fullTowers: number
  partialBinUnits: number
  partialTowerBins: number
  totalBins: number
  totalFullBins: number
} {
  const totalBins = Math.ceil(meal.totalMeals / 16)
  const totalFullBins = Math.floor(meal.totalMeals / 16)
  const partialBinUnits = meal.totalMeals % 16
  const allTowers = Math.ceil(totalBins / 10)
  const partialTowerBins = totalBins % 10
  const fullTowers = Math.floor(totalBins / 10)

  return {
    allTowers,
    fullTowers,
    partialBinUnits,
    partialTowerBins,
    totalBins,
    totalFullBins,
  }
}
