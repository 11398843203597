import { PDFViewer } from '@react-pdf/renderer'
import { useState } from 'react'

import {
  FacilityNetwork,
  Meal,
  ProductionCycle,
  ProductionRecord,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { filterFulfillmentFacilities } from 'utils/facility-networks'
import { getTagsByCategories } from 'utils/tags'

import Button from 'components/common/bs/Button'
import ComponentDocumentPDF from './ComponentDocumentPDF'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import TagTable from './TagTable'
import TransferTagDocumentPDF from './TransferTagDocumentPDF'

type PDFType =
  | 'transfer-to-fulfillment-components'
  | 'transfer-to-fulfillment-frozen'
  | 'transfer-to-fulfillment-sleeved-meals'
  | 'transfer-to-nonfulfillment-components'

const ShippingDocuments = ({
  facilityNetwork,
  productionCycle,
  productionRecord,
  productionTerm,
}: {
  facilityNetwork: FacilityNetwork
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord | ''
  productionTerm: string
}) => {
  const [allSelected, setAllSelected] = useState(true)
  const [selectedMeals, setSelectedMeals] = useState(() => {
    if (productionRecord) {
      return productionRecord.cycles[productionCycle].meals.map(
        (m) => m.mealCode
      )
    }

    return []
  })
  const [showLoadingText, setShowLoadingText] = useState(false)
  const [showPdf, setShowPdf] = useState<PDFType | false>(false)

  function _toggleSelectAll() {
    setAllSelected((allSelected) => !allSelected)
    setSelectedMeals(() => {
      if (allSelected || !productionRecord) {
        return []
      }

      return productionRecord.cycles[productionCycle].meals.map(
        (m) => m.mealCode
      )
    })
  }

  function _selectMeal(meal: Meal) {
    setAllSelected(false)
    setSelectedMeals((prevSelectedMeals) => {
      const newSelectedMeals = [...prevSelectedMeals]
      if (newSelectedMeals.includes(meal.mealCode)) {
        const index = newSelectedMeals.indexOf(meal.mealCode)
        newSelectedMeals.splice(index, 1)
      } else {
        newSelectedMeals.push(meal.mealCode)
      }

      return newSelectedMeals
    })
  }

  function _showPdf(type: PDFType) {
    setShowLoadingText(true)

    setTimeout(() => {
      setShowPdf(type)
    }, 300)
  }

  const filteredFulfillmentFacilities =
    filterFulfillmentFacilities(facilityNetwork)
  const fulfillmentFacility = filteredFulfillmentFacilities.ofType
  const nonFulfillmentFacility = filteredFulfillmentFacilities.notOfType

  let allMeals: Meal[] = []
  let toNonFulfillmentSleevedMeals: Meal[] = []
  let toFulfillmentSleevedMeals: Meal[] = []

  let toFulfillmentMealsForDoc: Meal[] = []
  let toFulfillmentMealsWithFrozenComponents: Meal[] = []
  let toFulfillmentMealsWithFrozenComponentsForDoc: Meal[] = []
  let toFulfillmentMealsWithTransferComponents: Meal[] = []
  let toFulfillmentMealsWithTransferComponentsForDoc: Meal[] = []

  let toNonFulfillmentMealsWithTransferComponents: Meal[] = []
  let toNonFulfillmentMealsWithTransferComponentsForDoc: Meal[] = []
  const acceptableContainers: TagCategories['container'][] = [
    'tray 1',
    'tray 1 bag',
    'tray 1 clamshell',
    'tray 1 dry sachet',
    'tray 1 sachet',
    'tray 2',
    'tray 2 bag',
    'tray 2 clamshell',
    'tray 2 dry sachet',
    'tray 2 sachet',
    'dry sachet',
    'sachet',
    '1 oz cup',
    '2 oz cup',
    '2 oz oval cup',
  ]
  if (productionRecord) {
    allMeals = productionRecord.cycles[productionCycle].meals
    toNonFulfillmentSleevedMeals = allMeals.filter(
      (m) =>
        !!m.tags.find(
          (t) =>
            t.category === 'sleeving_location' &&
            t.title === nonFulfillmentFacility?.title
        )
    )
    toFulfillmentSleevedMeals = allMeals.filter(
      (m) =>
        !!m.tags.find(
          (t) =>
            t.category === 'sleeving_location' &&
            t.title === fulfillmentFacility?.title
        )
    )

    toFulfillmentMealsWithFrozenComponents = allMeals.filter((meal) => {
      return !!meal.billOfMaterials.find((bom) => {
        return (
          bom.tags.find((t) => t.category === 'frozen' && t.title === 'yes') &&
          bom.tags.find(
            (t) =>
              t.category === 'portion_location' &&
              t.title === nonFulfillmentFacility?.title
          )
        )
      })
    })

    toFulfillmentMealsWithTransferComponents = toFulfillmentSleevedMeals.filter(
      (meal) => {
        return !!meal.billOfMaterials.find((bom) => {
          const portionedAtNonFulfillmentFacility = bom.tags.find(
            (t) =>
              t.category === 'portion_location' &&
              t.title === nonFulfillmentFacility?.title
          )
          const [frozenTag, containerTag] = getTagsByCategories(bom.tags, [
            'frozen',
            'container',
          ])

          const validContainer = !!(
            containerTag && acceptableContainers.includes(containerTag.title)
          )

          return !!(
            validContainer &&
            portionedAtNonFulfillmentFacility &&
            (!frozenTag || frozenTag.title === 'no')
          )
        })
      }
    )

    // ////////////////// FOR DOCUMENT ////////////////////////////
    // //////// Filters out selected meal codes ///////////////////////////
    toFulfillmentMealsForDoc = toNonFulfillmentSleevedMeals.filter((m) =>
      selectedMeals.includes(m.mealCode)
    )
    toFulfillmentMealsWithFrozenComponentsForDoc =
      toFulfillmentMealsWithFrozenComponents.filter((m) =>
        selectedMeals.includes(m.mealCode)
      )
    toFulfillmentMealsWithTransferComponentsForDoc =
      toFulfillmentMealsWithTransferComponents.filter((m) =>
        selectedMeals.includes(m.mealCode)
      )
    // //////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////

    toNonFulfillmentMealsWithTransferComponents =
      toNonFulfillmentSleevedMeals.filter((meal) => {
        return !!meal.billOfMaterials.find((bom) => {
          const portionedAtFulfillmentFacility = bom.tags.find(
            (t) =>
              t.category === 'portion_location' &&
              t.title === fulfillmentFacility?.title
          )
          const [frozenTag, containerTag] = getTagsByCategories(bom.tags, [
            'frozen',
            'container',
          ])

          const validContainer = !!(
            containerTag && acceptableContainers.includes(containerTag.title)
          )

          return (
            validContainer &&
            portionedAtFulfillmentFacility &&
            (!frozenTag || frozenTag.title === 'no')
          )
        })
      })

    toNonFulfillmentMealsWithTransferComponentsForDoc =
      toNonFulfillmentMealsWithTransferComponents.filter((m) =>
        selectedMeals.includes(m.mealCode)
      )
  }

  if (showPdf === 'transfer-to-fulfillment-sleeved-meals') {
    return (
      <div>
        <button
          className="my-3 text-blue hover:underline"
          onClick={() => {
            setShowPdf(false)
            setShowLoadingText(false)
          }}
        >
          Back to Table
        </button>
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <TransferTagDocumentPDF
            destinationFacility={fulfillmentFacility}
            productionCycle={productionCycle}
            term={productionTerm}
            transferComponents={toFulfillmentMealsForDoc}
          />
        </PDFViewer>
      </div>
    )
  }

  if (showPdf === 'transfer-to-fulfillment-frozen') {
    return (
      <div>
        <button
          className="my-3 text-blue hover:underline"
          onClick={() => {
            setShowPdf(false)
            setShowLoadingText(false)
          }}
        >
          Back to Table
        </button>
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <ComponentDocumentPDF
            destinationFacility={fulfillmentFacility}
            facilityNetwork={facilityNetwork}
            productionCycle={productionCycle}
            term={productionTerm}
            transferComponents={toFulfillmentMealsWithFrozenComponentsForDoc}
            type="frozen"
          />
        </PDFViewer>
      </div>
    )
  }

  if (showPdf === 'transfer-to-fulfillment-components') {
    return (
      <div>
        <button
          className="my-3 text-blue hover:underline"
          onClick={() => {
            setShowPdf(false)
            setShowLoadingText(false)
          }}
        >
          Back to Table
        </button>
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <ComponentDocumentPDF
            destinationFacility={fulfillmentFacility}
            facilityNetwork={facilityNetwork}
            productionCycle={productionCycle}
            term={productionTerm}
            transferComponents={toFulfillmentMealsWithTransferComponentsForDoc}
            type="component"
          />
        </PDFViewer>
      </div>
    )
  }

  if (showPdf === 'transfer-to-nonfulfillment-components') {
    return (
      <div>
        <button
          className="my-3 text-blue hover:underline"
          onClick={() => {
            setShowPdf(false)
            setShowLoadingText(false)
          }}
        >
          Back to Table
        </button>
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <ComponentDocumentPDF
            destinationFacility={nonFulfillmentFacility}
            facilityNetwork={facilityNetwork}
            productionCycle={productionCycle}
            term={productionTerm}
            transferComponents={
              toNonFulfillmentMealsWithTransferComponentsForDoc
            }
            type="component"
          />
        </PDFViewer>
      </div>
    )
  }

  return (
    <TabGroup>
      <div className="flex space-x-8 pt-10">
        <div className="shrink-0 xl:shrink">
          <TabList tabStyle="pills">
            <div className="flex flex-col">
              <Tab tabStyle="pills">
                {nonFulfillmentFacility?.displayName} to{' '}
                {fulfillmentFacility?.displayName}: Sleeved Meals
              </Tab>
              <Tab tabStyle="pills">
                {nonFulfillmentFacility?.displayName} to{' '}
                {fulfillmentFacility?.displayName}: Frozen Components
              </Tab>
              <Tab tabStyle="pills">
                {nonFulfillmentFacility?.displayName} to{' '}
                {fulfillmentFacility?.displayName}: Meal Components
              </Tab>
              <Tab tabStyle="pills">
                {fulfillmentFacility?.displayName} to{' '}
                {nonFulfillmentFacility?.displayName}: Meal Components
              </Tab>
            </div>
          </TabList>
        </div>
        <div className="grow">
          <TabPanels>
            <TabPanel>
              <div>
                <Button
                  buttonStyle="stroke"
                  disabled={showLoadingText}
                  onClick={() =>
                    _showPdf('transfer-to-fulfillment-sleeved-meals')
                  }
                >
                  {showLoadingText ? 'Loading PDF' : 'Print Transfer Tags'}
                </Button>
              </div>
              <TagTable
                allSelected={allSelected}
                destination={fulfillmentFacility?.title ?? ''}
                facilityNetwork={facilityNetwork}
                selectMeal={_selectMeal}
                selectedMeals={selectedMeals}
                toggleSelectAll={_toggleSelectAll}
                transferComponents={toNonFulfillmentSleevedMeals}
                type="meal"
              />
            </TabPanel>
            <TabPanel>
              <div>
                <Button
                  buttonStyle="stroke"
                  disabled={showLoadingText}
                  onClick={() => _showPdf('transfer-to-fulfillment-frozen')}
                >
                  {showLoadingText ? 'Loading PDF' : 'Print Transfer Tags'}
                </Button>
              </div>
              <TagTable
                allSelected={allSelected}
                destination={fulfillmentFacility?.title ?? ''}
                facilityNetwork={facilityNetwork}
                selectMeal={_selectMeal}
                selectedMeals={selectedMeals}
                toggleSelectAll={_toggleSelectAll}
                transferComponents={toFulfillmentMealsWithFrozenComponents}
                type="frozen"
              />
            </TabPanel>
            <TabPanel>
              <div>
                <Button
                  buttonStyle="stroke"
                  disabled={showLoadingText}
                  onClick={() => _showPdf('transfer-to-fulfillment-components')}
                >
                  {showLoadingText ? 'Loading PDF' : 'Print Transfer Tags'}
                </Button>
              </div>
              <TagTable
                allSelected={allSelected}
                destination={fulfillmentFacility?.title ?? ''}
                facilityNetwork={facilityNetwork}
                selectMeal={_selectMeal}
                selectedMeals={selectedMeals}
                toggleSelectAll={_toggleSelectAll}
                transferComponents={toFulfillmentMealsWithTransferComponents}
                type="component"
              />
            </TabPanel>
            <TabPanel>
              <div>
                <Button
                  buttonStyle="stroke"
                  disabled={showLoadingText}
                  onClick={() =>
                    _showPdf('transfer-to-nonfulfillment-components')
                  }
                >
                  {showLoadingText ? 'Loading PDF' : 'Print Transfer Tags'}
                </Button>
              </div>
              <TagTable
                allSelected={allSelected}
                destination={nonFulfillmentFacility?.title ?? ''}
                facilityNetwork={facilityNetwork}
                selectMeal={_selectMeal}
                selectedMeals={selectedMeals}
                toggleSelectAll={_toggleSelectAll}
                transferComponents={toNonFulfillmentMealsWithTransferComponents}
                type="component"
              />
            </TabPanel>
          </TabPanels>
        </div>
      </div>
    </TabGroup>
  )
}

export default ShippingDocuments
