import { TagCategories } from 'types/combinedAPI/domainModels'

import { useFloating } from 'hooks/floating'
import Button from 'components/common/bs/Button'
import ChevronDownIcon from 'components/common/icons/ChevronDownIcon'
import DropdownItem from './DropdownItem'
import Menu, { MenuButton, MenuItems } from 'components/common/Menu'

const DropdownPrepDay = ({
  disabled = false,
  onChange,
  prepDay,
}: {
  disabled?: boolean
  onChange(day: TagCategories['day_of_week']): void
  prepDay: TagCategories['day_of_week'] | ''
}): JSX.Element => {
  const { floating, reference, strategy, x, y } = useFloating()

  return (
    <div className="flex items-center space-x-2">
      <span className="font-bold">Prep Day</span>
      <Menu>
        <MenuButton ref={reference}>
          <Button buttonStyle="light-grey" disabled={disabled}>
            <span className="flex items-center">
              {prepDay || 'Select Day'}
              <div className="h-4 w-4">
                <ChevronDownIcon />
              </div>
            </span>
          </Button>
        </MenuButton>

        <MenuItems
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
        >
          <DropdownItem
            onClick={() => {
              onChange('monday')
            }}
          >
            Monday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('tuesday')
            }}
          >
            Tuesday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('wednesday')
            }}
          >
            Wednesday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('thursday')
            }}
          >
            Thursday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('friday')
            }}
          >
            Friday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('saturday')
            }}
          >
            Saturday
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              onChange('sunday')
            }}
          >
            Sunday
          </DropdownItem>
        </MenuItems>
      </Menu>
    </div>
  )
}

export default DropdownPrepDay
