import { qaParts } from 'utils/qa'

import { useAppSelector } from 'hooks'
import H3 from 'components/common/bs/H3'
import SensoryTableHeader from './SensoryTableHeader'
import SensoryTableRow from 'components/production/qa/sensory/SensoryTableRow'
import Table, { TBody } from 'components/common/Table'

const SensoryTracking = (): JSX.Element => {
  const productionRecord = useAppSelector(
    (state) => state.production.productionRecord
  )
  const productionCycle = useAppSelector(
    (state) => state.production.productionCycle
  )
  const sensoryParts = productionRecord
    ? qaParts('sensory', productionRecord)
    : null

  return (
    <>
      <div className="flex py-5 print:hidden">
        <H3>Sensory Tracking</H3>
      </div>
      <Table>
        <SensoryTableHeader />
        {sensoryParts && (
          <TBody>
            {sensoryParts.cycles[productionCycle].map((part, i) => {
              if (productionCycle === '2' && part.combined) {
                return null
              }

              return (
                <SensoryTableRow
                  key={i}
                  part={part}
                  productionCycle={productionCycle}
                />
              )
            })}
          </TBody>
        )}
      </Table>
    </>
  )
}

export default SensoryTracking
