import { get } from './baseAPI'
import { Vendor } from 'types/combinedAPI/domainModels'

export type GetVendorsResponse = Vendor[]

export const ENDPOINTS = {
  GET_VENDORS: () => ({
    path: '/vendors',
    version: 'v0' as const,
  }),
}

export async function getVendors(): Promise<GetVendorsResponse> {
  const response = await get<{ data: Vendor[] }>(ENDPOINTS.GET_VENDORS())

  return response.data
}
