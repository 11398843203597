import { GarnishContainer, TagCategories } from 'types/combinedAPI/domainModels'

export const GARNISH_CONTAINERS_OBJECT: Record<
  GarnishContainer,
  { colorForContainer: string }
> = {
  '1 oz cup': {
    colorForContainer: '(204, 198, 0)',
  },
  '2 oz cup': {
    colorForContainer: '(204, 198, 0)',
  },
  '2 oz oval cup': {
    colorForContainer: '(204, 198, 0)',
  },
  bag: {
    colorForContainer: '(144, 24, 24)',
  },
  'dry sachet': {
    colorForContainer: '(234, 209, 220)',
  },
  'pre-portioned': {
    colorForContainer: '(245, 195, 194)',
  },
  sachet: {
    colorForContainer: '(137, 207, 240)',
  },
  'veggie bags': {
    colorForContainer: '(154, 183, 211)',
  },
  'mushroom bags': {
    colorForContainer: '(199, 221, 170)',
  },
  '9x14 bag': {
    colorForContainer: '(246, 234, 194)',
  },
  '14x16 bag': {
    colorForContainer: '(85, 203, 205)',
  },
  'unsealed body armor': {
    colorForContainer: '(210, 204, 241)',
  },
  'sealed body armor': {
    colorForContainer: '(255, 204, 241)',
  },
  'bagged meal extra': {
    colorForContainer: '(218, 160, 241)',
  },
}

export const GARNISH_CONTAINERS: GarnishContainer[] = [
  '1 oz cup',
  '2 oz cup',
  '2 oz oval cup',
  'bag',
  'dry sachet',
  'pre-portioned',
  'sachet',
  'veggie bags',
  'mushroom bags',
  '9x14 bag',
  '14x16 bag',
  'unsealed body armor',
  'sealed body armor',
  'bagged meal extra',
]

export const COLORS_FOR_PORTION_DATE: Record<
  TagCategories['portion_date'],
  string
> = {
  monday: '#ead1dc',
  tuesday: '#d9d2e9',
  wednesday: '#d0e2f4',
  thursday: '#d9ead3',
  friday: '#fce5cd',
  saturday: '',
  sunday: '',
  monday2: '',
}
