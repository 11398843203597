import CSVReader from 'react-csv-reader'
import { difference, keys, sortBy } from 'lodash-es'
import { PDFViewer } from '@react-pdf/renderer'
import { useState } from 'react'

import { getCarrierName } from 'utils/lineHauls'
import { LineHaul, ProductionCycle } from 'types/combinedAPI/domainModels'
import { parseCarriers } from './parse-carriers'
import { ShippingCarrierCSVRow } from 'types/internal'

import Button from 'components/common/bs/Button'
import ErrorDisplay from 'components/common/ErrorDisplay'
import ExpectedLineHauls from './ExpectedLineHauls'
import H4 from 'components/common/bs/H4'
import PalletSheetPDF from './PalletSheetPDF'
import SummaryTable from './SummaryTable'

const PalletSheets = ({
  lineHauls,
  productionCycle,
}: {
  lineHauls: LineHaul[]
  productionCycle: ProductionCycle
}): JSX.Element => {
  const [csvRowsByCarrier, setCSVRowsByCarrier] = useState<Record<
    string,
    ShippingCarrierCSVRow[]
  > | null>(null)
  const [selectedCSVCarrierName, setSelectedCSVCarrierName] = useState('')

  const lineHaulsForCycle = lineHauls.filter((lineHaul) => {
    return `${lineHaul.cycle}` === productionCycle
  })
  const orderedLineHauls = sortBy(lineHaulsForCycle, 'packoutPosition')

  const expectedCarrierNames = lineHaulsForCycle.map((lineHaul) =>
    getCarrierName(lineHaul)
  )
  const carrierNamesFromCSV = keys(csvRowsByCarrier)
  const missingCarrierNamesFromCSV = difference(
    expectedCarrierNames,
    carrierNamesFromCSV
  )

  const selectedCSVCarrierData = csvRowsByCarrier?.[selectedCSVCarrierName]
  const selectedLineHaul = lineHaulsForCycle.find(
    (haul) => getCarrierName(haul) === selectedCSVCarrierName
  )

  return (
    <div className="pt-10">
      <ExpectedLineHauls
        lineHauls={orderedLineHauls}
        productionCycle={productionCycle}
      />

      <div className="mt-5 flex space-x-8">
        <div
          className="w-1/4"
          style={{ height: `${window.innerHeight - 56}px` }}
        >
          <H4>Upload Pallet Sheet CSV</H4>
          <CSVReader
            onFileLoaded={(data) => {
              setCSVRowsByCarrier(parseCarriers(data))
            }}
            parserOptions={{
              dynamicTyping: true,
              header: true,
              skipEmptyLines: true,
            }}
          />

          {csvRowsByCarrier && missingCarrierNamesFromCSV.length > 0 && (
            <div className="my-5">
              <ErrorDisplay>
                The following expected carriers were not found in the CSV:
                <ul className="list-disc pl-8">
                  {missingCarrierNamesFromCSV.map((carrier) => {
                    return <li key={carrier}>{carrier}</li>
                  })}
                </ul>
              </ErrorDisplay>
            </div>
          )}

          {csvRowsByCarrier && (
            <>
              {selectedCSVCarrierName ? (
                <Button
                  buttonStyle="light-grey"
                  isFull
                  onClick={() => {
                    setSelectedCSVCarrierName('')
                  }}
                >
                  Back to Summary
                </Button>
              ) : (
                sortBy(carrierNamesFromCSV).map((carrierName) => {
                  const isExpectedCarrier =
                    expectedCarrierNames.includes(carrierName)

                  return (
                    <div key={carrierName} className="mb-5">
                      <Button
                        buttonStyle="black"
                        disabled={!isExpectedCarrier}
                        isFull
                        onClick={() => {
                          setSelectedCSVCarrierName(carrierName)
                        }}
                      >
                        View {carrierName}
                      </Button>
                      {!isExpectedCarrier && (
                        <ErrorDisplay>
                          {carrierName} was not an expected carrier
                        </ErrorDisplay>
                      )}
                    </div>
                  )
                })
              )}
            </>
          )}
        </div>

        <div
          className="w-3/4"
          style={{ height: `${window.innerHeight - 56}px` }}
        >
          <PalletSheetDetails
            csvRowsByCarrier={csvRowsByCarrier}
            selectedCSVCarrierData={selectedCSVCarrierData}
            selectedCSVCarrierName={selectedCSVCarrierName}
            selectedLineHaul={selectedLineHaul}
          />
        </div>
      </div>
    </div>
  )
}

export default PalletSheets

const PalletSheetDetails = ({
  csvRowsByCarrier,
  selectedCSVCarrierData,
  selectedCSVCarrierName,
  selectedLineHaul,
}: {
  csvRowsByCarrier: Record<string, ShippingCarrierCSVRow[]> | null
  selectedCSVCarrierData: ShippingCarrierCSVRow[] | undefined
  selectedCSVCarrierName: string
  selectedLineHaul: LineHaul | undefined
}): JSX.Element | null => {
  if (selectedCSVCarrierData) {
    if (selectedLineHaul) {
      return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <PalletSheetPDF
            carrierData={selectedCSVCarrierData}
            selectedLineHaul={selectedLineHaul}
          />
        </PDFViewer>
      )
    }

    return (
      <ErrorDisplay>
        Could not find line haul data for the selected carrier:{' '}
        {selectedCSVCarrierName}
      </ErrorDisplay>
    )
  }

  if (csvRowsByCarrier) {
    return <SummaryTable key="carriers-summary" carriers={csvRowsByCarrier} />
  }

  return null
}
