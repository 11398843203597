import { lazy, Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { analytics } from 'utils/analytics'

import AuthWrapper from './AuthWrapper'
import DashboardPage from 'components/dashboard/DashboardPage'
import { FiltersProvider } from 'contexts/filters'
import Header from './components/common/Header'
import ListPurchaseOrdersPage, {
  LIST_PURCHASE_ORDERS_FILTER_KEYS,
} from 'components/purchaseOrders/ListPurchaseOrdersPage'
import NotificationBar from './components/common/NotificationBar'
import ProductionTermPage from 'components/production/ProductionTermPage'
import PurchaseOrderPage from 'components/purchaseOrders/PurchaseOrderPage'
import SavePurchaseOrderPage from 'components/purchaseOrders/SavePurchaseOrderPage'
import StockLabelsPage from 'components/inventory/StockLabelsPage'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  const location = useLocation()

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  useEffect(() => {
    analytics.page()
  }, [location.pathname])

  return (
    <div>
      <Header />
      <NotificationBar />
      <main className="mt-header">
        <AuthWrapper>
          <Routes>
            <Route element={<ProductionTermPage />} path="terms" />

            <Route
              element={<ProductionTermPage />}
              path="productionRecords/parts/:partId"
            />
            <Route element={<ProductionTermPage />} path="productionRecords" />

            {/* Purchase Orders */}
            <Route
              element={
                <FiltersProvider
                  filterKeys={LIST_PURCHASE_ORDERS_FILTER_KEYS}
                  filterPathname="/purchaseOrders"
                />
              }
              path="purchaseOrders"
            >
              <Route element={<ListPurchaseOrdersPage />} index />
              <Route element={<PurchaseOrderPage />} path=":id" />
              <Route element={<SavePurchaseOrderPage />} path="create" />
              <Route element={<SavePurchaseOrderPage />} path=":id/edit" />
            </Route>

            {/* Inventory */}
            <Route element={<StockLabelsPage />} path="stockLabels" />

            <Route element={<DashboardPage />} path="/" />
          </Routes>
        </AuthWrapper>
      </main>

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  )
}

export default App
