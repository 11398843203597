import { useEffect } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  getFacilityNetworks,
  GetFacilityNetworksResponse,
} from 'services/combinedAPI/facilityNetworks'
import { selectFacilityNetwork } from 'slices/production'

import { useAppDispatch } from 'hooks'

export function useFacilityNetworks(
  omitOffsiteStorage = false,
  opts?: Omit<
    UseQueryOptions<GetFacilityNetworksResponse, Error>,
    'queryFn' | 'queryKey'
  >
) {
  return useQuery<GetFacilityNetworksResponse, Error>({
    ...opts,
    queryKey: ['facilityNetworks', omitOffsiteStorage],
    queryFn: () => {
      return getFacilityNetworks(omitOffsiteStorage)
    },
  })
}

export function useSelectFacilityNetwork(
  facilityNetworkID: string | null,
  omitOffsiteStorage = false
): void {
  const dispatch = useAppDispatch()

  const { data: facilityNetworks = [] } =
    useFacilityNetworks(omitOffsiteStorage)
  const facilityNetwork = facilityNetworks.find(
    ({ id }) => id === facilityNetworkID
  )

  useEffect(() => {
    if (facilityNetwork) {
      dispatch(selectFacilityNetwork(facilityNetwork))
    }
  }, [dispatch, facilityNetwork])
}
