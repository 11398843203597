import { Td, Tr } from 'components/common/Table'
import { ProductionCycle } from 'types/combinedAPI/domainModels'

const WeightCheckTableRow = ({
  part,
  productionCycle,
}: {
  part: {
    combined: boolean
    cookType?: string
    expectedWeight?: number
    mealCodes: string
    partTitle: string
    totalWeight?: number
  }
  productionCycle: ProductionCycle
}): JSX.Element | null => {
  let appendThis = ''

  if (productionCycle === '2') {
    appendThis = '-A'
  }

  if (productionCycle === '2' && part.combined) {
    return null
  }

  return (
    <Tr height="80px" withHover>
      <Td>
        {part.partTitle}
        {appendThis}
      </Td>
      <Td>{part.cookType?.replace('_', ' ')}</Td>
      <Td>{part.totalWeight?.toFixed(2)} lbs</Td>
      <Td>{part.expectedWeight?.toFixed(2)} lbs</Td>
      <Td></Td>
      <Td>{part.mealCodes}</Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
    </Tr>
  )
}

export default WeightCheckTableRow
