import { clsx } from 'clsx'
import { orderBy } from 'lodash-es'
import { parse } from 'papaparse'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'

import { csvParser, getVendorOption } from './helpers'
import { PurchasedGood, Vendor } from 'types/combinedAPI/domainModels'
import { ReactSelectValue } from 'types/internal'

import { usePurchasedGoods } from 'hooks/combinedAPI/purchasedGoods'
import { useVendors } from 'hooks/combinedAPI/vendors'
import Button from 'components/common/Button'
import CircleLoader from 'components/common/CircleLoader'
import DocumentTextIcon from 'components/common/icons/DocumentTextIcon'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import Modal, { ModalBody, ModalHeader } from 'components/common/Modal'
import Select from 'components/common/Select'
import UploadIcon from 'components/common/icons/UploadIcon'

const UploadFromCSV = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const onCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <>
      <div
        className="flex cursor-pointer items-center space-x-2 text-sm text-grey"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <div className="h-4 w-4 cursor-pointer text-grey">
          <UploadIcon />
        </div>
        <span>Upload from CSV</span>
      </div>

      {isOpen && <UploadCSVModal onCloseModal={onCloseModal} />}
    </>
  )
}

export default UploadFromCSV

const UploadCSVModal = ({
  onCloseModal,
}: {
  onCloseModal(): void
}): JSX.Element => {
  const [missingCaseWeights, setMissingCaseWeights] = useState<string[]>([])
  const [missingSKUs, setMissingSKUs] = useState<string[]>([])
  const [vendor, setVendor] = useState<ReactSelectValue<Vendor> | null>(null)

  const {
    data: vendors = [],
    error: loadVendorsError,
    isLoading: isLoadingVendors,
  } = useVendors()

  useEffect(() => {
    if (!vendor) {
      const defaultVendor = orderBy(vendors, 'name').find(
        ({ name }) => name === 'Sysco' || name === 'US Foods'
      )

      setVendor(defaultVendor ? getVendorOption(defaultVendor) : null)
    }
  }, [vendor, vendors])

  // We currently only support a subset of vendors for CSV uploads.
  const vendorOptions = vendors
    .filter(({ name }) => {
      return ['Sysco', 'US Foods'].includes(name)
    })
    .map((vendor) => {
      return getVendorOption(vendor)
    })

  const chosenVendorID = vendor?.value.id

  const {
    data: purchasedGoodsForVendor = [],
    error: loadPurchasedGoodsError,
    isLoading: isLoadingPurchasedGoods,
  } = usePurchasedGoods({ vendorID: chosenVendorID })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-upload-from-csv-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Upload from CSV
            </ModalHeader>
          </div>

          <div className="my-4 space-y-4">
            <FormGroup label="Vendor">
              <Select<ReactSelectValue<Vendor>>
                isLoading={isLoadingVendors}
                loadError={
                  loadVendorsError
                    ? `There was an error loading vendors. Error: ${loadVendorsError?.message}`
                    : undefined
                }
                name="csv-vendor"
                onChange={(newVendor) => {
                  setVendor(newVendor)
                  setMissingCaseWeights([])
                  setMissingSKUs([])
                }}
                options={vendorOptions}
                value={vendor}
              />
            </FormGroup>

            {vendor && (
              <CSVParser
                isLoadingPurchasedGoods={isLoadingPurchasedGoods}
                loadPurchasedGoodsError={loadPurchasedGoodsError}
                onCSVParseSuccess={onCloseModal}
                purchasedGoodsForVendor={purchasedGoodsForVendor}
                setMissingCaseWeights={setMissingCaseWeights}
                setMissingSKUs={setMissingSKUs}
                vendor={vendor}
              />
            )}

            {(missingSKUs.length > 0 || missingCaseWeights.length > 0) && (
              <ErrorDisplay>
                Please fix the below issues, re-sync IL, and try again.
              </ErrorDisplay>
            )}

            {missingSKUs.length > 0 && (
              <MissingDataList
                message="The following SKUs were not found in the Ingredient List:"
                missingData={missingSKUs}
                title="Missing SKUs"
              />
            )}

            {missingCaseWeights.length > 0 && (
              <MissingDataList
                message="The following SKUs do not have case weights:"
                missingData={missingCaseWeights}
                title="Missing Case Weights"
              />
            )}
          </div>

          <div className="mt-8 flex justify-end">
            <div className="w-24">
              <Button onClick={onCloseModal}>Done</Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const CSVParser = ({
  isLoadingPurchasedGoods,
  loadPurchasedGoodsError,
  onCSVParseSuccess,
  purchasedGoodsForVendor,
  setMissingCaseWeights,
  setMissingSKUs,
  vendor,
}: {
  isLoadingPurchasedGoods: boolean
  loadPurchasedGoodsError: Error | null
  onCSVParseSuccess(): void
  purchasedGoodsForVendor: PurchasedGood[]
  setMissingCaseWeights: React.Dispatch<React.SetStateAction<string[]>>
  setMissingSKUs: React.Dispatch<React.SetStateAction<string[]>>
  vendor: ReactSelectValue<Vendor>
}) => {
  const [csvData, setCsvData] = useState<string[][]>([])
  const [csvFilename, setCsvFilename] = useState('')
  const [csvLoadError, setCsvLoadError] = useState('')

  const { setValues } = useFormikContext()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    function onCSVLoadError() {
      setCsvLoadError('There was an error reading the CSV. Please try again.')
    }

    acceptedFiles.forEach((file) => {
      setCsvFilename(file.name)

      const reader = new FileReader()

      reader.onabort = onCSVLoadError
      reader.onerror = onCSVLoadError
      reader.onload = () => {
        setCsvData(parse(reader.result as string).data as string[][])
      }
      reader.readAsText(file)
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
  })

  useEffect(() => {
    if (
      csvData.length === 0 ||
      isLoadingPurchasedGoods ||
      loadPurchasedGoodsError
    ) {
      return
    }

    const { formData, missingCaseWeights, missingConfig, missingSKUs } =
      csvParser({
        csvData,
        purchasedGoodsForVendor,
        vendor: vendor.value,
      })

    if (!missingConfig) {
      if (missingSKUs.length === 0 && missingCaseWeights.length === 0) {
        setValues(formData)
        onCSVParseSuccess()
      } else {
        setMissingCaseWeights(missingCaseWeights)
        setMissingSKUs(missingSKUs)
      }
    }
  }, [
    csvData,
    isLoadingPurchasedGoods,
    loadPurchasedGoodsError,
    onCSVParseSuccess,
    purchasedGoodsForVendor,
    setMissingCaseWeights,
    setMissingSKUs,
    setValues,
    vendor,
  ])

  const loadError = csvLoadError || loadPurchasedGoodsError?.message

  if (csvData.length > 0 && (isLoadingPurchasedGoods || loadError)) {
    if (isLoadingPurchasedGoods) {
      return (
        <div className="flex items-center space-x-4 border border-dashed border-orange p-4 text-sm text-grey">
          <span>Loading...</span>
          <CircleLoader loaderStyle="colored" />
        </div>
      )
    }

    return (
      <ErrorDisplay>
        Failed to load data for CSV parsing. Error: {loadError}
      </ErrorDisplay>
    )
  }

  return (
    <>
      <div
        {...getRootProps()}
        className={clsx(
          'flex cursor-pointer justify-center border border-dashed p-8 text-sm text-grey',
          {
            'border-orange': isDragActive,
            'border-light-grey': !isDragActive,
          }
        )}
      >
        <input data-testid="upload-csv-input" {...getInputProps()} />
        <div>
          {isDragActive ? (
            'Drop files here...'
          ) : (
            <div className="flex items-center space-x-2">
              <div className="h-4 w-4 text-grey">
                <DocumentTextIcon />
              </div>
              <span>Drag & Drop a CSV here or click to select</span>
            </div>
          )}
        </div>
      </div>
      {csvFilename && (
        <div className="space-x-2 text-sm">
          <span className="uppercase text-grey">Current File:</span>
          <span>{csvFilename}</span>
        </div>
      )}
    </>
  )
}

const MissingDataList = ({
  message,
  missingData,
  title,
}: {
  message: string
  missingData: string[]
  title: string
}): JSX.Element => {
  return (
    <div className="text-sm" data-testid="missing-data-list">
      <h2 className="font-bold">{title}</h2>
      <p>{message}</p>
      <ul className="list-disc pl-8">
        {missingData.map((missingItem, i) => {
          return <li key={i}>{missingItem}</li>
        })}
      </ul>
    </div>
  )
}
