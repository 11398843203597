import { useState } from 'react'

import {
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import ExecutionWorkOrders from 'components/production/execution/work-orders/ExecutionWorkOrders'
import MealCompendium from 'components/production/execution/documents/MealCompendium'

const ExecutionDocuments = ({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
}): JSX.Element => {
  const [printContent, setPrintContent] = useState<'collated' | 'compendium'>(
    'compendium'
  )

  function printDocument(document: 'collated' | 'compendium') {
    setPrintContent(document)
    window.setTimeout(() => {
      window.print()
    }, 800)
  }

  return (
    <>
      <div className="flex justify-center space-x-8 pt-10 print:hidden">
        <div className="w-96 rounded border border-light-grey p-5">
          <div className="text-xl font-semibold">Meal Compendium</div>
          <p className="mb-4">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
          <button
            className="text-blue hover:underline"
            onClick={() => printDocument('compendium')}
          >
            <i className="fas fa-print"></i> Print
          </button>
        </div>
        <div className="w-96 rounded border border-light-grey p-5">
          <div className="text-xl font-semibold">Collated Work Orders</div>
          <p className="mb-4">Print the collated work orders.</p>
          <button
            className="text-blue hover:underline"
            onClick={() => printDocument('collated')}
          >
            <i className="fas fa-print"></i> Print
          </button>
        </div>
      </div>
      <div className="hidden print:block">
        {printContent === 'compendium' && (
          <MealCompendium
            productionCycle={productionCycle}
            productionRecord={productionRecord}
          />
        )}
        {printContent === 'collated' && (
          <ExecutionWorkOrders
            productionCycle={productionCycle}
            productionRecord={productionRecord}
          />
        )}
      </div>
    </>
  )
}

export default ExecutionDocuments
