import { assign, createMachine } from 'xstate'
import { FormikErrors, useFormikContext } from 'formik'
import { isEqual, throttle } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { useMachine } from '@xstate/react'

import { errorsToTouched } from 'utils/forms'
import { getAPIErrorMessage } from 'utils/api'

import { useHasProcessed } from 'hooks/loading'

interface Context<ValueType> {
  currentValue: ValueType
  editError: string
  initialValue: ValueType
  shouldResetOnSuccess: boolean
}

type Events<ValueType> =
  | {
      type: 'EDIT'
    }
  | {
      type: 'INITIAL_VALUE_UPDATED'
      value: ValueType
    }
  | {
      type: 'SUBMIT'
    }
  | {
      submitOnUpdate: boolean
      type: 'UPDATE_VALUE'
      value: ValueType
    }

type Services = {
  saveValue: {
    data: unknown
  }
}

export function createInlineEditMachine<ValueType>({
  initialValue,
  name,
  shouldResetOnSuccess,
}: {
  initialValue: ValueType
  name: string
  shouldResetOnSuccess: boolean
}) {
  return createMachine<Context<ValueType>, Events<ValueType>>(
    {
      id: `inlineEdit-${name}`,
      initial: 'idle',
      context: {
        currentValue: initialValue,
        editError: '',
        initialValue,
        shouldResetOnSuccess,
      },
      predictableActionArguments: true,
      schema: {
        context: {} as Context<ValueType>,
        events: {} as Events<ValueType>,
        services: {} as Services,
      },
      states: {
        idle: {
          on: {
            EDIT: {
              target: 'editing',
            },
            INITIAL_VALUE_UPDATED: {
              actions: ['updateCurrentValue', 'updateInitialValue'],
            },
          },
        },
        editing: {
          on: {
            SUBMIT: {
              target: 'checkingIfSaveNeeded',
            },
            UPDATE_VALUE: [
              {
                actions: ['updateCurrentValue'],
                cond: 'shouldSubmitWithUpdate',
                target: 'checkingIfSaveNeeded',
              },
              { actions: ['updateCurrentValue'] },
            ],
          },
        },
        checkingIfSaveNeeded: {
          always: [
            {
              cond: 'isValueSameAsInitial',
              target: 'idle',
            },
            {
              target: 'saving',
            },
          ],
        },
        saving: {
          entry: ['clearEditError'],
          invoke: {
            src: 'saveValue',
            onDone: [
              {
                actions: ['resetCurrentValue'],
                cond: 'shouldResetOnSuccess',
                target: 'idle',
              },
              {
                target: 'idle',
              },
            ],
            onError: {
              actions: ['updateEditError'],
              target: 'idle',
            },
          },
          on: {
            INITIAL_VALUE_UPDATED: {
              actions: ['updateInitialValue'],
            },
          },
        },
      },
    },
    {
      actions: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clearEditError: assign((_context) => {
          return {
            editError: '',
          }
        }),
        resetCurrentValue: assign((context) => {
          return {
            currentValue: context.initialValue,
          }
        }),
        updateCurrentValue: assign((context, event) => {
          if (
            event.type !== 'INITIAL_VALUE_UPDATED' &&
            event.type !== 'UPDATE_VALUE'
          ) {
            return context
          }

          return {
            currentValue: event.value,
          }
        }),
        updateEditError: assign((_context, event) => {
          return {
            // Typing service done events is not great in XState right now. Waiting for v5 to re-evaluate.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            editError: getAPIErrorMessage(event.data),
          }
        }),
        updateInitialValue: assign((context, event) => {
          if (event.type !== 'INITIAL_VALUE_UPDATED') {
            return context
          }

          return {
            initialValue: event.value,
          }
        }),
      },
      guards: {
        isValueSameAsInitial: (context) => {
          return isEqual(context.currentValue, context.initialValue)
        },
        shouldResetOnSuccess: (context) => {
          return context.shouldResetOnSuccess
        },
        shouldSubmitWithUpdate: (_context, event) => {
          return 'submitOnUpdate' in event && event.submitOnUpdate
        },
      },
    }
  )
}

export function useInlineEdit<ValueType>({
  initialValue,
  name,
  onChange,
  shouldResetOnSuccess = false,
}: {
  initialValue: ValueType
  name: string
  onChange(newValue: ValueType): Promise<unknown>
  shouldResetOnSuccess?: boolean
}) {
  const [state, send] = useMachine(
    () => createInlineEditMachine({ initialValue, name, shouldResetOnSuccess }),
    {
      services: {
        saveValue: (context) => {
          return onChange(context.currentValue)
        },
      },
    }
  )
  const { currentValue, editError } = state.context

  useDeepCompareEffectNoCheck(() => {
    send({ type: 'INITIAL_VALUE_UPDATED', value: initialValue })
  }, [initialValue])

  return {
    currentValue,
    editError,
    isChanging: state.matches('saving'),
    isEditing:
      state.matches('editing') ||
      state.matches('checkingIfSaveNeeded') ||
      state.matches('saving'),
    onStartEdit: () => {
      send({ type: 'EDIT' })
    },
    onSubmit: () => {
      send({ type: 'SUBMIT' })
    },
    onUpdateValue: (
      value: ValueType,
      { submitOnUpdate = false }: { submitOnUpdate?: boolean } = {}
    ) => {
      send({
        submitOnUpdate,
        type: 'UPDATE_VALUE',
        value,
      })
    },
  }
}

/**
 * Provides functionality for displaying errors and validating only after the submit button has been
 * clicked. The idea here is that it's annoying to be validating a form as a user goes on blur because
 * there might be dependent validations later on that will appear to the user even though they just
 * haven't gotten to that step yet.
 *
 * This functionality will only return the form errors after the user has attempted to submit and it
 * will throttle validation on change until the user fixes all errors in the form. Once the form is valid
 * and / or saved, the errors will not be returned until the user attempts to save again.
 */
export function useSubmitValidation<T>({
  initialShouldValidate = false,
  isSaving,
}: {
  initialShouldValidate?: boolean
  isSaving: boolean
}): {
  errors: FormikErrors<T> | undefined
  onClickSubmit(): void
} {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [shouldValidateInitially, setShouldValidateInitially] = useState(
    initialShouldValidate
  )
  const { errors, isValid, setTouched, submitForm, validateForm, values } =
    useFormikContext<T>()

  const hasErrors = shouldValidate && !isValid

  useEffect(() => {
    if (shouldValidateInitially) {
      validateForm()

      if (!isValid) {
        setShouldValidate(true)
        setShouldValidateInitially(false)
      }
    }
  }, [isValid, shouldValidateInitially, validateForm])

  const hasSaved = useHasProcessed(isSaving)
  useEffect(() => {
    if (isValid || hasSaved) {
      // When the form becomes valid, we want to reset our flag for if we clicked submit so
      // that we give user visual feedback that they can try submitting again.
      setShouldValidate(false)
    }
  }, [isValid, hasSaved])

  useEffect(() => {
    const throttledValidate = throttle(
      () => {
        validateForm()
      },
      400,
      {
        leading: false,
      }
    )
    if (hasErrors) {
      throttledValidate()
    }

    return () => {
      throttledValidate.cancel()
    }
  }, [hasErrors, validateForm, values])

  useEffect(() => {
    if (hasErrors) {
      setTouched(errorsToTouched(errors))
    }
  }, [errors, hasErrors, setTouched])

  const onClickSubmit = useCallback(() => {
    submitForm()
    setShouldValidate(true)
  }, [submitForm])

  return { errors: hasErrors ? errors : undefined, onClickSubmit }
}
