import { clsx } from 'clsx'
import { CSSProperties, ReactNode } from 'react'

const Table = ({ children }: { children: ReactNode }) => {
  return <table className="w-full">{children}</table>
}

export default Table

export const TBody = ({ children }: { children: ReactNode }) => {
  return <tbody>{children}</tbody>
}

export const Td = ({ children }: { children?: ReactNode }) => {
  return <td className="border border-light-grey p-3 align-top">{children}</td>
}

export const Th = ({
  backgroundColor,
  children,
  width,
}: {
  backgroundColor?: CSSProperties['backgroundColor']
  children?: ReactNode
  width?: CSSProperties['width']
}) => {
  return (
    <th
      className={`border border-light-grey p-3 text-left align-bottom ${backgroundColor}`}
      style={{ width }}
    >
      {children}
    </th>
  )
}

export const Thead = ({ children }: { children: ReactNode }) => {
  return <thead>{children}</thead>
}

export const Tr = ({
  children,
  height,
  withHover,
  withBlackTopBorder,
}: {
  children: ReactNode
  height?: CSSProperties['height']
  withHover?: boolean
  withBlackTopBorder?: boolean
}) => {
  return (
    <tr
      className={clsx({
        // This is border-width of 2 because the borders collapse so this actually
        // adds a noticeable top border.
        'border-t-2 border-black': withBlackTopBorder,
        'hover:bg-gray-100': withHover,
      })}
      style={{ height }}
    >
      {children}
    </tr>
  )
}
