import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePDF } from '@react-pdf/renderer'

import { error } from 'slices/notifications'
import { generate128Barcode } from 'utils/barcodes'
import { getLineItemLotNumber } from './helpers'
import { PurchaseOrderFull } from 'types/internal'

import { useAppDispatch } from 'hooks'
import { useFullPurchaseOrders } from 'hooks/combinedAPI/purchaseOrders'
import ReceivingPDF from './ReceivingPDF'

const PrintReceivingPDF = ({
  children,
  purchaseOrderIDs,
  selectedFacilityNetworkID,
}: {
  children(opts: { isLoading: boolean }): JSX.Element
  purchaseOrderIDs: string[]
  selectedFacilityNetworkID: string | null
}): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [hasRequestedPrint, setHasRequestedPrint] = useState(false)

  const { purchaseOrders, isLoading } = useFullPurchaseOrders({
    enabled: hasRequestedPrint,
    onFetchPOError: () => {
      dispatch(
        error('Failed to load purchase order for printing receiving document.')
      )
      setHasRequestedPrint(false)
    },
    onFetchPurchasedGoodsError: () => {
      dispatch(
        error('Failed to load purchased goods for printing receiving document.')
      )
      setHasRequestedPrint(false)
    },
    purchaseOrderIDs,
    refetchOnWindowFocus: false,
    selectedFacilityNetworkID,
  })

  const onFailed = useCallback(
    (errorMessage: string) => {
      dispatch(
        error(
          `Failed to generate receiving document PDF. Error: ${errorMessage}`
        )
      )

      setHasRequestedPrint(false)
    },
    [dispatch]
  )

  const onPrinted = useCallback(() => {
    setHasRequestedPrint(false)
  }, [])

  if (!hasRequestedPrint) {
    return (
      <div
        onClick={() => {
          setHasRequestedPrint(true)
        }}
      >
        {children({ isLoading: false })}
      </div>
    )
  }

  if (!isLoading && purchaseOrders.length > 0) {
    return (
      <AutoPrintReceivingDoc
        onFailed={onFailed}
        onPrinted={onPrinted}
        purchaseOrders={purchaseOrders}
      >
        {children}
      </AutoPrintReceivingDoc>
    )
  }

  return children({ isLoading: true })
}

export default PrintReceivingPDF

const AutoPrintReceivingDoc = ({
  children,
  onFailed,
  onPrinted,
  purchaseOrders,
}: {
  children(opts: { isLoading: boolean }): JSX.Element
  onFailed(errorMessage: string): void
  onPrinted(): void
  purchaseOrders: PurchaseOrderFull[]
}): JSX.Element | null => {
  const purchaseOrdersWithBarcodes = useMemo(() => {
    return purchaseOrders.map((purchaseOrder) => {
      return {
        ...purchaseOrder,
        lineItems: purchaseOrder.lineItems.map((lineItem) => {
          const lotNumber = getLineItemLotNumber({
            lineItem,
            snapReceiptID: purchaseOrder.snapReceiptID,
          })

          return {
            ...lineItem,
            barcodes: {
              baseSku: lineItem.baseSku
                ? generate128Barcode(lineItem.baseSku)
                : '',
              lotNumber: lotNumber ? generate128Barcode(lotNumber) : '',
              receivingUnitWt: generate128Barcode(
                `${lineItem.receivingUnitWt}`
              ),
            },
          }
        }),
      }
    })
  }, [purchaseOrders])

  const [instance] = usePDF({
    document: <ReceivingPDF purchaseOrders={purchaseOrdersWithBarcodes} />,
  })

  useEffect(() => {
    if (instance.url) {
      const win = window.open(instance.url)
      win?.print()
      onPrinted()
    }
  }, [instance.url, onPrinted])

  useEffect(() => {
    if (instance.error) {
      onFailed(instance.error)
    }
  }, [instance.error, onFailed])

  return children({ isLoading: true })
}
