import {
  FacilityNetwork,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { makeBetterProductionRecord } from 'utils/production'
import { PartFilters } from 'hooks/combinedAPI/productionParts'

import { useIsReadOnly } from 'contexts/auth'
import Button from 'components/common/bs/Button'
import Checkbox from 'components/common/Checkbox'
import Input from 'components/common/bs/Input'
import ProductionPartTable from './ProductionPartTable'

const PlanningParts = ({
  facilityNetwork,
  filters,
  onChangeFilter,
  onViewAllPartsClicked,
  productionCycle,
  productionRecord,
  productionTerm,
  refetchProductionRecord,
}: {
  facilityNetwork: FacilityNetwork
  filters: PartFilters
  onChangeFilter<FilterName extends keyof PartFilters>(
    filterName: FilterName,
    value: PartFilters[FilterName]
  ): void
  onViewAllPartsClicked(): void
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
  refetchProductionRecord(): void
}): JSX.Element => {
  const isReadOnly = useIsReadOnly()

  const betterProductionRecord = makeBetterProductionRecord(productionRecord)
  const partsForViewing = betterProductionRecord.cycles[productionCycle].parts

  const filteredPartsForViewing = partsForViewing.filter(
    ({
      apiMealCode,
      combined,
      combinedNumBatches,
      id,
      numBatches,
      parentPartTitle,
      title,
    }) => {
      const parsedSearch = filters.search.toLowerCase()
      const currentNumBatches = combined ? combinedNumBatches : numBatches

      return (
        (!filters.mealCode || apiMealCode === filters.mealCode) &&
        (filters.partIDs.length === 0 || filters.partIDs.includes(id)) &&
        (!parsedSearch ||
          title.toLowerCase().includes(parsedSearch) ||
          parentPartTitle.toLowerCase().includes(parsedSearch)) &&
        (!filters.hideBatched || currentNumBatches === 0)
      )
    }
  )

  function onSearchChange(search: string) {
    onChangeFilter('search', search)
  }

  function onToggleHideBatches() {
    onChangeFilter('hideBatched', !filters.hideBatched)
  }

  return (
    <>
      <div className="my-8 flex items-center space-x-4">
        <Input
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Search Products"
          value={filters.search}
        />
        <div className="shrink-0">
          <Checkbox
            checked={filters.hideBatched}
            label="Hide batched parts"
            name="hide-batched-parts"
            onChange={onToggleHideBatches}
          />
        </div>
        <div className="shrink-0">
          <Button
            buttonStyle="stroke"
            onClick={() => {
              onViewAllPartsClicked()
            }}
          >
            View All Parts
          </Button>
        </div>
      </div>

      <div className="space-y-20">
        {filteredPartsForViewing.map((part) => {
          return (
            <ProductionPartTable
              key={part.id}
              facilityNetwork={facilityNetwork}
              part={part}
              productionCycle={productionCycle}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              readOnly={isReadOnly}
              refetchProductionRecord={refetchProductionRecord}
            />
          )
        })}
      </div>
    </>
  )
}

export default PlanningParts
