import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'

export interface Props {
  apiError?: Error | null
  children: JSX.Element
  confirmText: string
  isLoading?: boolean
  onCancel(): void
  onConfirm(): void
  title: string
}

const ConfirmationModal = ({
  apiError = undefined,
  children,
  confirmText,
  isLoading = false,
  onCancel,
  onConfirm,
  title,
}: Props): JSX.Element => {
  return (
    <Modal onCloseModal={onCancel}>
      <ModalBody>
        <div className="w-confirmation-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCancel}>{title}</ModalHeader>
          </div>

          {children}

          <div className="mt-8">
            <ModalButtons
              apiError={apiError}
              isLoading={isLoading}
              mainActionBtnText={confirmText}
              onCancel={onCancel}
              onMainAction={onConfirm}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
