import { BoxSize } from 'types/combinedAPI/domainModels'
import { InsulationTypeName } from 'types/internal'

import { findInsulationTypeByName } from './insulation-types'

export function displayCartonLayout(
  boxSize: BoxSize,
  insulationName: InsulationTypeName
): { displayImage: string; displayText: string } {
  const insulationType = findInsulationTypeByName(insulationName)
  if (!insulationType) {
    return { displayImage: '', displayText: '' }
  }

  const { boxesPerLayer, boxesPerPallet, layoutImage } =
    insulationType.palletInfo[boxSize]
  const numLayers = boxesPerPallet / boxesPerLayer

  return {
    displayImage: layoutImage,
    displayText: `${boxesPerLayer} per layer, ${numLayers} layers, ${boxesPerPallet} per pallet`,
  }
}
