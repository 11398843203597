import { groupBy, omitBy, orderBy } from 'lodash-es'

import { InsulationTypeName, ShippingCarrierCSVRow } from 'types/internal'

const insulationTypeOrder: ReadonlyArray<InsulationTypeName> = [
  '2in_top_2_bottom_ice', // Superhot
  '2in_top_bottom_ice', // Superhot
  '1.5in_silver', // IC
  '1in_white_top_bottom_ice', // WH_TB
  '1in_white_bottom_ice', // WH_B
  '700gsm_bottom_ice', // Winter_B
  'TGA8_bottom_ice', // TG_B
  '0.5in_WK_top_bottom_ice', // WK_TB
  '0.5in_WK_bottom_ice', // WK_B
]

export function parseCarriers(
  carrierDataFromCSVFile: ShippingCarrierCSVRow[]
): Record<string, ShippingCarrierCSVRow[]> {
  const csvRowsWithoutInvalidInsulation = omitBy(
    carrierDataFromCSVFile,
    ({ insulation }) => {
      return insulation === 'invalid-insulation'
    }
  )

  const orderedCSVRows = orderBy(
    csvRowsWithoutInvalidInsulation,
    ({ insulation }) => {
      return insulationTypeOrder.indexOf(insulation)
    }
  )

  const groupedCarrierData: Record<string, ShippingCarrierCSVRow[]> = groupBy(
    orderedCSVRows,
    'carrier'
  )

  delete groupedCarrierData['fedex air']

  return groupedCarrierData
}
