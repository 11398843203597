import { BrowserRouter } from 'react-router-dom'
import {
  BrowserTracing,
  ErrorBoundary as SentryErrorBoundary,
  init as SentryInit,
} from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'

import { getEnvVar } from 'utils/env'
import { isAxiosResponseError } from 'utils/api'
import { registerPDFFonts } from 'utils/pdfs'
import store from './store/createStore'

import './index.css'
import 'apiRequests'
import { AuthProvider } from 'contexts/auth'
import { ToastProvider } from 'contexts/toast'
import App from './App'

SentryInit({
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  dsn: getEnvVar('SENTRY_DSN'),
  environment: getEnvVar('APP_ENV'),
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const queryError = error as Error

        // We don't want to retry an API request if we got an Unauthorized response code - the user has to
        // log back in and get a new, valid token.
        return (
          failureCount < 3 &&
          (!isAxiosResponseError(queryError) ||
            queryError.response?.status !== 401)
        )
      },
    },
  },
})

registerPDFFonts()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SentryErrorBoundary fallback={<p>An error has occured</p>}>
          <Provider store={store}>
            <AuthProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </AuthProvider>
          </Provider>
        </SentryErrorBoundary>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>
)
