import { clsx } from 'clsx'
import { createPortal } from 'react-dom'
import { ReactNode, useEffect } from 'react'

import APIErrorDisplay from './APIErrorDisplay'
import Button from './Button'
import ButtonLoading, { Props as ButtonLoadingProps } from './ButtonLoading'
import XIcon from 'components/common/icons/XIcon'

let modalRoot = document.getElementById('modal-root')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')
  document.body.appendChild(modalRoot)
}

const Modal = ({
  children,
  isCentered = false,
  onCloseModal,
}: {
  children: ReactNode
  isCentered?: boolean
  onCloseModal?: () => void
}): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden')

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  useEffect(() => {
    /**
     * Responds to a keydown event.
     */
    function onKeyDown(event: KeyboardEvent) {
      // Close our modal if the user pressed the Escape key. We don't want to close it if they pressed
      // that key on an input though because they were probably canceling something.
      if (
        event.target instanceof Element &&
        event.target.tagName !== 'INPUT' &&
        event.key === 'Escape'
      ) {
        onCloseModal?.()
      }
    }

    if (onCloseModal) {
      document.addEventListener('keydown', onKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onCloseModal])

  const marginY = isCentered ? 'my-auto' : 'my-modal-y'

  return createPortal(
    <div>
      <div
        className="fixed inset-0 z-30 h-full w-full bg-dark-grey opacity-80"
        onClick={onCloseModal}
      />
      <div className="fixed inset-0 z-30 mx-4 flex h-full items-start justify-center overflow-auto">
        <div
          className={`max-w-full ${marginY} mx-auto rounded bg-white shadow-lg`}
        >
          {children}
        </div>
      </div>
    </div>,
    modalRoot as HTMLDivElement
  )
}

export default Modal

export const ModalBody = ({
  children,
  isFullScreen = false,
}: {
  children: ReactNode
  isFullScreen?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx('flex flex-col p-4', {
        'h-full-screen-modal': isFullScreen,
      })}
      role="dialog"
    >
      {children}
    </div>
  )
}

export const ModalButtons = ({
  apiError = null,
  cancelBtnText = 'Cancel',
  mainActionBtnStyle = 'primary',
  mainActionBtnText,
  isLoading = false,
  onCancel,
  onMainAction,
}: {
  apiError?: Error | null
  cancelBtnText?: string
  mainActionBtnStyle?: ButtonLoadingProps['buttonStyle']
  mainActionBtnText: string
  isLoading?: boolean
  onCancel(): void
  onMainAction(): void
}): JSX.Element => {
  return (
    <div>
      {apiError && <APIErrorDisplay error={apiError} />}
      <div className="mt-4 flex items-center justify-end space-x-2">
        <div className="w-24">
          <Button buttonStyle="cancel" onClick={onCancel}>
            {cancelBtnText}
          </Button>
        </div>
        <div className="w-24">
          <ButtonLoading
            buttonStyle={mainActionBtnStyle}
            isLoading={isLoading}
            onClick={() => {
              onMainAction()
            }}
          >
            {mainActionBtnText}
          </ButtonLoading>
        </div>
      </div>
    </div>
  )
}

export const ModalHeader = ({
  children,
  onClickClose,
}: {
  children: ReactNode
  onClickClose?: () => void
}): JSX.Element => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-xl">{children}</h1>
      {onClickClose && (
        <div
          className="h-5 w-5 cursor-pointer text-dark-grey"
          data-testid="modal-header-close"
          onClick={onClickClose}
        >
          <XIcon />
        </div>
      )}
    </div>
  )
}
