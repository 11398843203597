import { uniqBy } from 'lodash-es'

import { ComposedMeal, ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import {
  Meal,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { useBulkUpdateProductionPartTags } from 'hooks/combinedAPI/productionParts'
import Button from 'components/common/bs/Button'
import Popover from 'components/common/Popover'

export interface Props {
  productionCycle: ProductionCycle
  productionMeal: ComposedMeal
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
  slot: 'component1' | 'component2' | 'garnish'
  unassignedBOMs: ComposedMealBOMItem[]
}

const UnassignedBOMsButton = ({
  productionCycle,
  productionMeal,
  productionMealCycle2,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
  slot,
  unassignedBOMs,
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const { mutateAsync: bulkUpdateTags } = useBulkUpdateProductionPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  if (unassignedBOMs.length === 0 || productionCycle === '2') {
    return null
  }

  function addBOMToComponent(bom: ComposedMealBOMItem) {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to add BOMs to components.')
      )
    }

    const trayNumber = slot === 'component1' ? 1 : 2
    const componentBOMs = productionMeal[`component${trayNumber}`]

    const tags = componentBOMs[0]?.tags ?? [
      ...bom.tags,
      { category: 'container', title: `tray ${trayNumber}` },
    ]

    bulkUpdateTags({
      boms: [...componentBOMs, bom],
      productionCycle,
      productionMealCycle2,
      productionRecord,
      productionTerm,
      tags,
    })
  }

  async function addBOMToGarnish(bom: ComposedMealBOMItem) {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to add BOMs to garnishes.')
      )
    }

    bulkUpdateTags({
      boms: [bom],
      productionCycle,
      productionMealCycle2,
      productionRecord,
      productionTerm,
      tags: uniqBy(
        [{ category: 'container', title: 'sachet' } as const, ...bom.tags],
        'category'
      ),
    })
  }

  return (
    <Popover
      name={`unassigned-boms-popover-${slot}`}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <Button
            aria-label={`Add BOM to ${slot}`}
            buttonStyle="red"
            disabled={readOnly}
          >
            <i className="fas fa-plus" />
          </Button>
        </div>
      )}
    >
      <div className="w-56">
        <div className="border-b border-light-grey p-3 text-sm font-bold">
          Assign Parts
        </div>
        <div>
          {unassignedBOMs.map((bom, i) => (
            <button
              key={i}
              className="p-2 text-base text-blue hover:underline"
              data-testid="pop-over-button"
              onClick={() => {
                if (slot === 'garnish') {
                  addBOMToGarnish(bom)
                } else {
                  addBOMToComponent(bom)
                }
              }}
            >
              {bom.title}
            </button>
          ))}
        </div>
      </div>
    </Popover>
  )
}

export default UnassignedBOMsButton
