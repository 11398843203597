/**
 * These +/- offsets come from USDA regulations from
 * Table 2-5 "Maximum Allowable Variations (MAVs) for Packages Labeled by Weight" in PDF:
 * https://www.nist.gov/system/files/documents/2017/04/28/08-appa-11-hb133-final.pdf.
 *
 * Note: The mapping in this function is not complete since we don't typically have input weights
 * above 698.
 */
export function getMaxAllowableWeightVariation(grams: number): number {
  if (grams < 36) {
    return grams * 0.1
  } else if (floatInRange(grams, 35, 54)) {
    return 3.6
  } else if (floatInRange(grams, 54, 81)) {
    return 5.4
  } else if (floatInRange(grams, 81, 117)) {
    return 7.2
  } else if (floatInRange(grams, 117, 154)) {
    return 9.0
  } else if (floatInRange(grams, 154, 208)) {
    return 10.8
  } else if (floatInRange(grams, 208, 263)) {
    return 12.7
  } else if (floatInRange(grams, 263, 317)) {
    return 14.5
  } else if (floatInRange(grams, 317, 381)) {
    return 16.3
  } else if (floatInRange(grams, 318, 426)) {
    return 18.1
  } else if (floatInRange(grams, 426, 489)) {
    return 19.9
  } else if (floatInRange(grams, 489, 571)) {
    return 21.7
  } else if (floatInRange(grams, 571, 635)) {
    return 23.5
  } else if (floatInRange(grams, 635, 698)) {
    return 25.4
  }

  return 0
}

function floatInRange(f: number, a: number, b: number): boolean {
  return f > a && f <= b
}
