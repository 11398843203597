import { hideLoading, resetLoading, showLoading } from 'react-redux-loading-bar'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  GetProductionRecord,
  getProductionRecord,
  GetProductionRecordResponse,
} from 'services/combinedAPI/productionRecords'
import { setProductionRecord } from 'slices/production'

import { useAppDispatch } from 'hooks'

export function useInvalidateProductionRecord() {
  const queryClient = useQueryClient()

  return {
    invalidateProductionRecord: ({
      facilityNetworkTitle,
      productionTerm,
    }: {
      facilityNetworkTitle: string
      productionTerm: string
    }) => {
      queryClient.invalidateQueries([
        'production-records',
        facilityNetworkTitle,
        productionTerm,
      ])
    },
  }
}

export function useProductionRecord({
  facilityNetworkTitle,
  productionTerm,
  ...rest
}: GetProductionRecord &
  Omit<
    UseQueryOptions<GetProductionRecordResponse, Error>,
    'enabled' | 'queryFn' | 'queryKey' | 'refetchOnWindowFocus'
  >) {
  const dispatch = useAppDispatch()

  return useQuery<GetProductionRecordResponse, Error>({
    ...rest,
    enabled: !!(facilityNetworkTitle && productionTerm),
    onError: (...args) => {
      console.log(args[0])

      rest?.onError?.(...args)
    },
    onSettled: (...args) => {
      dispatch(hideLoading())

      rest?.onSettled?.(...args)
    },
    onSuccess: (...args) => {
      dispatch(setProductionRecord(args[0]))

      rest?.onSuccess?.(...args)
    },
    queryFn: ({ signal }) => {
      dispatch(resetLoading())
      dispatch(showLoading())

      return getProductionRecord(
        {
          facilityNetworkTitle,
          productionTerm,
        },
        {
          abortSignal: signal,
        }
      )
    },
    queryKey: ['production-records', facilityNetworkTitle, productionTerm],
    refetchOnWindowFocus: false,
  })
}
