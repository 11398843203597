const LastTower = ({
  maxBinsPerTower,
  partialBinComponents,
  partialTowerBins,
}: {
  maxBinsPerTower: number
  partialBinComponents: number
  partialTowerBins: number
}): JSX.Element | null => {
  if (partialTowerBins) {
    if (partialBinComponents) {
      return <>({partialTowerBins - 1} full, 1 partial)</>
    }
  } else {
    if (partialBinComponents) {
      return <>({maxBinsPerTower - 1} full, 1 partial)</>
    }

    return <>({maxBinsPerTower} full)</>
  }

  return null
}

export default LastTower
