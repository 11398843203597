import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  DeleteProductionMeal,
  deleteProductionMeal,
  DeleteProductionMealResponse,
  UpdateProductionMeal,
  updateProductionMeal,
  UpdateProductionMealResponse,
  UpdateProductionMealTags,
  updateProductionMealTags,
  UpdateProductionMealTagsResponse,
} from 'services/combinedAPI/productionMeals'
import { error } from 'slices/notifications'
import { isAxiosResponseError } from 'utils/api'
import { Meal, ProductionCycle } from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'

export function useDeleteProductionMeal(
  opts?: Omit<
    UseMutationOptions<
      DeleteProductionMealResponse,
      Error,
      DeleteProductionMeal
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<DeleteProductionMealResponse, Error, DeleteProductionMeal>(
    {
      ...opts,
      onError: (...args) => {
        const err = args[0]

        const errorMessage = isAxiosResponseError(err)
          ? err.response?.data.message ??
            'There was an unknown error deleting the meal.'
          : err.message

        dispatch(error(errorMessage))

        opts?.onError?.(...args)
      },
      mutationFn: (opts) => {
        return deleteProductionMeal(opts)
      },
    }
  )
}

export function useUpdateProductionMeal(
  opts?: Omit<
    UseMutationOptions<
      UpdateProductionMealResponse,
      Error,
      UpdateProductionMeal
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<UpdateProductionMealResponse, Error, UpdateProductionMeal>(
    {
      ...opts,
      onError: (...args) => {
        const apiError = args[0]

        const errorMessage = isAxiosResponseError(apiError)
          ? apiError.response?.data.message ??
            'Unknown error updating production meal'
          : apiError.message

        dispatch(error(errorMessage))

        opts?.onError?.(...args)
      },
      mutationFn: (opts) => {
        return updateProductionMeal(opts)
      },
    }
  )
}

export function useUpdateProductionMealTags(
  opts?: Omit<
    UseMutationOptions<
      UpdateProductionMealTagsResponse[],
      Error,
      UpdateProductionMealTags & {
        productionCycle: ProductionCycle
        productionMealCycle2: Meal | undefined
      }
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<
    UpdateProductionMealTagsResponse[],
    Error,
    UpdateProductionMealTags & {
      productionCycle: ProductionCycle
      productionMealCycle2: Meal | undefined
    }
  >({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error updating production meal tags'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    mutationFn: (opts) => {
      const { productionCycle, productionMealCycle2, ...rest } = opts

      const promises = [updateProductionMealTags(rest)]

      if (productionCycle === '1' && productionMealCycle2) {
        promises.push(
          updateProductionMealTags({
            ...opts,
            mealID: productionMealCycle2.id,
          })
        )
      }

      return Promise.all(promises)
    },
  })
}
