import { clsx } from 'clsx'
import { forwardRef, InputHTMLAttributes } from 'react'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean
  isAppend?: boolean
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { hasError = false, isAppend = false, ...rest },
  ref
) {
  return (
    <input
      ref={ref}
      className={clsx('h-9 w-full rounded border px-3', {
        'border-red': hasError,
        'border-light-grey': !hasError,
        'rounded-r-none border-r-0': isAppend,
      })}
      data-testid={rest.name}
      {...rest}
    />
  )
})

export default Input
