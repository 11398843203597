import { uniqBy } from 'lodash-es'

import { error } from 'slices/notifications'
import { FacilityNetwork, Tag } from 'types/combinedAPI/domainModels'
import { getTitlesByCategories } from 'utils/tags'

import { useAppDispatch } from 'hooks'
import DropdownCanned from './DropdownCanned'
import DropdownCook from './DropdownCook'
import DropdownLabels from './DropdownLabels'
import DropdownManual from './DropdownManual'
import DropdownMix from './DropdownMix'
import DropdownPrepare from './DropdownPrepare'
import DropdownPrepDay from './DropdownPrepDay'
import DropdownPrepLocation from './DropdownPrepLocation'
import { Td, Tr } from 'components/common/Table'

export interface Props {
  facilityNetwork: FacilityNetwork
  onTagsChanged(opts: { tags: Tag[] }): void
  readOnly: boolean
  tags: Tag[]
}

const ProductionPartTagDropdowns = ({
  facilityNetwork,
  onTagsChanged,
  readOnly,
  tags,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const [prepDay, prepLocation] = getTitlesByCategories(tags, [
    'day_of_week',
    'prep_location',
  ])

  const prepLocationFacility = facilityNetwork.facilities.find(
    (n) => n.title === prepLocation
  )
  const prepLocationDisplayName = prepLocationFacility
    ? prepLocationFacility.displayName
    : ''

  async function editTag(tag: Tag) {
    if (readOnly) {
      return dispatch(error('You do not have permission to update tags.'))
    }

    onTagsChanged({ tags: uniqBy([tag, ...tags], 'category') })
  }

  return (
    <>
      <Tr>
        <Td>
          <b>Add Production Tags</b>
          <br />
          <div className="flex">
            <DropdownPrepare
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'prepare', title })
              }}
            />
            <DropdownCook
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'cook', title })
              }}
            />
            <DropdownMix
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'mix', title })
              }}
            />
            <DropdownCanned
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'canned', title })
              }}
            />
            <DropdownManual
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'manual', title })
              }}
            />
            <DropdownLabels
              disabled={readOnly}
              onChange={(title) => {
                editTag({ category: 'prep_labels', title })
              }}
            />
          </div>
        </Td>
      </Tr>

      <Tr>
        <Td>
          <DropdownPrepDay
            disabled={readOnly}
            onChange={(day) => {
              editTag({ category: 'day_of_week', title: day })
            }}
            prepDay={prepDay}
          />
        </Td>
      </Tr>

      <Tr>
        <Td>
          <DropdownPrepLocation
            disabled={readOnly}
            facilityNetwork={facilityNetwork}
            onChange={(location) => {
              editTag({ category: 'prep_location', title: location })
            }}
            prepLocationDisplayName={prepLocationDisplayName}
          />
        </Td>
      </Tr>
    </>
  )
}

export default ProductionPartTagDropdowns
