import {
  addToDate,
  DATE_FORMATS,
  formatDate,
  getCurrentDate,
  getDifferenceInCalendarWeeks,
  getPreviousMondayDate,
  isDateMonday,
} from 'utils/dates'

interface Term {
  id: number
  productionDates: Date[] | null
  startDate: Date
}

interface ProductionEvent {
  description: string
  differenceDescription: string
  differenceInDaysFromTermStartDate: number
  title: string
}

export const productionEvents: ProductionEvent[] = [
  {
    title: 'Production Start',
    description: 'First production day',
    differenceDescription: 'Sun (-1)',
    differenceInDaysFromTermStartDate: -1,
  },
  {
    title: 'Production End',
    description: 'Last meal box is shipped',
    differenceDescription: 'Wed (+1)',
    differenceInDaysFromTermStartDate: 9,
  },
  {
    title: 'Cycle 1\nPackout Start',
    description: 'First meal box is shipped',
    differenceDescription: 'Sat (0)',
    differenceInDaysFromTermStartDate: 5,
  },
  {
    title: 'Cycle 1\nPackout End',
    description: '-',
    differenceDescription: 'Mon (+1)',
    differenceInDaysFromTermStartDate: 7,
  },
  {
    title: 'Cycle 2\nPackout Start',
    description: '-',
    differenceDescription: 'Tue (+1)',
    differenceInDaysFromTermStartDate: 8,
  },
  {
    title: 'Cycle 2\nPackout End',
    description: 'Last meal box is shipped',
    differenceDescription: 'Wed (+1)',
    differenceInDaysFromTermStartDate: 9,
  },
  {
    title: 'Production meal\ncounts in',
    description: 'Meals to be produced',
    differenceDescription: 'Fri (-1)',
    differenceInDaysFromTermStartDate: -3,
  },
  {
    title: 'Final meal\ncounts in',
    description: 'Updated with Frozen Meals',
    differenceDescription: 'Thu (0)',
    differenceInDaysFromTermStartDate: 3,
  },
]

export default function ProductionTermDates({
  onTermClicked,
}: {
  onTermClicked: (productionTerm: string) => void
}) {
  const { currentTerm, terms } = getProductionTerms({ productionEvents })

  return (
    <div className="max-h-[700px] overflow-y-auto border p-4 text-center">
      <div className="grid grid-cols-production-term-dates">
        <div className="text-uppercase col-span-2 col-start-3 bg-blue/25 p-1">
          Production
        </div>
        <div className="text-uppercase col-span-4 border-r border-l border-white bg-blue/25 p-1">
          Packout and Shipping
        </div>
        <div className="text-uppercase col-span-2 bg-blue/25 p-1">
          Meal Counts
        </div>

        {productionEvents.map(({ title, differenceDescription }, index) => {
          return (
            <div
              key={title}
              className={`p-2 text-xs text-grey ${
                index === 0 ? 'col-start-3' : ''
              }`}
            >
              {differenceDescription}
            </div>
          )
        })}

        {productionEvents.map(({ title }, index) => {
          return (
            <div
              key={title}
              className={`mb-1 flex items-center justify-center whitespace-pre-wrap bg-gray-100 p-1 text-sm font-bold ${
                index === 0 ? 'col-start-3' : 'border-l'
              }`}
            >
              <span>{title}</span>
            </div>
          )
        })}

        <div className="col-start-2 flex items-center justify-center border-r border-l bg-gray-100 font-bold">
          Term
        </div>

        {productionEvents.map(({ description, title }, index) => {
          let columnClasses = ''
          if (index === 1 || index === 5 || index === 7) {
            columnClasses = 'border-r'
          }
          return (
            <div
              key={title}
              className={`flex items-center justify-center bg-gray-100 p-2 text-xs ${columnClasses}`}
            >
              <span>{description}</span>
            </div>
          )
        })}

        {terms.map((term) => {
          return (
            <TermRow
              key={term.id}
              isCurrentTerm={term.id === currentTerm.id}
              onTermClicked={onTermClicked}
              term={term}
            />
          )
        })}
      </div>
    </div>
  )
}

const TermRow = ({
  isCurrentTerm,
  onTermClicked,
  term,
}: {
  isCurrentTerm: boolean
  onTermClicked: (productionTerm: string) => void
  term: Term
}) => {
  const bgColor = isCurrentTerm ? 'bg-yellow-200' : ''
  const testIDProp = isCurrentTerm
    ? { ['data-testid']: 'production-terms-current-term' }
    : {}

  return (
    <>
      {isCurrentTerm && (
        <div className={`${bgColor} p-1 text-right`} {...testIDProp}>
          <span>Current Term {'>>'}</span>
        </div>
      )}
      <div
        className={`${bgColor} cursor-pointer border-b border-l border-r p-1 text-bs-blue transition-colors hover:text-bs-blue-hover hover:underline ${
          !isCurrentTerm ? 'col-start-2' : ''
        }`}
        onClick={() => onTermClicked(term.id.toString())}
        {...testIDProp}
      >
        {term.id}
      </div>
      {term.productionDates?.map((date, index) => {
        let columnClasses = ''
        if (index === 0) {
          columnClasses = 'font-bold'
        } else if (index === 1) {
          columnClasses = 'font-bold border-r'
        } else if (index === 5 || index === 7) {
          columnClasses = 'border-r'
        }

        return (
          <div
            key={index}
            className={`${columnClasses} ${bgColor} border-b p-1`}
            {...testIDProp}
          >
            {formatDate(date, {
              format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
            })}
          </div>
        )
      })}
    </>
  )
}

export const getProductionTerms = ({
  productionEvents,
}: {
  productionEvents: ProductionEvent[]
}) => {
  const defaultTerm: Term = {
    id: 271,
    productionDates: null,
    startDate: new Date('2022-03-07T17:00:00Z'),
  }

  const today = getCurrentDate()
  const startDate = isDateMonday(today) ? today : getPreviousMondayDate(today)
  startDate.setUTCHours(17, 0, 0)
  const weeksDifference = getDifferenceInCalendarWeeks(
    startDate,
    defaultTerm.startDate,
    { weekStartsOn: 1 }
  )

  const currentTerm: Term = {
    id: defaultTerm.id + weeksDifference,
    productionDates: null,
    startDate,
  }

  const numberOfFutureTerms = 8
  const totalNumberOfTerms = 29
  const lastFutureTermID = currentTerm.id + numberOfFutureTerms

  const terms: Term[] = Array.from(
    { length: totalNumberOfTerms },
    (_unused, index) => {
      const id = lastFutureTermID - index
      const differenceInWeeks = id - currentTerm.id
      const daysToAdd = differenceInWeeks * 7

      const startDate = addToDate(currentTerm.startDate, {
        quantity: daysToAdd,
        units: 'days',
      })

      const productionDates = productionEvents.map((productionEvent) => {
        return addToDate(startDate, {
          quantity: productionEvent.differenceInDaysFromTermStartDate,
          units: 'days',
        })
      })

      return {
        id,
        productionDates,
        startDate,
      }
    }
  )

  return { currentTerm, terms }
}
