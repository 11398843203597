import { cloneDeep } from 'lodash-es'
import { hideLoading, resetLoading, showLoading } from 'react-redux-loading-bar'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useState } from 'react'

import {
  CombineProductionPart,
  combineProductionPart,
  CombineProductionPartResponse,
  EditProductionPart,
  editProductionPart,
  EditProductionPartResponse,
  UncombineProductionPart,
  uncombineProductionPart,
  UncombineProductionPartResponse,
  UpdateBatchNumberWithChildren,
  updateBatchNumberWithChildren,
  UpdateBatchNumberWithChildrenResponse,
  updateTagsForComponent,
  UpdateTagsForComponentResponse,
} from 'services/combinedAPI/productionParts'
import { ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import { getCycle2BOMIDs } from 'utils/boms'
import { getCycle2DayOfWeek } from 'utils/productionParts'
import { getCycle2Part } from 'utils/production'
import { getTagByCategory } from 'utils/tags'
import { isAxiosResponseError } from 'utils/api'
import {
  Meal,
  ProductionCycle,
  ProductionPart,
  ProductionRecord,
  Tag,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'

export interface PartFilters {
  hideBatched: boolean
  mealCode: number | null
  partIDs: string[]
  search: string
}

interface UseBulkUpdateProductionPartTags {
  boms: ComposedMealBOMItem[]
  productionCycle: ProductionCycle
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  tags: Tag[]
}

export function useBulkUpdateProductionPartTags(
  opts?: Omit<
    UseMutationOptions<
      UpdateTagsForComponentResponse,
      Error,
      UseBulkUpdateProductionPartTags
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<
    UpdateTagsForComponentResponse,
    Error,
    UseBulkUpdateProductionPartTags
  >({
    ...opts,
    onError: (...args) => {
      console.log(args[0])

      opts?.onError?.(...args)
    },
    onSettled: (...args) => {
      dispatch(hideLoading())

      opts?.onSettled?.(...args)
    },
    mutationFn: ({
      boms,
      productionCycle,
      productionMealCycle2,
      productionRecord,
      productionTerm,
      tags,
    }) => {
      dispatch(resetLoading())
      dispatch(showLoading())

      let bomIDsToUpdate = boms.map((bom) => bom.id)

      if (productionCycle === '1') {
        bomIDsToUpdate = [
          ...bomIDsToUpdate,
          ...getCycle2BOMIDs({
            boms,
            productionMealCycle2,
            productionRecord,
          }),
        ]
      }

      return updateTagsForComponent({
        data: { bomIDs: bomIDsToUpdate, tags },
        productionTerm,
      })
    },
  })
}

export function useCombineProductionPart(
  opts?: Omit<
    UseMutationOptions<
      CombineProductionPartResponse,
      Error,
      CombineProductionPart
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<
    CombineProductionPartResponse,
    Error,
    CombineProductionPart
  >({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error combining production part'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    onSettled: (...args) => {
      dispatch(hideLoading())

      opts?.onSettled?.(...args)
    },
    mutationFn: ({ productionPartID, productionTerm }) => {
      dispatch(resetLoading())
      dispatch(showLoading())

      return combineProductionPart({
        productionPartID,
        productionTerm,
      })
    },
  })
}

interface UseEditPartTags {
  numBatches: number
  part: ProductionPart
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
  tags: Tag[]
  updateCycle2?: boolean
}

export function useEditPartTags(
  opts?: Omit<
    UseMutationOptions<EditProductionPartResponse[], Error, UseEditPartTags>,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<EditProductionPartResponse[], Error, UseEditPartTags>({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error editing production part'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    mutationFn: ({
      numBatches,
      part,
      productionCycle,
      productionRecord,
      productionTerm,
      tags,
      updateCycle2 = true,
    }) => {
      const promises = [
        editProductionPart({
          data: { numBatches, tags },
          productionPartID: part.id,
          productionTerm,
        }),
      ]

      const cycle2Part = getCycle2Part(productionRecord, part)

      if (updateCycle2 && productionCycle === '1' && cycle2Part) {
        const cycle2Payload = {
          numBatches: cycle2Part.numBatches,
          tags: cloneDeep(tags),
        }

        const tagForDayOfWeek = getTagByCategory(
          cycle2Payload.tags,
          'day_of_week'
        )

        if (tagForDayOfWeek) {
          tagForDayOfWeek.title = getCycle2DayOfWeek(tagForDayOfWeek.title)
        }

        promises.push(
          editProductionPart({
            data: cycle2Payload,
            productionPartID: cycle2Part.id,
            productionTerm,
          })
        )
      }

      return Promise.all(promises)
    },
  })
}

export function useEditProductionPart(
  opts?: Omit<
    UseMutationOptions<EditProductionPartResponse, Error, EditProductionPart>,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<EditProductionPartResponse, Error, EditProductionPart>({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error editing production part'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    mutationFn: (opts) => {
      return editProductionPart(opts)
    },
  })
}

export function usePartFilters(): {
  changeFilter<FilterName extends keyof PartFilters>(
    filterName: FilterName,
    value: PartFilters[FilterName]
  ): void
  filters: PartFilters
  reset(): void
} {
  const initialFilterState = {
    hideBatched: false,
    mealCode: null,
    partIDs: [],
    partProductionID: '',
    search: '',
  }
  const [filters, setFilters] = useState<PartFilters>(initialFilterState)

  return {
    changeFilter: (filterName, value) => {
      setFilters((filters) => {
        let newFilters = { ...filters }

        if (
          filterName === 'mealCode' ||
          filterName === 'partIDs' ||
          filterName === 'search'
        ) {
          newFilters = { ...initialFilterState }
        }

        newFilters[filterName] = value

        return newFilters
      })
    },
    filters,
    reset: () => {
      setFilters(initialFilterState)
    },
  }
}

export function useUncombineProductionPart(
  opts?: Omit<
    UseMutationOptions<
      UncombineProductionPartResponse,
      Error,
      UncombineProductionPart
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<
    UncombineProductionPartResponse,
    Error,
    UncombineProductionPart
  >({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error uncombining production part'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    onSettled: (...args) => {
      dispatch(hideLoading())

      opts?.onSettled?.(...args)
    },
    mutationFn: (opts) => {
      dispatch(resetLoading())
      dispatch(showLoading())

      return uncombineProductionPart(opts)
    },
  })
}

export function useUpdateBatchNumberWithChildren(
  opts?: Omit<
    UseMutationOptions<
      UpdateBatchNumberWithChildrenResponse,
      Error,
      UpdateBatchNumberWithChildren
    >,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<
    UpdateBatchNumberWithChildrenResponse,
    Error,
    UpdateBatchNumberWithChildren
  >({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ??
          'Unknown error updating batch number with children'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    onSettled: (...args) => {
      dispatch(hideLoading())

      opts?.onSettled?.(...args)
    },
    mutationFn: (opts) => {
      dispatch(resetLoading())
      dispatch(showLoading())

      return updateBatchNumberWithChildren(opts)
    },
  })
}
