import { DndProvider } from 'react-dnd'
import { flattenDeep, keys, sortBy } from 'lodash-es'
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  ProductionCycle,
  ProductionRecord,
  Tag,
} from 'types/combinedAPI/domainModels'
import { ProductionPartWithCycle } from 'types/internal'

import { useAppSelector } from 'hooks'
import PrepDay from './PrepDay'

const PlanningProductionSchedule = ({
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: {
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}): JSX.Element => {
  const productionCycle = useAppSelector(
    (state) => state.production.productionCycle
  )

  function getClipboardText(part: ProductionPartWithCycle): string {
    const meal = productionRecord.cycles[productionCycle].meals.find(
      (m) => m.id === part.mealID
    )

    if (!meal) {
      return `Part has no meal in this term, part: ${part.title} mealID: ${part.mealID}`
    }

    const mealTitle = part.mealPartTitle
    const title = part.title
    const totalWeightBefore = part.totalWeightPounds.toFixed(2)
    const totalWeightAfter = part.finalWeightPounds.toFixed(2)
    const batches = part.numBatches
    const weightPerBatch = (part.totalWeightPounds / batches).toFixed(2)
    const parentPartTitle =
      part.parentPartID === part.mealID ? 'Assembly' : part.parentPartTitle

    return `${title} (${parentPartTitle}) #${meal.mealCode}${
      Number(part.cycleNumber) > 1 ? ' - a' : ''
    } [${mealTitle})] ${totalWeightBefore}lbs/${totalWeightAfter}lbs | ${batches} batches x ${weightPerBatch}lbs per batch`
  }

  function getPartsForTag(
    parts: ProductionPartWithCycle[],
    tag: Tag
  ): ProductionPartWithCycle[] {
    let pahts: ProductionPartWithCycle[] = []
    const parsed: ProductionPartWithCycle[] = []

    parts.map((part) => {
      if (part.cycleNumber === '2' && part.combined) {
        return null
      }

      if (part.cycleNumber === '1' && part.combined) {
        return parsed.push({
          ...productionRecord.combinedParts[part.id],
          cycleNumber: '1',
        })
      }

      return parsed.push(part)
    })

    pahts = parsed.filter((part) => {
      return !!part.tags.find(
        (t) => t.title === tag.title && t.category === tag.category
      )
    })

    return pahts
  }

  const unflattened: ProductionPartWithCycle[] = []
  const cycles = keys(productionRecord.cycles) as ProductionCycle[]
  cycles.forEach((cycleNumber) => {
    productionRecord.cycles[cycleNumber].parts.forEach((part) => {
      const partWithCycle = { ...part, cycleNumber }

      if (partWithCycle.totalWeightPounds > 0) {
        unflattened.push(partWithCycle)
      }
    })
  })

  const parts = sortBy(flattenDeep(unflattened), 'apiMealCode')
  const weekdays = [
    {
      day: 'Sunday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'sunday'
          )
      ),
    },
    {
      day: 'Monday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'monday'
          )
      ),
    },
    {
      day: 'Tuesday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'tuesday'
          )
      ),
    },
    {
      day: 'Wednesday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'wednesday'
          )
      ),
    },
    {
      day: 'Thursday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'thursday'
          )
      ),
    },
    {
      day: 'Friday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'friday'
          )
      ),
    },
    {
      day: 'Saturday' as const,
      parts: parts.filter(
        (part) =>
          !!part.tags.find(
            (t) => t.category === 'day_of_week' && t.title === 'saturday'
          )
      ),
    },
  ]

  return (
    <>
      <div className="py-5">
        <div className="space-y-4 overflow-scroll">
          <div className="bg-[#d1ecf1] px-5 py-3">
            <i className="fas fa-exclamation" />
            &nbsp; This view uses parts from ALL CYCLES. For best viewing, use a
            wide screen, or zoom out.&nbsp;
            <i className="fas fa-exclamation" />
          </div>

          <div className="flex">
            {productionRecord.cycles[productionCycle].meals.map((meal, i) => [
              <div
                key={i}
                className="border border-r-0 border-light-grey px-5 py-3 text-xs last-of-type:border-r"
              >
                <b>#{meal.mealCode}</b>
                <br />
                {meal.shortTitle || meal.partTitle}
              </div>,
            ])}
          </div>
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="flex flex-wrap space-x-8" style={{ fontSize: '90%' }}>
          {weekdays.map((day, i) => (
            <div key={i} className="grow basis-0">
              <PrepDay
                day={day}
                getClipboardText={getClipboardText}
                getPartsForTag={getPartsForTag}
                productionTerm={productionTerm}
                readOnly={readOnly}
                refetchProductionRecord={refetchProductionRecord}
              />
            </div>
          ))}
        </div>
      </DndProvider>
    </>
  )
}

export default PlanningProductionSchedule
