import { FormDataValidated, Stage, Stages } from './helpers'
import { isAxiosResponseError } from 'utils/api'

import Button from 'components/common/Button'
import CheckIcon from 'components/common/icons/CheckIcon'
import CircleLoader from 'components/common/CircleLoader'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormGroup from 'components/common/FormGroup'
import SuccessDisplay from 'components/common/SuccessDisplay'
import XIcon from 'components/common/icons/XIcon'

const CreateShortPickOrderCreation = ({
  hasError,
  isCreating,
  isSuccess,
  onClickDone,
  purchasedGoods,
  stages,
}: {
  hasError: boolean
  isCreating: boolean
  isSuccess: boolean
  onClickDone(): void
  purchasedGoods: FormDataValidated['purchasedGoods']
  stages: Stages
}): JSX.Element => {
  return (
    <>
      <div className="space-y-4">
        {isCreating ? (
          <p className="text-sm font-bold">
            The below short pick orders are being created. Please do not close
            this modal.
          </p>
        ) : (
          <>
            {isSuccess && (
              <SuccessDisplay>
                All short pick orders were queued for creation! Please check the{' '}
                <span className="font-semibold">#status-ops-tech</span> Slack
                channel for current status.
              </SuccessDisplay>
            )}
          </>
        )}

        <div className="space-y-4">
          {purchasedGoods.map((purchasedGood, i) => {
            return (
              <CreationRow
                key={purchasedGood.clientSideID}
                creationError={stages[purchasedGood.clientSideID]?.error}
                index={i}
                purchasedGood={purchasedGood}
                stage={stages[purchasedGood.clientSideID]?.stage}
              />
            )
          })}
        </div>
      </div>
      {!isCreating && (
        <div className="mt-8">
          {hasError && (
            <ErrorDisplay>
              Not all short pick orders were queued successfully.
            </ErrorDisplay>
          )}

          <div className="mt-4 flex items-center justify-end">
            <div className="w-24">
              <Button
                onClick={() => {
                  onClickDone()
                }}
                type="button"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CreateShortPickOrderCreation

const CreationRow = ({
  creationError,
  index,
  purchasedGood,
  stage,
}: {
  creationError: Error | undefined
  index: number
  purchasedGood: FormDataValidated['purchasedGoods'][number]
  stage: Stage | undefined
}): JSX.Element => {
  let errorMessage = ''
  let statusContent = <CircleLoader loaderStyle="colored" />
  if (stage === 'success') {
    statusContent = (
      <div
        aria-label={`Purchased Good ${index + 1} Success`}
        className="h-6 w-6 text-green"
      >
        <CheckIcon />
      </div>
    )
  } else if (creationError) {
    errorMessage = isAxiosResponseError(creationError)
      ? creationError.response?.data.message ??
        'Unknown error creating short pick order'
      : creationError.message

    statusContent = (
      <div className="h-6 w-6 text-red">
        <XIcon />
      </div>
    )
  }

  return (
    <div
      key={purchasedGood.item.value.id}
      className="flex items-center space-x-2"
    >
      <div className="flex-grow">
        <FormGroup
          error={errorMessage ? `Error: ${errorMessage}` : undefined}
          label="Purchased Good"
        >
          <p className="text-sm">
            {purchasedGood.item.label}{' '}
            <span className="text-xs text-grey">
              ({purchasedGood.item.value.baseSku})
            </span>
          </p>
        </FormGroup>
      </div>
      <div className="w-20 flex-shrink-0">
        <div className="flex items-center space-x-2">
          <span>{purchasedGood.count}</span>
          <div className="w-12">lbs</div>
        </div>
      </div>
      <div className="w-8 flex-shrink-0">{statusContent}</div>
    </div>
  )
}
