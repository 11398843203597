import { clsx } from 'clsx'
import { keys } from 'lodash-es'
import { Link } from 'react-router-dom'

import { getTheme } from 'utils/theme-helper'
import { ProductionCycle } from 'types/combinedAPI/domainModels'
import {
  selectFacilityNetwork,
  setPlanningTab,
  setProductionCycle,
  setProductionRecord,
} from 'slices/production'
import { themes } from '../../constants/themes'

import { useAppDispatch, useAppSelector } from 'hooks'
import { useAuth } from 'contexts/auth'

const Header = () => {
  const dispatch = useAppDispatch()

  const { isLoggedIn, onJWTChanged, userInfo } = useAuth()

  const facilityNetwork = useAppSelector(
    (state) => state.production.facilityNetwork
  )
  const loading = useAppSelector((state) => state.loading.default)
  const productionCycle = useAppSelector(
    (state) => state.production.productionCycle
  )
  const productionRecord = useAppSelector(
    (state) => state.production.productionRecord
  )

  function _clickTermBreadcrumb() {
    if (!productionRecord) {
      return
    }

    window.scrollTo(0, 0)
    dispatch(setPlanningTab(0))
  }

  function _setProductionCycle(cycle: ProductionCycle) {
    if (!productionRecord) {
      return
    }

    dispatch(setProductionCycle(cycle))
  }

  function _logout() {
    onJWTChanged(null)
  }

  function _resetGlobalState() {
    dispatch(setProductionRecord(''))
    dispatch(selectFacilityNetwork(''))
  }

  const theme = getTheme(themes, facilityNetwork)

  return (
    <div
      className="fixed left-0 right-0 top-0 z-20 flex h-16 items-center justify-between px-4 print:hidden"
      style={{
        backgroundColor: theme.headerColor,
      }}
    >
      <div className="flex items-center space-x-4 text-lg font-bold text-white">
        <div>
          <span onClick={() => _resetGlobalState()}>
            <Link to="/">misevala</Link>
          </span>
        </div>
        {facilityNetwork && <span>{facilityNetwork.displayName}</span>}
        {productionRecord && (
          <span
            className="cursor-pointer underline"
            onClick={() => _clickTermBreadcrumb()}
          >
            Term {productionRecord.termID}
          </span>
        )}
      </div>

      {productionRecord && (
        <div className="absolute left-1/2 top-1/2 flex w-[300px] -translate-x-1/2 -translate-y-1/2 justify-center">
          <div>
            {keys(productionRecord.cycles).map((cycle, i) => (
              <button
                key={i}
                className={clsx(
                  'rounded-full border px-8 py-4 leading-none text-white'
                )}
                onClick={() => _setProductionCycle(cycle as ProductionCycle)}
                style={{
                  backgroundColor:
                    productionCycle === cycle
                      ? theme.cycleButtonColor
                      : undefined,
                  borderColor: theme.cycleButtonColor,
                }}
              >
                Cycle {cycle}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-end">
        {loading ? (
          <div className="flex items-center space-x-2">
            <div className="h-6 w-6 animate-spinner-grow rounded-full bg-green opacity-0" />
            <div className="h-6 w-6 animate-spinner-grow rounded-full bg-blue opacity-0" />
            <div className="h-6 w-6 animate-spinner-grow rounded-full bg-gold opacity-0" />
            <div className="h-6 w-6 animate-spinner-grow rounded-full bg-red opacity-0" />
          </div>
        ) : (
          ''
        )}

        {isLoggedIn && (
          <div className="text-right text-sm text-white">
            <div>{userInfo?.appAuth.appClaims.email}</div>
            <a className="block cursor-pointer" onClick={_logout}>
              Logout <i className="fas fa-sign-out-alt" />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
