import { PrepLabel } from 'types/combinedAPI/domainModels'
import { put } from './baseAPI'

export interface EditPrepLabel {
  data: { location: string }
  prepLabelID: string
  productionTerm: string
}

export type EditPrepLabelResponse = PrepLabel

export const ENDPOINTS = {
  EDIT_PREP_LABEL: ({
    prepLabelID,
    productionTerm,
  }: Omit<EditPrepLabel, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/prepLabels/${prepLabelID}`,
    version: 'v0' as const,
  }),
}

export async function editPrepLabel({
  data,
  prepLabelID,
  productionTerm,
}: EditPrepLabel): Promise<EditPrepLabelResponse> {
  return put({
    data,
    ...ENDPOINTS.EDIT_PREP_LABEL({ prepLabelID, productionTerm }),
  })
}
