import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { ReactNode } from 'react'

import { commonPDFStyles } from 'utils/pdfs'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatCommas } from 'utils/general'
import { formatDollars } from 'utils/currency'
import {
  getFormattedUnitPrice,
  getMissingVendorSKUs,
  orderLineItems,
} from './helpers'
import {
  PurchaseOrderFull,
  PurchaseOrderLineItemExpanded,
} from 'types/internal'

const COLUMN_WIDTHS = ['30%', '30%', '7%', '7%', '7%', '9%']

const ReceivingPDF = ({
  purchaseOrders,
}: {
  purchaseOrders: PurchaseOrderFull[]
}): JSX.Element => {
  const documentTitle = `receiving-doc${
    purchaseOrders.length === 1 ? `-${purchaseOrders[0].snapReceiptID}` : `s`
  }`

  return (
    <Document title={documentTitle}>
      {purchaseOrders.map((purchaseOrder) => {
        const missingVendorSKUs = getMissingVendorSKUs(purchaseOrder)
        const orderedLineItems = orderLineItems(purchaseOrder.lineItems)

        return (
          <Page
            key={purchaseOrder.id}
            orientation="landscape"
            size="LETTER"
            style={{
              ...commonPDFStyles.page,
              width: '11in',
              height: '8.5in',
              paddingLeft: '5px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px 0',
              }}
            >
              <Text style={{ marginRight: '40px', fontSize: '26px' }}>
                {purchaseOrder.snapReceiptID}
              </Text>
              <View style={{ marginRight: '40px' }}>
                <LabelGroup label="Vendor Name">
                  <Text>{purchaseOrder.vendor.name}</Text>
                </LabelGroup>
              </View>
              <LabelGroup label="Requested Delivery Date">
                <Text>
                  {formatDate(purchaseOrder.requestedDeliveryDate, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </Text>
              </LabelGroup>
            </View>
            <View
              style={{
                width: '100%',
                height: '1px',
                margin: '10px 0',
                backgroundColor: '#e4e4e4',
              }}
            />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '5px',
                borderBottom: '1px solid #e4e4e4',
                color: '#696969',
                fontSize: '11px',
                textTransform: 'uppercase',
              }}
            >
              <Text style={{ width: COLUMN_WIDTHS[0] }}>Purchased Good</Text>
              <Text style={{ width: COLUMN_WIDTHS[1] }}>Tovala Lot Number</Text>
              <Text style={{ width: COLUMN_WIDTHS[2] }}>QTY</Text>
              <Text style={{ width: COLUMN_WIDTHS[3] }}>Unit Wt (lbs)</Text>
              <Text style={{ width: COLUMN_WIDTHS[4] }}>Total Wt (lbs)</Text>
              <Text style={{ width: COLUMN_WIDTHS[5] }}>Cost</Text>
            </View>
            {orderedLineItems.map((lineItem, i) => {
              return (
                <View key={i} style={{ marginBottom: '10px' }} wrap={false}>
                  <LineItemRow lineItem={lineItem} />
                </View>
              )
            })}

            <Text
              fixed
              render={({ subPageNumber, subPageTotalPages }) => {
                return `${purchaseOrder.snapReceiptID} / ${subPageNumber} of ${subPageTotalPages}`
              }}
              style={{
                position: 'absolute',
                right: 10,
                bottom: 10,
                color: '#696969',
                fontSize: '11px',
              }}
            />

            {missingVendorSKUs.length > 0 && (
              <View
                fixed={true}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 0, 0, 0.75)',
                }}
              >
                <Text
                  style={{ width: '75%', marginTop: '80px', color: '#fff' }}
                >
                  Some SKUs in this purchase order are not marked as available
                  for this facility network. Please see the purchase order
                  details page for more details and check the Ingredient List or
                  reach out in #help-ops-tech.
                </Text>
              </View>
            )}
          </Page>
        )
      })}
    </Document>
  )
}

export default ReceivingPDF

const LabelGroup = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}): JSX.Element => {
  return (
    <View style={{ fontSize: '16px' }}>
      <Text
        style={{
          marginBottom: '5px',
          color: '#696969',
          fontSize: '12px',
          textTransform: 'uppercase',
        }}
      >
        {label}
      </Text>
      {children}
    </View>
  )
}

const LineItemRow = ({
  lineItem,
}: {
  lineItem: PurchaseOrderLineItemExpanded
}): JSX.Element => {
  const { barcodes } = lineItem
  const needsToCheckQty =
    !Number.isInteger(lineItem.receivingQty) || lineItem.receivingUnitWt === 0

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '22px 0',
        borderBottom: '1px solid #e4e4e4',
        fontSize: '12px',
      }}
    >
      <View style={{ width: COLUMN_WIDTHS[0] }}>
        <Text style={{ maxLines: 1, textOverflow: 'ellipsis' }}>
          {lineItem.vendorSKU?.name}
        </Text>
        <Text
          style={{
            maxLines: 1,
            textOverflow: 'ellipsis',
            fontSize: '11px',
            color: '#696969',
          }}
        >
          Vendor SKU: {lineItem.vendorSKU?.sku || 'None'}
        </Text>
        {lineItem.baseSku && (
          <Image
            src={() => {
              return barcodes?.baseSku ? barcodes.baseSku : ''
            }}
            style={{ width: '100px', height: '30px' }}
          />
        )}
      </View>
      <View style={{ width: COLUMN_WIDTHS[1] }}>
        <Text>
          {lineItem.baseSku
            ? `Use LOT Code Guide for item: ${lineItem.baseSku}`
            : 'Use LOT Code Guide'}
        </Text>
      </View>
      <Text style={{ width: COLUMN_WIDTHS[2] }}>
        {needsToCheckQty
          ? 'CHECK'
          : `${lineItem.receivingQty} ${lineItem.receivingUnit}`}
      </Text>
      <View style={{ width: COLUMN_WIDTHS[3] }}>
        {needsToCheckQty ? null : (
          <>
            <Text style={{ marginRight: '10px' }}>
              {lineItem.receivingUnitWt}
            </Text>
            <Image
              src={() => {
                return barcodes?.receivingUnitWt ? barcodes.receivingUnitWt : ''
              }}
              style={{ width: '100%', height: '30px' }}
            />
          </>
        )}
      </View>
      <Text style={{ width: COLUMN_WIDTHS[4] }}>
        {formatCommas(lineItem.totalWt)}
      </Text>
      <View style={{ display: 'flex', width: COLUMN_WIDTHS[5] }}>
        <Text>{formatDollars(lineItem.total)}</Text>
        <Text style={{ color: '#696969', fontSize: '11px' }}>
          {getFormattedUnitPrice(lineItem)}
        </Text>
      </View>
    </View>
  )
}
