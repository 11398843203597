import { chunk, times } from 'lodash-es'
import { PropsWithChildren } from 'react'
import ReactPDF, { Document, Page, Text, View } from '@react-pdf/renderer'

import { commonPDFStyles } from 'utils/pdfs'
import { PrepLabel, ProductionCycle } from 'types/combinedAPI/domainModels'

type PrepLabelWithBatchNumber = PrepLabel & { batchNumber: number }

function assemblePartsArray(parts: PrepLabel[]) {
  const labels: PrepLabelWithBatchNumber[] = []

  parts.forEach((part) => {
    times(part.numBatches, (batchNumber) => {
      labels.push({ batchNumber: batchNumber + 1, ...part })
    })
  })

  return chunk(labels, 30)
}

function fontSizeFor(text: string, cycle: string) {
  let threshold = 6

  if (Number(cycle) > 1) {
    threshold = 8
  }

  if (text.length + threshold >= 40) {
    return 8
  }

  if (text.length + threshold >= 30) {
    return 10
  }

  return 12
}

export interface LabelPDFProps {
  parts: PrepLabel[]
  productionCycle: ProductionCycle
  term: string
}

const LabelPDF = ({ parts, productionCycle, term }: LabelPDFProps) => {
  const cycle = productionCycle
  const pages = assemblePartsArray(parts)

  return (
    <Document title={`prep-labels-term-${term}`}>
      {pages.map((parts, i) => (
        <Page
          key={i}
          size="LETTER"
          style={{
            ...commonPDFStyles.page,
            padding: '40px 18.24px 30px 18.24px',
          }}
          wrap={false}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
            }}
          >
            {parts.map((part, i) => {
              return (
                <Label key={i} cycle={cycle}>
                  <View
                    style={{
                      width: '100%',
                      height: '90%',
                      overflow: 'hidden',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 11,
                        left: 5,
                        textDecoration: 'underline',
                        top: 15,
                        position: 'absolute',
                      }}
                    >
                      {part.batchNumber} of {part.numBatches}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontSizeFor(part.partTitle, cycle),
                        lineHeight: 1.3,
                        textAlign: 'center',
                      }}
                    >
                      {part.partTitle}
                    </Text>
                    <Text
                      style={{
                        fontSize: 12,
                        lineHeight: 1.3,
                        textAlign: 'center',
                      }}
                    >
                      {(part.totalWeightPounds / part.numBatches).toFixed(2)}{' '}
                      lbs
                    </Text>
                    <Text
                      style={{
                        fontSize: fontSizeFor(part.parentTitle, cycle),
                        lineHeight: 1.3,
                        textAlign: 'center',
                      }}
                    >
                      {part.parentTitle}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontSizeFor(
                          part.shortTitle || part.mealTitle,
                          cycle
                        ),
                        lineHeight: 1.3,
                        textAlign: 'center',
                      }}
                    >
                      {part.shortTitle || part.mealTitle} (#
                      {`${part.apiMealCode}${Number(cycle) > 1 ? '-a' : ''}`})
                    </Text>
                    <Text
                      style={{
                        fontSize: 11,
                        right: 5,
                        textDecoration: 'underline',
                        top: 15,
                        position: 'absolute',
                      }}
                    >
                      term {term}
                    </Text>
                  </View>
                </Label>
              )
            })}
          </View>
        </Page>
      ))}
    </Document>
  )
}

export default LabelPDF

const Label = ({
  cycle,
  ...rest
}: PropsWithChildren<ReactPDF.ViewProps> & { cycle: string }) => {
  return (
    <View
      style={{
        width: '31%',
        position: 'relative',
        margin: '0',
        height: '10%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Number(cycle) > 1 ? '#ebc3bb' : undefined,
      }}
      {...rest}
    />
  )
}
