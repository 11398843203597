import { TagCategories } from 'types/combinedAPI/domainModels'

export function getTransferPartialBinUnit(
  container: TagCategories['container']
) {
  let unit = ''

  if (container.includes('tray')) {
    unit = 'trays'
  }

  if (container.includes('cup')) {
    unit = 'garnish trays'
  }

  if (container.includes('sachet')) {
    unit = 'sachets'

    if (container.includes('tray')) {
      unit = `tray ${unit}`
    }
  }

  if (container.includes('dry sachet')) {
    unit = 'dry sachets'

    if (container.includes('tray')) {
      unit = `tray ${unit}`
    }
  }

  return unit
}
