import { ComposedMeal } from 'types/internal'
import {
  Facility,
  FacilityNetwork,
  Meal,
  ProductionCycle,
  Tag,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { filterSleevingFacilities } from 'utils/facility-networks'
import { getLocationBackgroundColor, getTheme } from 'utils/theme-helper'
import { themes } from 'constants/themes'

import { useCachedTags } from 'hooks/tags'
import { useUpdateProductionMealTags } from 'hooks/combinedAPI/productionMeals'
import { uniqBy } from 'lodash-es'

const SLEEVING_CONFIGURATION_OPTIONS: TagCategories['sleeving_configuration'][] =
  [
    'Standard',
    'Tall Boi',
    'Drop Shelf',
    'NGT Drop Shelf',
    'Short Boi',
    'NGT Tall Boi',
  ]

export interface Props {
  facilityNetwork: FacilityNetwork
  productionCycle: ProductionCycle
  productionMeal: ComposedMeal
  productionMealCycle2: Meal | undefined
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}

const MealSleeving = ({
  facilityNetwork,
  productionCycle,
  productionMeal,
  productionMealCycle2,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: Props): JSX.Element => {
  const { mutateAsync: updateProductionMealTags } = useUpdateProductionMealTags(
    {
      onSettled: () => {
        refetchProductionRecord()
      },
    }
  )

  const { onTagsChanged, tags } = useCachedTags({
    initialTags: productionMeal.tags,
    updateTagsFn: async ({ tags }) => {
      return updateProductionMealTags({
        data: { tags },
        mealID: productionMeal.id,
        productionCycle,
        productionMealCycle2,
        productionTerm,
      })
    },
  })

  async function editTag(tag: Tag) {
    // Note: It's important for newTag to come first in the _.uniqBy call so the updated tag will
    // be used instead of the old, existing tag.
    onTagsChanged({ tags: uniqBy([tag, ...tags], 'category') })
  }

  const sleevingLocation =
    tags.find(({ category }) => category === 'sleeving_location')?.title ?? ''
  const theme = getTheme(themes, facilityNetwork)

  return (
    <div>
      <div className="p-2">
        <label
          className="mb-0"
          htmlFor={`sleeving-location-${productionMeal.id}`}
        >
          <b>Location:</b>
        </label>
        <select
          className="w-full border border-black text-[#212529]"
          disabled={readOnly}
          id={`sleeving-location-${productionMeal.id}`}
          onChange={(e) => {
            editTag({
              category: 'sleeving_location',
              title: e.target.value as Facility['title'],
            })
          }}
          style={{
            backgroundColor: getLocationBackgroundColor(
              sleevingLocation,
              theme
            ),
          }}
          value={sleevingLocation}
        >
          {filterSleevingFacilities(facilityNetwork).ofType.map((facility) => (
            <option key={facility.title} value={facility.title}>
              {facility.displayName}
            </option>
          ))}
        </select>
      </div>
      <div className="p-2">
        <label
          className="mb-0"
          htmlFor={`sleeving-configuration-${productionMeal.id}`}
        >
          <b>Configuration:</b>
        </label>
        <select
          className="w-full"
          data-testid="sleeving-configuration-dropdown"
          disabled={readOnly}
          id={`sleeving-configuration-${productionMeal.id}`}
          onChange={(e) => {
            editTag({
              category: 'sleeving_configuration',
              title: e.target
                .value as (typeof SLEEVING_CONFIGURATION_OPTIONS)[number],
            })
          }}
          value={
            tags.find(({ category }) => category === 'sleeving_configuration')
              ?.title ?? ''
          }
        >
          <option disabled value="">
            -
          </option>
          {SLEEVING_CONFIGURATION_OPTIONS.map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default MealSleeving
