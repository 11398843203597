import { PropsWithChildren } from 'react'
import ReactPDF, { Text, View } from '@react-pdf/renderer'

export const Signature = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        marginTop: '80px',
        width: '50%',
        height: '2px',
        backgroundColor: 'black',
      }}
      {...props}
    />
  )
}

export const FullBins = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        marginTop: '10px',
      }}
      {...props}
    />
  )
}

export const FullBinsText = ({
  style,
  ...rest
}: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '30px',
        ...style,
      }}
      {...rest}
    />
  )
}

export const ThickBorder = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        height: '3px',
        width: '100%',
        backgroundColor: 'black',
      }}
      {...props}
    />
  )
}

export const TermRow = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        width: '100%',
        padding: '25px 0',
      }}
      {...props}
    />
  )
}

export const TermText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '30px',
      }}
      {...props}
    />
  )
}

export const CodeColorContainer = ({
  style,
  ...rest
}: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        marginTop: '20px',
        height: '80px',
        ...style,
      }}
      {...rest}
    />
  )
}

export const CodeColorText = ({
  small,
  style,
  ...rest
}: PropsWithChildren<ReactPDF.TextProps> & { small?: boolean }) => {
  return (
    <Text
      style={{
        fontSize: small ? '28px' : '40px',
        fontWeight: 'bold',
        ...style,
      }}
      {...rest}
    />
  )
}

export const BigRedTitle = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '60px',
        color: 'red',
        textAlign: 'center',
      }}
      {...props}
    />
  )
}

export const TitleContainer = (
  props: PropsWithChildren<ReactPDF.ViewProps>
) => {
  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '20px',
      }}
      {...props}
    />
  )
}

export const MealRow = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        border: '1px solid black',
        // breakAfter: 'page',
        // breakInside: 'avoid-page',
      }}
      {...props}
    />
  )
}

export const ColText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '12px',
      }}
      {...props}
    />
  )
}

export const MealCol = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        width: '16.5%',
        padding: '5px',
      }}
      {...props}
    />
  )
}
