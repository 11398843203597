import { groupBy, keys, sortBy } from 'lodash-es'

import { getCarrierName } from 'utils/lineHauls'
import { LineHaul, ProductionCycle } from 'types/combinedAPI/domainModels'

import H4 from 'components/common/bs/H4'
import Table, { TBody, Td, Th, Thead, Tr } from 'components/common/Table'

const CYCLE_1_FULFILLMENT_DAY_ORDER = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]

const CYCLE_2_FULFILLMENT_DAY_ORDER = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

const ExpectedLineHauls = ({
  lineHauls,
  productionCycle,
}: {
  lineHauls: LineHaul[]
  productionCycle: ProductionCycle
}): JSX.Element => {
  const lineHaulsByDay = groupBy(lineHauls, (lineHaul) => lineHaul.dayOfWeek)

  const fulfillmentDaysArray =
    productionCycle === '1'
      ? CYCLE_1_FULFILLMENT_DAY_ORDER
      : CYCLE_2_FULFILLMENT_DAY_ORDER

  const sortedFulfillmentDays = sortBy(keys(lineHaulsByDay), (day) => {
    return fulfillmentDaysArray.indexOf(day)
  })

  return (
    <div>
      <H4>Expected Line Hauls</H4>

      <div className="flex space-x-8 lg:space-x-4">
        {sortedFulfillmentDays.map((dayOfWeek) => {
          return (
            <div key={dayOfWeek} className="w-1/3 shrink">
              <span data-testid="lineHaulDayOfWeek">{dayOfWeek}</span>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Packout Position</Th>
                    <Th>Carrier Origin</Th>
                    <Th>Packout Order</Th>
                  </Tr>
                </Thead>
                <TBody>
                  {lineHaulsByDay[dayOfWeek].map((lineHaul, index) => {
                    return (
                      <Tr key={'lineHaul-' + index}>
                        <Td>{lineHaul.packoutPosition}</Td>
                        <Td>{getCarrierName(lineHaul)}</Td>
                        <Td>{lineHaul.boxSizeOrder.join(', ')}</Td>
                      </Tr>
                    )
                  })}
                </TBody>
              </Table>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ExpectedLineHauls
