import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { reject } from 'lodash-es'

import { ComposedMealBOMItem } from 'types/internal'
import { error } from 'slices/notifications'
import {
  Meal,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { useBulkUpdateProductionPartTags } from 'hooks/combinedAPI/productionParts'

export interface Props {
  bom: ComposedMealBOMItem
  children: ReactNode
  productionCycle: ProductionCycle
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}

const MealBOMSlot = ({
  bom,
  children,
  productionCycle,
  productionMealCycle2,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const canRemove = !readOnly && productionCycle === '1'

  const { mutateAsync: bulkUpdateTags } = useBulkUpdateProductionPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  async function deleteContainerTag() {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to remove a component.')
      )
    }

    bulkUpdateTags({
      boms: [bom],
      productionCycle,
      productionMealCycle2,
      productionRecord,
      productionTerm,
      tags: reject(bom.tags, (tag) => tag.category === 'container'),
    })
  }

  return (
    <div
      className={clsx('flex flex-col p-2 text-xs', {
        'pl-8': canRemove,
      })}
    >
      <div className="relative mb-1 text-sm font-bold">
        {canRemove && (
          <div
            aria-label="Remove meal BOM"
            className="absolute -left-6 top-0 cursor-pointer"
            onClick={() => {
              deleteContainerTag()
            }}
          >
            <i className="fas fa-minus-circle" />
          </div>
        )}
        {bom.title}&nbsp;
        {bom.combined ? (
          <div className="absolute right-0 top-0 flex h-5 items-center bg-blue px-2 text-white">
            Combined
          </div>
        ) : null}
      </div>

      {children}
    </div>
  )
}

export default MealBOMSlot
