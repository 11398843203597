import { orderBy } from 'lodash-es'

import { BOMItem, ProductionPart } from 'types/combinedAPI/domainModels'
import { getProductionPartWeights } from 'utils/production'

import { Td, Tr } from 'components/common/Table'

const ProductionPartBOMs = ({
  part,
}: {
  part: ProductionPart
}): JSX.Element => {
  const { batchWt, totalWt, useYieldWeight, yieldWt } =
    getProductionPartWeights(part)

  return (
    <>
      {part.billOfMaterials &&
        orderBy(part.billOfMaterials, (bom) => bom.title.toLowerCase()).map(
          (bom, i) => {
            return (
              <ProductionPartBOM
                key={i}
                bom={bom}
                part={part}
                useYieldWeight={useYieldWeight}
              />
            )
          }
        )}
      <Tr withBlackTopBorder>
        <Td></Td>
        <Td>
          <b>{totalWt} lbs total</b>
        </Td>
        <Td>
          <b>{yieldWt} lbs yield</b>
        </Td>
        <Td>
          {part.numBatches
            ? `${batchWt} ${useYieldWeight ? 'yield' : 'total'} lbs per batch`
            : 'Has not been batched'}
        </Td>
      </Tr>
    </>
  )
}

export default ProductionPartBOMs

const ProductionPartBOM = ({
  bom,
  part,
  useYieldWeight,
}: {
  bom: BOMItem
  part: ProductionPart
  useYieldWeight: boolean
}): JSX.Element => {
  return (
    <Tr>
      <Td>{bom.title}</Td>
      <Td>{bom.totalWeightRequiredPounds.toFixed(2)}lbs total</Td>
      <Td>{bom.finalYieldPounds.toFixed(2)}lbs yield</Td>
      <Td>
        {part.numBatches &&
          (useYieldWeight
            ? bom.finalYieldPounds
            : bom.totalWeightRequiredPounds / part.numBatches
          ).toFixed(2)}{' '}
        lbs per batch
      </Td>
    </Tr>
  )
}
