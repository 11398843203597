import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'

interface NotificationState {
  level: 'error' | 'success'
  message: string
  timeoutId: number | null
}

const initialState: NotificationState = {
  level: 'error',
  message: '',
  timeoutId: null,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    dismissNotification: () => {
      return initialState
    },
    displayNotification: (state, action: PayloadAction<NotificationState>) => {
      if (state.timeoutId) {
        window.clearTimeout(state.timeoutId)
      }

      return action.payload
    },
    reset: () => {
      return initialState
    },
  },
})

function createNotification({
  dispatch,
  level,
  message,
}: {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
  level: 'error' | 'success'
  message: string
}) {
  return new Promise<void>((resolve) => {
    const timeoutId = window.setTimeout(() => {
      dispatch(notificationSlice.actions.dismissNotification())

      resolve()
    }, 3000)

    dispatch(
      notificationSlice.actions.displayNotification({
        level,
        message,
        timeoutId,
      })
    )
  })
}

export const error = createAsyncThunk(
  'notification/error',
  async (message: string, { dispatch }) => {
    return createNotification({ dispatch, level: 'error', message })
  }
)

export const success = createAsyncThunk(
  'notification/success',
  async (message: string, { dispatch }) => {
    return createNotification({ dispatch, level: 'success', message })
  }
)

export default notificationSlice.reducer

export const { reset } = notificationSlice.actions
