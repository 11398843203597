import { useRef, useState } from 'react'
import { Navigate } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'

import { ProductionRecord } from 'types/combinedAPI/domainModels'
import { setPlanningTab, setProductionRecord } from 'slices/production'

import { useAppDispatch, useAppSelector } from 'hooks'
import {
  useInvalidateLineHauls,
  useLineHauls,
} from 'hooks/combinedAPI/lineHauls'
import {
  useInvalidateProductionRecord,
  useProductionRecord,
} from 'hooks/combinedAPI/productionRecords'
import { useIsReadOnly } from 'contexts/auth'
import { usePartFilters } from 'hooks/combinedAPI/productionParts'
import Button from 'components/common/bs/Button'
import EmployeeLabels from 'components/production/shipping/employeeLabels/EmployeeLabels'
import ExecutionDocuments from 'components/production/execution/documents/ExecutionDocuments'
import ExecutionLabels from 'components/production/execution/labels/ExecutionLabels'
import ExecutionWorkOrders from './execution/work-orders/ExecutionWorkOrders'
import Input from 'components/common/bs/Input'
import PalletSheets from 'components/production/shipping/pallet-sheets/PalletSheets'
import PickOrders from './planning/pickOrders/PickOrders'
import PlanningMeals from 'components/production/planning/meals/PlanningMeals'
import PlanningParts from 'components/production/planning/parts/PlanningParts'
import PlanningProductionSchedule from './planning/schedule/PlanningProductionSchedule'
import ProductionTermDates from './dashboard/ProductionTermDates'
import QaChecklist from 'components/production/QaChecklist'
import QaDocuments from 'components/production/qa/QaDocuments'
import SensoryTracking from 'components/production/qa/sensory/SensoryTracking'
import ShippingDocuments from 'components/production/shipping/documents/ShippingDocuments'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import WeightCheck from 'components/production/qa/weightCheck/WeightCheck'
import WeightVerificationChecklist from 'components/production/qa/weightVerificationChecklist/WeightVerificationChecklist'

const ProductionTermPage = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const termInput = useRef<HTMLInputElement | null>(null)

  const facilityNetwork = useAppSelector(
    (state) => state.production.facilityNetwork
  )
  const productionRecord = useAppSelector(
    (state) => state.production.productionRecord
  )

  const [activeGlobalTab, setActiveGlobalTab] = useState(0)
  const [term, setTerm] = useState('')

  const { invalidateProductionRecord } = useInvalidateProductionRecord()
  const { refetch: refetchProductionRecord } = useProductionRecord({
    facilityNetworkTitle: facilityNetwork ? facilityNetwork.title : '',
    productionTerm: term,
  })

  const { invalidateLineHauls } = useInvalidateLineHauls()
  useLineHauls({
    facilityNetworkID: facilityNetwork ? facilityNetwork.id : '',
    productionTerm: term,
  })

  function fetchTerm(newProductionTerm: string) {
    onChangePlanningTab(0)

    dispatch(setProductionRecord(''))
    setTerm(newProductionTerm)

    if (
      productionRecord &&
      `${productionRecord.termID}` === newProductionTerm
    ) {
      invalidateProductionRecord({
        facilityNetworkTitle: facilityNetwork ? facilityNetwork.title : '',
        productionTerm: term,
      })
      invalidateLineHauls({
        facilityNetworkID: facilityNetwork ? facilityNetwork.id : '',
        productionTerm: term,
      })
    }
  }

  function onChangePlanningTab(tab: number) {
    setActiveGlobalTab(0)
    dispatch(setPlanningTab(tab))
  }

  if (!facilityNetwork) {
    return <Navigate replace={true} to="/" />
  }

  return (
    <div className="px-4 py-10">
      <div className="w-80 print:hidden">
        <form
          onSubmit={(event) => {
            event.preventDefault()

            fetchTerm(termInput.current?.value ?? '')
          }}
        >
          <div className="flex">
            <Input
              ref={termInput}
              aria-describedby="term-Id"
              aria-label="termId"
              autoFocus={true}
              isAppend
              placeholder="Fetch Term by Term ID"
            />
            <div className="w-44">
              <Button buttonStyle="stroke" isLeftAppend type="submit">
                Fetch Term
              </Button>
            </div>
          </div>
        </form>
      </div>

      {productionRecord ? (
        <ProductionTermBody
          activeGlobalTab={activeGlobalTab}
          onChangeGlobalTab={setActiveGlobalTab}
          onChangePlanningTab={onChangePlanningTab}
          productionRecord={productionRecord}
          refetchProductionRecord={() => {
            refetchProductionRecord({ cancelRefetch: true })
          }}
        />
      ) : (
        <div className="mt-4">
          <ProductionTermDates onTermClicked={fetchTerm} />
        </div>
      )}
    </div>
  )
}

export default ProductionTermPage

const ProductionTermBody = ({
  activeGlobalTab,
  onChangeGlobalTab,
  onChangePlanningTab,
  productionRecord,
  refetchProductionRecord,
}: {
  activeGlobalTab: number
  onChangeGlobalTab(tab: number): void
  onChangePlanningTab(tab: number): void
  productionRecord: ProductionRecord
  refetchProductionRecord(): void
}): JSX.Element => {
  const isReadOnly = useIsReadOnly()
  const isReadOnlyMod = useIsReadOnly('mod')

  const facilityNetwork = useAppSelector(
    (state) => state.production.facilityNetwork
  )
  const lineHauls = useAppSelector((state) => state.production.lineHauls)
  const planningTab = useAppSelector((state) => state.production.planningTab)
  const productionCycle = useAppSelector(
    (state) => state.production.productionCycle
  )

  const {
    filters: partFilters,
    changeFilter: changePartFilter,
    reset: resetPartFilters,
  } = usePartFilters()

  const multifacilityNetwork =
    facilityNetwork && facilityNetwork.facilities.length > 1

  const productionTerm = `${productionRecord.termID}`

  if (!facilityNetwork) {
    return <Navigate replace={true} to="/" />
  }

  return (
    <>
      <QaChecklist
        facilityNetwork={facilityNetwork}
        onClickViewParts={(partIDs) => {
          changePartFilter('partIDs', partIDs)
          onChangePlanningTab(1)
        }}
        productionCycle={productionCycle}
        productionRecord={productionRecord}
      />
      <div className="mt-8">
        <TabGroup onChange={onChangeGlobalTab} selectedIndex={activeGlobalTab}>
          <div className="print:hidden">
            <TabList>
              <Tab>Planning</Tab>
              <Tab>Execution</Tab>
              <Tab>QA</Tab>
              <Tab>Shipping</Tab>
            </TabList>
          </div>
          <TabPanels>
            <TabPanel>
              <div className="pt-8">
                <TabGroup
                  onChange={onChangePlanningTab}
                  selectedIndex={planningTab}
                >
                  <div className="print:hidden">
                    <TabList>
                      <Tab>Meals</Tab>
                      <Tab>Parts</Tab>
                      <Tab>Production Schedule</Tab>
                      <Tab>Pick Orders</Tab>
                    </TabList>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <PlanningMeals
                        // We want to reset this component's internal state when the production cycle changes.
                        // At the time of writing, this is so the focusedMealCountIndex gets reset to -1 before
                        // a rerender causes a meal count input to be focused (and the page to potentially scroll).
                        key={`${productionTerm}-${productionCycle}`}
                        facilityNetwork={facilityNetwork}
                        onMealCodeClicked={(mealCode) => {
                          changePartFilter('mealCode', mealCode)
                        }}
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                        refetchProductionRecord={refetchProductionRecord}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PlanningParts
                        facilityNetwork={facilityNetwork}
                        filters={partFilters}
                        onChangeFilter={changePartFilter}
                        onViewAllPartsClicked={resetPartFilters}
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                        refetchProductionRecord={refetchProductionRecord}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PlanningProductionSchedule
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                        readOnly={isReadOnly}
                        refetchProductionRecord={refetchProductionRecord}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PickOrders
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                      />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="pt-8">
                <TabGroup>
                  <div className="print:hidden">
                    <TabList>
                      <Tab>Work Orders</Tab>
                      <Tab>Labels</Tab>
                      <Tab>Documents</Tab>
                    </TabList>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <ExecutionWorkOrders
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ExecutionLabels
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        readOnly={isReadOnlyMod}
                        refetchProductionRecord={refetchProductionRecord}
                        termId={productionTerm}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ExecutionDocuments
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                      />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="pt-8">
                <TabGroup>
                  <div className="print:hidden">
                    <TabList>
                      <Tab>Sensory</Tab>
                      <Tab>Weight Check</Tab>
                      <Tab>Weight Verification Checklist</Tab>
                      <Tab>
                        <span>
                          Print Documents <i className="fas fa-print" />
                        </span>
                      </Tab>
                    </TabList>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <SensoryTracking />
                    </TabPanel>
                    <TabPanel>
                      <WeightCheck />
                    </TabPanel>
                    <TabPanel>
                      <WeightVerificationChecklist
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                      />
                    </TabPanel>
                    <TabPanel>
                      <QaDocuments
                        productionCycle={productionCycle}
                        productionRecord={productionRecord}
                        productionTerm={productionTerm}
                      />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="pt-8">
                <TabGroup>
                  <div className="print:hidden">
                    <TabList>
                      <Tab>Pallet Sheets</Tab>
                      {multifacilityNetwork && <Tab>Documents</Tab>}
                      <Tab>Employee Labels</Tab>
                    </TabList>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <PalletSheets
                        lineHauls={lineHauls}
                        productionCycle={productionCycle}
                      />
                    </TabPanel>
                    {multifacilityNetwork && (
                      <TabPanel>
                        <ShippingDocuments
                          facilityNetwork={facilityNetwork}
                          productionCycle={productionCycle}
                          productionRecord={productionRecord}
                          productionTerm={productionTerm}
                        />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <EmployeeLabels />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </>
  )
}
