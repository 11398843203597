import { clsx } from 'clsx'
import { InputHTMLAttributes, ReactNode } from 'react'

export type Props = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'checked' | 'disabled' | 'id' | 'onChange' | 'onClick' | 'readOnly'
> & {
  label?: ReactNode
  name: string
}

const Checkbox = ({
  checked,
  disabled = false,
  id,
  label,
  name,
  ...rest
}: Props): JSX.Element => {
  return (
    <div className="flex">
      <label
        className={clsx('relative mb-0 flex select-none items-center text-sm', {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        })}
      >
        {/* Hide the browser's default checkbox. */}
        <input
          {...rest}
          checked={checked}
          className="absolute h-0 w-0 cursor-pointer opacity-0"
          disabled={disabled}
          id={id}
          name={name}
          type="checkbox"
        />
        {/* Create a custom checkbox. */}
        <div
          className={clsx('relative h-4 w-4 flex-shrink-0 rounded border', {
            'border-orange bg-orange': checked && !disabled,
            'border-dark-grey bg-dark-grey opacity-70': checked && disabled,
            'border-light-grey bg-white': !checked && !disabled,
            'border-light-grey bg-light-grey opacity-70': !checked && disabled,
          })}
        >
          {checked && (
            <div
              className="absolute rotate-45 transform border-b-2 border-l-0 border-r-2 border-t-0 border-white"
              data-testid="custom-checkbox-checkmark"
              style={{
                top: 2,
                left: 5,
                width: 5,
                height: 9,
              }}
            />
          )}
        </div>

        {label && <span className="pl-2">{label}</span>}
      </label>
    </div>
  )
}
export default Checkbox
