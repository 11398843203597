import { ButtonHTMLAttributes } from 'react'
import { clsx } from 'clsx'

type ButtonStyle =
  | 'black'
  | 'blue'
  | 'blue-dark'
  | 'green'
  | 'grey'
  | 'light-grey'
  | 'red'
  | 'stroke'
  | 'turqouise'
  | 'yellow'
export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonStyle?: ButtonStyle
  isFull?: boolean
  isLeftAppend?: boolean
  isRightAppend?: boolean
}

const COLOR_CLASS_MAP = {
  black: 'bg-black text-white',
  blue: 'bg-bs-blue hover:bg-bs-blue-hover active:bg-blue text-white transition-colors',
  'blue-dark': 'bg-blue text-white',
  green: 'bg-green text-white',
  grey: 'bg-grey text-white hover:bg-dark-grey transition-colors',
  'light-grey':
    'bg-bs-lighter-grey hover:bg-bs-lighter-grey-hover transition-colors',
  red: 'bg-red text-white',
  stroke:
    'border border-grey text-grey hover:bg-grey hover:text-white transition-colors',
  turqouise: 'bg-bs-turqouise text-white',
  yellow: 'bg-bs-yellow',
} as const satisfies Record<ButtonStyle, string>

const Button = ({
  buttonStyle = 'stroke',
  isFull = false,
  isLeftAppend = false,
  isRightAppend = false,
  type = 'button',
  ...rest
}: Props): JSX.Element => {
  return (
    <button
      className={clsx(
        'h-9 rounded px-3 disabled:opacity-60',
        COLOR_CLASS_MAP[buttonStyle],
        {
          'w-full': isFull,
          'rounded-l-none': isLeftAppend,
          'rounded-r-none': isRightAppend,
        }
      )}
      type={type}
      {...rest}
    />
  )
}

export default Button
