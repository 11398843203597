import { PropsWithChildren } from 'react'
import ReactPDF, { Document, Page, Text, View } from '@react-pdf/renderer'

import { commonPDFStyles } from 'utils/pdfs'
import { EmployeeLabel } from 'types/internal'

const EmployeeDiscountPDF = ({
  data,
}: {
  data: EmployeeLabel[]
}): JSX.Element => {
  return (
    <Document title="employee discount labels">
      {data.map(
        (
          {
            box_extras,
            Meal_Selection,
            'new food customer (Y/N)': new_food_customer,
            shipping_line1,
            shipping_name,
            subscription_type,
            userid,
          },
          i
        ) => {
          return (
            <Page key={i} size={[384, 576]} style={commonPDFStyles.page}>
              <View
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LabelText>Facility: {shipping_line1}</LabelText>
                <LabelText>Meals: {Meal_Selection}</LabelText>
                <LabelText>Box Extras: {box_extras}</LabelText>
                <LabelText>&nbsp;</LabelText>
                <LabelText small>User: {userid}</LabelText>
                <LabelText small>Name: {shipping_name}</LabelText>
                <LabelText small>Box Size: {subscription_type}</LabelText>
                <LabelText small>QA: {new_food_customer}</LabelText>
              </View>
            </Page>
          )
        }
      )}
    </Document>
  )
}

export default EmployeeDiscountPDF

const LabelText = ({
  small,
  ...rest
}: PropsWithChildren<ReactPDF.TextProps> & { small?: boolean }) => {
  return (
    <Text
      style={{
        lineHeight: '1.5',
        textAlign: 'center',
        width: '100%',
        fontSize: small ? '14px' : undefined,
      }}
      {...rest}
    />
  )
}
