import { useState } from 'react'

import { error, success } from 'slices/notifications'
import {
  ProductionCycle,
  ProductionPart,
  Tag,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import {
  useEditProductionPart,
  useUpdateBatchNumberWithChildren,
} from 'hooks/combinedAPI/productionParts'
import { useUpdateOnValueChange } from 'hooks/general'
import Button from 'components/common/bs/Button'
import Checkbox from 'components/common/Checkbox'
import Input from 'components/common/bs/Input'

const ProductionPartBatches = ({
  initialNumBatches,
  onNumBatchesUpdated,
  part,
  productionCycle,
  productionTerm,
  readOnly,
  refetchProductionRecord,
  tags,
}: {
  initialNumBatches: number
  onNumBatchesUpdated(numBatches: number): void
  part: ProductionPart
  productionCycle: ProductionCycle
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
  tags: Tag[]
}): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const [batchChildren, setBatchChildren] = useState(false)
  // We have local numBatches state here as well because we don't want to update the num batches until the
  // user clicks "Save".
  const [numBatches, setNumBatches] = useUpdateOnValueChange<number | ''>(
    initialNumBatches
  )

  const {
    isLoading: isEditingProductionPart,
    mutateAsync: editProductionPart,
  } = useEditProductionPart({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  const {
    isLoading: isUpdatingBatchNumberWithChildren,
    mutate: updateBatchNumberWithChildren,
  } = useUpdateBatchNumberWithChildren({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  async function onSaveNumBatches() {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to update the number of batches.')
      )
    }

    if (numBatches === '') {
      return dispatch(error('Please enter a number of batches'))
    }

    onNumBatchesUpdated(numBatches)

    if (batchChildren) {
      updateBatchNumberWithChildren({
        data: { numBatches },
        productionPartID: part.id,
        productionTerm,
      })
    } else {
      try {
        await editProductionPart({
          data: { numBatches, tags },
          productionPartID: part.id,
          productionTerm,
        })

        dispatch(success('Successfully batched part(s)'))
      } catch (err) {
        // Pass on error. Error handled in hook.
      }
    }
  }

  const numBatchesInputID = `num-batches-${part.id}`

  // Setting the number of batches for a combined part only makes sense for the cycle 1 part since once something is combined
  // all actions are against cycle 1.
  if (part.combined && productionCycle === '2') {
    return null
  }

  return (
    <div>
      <label htmlFor={numBatchesInputID}>Number of Batches</label>
      <div className="flex flex-wrap items-center justify-between font-normal">
        <div className="py-2">
          <Checkbox
            aria-label="Checkbox for child batch"
            checked={batchChildren}
            label="Batch children"
            name="batch-children"
            onChange={() => {
              setBatchChildren((batchChildren) => !batchChildren)
            }}
          />
        </div>

        <div className="flex shrink-0">
          <div className="w-16">
            <Input
              key={part.id}
              id={numBatchesInputID}
              isAppend
              onChange={(e) => {
                setNumBatches(
                  e.target.value === '' ? '' : Number(e.target.value)
                )
              }}
              readOnly={readOnly}
              type="number"
              value={numBatches}
            />
          </div>
          <Button
            buttonStyle="black"
            disabled={
              readOnly ||
              isEditingProductionPart ||
              isUpdatingBatchNumberWithChildren
            }
            isLeftAppend
            onClick={onSaveNumBatches}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProductionPartBatches
