import { forEach, isArray, isPlainObject } from 'lodash-es'
import { FormikErrors, FormikTouched } from 'formik'

export function errorsToTouched(
  errors: FormikErrors<unknown>
): FormikTouched<unknown> {
  if (isArray(errors)) {
    return errors.map((currentErrors) => {
      if (isPlainObject(currentErrors)) {
        return errorsToTouched(currentErrors)
      }

      return !!currentErrors
    })
  }

  const touched: FormikTouched<unknown> = {}
  forEach(errors, (value, key) => {
    if (isArray(value) || isPlainObject(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      touched[key] = errorsToTouched(value)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      touched[key] = true
    }
  })

  return touched
}
