import { ReactNode } from 'react'

import Tooltip from '../Tooltip'

const ExclamationCircleIcon = ({
  tooltip,
}: {
  tooltip?: ReactNode
}): JSX.Element => {
  const icon = (
    <svg
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  )

  if (tooltip) {
    return <Tooltip trigger={icon}>{tooltip}</Tooltip>
  }

  return icon
}

export default ExclamationCircleIcon
