import { useCallback, useEffect, useState } from 'react'

import {
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { qaParts } from 'utils/qa'

import SensoryTableHeader from './sensory/SensoryTableHeader'
import SensoryTableRow from 'components/production/qa/sensory/SensoryTableRow'
import Table, { TBody } from 'components/common/Table'
import WeightVerificationChecklist from 'components/production/qa/weightVerificationChecklist/WeightVerificationChecklist'
import WeightCheckTableRow from 'components/production/qa/weightCheck/WeightCheckTableRow'
import WeightCheckTableHeader from './weightCheck/WeightCheckTableHeader'

interface Props {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
}

type QaDocument = 'sensory' | 'weight_check' | 'weight_verification'

const QaDocuments = (props: Props): JSX.Element => {
  const [printContent, setPrintContent] = useState<QaDocument | ''>('')

  const onPrintContentRendered = useCallback(() => {
    window.print()
  }, [])

  return (
    <>
      <div className="flex justify-center pt-10 print:hidden">
        <div className="grid w-4/5 grid-cols-3 gap-8">
          <div
            className="cursor-pointer space-y-2 rounded border border-light-grey p-5"
            onClick={() => {
              setPrintContent('sensory')
            }}
          >
            <div className="text-xl font-semibold">Sensory Tracking</div>
            <button className="text-blue hover:underline">
              <i className="fas fa-print"></i> Print
            </button>
          </div>
          <div
            className="cursor-pointer space-y-2 rounded border border-light-grey p-5"
            onClick={() => {
              setPrintContent('weight_check')
            }}
          >
            <div className="text-xl font-semibold">Weight Check</div>
            <button className="text-blue hover:underline">
              <i className="fas fa-print"></i> Print
            </button>
          </div>
          <div
            className="cursor-pointer space-y-2 rounded border border-light-grey p-5"
            onClick={() => {
              setPrintContent('weight_verification')
            }}
          >
            <div className="text-xl font-semibold">
              Weight Verification Checklist
            </div>
            <button className="text-blue hover:underline">
              <i className="fas fa-print"></i> Print
            </button>
          </div>
        </div>
      </div>
      <div className="hidden print:block">
        {printContent && (
          <PrintContent
            onPrintContentRendered={onPrintContentRendered}
            printContent={printContent}
            {...props}
          />
        )}
      </div>
    </>
  )
}

export default QaDocuments

const PrintContent = ({
  onPrintContentRendered,
  printContent,
  ...rest
}: Props & {
  onPrintContentRendered(): void
  printContent: QaDocument
}) => {
  const { productionCycle, productionRecord, productionTerm } = rest

  useEffect(() => {
    let size = 'portait'
    const style = document.createElement('style')

    if (printContent === 'weight_verification') {
      size = 'landscape'
    }

    document.head.appendChild(style)
    style.innerHTML = `@page {size: ${size}}`

    onPrintContentRendered()

    return () => {
      document.head.removeChild(style)
    }
  }, [onPrintContentRendered, printContent])

  if (printContent === 'sensory') {
    const sensoryParts = qaParts('sensory', productionRecord)

    return (
      <Table>
        <SensoryTableHeader />
        {sensoryParts && (
          <TBody>
            {sensoryParts.cycles[productionCycle].map((part, i) => {
              return (
                <SensoryTableRow
                  key={i}
                  part={part}
                  productionCycle={productionCycle}
                />
              )
            })}
          </TBody>
        )}
      </Table>
    )
  } else if (printContent === 'weight_verification') {
    return (
      <WeightVerificationChecklist
        productionCycle={productionCycle}
        productionRecord={productionRecord}
        productionTerm={productionTerm}
      />
    )
  } else if (printContent === 'weight_check') {
    const weightCheckParts = qaParts('weight_check', productionRecord)

    return (
      <Table>
        <WeightCheckTableHeader />
        <TBody>
          {weightCheckParts &&
            weightCheckParts.cycles[productionCycle].map((part, i) => {
              return (
                <WeightCheckTableRow
                  key={i}
                  part={part}
                  productionCycle={productionCycle}
                />
              )
            })}
        </TBody>
      </Table>
    )
  }

  return null
}
