import { clsx } from 'clsx'
import { Fragment } from 'react'
import { orderBy } from 'lodash-es'

import {
  getProductionPartWeights,
  makeBetterProductionRecord,
  workOrderDays,
} from 'utils/production'
import {
  ProductionCycle,
  ProductionRecord,
  TagCategories,
} from 'types/combinedAPI/domainModels'

import H2 from 'components/common/bs/H2'
import H3 from 'components/common/bs/H3'
import Table, { TBody, Td, Th, Thead, Tr } from 'components/common/Table'

const TAG_COLORS: Partial<Record<keyof TagCategories, string>> = {
  cook: 'bg-bs-yellow',
  mix: 'bg-black text-white',
  prepare: 'bg-blue text-white',
}

const ExecutionWorkOrders = ({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): JSX.Element => {
  const workOrders = workOrderDays(
    makeBetterProductionRecord(productionRecord).cycles[productionCycle].parts
  )

  return (
    <div>
      {workOrders.map((workOrder, workOrderIndex) => {
        return (
          <Fragment key={workOrderIndex}>
            <div className="print:break-after-page">
              <H2>{workOrder.day}</H2>
            </div>
            <div className="divide-y divide-light-grey print:divide-y-0">
              {workOrder.parts.map((part, i) => {
                if (part.combined && Number(productionCycle) > 1) {
                  return null
                }

                const productionPart = part.combined
                  ? productionRecord.combinedParts[part.id]
                  : part

                const { batchWt, totalWt, useYieldWeight, yieldWt } =
                  getProductionPartWeights(productionPart)
                const prepDay =
                  productionPart.tags.find((t) => t.category === 'day_of_week')
                    ?.title ?? 'NO PREP DAY'
                const prepLocation =
                  productionPart.tags.find(
                    (t) => t.category === 'prep_location'
                  )?.title ?? 'NO PREP LOCATION'

                const backgroundColor = productionPart.combined
                  ? 'bg-[#57c4e5] print:bg-inherit'
                  : 'bg-[#ececec] print:bg-inherit'

                return (
                  <div
                    key={i}
                    className="py-8 print:break-after-page print:py-0"
                  >
                    <div className="mb-2">
                      <div className="flex text-center font-bold">
                        <div
                          style={{
                            width: '115px',
                            backgroundColor: 'yellow',
                          }}
                        >
                          #{productionPart.apiMealCode}
                          {Number(productionCycle) > 1 ? ' - a' : ''}
                        </div>
                        {productionPart.combined && (
                          <div
                            style={{
                              width: '115px',
                              color: 'white',
                              backgroundColor: 'blue',
                            }}
                          >
                            combined
                          </div>
                        )}
                      </div>
                      <div className="flex space-x-2 text-lg font-bold">
                        <span>{productionPart.title}</span>
                        <div className="space-x-2">
                          {productionPart.tags.map((tag, i) => {
                            if (tag.category === 'prep_location') {
                              return null
                            }

                            return (
                              <div
                                key={i}
                                className={clsx(
                                  'inline-flex cursor-pointer rounded border border-black p-1 text-xs leading-3',
                                  TAG_COLORS[tag.category] ??
                                    'bg-bs-lighter-grey'
                                )}
                              >
                                {tag.title}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="text-sm">
                        {productionPart.parentPartTitle} (
                        {productionPart.mealPartTitle})
                      </div>
                    </div>
                    <div className="mb-4 flex space-x-8">
                      <div className="w-5/6">
                        <Table>
                          <Thead>
                            <Tr>
                              <Th backgroundColor={backgroundColor} width="40%">
                                <b>Ingredient / Part</b>
                              </Th>
                              <Th backgroundColor={backgroundColor} width="20%">
                                <b>Total Weight</b>
                              </Th>
                              <Th backgroundColor={backgroundColor} width="20%">
                                <b>Total Yield</b>
                              </Th>
                              <Th backgroundColor={backgroundColor}>
                                <b># of Batches</b>
                                <br />
                                <div>{productionPart.numBatches}</div>
                              </Th>
                            </Tr>
                          </Thead>
                          <TBody>
                            {orderBy(productionPart.billOfMaterials, (mat) =>
                              mat.title.toLowerCase()
                            ).map((mat, i) => {
                              return (
                                <Tr key={i}>
                                  <Td>
                                    <div>{mat.title}</div>
                                    {mat.baseSku && (
                                      <div className="text-sm text-dark-grey">
                                        Tovala Base SKU: {mat.baseSku}
                                      </div>
                                    )}
                                  </Td>
                                  <Td>
                                    {mat.totalWeightRequiredPounds.toFixed(2)}
                                    lbs total
                                  </Td>
                                  <Td>
                                    {mat.finalYieldPounds.toFixed(2)}lbs yield
                                  </Td>
                                  <Td>
                                    {productionPart.numBatches &&
                                      (useYieldWeight
                                        ? mat.finalYieldPounds
                                        : mat.totalWeightRequiredPounds /
                                          productionPart.numBatches
                                      ).toFixed(2)}{' '}
                                    lbs / batch
                                  </Td>
                                </Tr>
                              )
                            })}
                            <Tr withBlackTopBorder>
                              <Td>
                                <b>
                                  Total BoMs:{' '}
                                  {productionPart.billOfMaterials.length}
                                </b>
                              </Td>
                              <Td>
                                <b>{totalWt} lbs total</b>
                              </Td>
                              <Td>
                                <b>{yieldWt} lbs yield</b>
                              </Td>
                              <Td>
                                {batchWt} {useYieldWeight ? 'yield' : 'total'}{' '}
                                lbs / batch
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <b>Prep Location: {prepLocation}</b>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <b>Prep Day: {prepDay}</b>
                              </Td>
                            </Tr>
                          </TBody>
                        </Table>
                      </div>
                      <div className="w-1/6">
                        <div className="mb-5">
                          <b>Cook Initials</b>
                        </div>
                        <div className="mb-5">
                          <b>Final Weight</b>
                        </div>
                        <div className="mb-5">
                          <b>Temperature</b>
                        </div>
                        <div className="mb-5">
                          <b>Time</b>
                        </div>
                      </div>
                    </div>
                    <H3>Procedure ({productionPart.procedure.length} steps)</H3>
                    {productionPart.procedure.map((step, i) => (
                      <div key={i}>
                        {step.stepNumber}.) {step.instructions}
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default ExecutionWorkOrders
