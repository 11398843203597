import { useState } from 'react'

import { composeMealsForCycle } from 'utils/meal'
import {
  FacilityNetwork,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { getMealTotalsAcrossCycles } from 'utils/productionMeals'
import { setPlanningTab } from 'slices/production'

import { useAppDispatch } from 'hooks'
import PlanningMealsRow, {
  Props as PlanningMealsRowProps,
} from './PlanningMealsRow'

const PlanningMeals = ({
  facilityNetwork,
  onMealCodeClicked,
  productionCycle,
  productionRecord,
  productionTerm,
  refetchProductionRecord,
}: {
  facilityNetwork: FacilityNetwork
  onMealCodeClicked(mealCode: number): void
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
  refetchProductionRecord(): void
}) => {
  const dispatch = useAppDispatch()

  const [focusedMealCountIndex, setFocusedMealCountIndex] = useState<number>(-1)

  const handleMealClick: PlanningMealsRowProps['handleMealClick'] = (meal) => {
    window.scrollTo(0, 0)

    onMealCodeClicked(meal.mealCode)
    dispatch(setPlanningTab(1))
  }

  const composedMeals = composeMealsForCycle(
    productionRecord.cycles[productionCycle].meals
  )
  const mealTotalsAcrossCycles = getMealTotalsAcrossCycles({ productionRecord })

  return (
    <div className="my-4 print:hidden">
      <div className="pb-10">
        <div className="flex py-4" style={{ backgroundColor: '#858585' }}>
          <div className="w-[5%] text-center text-sm">
            <b>Code</b>
          </div>
          <div className="w-[5%] text-center text-sm">
            <b>Count</b>
          </div>
          <div className="w-[10%] text-center text-sm">
            <b>Meal Title</b>
          </div>
          <div className="w-[5%] text-center text-sm">
            <b>Sleeving</b>
          </div>
          <div className="w-[25%] text-center text-sm">
            <b>Component 1</b>
          </div>
          <div className="w-[25%] text-center text-sm">
            <b>Component 2</b>
          </div>
          <div className="w-[25%] text-center text-sm">
            <b>Garnishes</b>
          </div>
        </div>
        {composedMeals.map((prodMeal, i) => {
          return (
            <PlanningMealsRow
              key={prodMeal.id}
              facilityNetwork={facilityNetwork}
              handleMealClick={handleMealClick}
              isMealCountFocused={focusedMealCountIndex === i}
              mealTotalAcrossCycles={mealTotalsAcrossCycles[prodMeal.mealCode]}
              onChangeFocusedMealCountIndex={(direction) => {
                if (direction === 'up') {
                  setFocusedMealCountIndex((index) => index - 1)
                } else {
                  setFocusedMealCountIndex((index) => index + 1)
                }
              }}
              onMealCountFocused={() => {
                setFocusedMealCountIndex(i)
              }}
              productionCycle={productionCycle}
              productionMeal={prodMeal}
              productionRecord={productionRecord}
              productionTerm={productionTerm}
              refetchProductionRecord={refetchProductionRecord}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PlanningMeals
