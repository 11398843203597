import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { reject } from 'lodash-es'
import { useState } from 'react'

import { makeBetterProductionRecord, parsePrepLabels } from 'utils/production'
import {
  PrepLabel,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { success } from 'slices/notifications'

import { useAppDispatch } from 'hooks'
import { useEditPrepLabel } from 'hooks/combinedAPI/prepLabels'
import { useFloating } from 'hooks/floating'
import Button from 'components/common/bs/Button'
import ChevronDownIcon from 'components/common/icons/ChevronDownIcon'
import LabelPDFViewer from './LabelPDFViewer'
import Menu, { MenuButton, MenuItem, MenuItems } from 'components/common/Menu'

const ExecutionLabels = ({
  productionCycle,
  productionRecord,
  readOnly,
  refetchProductionRecord,
  termId,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  readOnly: boolean
  refetchProductionRecord(): void
  termId: string
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [location, setLocation] = useState<string | null>(null)
  const [hideLabels, setHideLabels] = useState<string[]>([])
  const [showLoadingText, setShowLoadingText] = useState(false)
  const [viewPdf, setViewPdf] = useState(false)

  const { mutateAsync: editPrepLabel } = useEditPrepLabel()

  const locations = [
    'refrigerated',
    'aromatics',
    'dry, non-glass',
    'strained',
    'frozen grains',
    'dry, glass',
    'frozen',
    'tovala prep',
    'no location',
  ]
  const betterProductionRecord = makeBetterProductionRecord(productionRecord)
  const prepLabels = parsePrepLabels(
    betterProductionRecord,
    location,
    productionCycle,
    hideLabels
  )

  async function setLocationForPart(label: PrepLabel, location: string) {
    dispatch(showLoading())

    try {
      await editPrepLabel({
        productionTerm: termId,
        prepLabelID: label.id,
        data: { location },
      })

      // add label id to hidden labels and then refetch
      setHideLabels((prevHideLabels) => {
        const labelIds = [...prevHideLabels]
        labelIds.push(label.id)

        return labelIds
      })

      refetchProductionRecord()
      dispatch(success('Succesfully set location for part'))
    } catch (err) {
      // Pass on error here. Error is handled in hook.
    } finally {
      dispatch(hideLoading())
    }
  }

  function filterLabelsByLocation(location: string) {
    setLocation(location)
    setHideLabels([])
  }

  function showLabels() {
    setViewPdf(false)
    setShowLoadingText(false)
  }

  function showPdf() {
    setShowLoadingText(true)

    window.setTimeout(() => {
      setViewPdf(true)
    }, 400)
  }

  const { floating, reference, strategy, x, y } = useFloating()

  if (viewPdf) {
    return (
      <LabelPDFViewer
        parts={prepLabels}
        productionCycle={productionCycle}
        showLabels={showLabels}
        term={termId}
      />
    )
  }

  return (
    <div className="mt-8">
      <div className="flex">
        <Menu>
          <MenuButton ref={reference}>
            <div className="flex">
              <Button buttonStyle="blue" isRightAppend={!!location}>
                <span className="flex items-center">
                  {location || 'Please select a location'}
                  <div className="h-4 w-4">
                    <ChevronDownIcon />
                  </div>
                </span>
              </Button>
            </div>
          </MenuButton>

          <MenuItems
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {locations.map((location, i) => (
              <MenuItem key={i}>
                <button
                  className="flex w-full p-2 ui-active:bg-lightest-grey"
                  onClick={() => filterLabelsByLocation(location)}
                >
                  {location || 'No Location'}
                </button>
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>

        {location && (
          <Button
            buttonStyle="black"
            disabled={showLoadingText}
            isLeftAppend
            onClick={() => showPdf()}
          >
            {showLoadingText ? 'Loading PDF' : 'View PDF'}
          </Button>
        )}
      </div>
      {!productionRecord.cycles[productionCycle].prepLabels.length && (
        <span>There are currently no labels ready for this term.</span>
      )}
      {prepLabels.length ? (
        <div className="flex flex-wrap justify-between">
          {prepLabels.map((part, i) => {
            return (
              <LabelCard
                key={i}
                locations={locations}
                onChangeLocation={(location) => {
                  setLocationForPart(part, location)
                }}
                part={part}
                readOnly={readOnly}
              />
            )
          })}
        </div>
      ) : (
        <span>Select a location to view labels</span>
      )}
    </div>
  )
}

export default ExecutionLabels

const LabelCard = ({
  locations,
  onChangeLocation,
  part,
  readOnly,
}: {
  locations: string[]
  onChangeLocation(location: string): void
  part: PrepLabel
  readOnly: boolean
}) => {
  const { floating, reference, strategy, x, y } = useFloating()

  return (
    <div
      className="mt-5 rounded border border-light-grey p-5 text-center"
      style={{
        width: '24%',
      }}
    >
      <div className="text-xl font-semibold">{part.partTitle}</div>
      <p className="mb-2">
        {part.parentTitle} ({part.shortTitle || part.mealTitle})
        <br />
        {part.numBatches} batches | {part.totalWeightPounds.toFixed(2)} total
        lbs ({(part.totalWeightPounds / part.numBatches).toFixed(2)} lbs per
        batch)
      </p>

      <div className="flex justify-center">
        {readOnly ? (
          <span>
            Location: <b>{part.location || 'No Location'}</b>
          </span>
        ) : (
          <>
            <Menu>
              <MenuButton ref={reference}>
                <Button buttonStyle="light-grey">
                  <span className="flex items-center">
                    {part.location || 'No Location'}
                    <div className="h-4 w-4">
                      <ChevronDownIcon />
                    </div>
                  </span>
                </Button>
              </MenuButton>

              <MenuItems
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                {reject(locations, (p) => p === part.location).map(
                  (location, i) => (
                    <MenuItem key={i}>
                      <button
                        className="flex w-full p-2 ui-active:bg-lightest-grey"
                        onClick={() => {
                          onChangeLocation(location)
                        }}
                      >
                        {location || 'No Location'}
                      </button>
                    </MenuItem>
                  )
                )}
              </MenuItems>
            </Menu>
          </>
        )}
      </div>
    </div>
  )
}
