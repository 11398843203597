import { get } from './baseAPI'
import { ProductionRecord } from 'types/combinedAPI/domainModels'

export interface GetProductionRecord {
  facilityNetworkTitle: string
  productionTerm: string
}

export type GetProductionRecordResponse = ProductionRecord

export const ENDPOINTS = {
  GET_PRODUCTION_RECORD: ({
    facilityNetworkTitle,
    productionTerm,
  }: GetProductionRecord) => ({
    path: `/productionRecords/${productionTerm}?facilityNetwork=${facilityNetworkTitle}`,
    version: 'v0' as const,
  }),
}

export async function getProductionRecord(
  opts: GetProductionRecord,
  { abortSignal }: { abortSignal?: AbortSignal }
): Promise<GetProductionRecordResponse> {
  return get({
    config: { signal: abortSignal },
    ...ENDPOINTS.GET_PRODUCTION_RECORD(opts),
  })
}
