import { compact, uniq } from 'lodash-es'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQueries,
  UseQueryOptions,
} from '@tanstack/react-query'

import { getExpandedLineItem } from 'components/purchaseOrders/helpers'
import {
  createPurchaseOrder,
  deletePurchaseOrder,
  DeletePurchaseOrder,
  DeletePurchaseOrderResponse,
  editPurchaseOrder,
  getPurchaseOrder,
  GetPurchaseOrderResponse,
  GetPurchaseOrders,
  getPurchaseOrders,
  GetPurchaseOrdersResponse,
  SavePurchaseOrderBody,
} from 'services/combinedAPI/purchaseOrders'
import {
  PurchasedGood,
  PurchaseOrderLineItem,
  PurchaseOrderWithLineItems,
  Vendor,
} from 'types/combinedAPI/domainModels'
import { PurchaseOrderFull, VendorSKUsInPO } from 'types/internal'

import { useBulkPurchasedGoods } from './purchasedGoods'
import { useVendors } from './vendors'

interface Props {
  enabled?: boolean
  onFetchPOError?: () => void
  onFetchPurchasedGoodsError?: () => void
  refetchOnWindowFocus?: boolean
  selectedFacilityNetworkID: string | null
}

interface VendorsFull {
  [id: string]: Vendor
}

export function useBulkPurchaseOrders({
  purchaseOrderIDs,
  ...rest
}: { purchaseOrderIDs: (string | undefined)[] } & Omit<
  UseQueryOptions<GetPurchaseOrderResponse, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQueries({
    queries: purchaseOrderIDs.map<
      UseQueryOptions<GetPurchaseOrderResponse, Error>
    >((purchaseOrderID) => {
      return {
        ...rest,
        enabled: rest.enabled && !!purchaseOrderID,
        queryFn: () => {
          if (!purchaseOrderID) {
            throw new Error('ID of purchase order to load not supplied.')
          }

          return getPurchaseOrder({ purchaseOrderID })
        },
        queryKey: ['purchase-orders', purchaseOrderID],
      }
    }),
  })
}

export function useDeletePurchaseOrder(
  opts?: Omit<
    UseMutationOptions<DeletePurchaseOrderResponse, Error, DeletePurchaseOrder>,
    'mutationFn'
  >
) {
  return useMutation<DeletePurchaseOrderResponse, Error, DeletePurchaseOrder>({
    ...opts,
    mutationFn: ({ purchaseOrderID }) => {
      return deletePurchaseOrder({ purchaseOrderID })
    },
  })
}

export function useFullPurchaseOrder({
  purchaseOrderID,
  ...rest
}: Props & {
  purchaseOrderID: string | undefined
}) {
  const { purchaseOrders, ...restResponse } = useFullPurchaseOrders({
    ...rest,
    purchaseOrderIDs: compact([purchaseOrderID]),
  })

  // Return a single purchase order
  return { ...restResponse, purchaseOrder: purchaseOrders[0] ?? null }
}

export function useFullPurchaseOrders({
  enabled = true,
  onFetchPOError = undefined,
  onFetchPurchasedGoodsError = undefined,
  purchaseOrderIDs,
  refetchOnWindowFocus = true,
  selectedFacilityNetworkID,
}: Props & {
  purchaseOrderIDs: string[]
}): {
  error: Error | null
  isLoading: boolean
  purchaseOrders: PurchaseOrderFull[]
} {
  const purchaseOrdersResults = useBulkPurchaseOrders({
    enabled,
    onError: onFetchPOError,
    purchaseOrderIDs,
    refetchOnWindowFocus,
  })
  const purchaseOrders = compact(
    purchaseOrdersResults.map(
      (purchaseOrdersResult) => purchaseOrdersResult.data
    )
  )

  const lineItems = purchaseOrders
    .map((purchaseOrder) => purchaseOrder.lineItems ?? [])
    .flat()
  const vendorIDs = uniq(purchaseOrders.map(({ vendor }) => vendor.id))

  const purchasedGoodsResults = useBulkPurchasedGoods({
    onError: onFetchPurchasedGoodsError,
    refetchOnWindowFocus,
    vendorIDs,
  })
  const purchasedGoods = purchasedGoodsResults
    .map((purchasedGoodsResult) => purchasedGoodsResult.data ?? [])
    .flat()

  const vendorSKUsInPO = getVendorSKUsInPO({
    lineItems,
    purchasedGoods,
    selectedFacilityNetworkID,
  })

  const {
    data: vendors = [],
    isError,
    isLoading: isLoadingVendors,
  } = useVendors()

  const vendorsFull: VendorsFull = {}
  vendors.forEach((vendor) => {
    vendorsFull[vendor.id] = { ...vendor }
  })

  const isLoadingPurchaseOrders = purchaseOrdersResults.some(
    (purchaseOrdersResult) => purchaseOrdersResult.isLoading
  )
  const isLoadingPurchasedGoods = purchasedGoodsResults.some(
    (purchasedGoodsResult) => purchasedGoodsResult.isLoading
  )

  const isLoading =
    isLoadingPurchaseOrders || isLoadingPurchasedGoods || isLoadingVendors

  const hasLoadPOError = purchaseOrdersResults.some(
    (purchaseOrdersResult) => purchaseOrdersResult.isError
  )
  const hasLoadPurchasedGoodsError = purchasedGoodsResults.some(
    (purchasedGoodsResult) => purchasedGoodsResult.isError
  )

  const loadPOErrors = purchaseOrdersResults.map(
    (purchaseOrdersResult) => purchaseOrdersResult.error
  )
  const loadPurchasedGoodsErrors = purchasedGoodsResults.map(
    (purchasedGoodsResult) => purchasedGoodsResult.error
  )

  return {
    error:
      hasLoadPOError || hasLoadPurchasedGoodsError || isError
        ? ((loadPOErrors[0] || loadPurchasedGoodsErrors[0] || isError) as Error)
        : null,
    isLoading,
    purchaseOrders: purchaseOrders.map((purchaseOrder) => {
      return getPurchaseOrderWithLineItems({
        purchaseOrder,
        vendorsFull,
        vendorSKUsInPO,
      })
    }),
  }
}

export function usePurchaseOrders({
  filters,
  ...rest
}: Omit<GetPurchaseOrders, 'nextCursor'> &
  Omit<
    UseInfiniteQueryOptions<GetPurchaseOrdersResponse, Error>,
    'queryFn' | 'queryKey' | 'getNextPageParam'
  >) {
  return useInfiniteQuery<GetPurchaseOrdersResponse, Error>({
    ...rest,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor
    },
    queryFn: ({ pageParam: nextCursor }) => {
      return getPurchaseOrders({ filters, nextCursor })
    },
    queryKey: ['purchaseOrders', filters],
  })
}

export function useSavePurchaseOrder({
  purchaseOrderID,
  ...rest
}: {
  purchaseOrderID?: string
} & Omit<
  UseMutationOptions<
    PurchaseOrderWithLineItems,
    Error,
    SavePurchaseOrderBody & { isEdit: boolean }
  >,
  'mutationFn'
>) {
  return useMutation<
    PurchaseOrderWithLineItems,
    Error,
    SavePurchaseOrderBody & { isEdit: boolean }
  >({
    ...rest,
    mutationFn: ({ isEdit, ...rest }) => {
      if (isEdit) {
        if (!purchaseOrderID) {
          throw new Error('Missing ID of purchase order to edit.')
        }

        return editPurchaseOrder({ data: rest, purchaseOrderID })
      }

      return createPurchaseOrder({ data: rest })
    },
  })
}

const getVendorSKUsInPO = ({
  lineItems,
  purchasedGoods,
  selectedFacilityNetworkID,
}: {
  lineItems: PurchaseOrderLineItem[]
  purchasedGoods: PurchasedGood[]
  selectedFacilityNetworkID: string | null
}) => {
  const skuIDs = lineItems.map((lineItem) => lineItem.skuID)

  const vendorSKUsInPO: VendorSKUsInPO = {}

  if (selectedFacilityNetworkID) {
    purchasedGoods.forEach((purchasedGood) => {
      purchasedGood.vendorSkus.forEach((vendorSKU) => {
        const facilityNetworkInfo =
          vendorSKU.facilityNetworkInfo[selectedFacilityNetworkID]

        if (skuIDs.includes(vendorSKU.id)) {
          vendorSKUsInPO[vendorSKU.id] = {
            isAvailable: !!facilityNetworkInfo?.isAvailable,
            vendorSKUWithPurchasedGood: {
              ...vendorSKU,
              purchasedGood,
            },
          }
        }
      })
    })
  }

  return vendorSKUsInPO
}

const getPurchaseOrderWithLineItems = ({
  purchaseOrder,
  vendorsFull,
  vendorSKUsInPO,
}: {
  purchaseOrder: PurchaseOrderWithLineItems
  vendorsFull: VendorsFull
  vendorSKUsInPO: VendorSKUsInPO
}) => {
  const lineItems = purchaseOrder?.lineItems ?? []

  return {
    ...purchaseOrder,
    lineItems: lineItems.map((lineItem) => {
      return getExpandedLineItem({ lineItem, vendorSKUsInPO })
    }),
    vendor: vendorsFull[purchaseOrder.vendor.id],
  }
}
