import { Fragment, PropsWithChildren } from 'react'
import ReactPDF, {
  Document,
  Image,
  Page,
  Text,
  View,
} from '@react-pdf/renderer'
import { times } from 'lodash-es'

import { BoxSize, LineHaul } from 'types/combinedAPI/domainModels'
import { boxesPerPallet } from './boxes-per-pallet'
import { commonPDFStyles } from 'utils/pdfs'
import { displayCartonLayout } from './display-carton-layout'
import { findInsulationTypeByName } from './insulation-types'
import { InsulationTypeName, ShippingCarrierCSVRow } from 'types/internal'

function getCartonText(
  insulationName: InsulationTypeName,
  boxSize: BoxSize
): string {
  const insulationType = findInsulationTypeByName(insulationName)

  return insulationType
    ? `${boxSize}${insulationType.palletSheetAbbreviation}`
    : ''
}

const PalletSheetPDF = ({
  carrierData,
  selectedLineHaul,
}: {
  carrierData: ShippingCarrierCSVRow[]
  selectedLineHaul: LineHaul
}): JSX.Element => {
  const carrierAbbreviation = selectedLineHaul.info.abbreviation

  return (
    <Document title="pallet-sheets">
      <Page
        style={{
          ...commonPDFStyles.page,
          padding: '30px 18.24px 30px 18.24px',
          height: '800px',
          width: '100%',
        }}
      >
        <View style={{ marginBottom: '8px' }}>
          <SmallText>
            {carrierAbbreviation} - Term: _______________ Date:
            _________________
          </SmallText>
        </View>
        <TableContainer>
          {selectedLineHaul.boxSizeOrder.map((boxSize) => {
            return carrierData.map((row, i) => {
              const palletBoxes = boxesPerPallet(row, boxSize)

              if (!palletBoxes || palletBoxes.totalPallets <= 0) {
                return null
              }

              // Only show summary rows for box size/insulation that have pallets
              return (
                <TableRow key={i}>
                  <DisplayColumn>
                    <Text>{palletBoxes.displayText}</Text>
                    <Text>Verified</Text>
                  </DisplayColumn>
                  <BoxColumn>
                    {times(palletBoxes.totalPallets, (index) => (
                      <FillInCell key={i + index}>
                        <SmallestText>{`${index + 1}`}</SmallestText>
                      </FillInCell>
                    ))}
                  </BoxColumn>
                </TableRow>
              )
            })
          })}
        </TableContainer>
      </Page>
      {selectedLineHaul
        ? selectedLineHaul.boxSizeOrder.map((boxSize) => {
            return (
              <BoxOrderSheets
                key={boxSize}
                boxSize={boxSize}
                carrier={carrierData}
                carrierAbbreviation={carrierAbbreviation}
              />
            )
          })
        : null}
    </Document>
  )
}

export default PalletSheetPDF

const BoxOrderSheets = ({
  boxSize,
  carrier,
  carrierAbbreviation,
}: {
  carrierAbbreviation: string
  carrier: ShippingCarrierCSVRow[]
  boxSize: BoxSize
}): JSX.Element => {
  return (
    <>
      {carrier.map((c, carrierIndex) => {
        const bpp = boxesPerPallet(c, boxSize)

        if (!bpp) {
          return null
        }

        const { displayImage, displayText } = displayCartonLayout(
          boxSize,
          c.insulation
        )

        return (
          <Fragment key={carrierIndex}>
            {times(bpp.totalPallets, (palletNumber) => (
              <Page
                key={palletNumber}
                style={{
                  ...commonPDFStyles.page,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  padding: '40px 18.24px 70px 18.24px',
                  height: '800px',
                }}
              >
                <Labels>
                  <TopLabels>
                    <LineHaulLabel>
                      LINE: <BlueText>{carrierAbbreviation}</BlueText>
                    </LineHaulLabel>
                    <CartonTypeLabel>
                      CTN TYPE:{' '}
                      <BlueText>
                        {' '}
                        {getCartonText(c.insulation, boxSize)}{' '}
                      </BlueText>
                    </CartonTypeLabel>
                    <NumberOfCartons>
                      # OF CTNS:{' '}
                      <BlueText>
                        {palletNumber + 1 === bpp.totalPallets
                          ? bpp.boxesOnLastPallet
                          : bpp.boxesPerPallet}
                      </BlueText>
                    </NumberOfCartons>
                  </TopLabels>
                  <PalletNumber>
                    PALLET # {palletNumber + 1} OF {bpp.totalPallets}
                  </PalletNumber>
                  <View>
                    <Text>{displayText}</Text>
                    <CartonImageContainer>
                      <Image src={displayImage} />
                    </CartonImageContainer>
                  </View>
                  <Signature>Verified By:</Signature>
                  <LineHaulLabel>
                    LINE: <BlueText>{carrierAbbreviation}</BlueText>
                  </LineHaulLabel>
                </Labels>
              </Page>
            ))}
          </Fragment>
        )
      })}
    </>
  )
}

const SmallText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return <Text style={{ fontSize: '14px' }} {...props} />
}

const TableContainer = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        display: 'flex',
        width: '100%',
        border: '1px solid black',
      }}
      {...props}
    />
  )
}

const TableRow = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '10px',
      }}
      {...props}
    />
  )
}

const DisplayColumn = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        fontSize: '8px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '10%',
      }}
      {...props}
    />
  )
}

const BoxColumn = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '90%',
      }}
      {...props}
    />
  )
}

const SmallestText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return <Text style={{ fontSize: '10px' }} {...props} />
}

const CartonImageContainer = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        width: '200px',
        margin: 'auto',
      }}
      {...props}
    />
  )
}

const FillInCell = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        height: '30px',
        width: '6.25%',
        backgroundColor: 'orange',
      }}
      {...props}
    />
  )
}

const BlueText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return <Text style={{ color: '#1355cc' }} {...props} />
}

const TopLabels = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
      {...props}
    />
  )
}

const Labels = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      {...props}
    />
  )
}

const LineHaulLabel = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return <Text style={{ fontSize: '36px' }} {...props} />
}

const Signature = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '20px',
        width: '100%',
        textAlign: 'left',
      }}
      {...props}
    />
  )
}

const CartonTypeLabel = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '24px',
      }}
      {...props}
    />
  )
}

const NumberOfCartons = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '24px',
        marginTop: '15px',
      }}
      {...props}
    />
  )
}

const PalletNumber = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        fontSize: '60px',
      }}
      {...props}
    />
  )
}
