import { clsx } from 'clsx'
import { compact } from 'lodash-es'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatCommas } from 'utils/general'
import { formatDollars } from 'utils/currency'
import {
  getFormattedUnitPrice,
  getMissingVendorSKUs,
  orderLineItems,
} from './helpers'
import {
  PurchaseOrderFull,
  PurchaseOrderLineItemExpanded,
  VendorSKUWithPurchasedGood,
} from 'types/internal'

import {
  useDeletePurchaseOrder,
  useFullPurchaseOrder,
} from 'hooks/combinedAPI/purchaseOrders'
import { useIsReadOnly } from 'contexts/auth'
import { useSelectFacilityNetwork } from 'hooks/combinedAPI/facilityNetworks'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Breadcrumbs from 'components/common/Breadcrumbs'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import ErrorDisplay from 'components/common/ErrorDisplay'
import ExclamationCircleIcon from 'components/common/icons/ExclamationCircleIcon'
import FormGroup from 'components/common/FormGroup'
import LineItemsTableCols from './LineItemTableCols'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'
import PageLayout from './PageLayout'
import PencilIcon from 'components/common/icons/PencilIcon'
import PrintReceivingPDF from './PrintReceivingPDF'
import PrinterIcon from 'components/common/icons/PrinterIcon'
import PurchaseOrderPageLoading from './PurchaseOrderPageLoading'
import TrashIcon from 'components/common/icons/TrashIcon'
import VendorPurchaseOrder from './VendorPurchaseOrder'

const PurchaseOrderPage = (): JSX.Element => {
  const { id: purchaseOrderID } = useParams()

  const { search: locationSearch } = useLocation()
  const selectedFacilityNetworkID = new URLSearchParams(locationSearch).get(
    'facilityNetworkID'
  )

  useSelectFacilityNetwork(selectedFacilityNetworkID)

  const {
    error: loadPOError,
    isLoading: isLoadingPurchaseOrder,
    purchaseOrder,
  } = useFullPurchaseOrder({
    purchaseOrderID,
    selectedFacilityNetworkID,
  })

  if (!loadPOError && isLoadingPurchaseOrder) {
    return <PurchaseOrderPageLoading />
  }

  if (loadPOError || !purchaseOrder) {
    return (
      <PageLayout>
        <APIErrorDisplay
          error={loadPOError}
          fallbackError="Could not load requested purchase order"
        />
      </PageLayout>
    )
  }

  const missingVendorSKUs = getMissingVendorSKUs(purchaseOrder)

  return (
    <PageLayout>
      <div className="flex items-center justify-between">
        <div>
          <Breadcrumbs
            breadcrumbs={[
              {
                link: `/purchaseOrders?facilityNetworkID=${selectedFacilityNetworkID}`,
                text: 'Purchase Orders',
              },
              { text: purchaseOrder.snapReceiptID },
            ]}
          />

          <h1 className="text-2xl">{purchaseOrder.snapReceiptID}</h1>
        </div>

        <div className="flex items-center space-x-4">
          <DeletePurchaseOrder
            purchaseOrder={purchaseOrder}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          />
          <EditPurchaseOrder
            purchaseOrder={purchaseOrder}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          />
          <PrintReceivingPDF
            purchaseOrderIDs={compact([purchaseOrderID])}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          >
            {({ isLoading }) => {
              return (
                <div className="w-48 sm:w-20">
                  <ButtonLoading isLoading={isLoading} type="button">
                    <div className="flex items-center justify-between space-x-2">
                      {!isLoading && (
                        <div className="h-4 w-4">
                          <PrinterIcon />
                        </div>
                      )}
                      <span className="sm:hidden">Print Receiving PDF</span>
                      <span className="hidden sm:inline">Print</span>
                    </div>
                  </ButtonLoading>
                </div>
              )
            }}
          </PrintReceivingPDF>
          <VendorPurchaseOrder
            purchaseOrder={purchaseOrder}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          />
        </div>
      </div>

      <div className="my-8 flex w-3/4 items-center justify-between sm:grid sm:w-full sm:grid-cols-4 sm:justify-start sm:gap-5 sm:text-sm">
        <FormGroup label="Stage">{purchaseOrder.stage}</FormGroup>
        <FormGroup label="Vendor">{purchaseOrder.vendor.name}</FormGroup>
        <FormGroup label="Facility">
          {purchaseOrder.facility.displayName}
        </FormGroup>
        <FormGroup label="Requested Delivery Date">
          {formatDate(purchaseOrder.requestedDeliveryDate, {
            format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
          })}
        </FormGroup>
        <FormGroup label="Supplier Reference">
          {purchaseOrder.supplierRef || 'N/A'}
        </FormGroup>
        <FormGroup label="Last Modified Date">
          {formatDate(purchaseOrder.updated, {
            format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
          })}
        </FormGroup>
      </div>

      <LineItemsTable
        freightCost={purchaseOrder.freightCost}
        lineItems={purchaseOrder.lineItems}
        missingVendorSKUs={missingVendorSKUs}
      />
    </PageLayout>
  )
}

export default PurchaseOrderPage

const EditPurchaseOrder = ({
  purchaseOrder,
  selectedFacilityNetworkID,
}: {
  purchaseOrder: PurchaseOrderFull
  selectedFacilityNetworkID: string | null
}): JSX.Element | null => {
  const isReadOnlyProcurement = useIsReadOnly('procurement')

  const navigate = useNavigate()

  if (purchaseOrder.stage !== 'Due In' || isReadOnlyProcurement) {
    return null
  }

  return (
    <div className="w-20">
      <Button
        onClick={() => {
          navigate(
            `../${purchaseOrder.id}/edit?facilityNetworkID=${selectedFacilityNetworkID}`
          )
        }}
      >
        <div className="flex items-center justify-center space-x-2">
          <div className="h-4 w-4 text-white">
            <PencilIcon />
          </div>
          <span>Edit</span>
        </div>
      </Button>
    </div>
  )
}

const DeletePurchaseOrder = ({
  purchaseOrder,
  selectedFacilityNetworkID,
}: {
  purchaseOrder: PurchaseOrderFull
  selectedFacilityNetworkID: string | null
}): JSX.Element | null => {
  const isReadOnlyProcurement = useIsReadOnly('procurement')

  const [isOpen, setIsOpen] = useState(false)

  if (purchaseOrder.stage !== 'Due In' || isReadOnlyProcurement) {
    return null
  }

  return (
    <>
      <div className="w-24">
        <Button
          buttonStyle="danger"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <div className="flex items-center justify-center space-x-2">
            <div className="h-4 w-4">
              <TrashIcon />
            </div>
            <span>Delete</span>
          </div>
        </Button>
      </div>
      {isOpen && (
        <DeletePurchaseOrderModal
          onCloseModal={() => {
            setIsOpen(false)
          }}
          purchaseOrder={purchaseOrder}
          selectedFacilityNetworkID={selectedFacilityNetworkID}
        />
      )}
    </>
  )
}

const DeletePurchaseOrderModal = ({
  onCloseModal,
  purchaseOrder,
  selectedFacilityNetworkID,
}: {
  onCloseModal(): void
  purchaseOrder: PurchaseOrderFull
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const navigate = useNavigate()

  const {
    error: deletePOError,
    isError: hasDeletePOError,
    isLoading: isDeletingPurchaseOrder,
    mutate: deletePurchaseOrder,
  } = useDeletePurchaseOrder({
    onSuccess: () => {
      navigate(`/purchaseOrders?facilityNetworkID=${selectedFacilityNetworkID}`)
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-delete-purchase-order-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Delete Purchase Order
            </ModalHeader>
          </div>

          <p className="my-8">
            Are you sure you want to delete purchase order{' '}
            <span className="font-semibold">{purchaseOrder.snapReceiptID}</span>
            ? This cannot be undone.
          </p>

          <div className="mt-8">
            <ModalButtons
              apiError={hasDeletePOError ? deletePOError : null}
              isLoading={isDeletingPurchaseOrder}
              mainActionBtnStyle="danger"
              mainActionBtnText="Delete"
              onCancel={onCloseModal}
              onMainAction={() => {
                deletePurchaseOrder({ purchaseOrderID: purchaseOrder.id })
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const LineItemsTable = ({
  freightCost,
  lineItems,
  missingVendorSKUs,
}: {
  freightCost: PurchaseOrderFull['freightCost']
  lineItems: PurchaseOrderLineItemExpanded[]
  missingVendorSKUs: (VendorSKUWithPurchasedGood | number)[]
}): JSX.Element => {
  const totalCostForLineItems = lineItems.reduce((total, lineItem) => {
    return total + lineItem.total
  }, 0)

  const totalCost = totalCostForLineItems + freightCost

  const gridClasses =
    'grid grid-cols-purchase-order-table-single sm:grid-cols-purchase-order-table-single-mobile gap-4 items-center justify-items-center'

  const orderedLineItems = orderLineItems(lineItems)

  return (
    <div>
      {missingVendorSKUs.length > 0 && (
        <ErrorDisplay>
          <div role="alert">
            <p>
              The following SKUs are not marked as available for this facility
              network. Please check the Ingredient List or reach out in
              #help-ops-tech.
            </p>
            <ul className="mt-2 list-disc pl-8">
              {missingVendorSKUs.map((vendorSKU) => {
                if (typeof vendorSKU === 'number') {
                  return (
                    <li key={vendorSKU}>
                      Unknown Purchased Good Name (Internal Misevala SKU:{' '}
                      {vendorSKU})
                    </li>
                  )
                }

                return (
                  <li key={vendorSKU.id}>
                    {vendorSKU.purchasedGood.name} (Vendor SKU: {vendorSKU.sku})
                  </li>
                )
              })}
            </ul>
          </div>
        </ErrorDisplay>
      )}

      <div
        className={`sticky top-[59px] ${gridClasses} z-10 mb-4 border-b border-light-grey bg-white pb-2 pt-4 text-sm font-normal uppercase text-grey`}
      >
        <LineItemsTableCols
          purchasedGoodCol="Purchased Good"
          qtyCol="QTY"
          totalPriceCol="Total"
          totalWtCol="Total Wt (lbs)"
          unitPriceCol="Unit Price"
          unitWtCol="Unit Wt (lbs)"
        />
      </div>

      <div className="space-y-4 sm:text-sm">
        {lineItems.length === 0 && <p>No line items</p>}
        {orderedLineItems.map((lineItem, i) => {
          return (
            <LineItemTableRow
              key={i}
              gridClasses={gridClasses}
              lineItem={lineItem}
            />
          )
        })}
      </div>

      <div
        className={`${gridClasses} mt-4 border-t border-light-grey pt-4 text-sm uppercase text-grey`}
      >
        <LineItemsTableCols
          purchasedGoodCol="Freight Cost"
          totalPriceCol={formatDollars(freightCost)}
        />
      </div>

      <div
        className={`${gridClasses} mt-4 border-t border-light-grey pt-2 text-sm font-bold uppercase`}
      >
        <LineItemsTableCols
          purchasedGoodCol="Total"
          totalPriceCol={formatDollars(totalCost)}
        />
      </div>
    </div>
  )
}

const LineItemTableRow = ({
  gridClasses,
  lineItem,
}: {
  gridClasses: string
  lineItem: PurchaseOrderFull['lineItems'][number]
}): JSX.Element => {
  const hasWarning = !lineItem.vendorSKU || !lineItem.isAvailable

  return (
    <div className={gridClasses}>
      <LineItemsTableCols
        purchasedGoodCol={
          <div className="relative flex items-center space-x-2">
            {hasWarning && (
              <div className="absolute left-0 top-1/2 -translate-x-full -translate-y-1/2 transform">
                <div className="h-5 w-5 text-red">
                  <ExclamationCircleIcon />
                </div>
              </div>
            )}
            {lineItem.vendorSKU ? (
              <div>
                <div
                  className={clsx({
                    'text-red': hasWarning,
                  })}
                >
                  {lineItem.vendorSKU.name}
                </div>
                <div className="text-sm text-grey">
                  Vendor SKU: {lineItem.vendorSKU.sku || 'None'}
                </div>
              </div>
            ) : (
              <div className="text-sm text-grey">
                Internal SKU ID: {lineItem.vendorSKUID}
              </div>
            )}
          </div>
        }
        qtyCol={
          <div className="flex items-center space-x-2">
            <span>{lineItem.orderQty}</span>
            <span>{lineItem.orderUnit}</span>
          </div>
        }
        totalPriceCol={formatDollars(lineItem.total)}
        totalWtCol={formatCommas(lineItem.totalWt)}
        unitPriceCol={<span>{getFormattedUnitPrice(lineItem, 3)}</span>}
        unitWtCol={lineItem.orderUnitWt}
      />
    </div>
  )
}
