import { ReactNode } from 'react'
import { uniqBy } from 'lodash-es'
import { useDrop } from 'react-dnd'

import { error } from 'slices/notifications'
import { ProductionPartWithCycle } from 'types/internal'
import {
  ProductionPart,
  Tag,
  TagCategories,
} from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { useEditProductionPart } from 'hooks/combinedAPI/productionParts'
import H3 from 'components/common/bs/H3'
import H5 from 'components/common/bs/H5'
import PrepItem from './PrepItem'

interface Day {
  day:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
  parts: ProductionPartWithCycle[]
}

const PrepDay = ({
  day,
  getClipboardText,
  getPartsForTag,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: {
  day: Day
  getClipboardText(part: ProductionPartWithCycle): string
  getPartsForTag(
    parts: ProductionPartWithCycle[],
    tag: Tag
  ): ProductionPartWithCycle[]
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { mutate: editProductionPart } = useEditProductionPart({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  async function editPrepDay(
    part: ProductionPart,
    prepDay: TagCategories['day_of_week']
  ) {
    if (readOnly) {
      return dispatch(
        error('You do not have permission to update the prep day.')
      )
    }

    editProductionPart({
      data: {
        numBatches: part.numBatches,
        tags: uniqBy(
          [{ category: 'day_of_week', title: prepDay } as const, ...part.tags],
          'category'
        ),
      },
      productionPartID: part.id,
      productionTerm,
    })
  }

  const [, drop] = useDrop<{ part: ProductionPartWithCycle }, unknown, unknown>(
    {
      accept: 'prepItem',
      drop(item) {
        if (readOnly) {
          return dispatch(
            error('You do not have permission to adjust prep days.')
          )
        }

        return editPrepDay(
          item.part,
          day.day.toLowerCase() as TagCategories['day_of_week']
        )
      },
    }
  )

  const batchMixParts = getPartsForTag(day.parts, {
    category: 'mix',
    title: 'batch_mix',
  })
  const drainParts = getPartsForTag(day.parts, {
    category: 'canned',
    title: 'drain',
  })
  const kettleParts = getPartsForTag(day.parts, {
    category: 'cook',
    title: 'kettle',
  })
  const knifeParts = getPartsForTag(day.parts, {
    category: 'manual',
    title: 'knife',
  })
  const openParts = getPartsForTag(day.parts, {
    category: 'canned',
    title: 'open',
  })
  const ovenParts = getPartsForTag(day.parts, {
    category: 'cook',
    title: 'oven',
  })
  const planetaryMixerParts = getPartsForTag(day.parts, {
    category: 'mix',
    title: 'planetary_mixer',
  })
  const portionAndFreezeParts = getPartsForTag(day.parts, {
    category: 'prepare',
    title: 'portion_and_freeze',
  })
  const roboParts = getPartsForTag(day.parts, {
    category: 'manual',
    title: 'robo',
  })
  const sauceMixParts = getPartsForTag(day.parts, {
    category: 'mix',
    title: 'sauce_mix',
  })
  const skilletParts = getPartsForTag(day.parts, {
    category: 'cook',
    title: 'skillet',
  })
  const thawFrozenParts = getPartsForTag(day.parts, {
    category: 'prepare',
    title: 'thaw_frozen',
  })
  const vcmParts = getPartsForTag(day.parts, {
    category: 'manual',
    title: 'vcm',
  })

  return (
    <div key={day.day} ref={drop}>
      <H3>{day.day}</H3>
      <PrepDayCategory>
        <H5>Cook</H5>

        <div>
          <b>Skillet</b>
          {skilletParts.length > 0 && (
            <PrepDayCategorySection>
              {skilletParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="warning"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Kettle</b>
          {kettleParts.length > 0 && (
            <PrepDayCategorySection>
              {kettleParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="warning"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Oven</b>
          {ovenParts.length > 0 && (
            <PrepDayCategorySection>
              {ovenParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="warning"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>
      </PrepDayCategory>

      <PrepDayCategory>
        <H5>Prepare</H5>

        <div>
          <b>Thaw Frozen</b>
          {thawFrozenParts.length > 0 && (
            <PrepDayCategorySection>
              {thawFrozenParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="primary"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Portion and Freeze</b>
          {portionAndFreezeParts.length > 0 && (
            <PrepDayCategorySection>
              {portionAndFreezeParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="primary"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>
      </PrepDayCategory>

      <PrepDayCategory>
        <H5>Mix</H5>

        <div>
          <b>Sauce Mix</b>
          {sauceMixParts.length > 0 && (
            <PrepDayCategorySection>
              {sauceMixParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="dark"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Batch Mix</b>
          {batchMixParts.length > 0 && (
            <PrepDayCategorySection>
              {batchMixParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="dark"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Planetary Mixer</b>
          {planetaryMixerParts.length > 0 && (
            <PrepDayCategorySection>
              {planetaryMixerParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="dark"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>
      </PrepDayCategory>

      <PrepDayCategory>
        <H5>Manual</H5>

        <div>
          <b>Knife Work</b>
          {knifeParts.length > 0 && (
            <PrepDayCategorySection>
              {knifeParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="success"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Robo Coupe</b>
          {roboParts.length > 0 && (
            <PrepDayCategorySection>
              {roboParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="success"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>VCM</b>
          {vcmParts.length > 0 && (
            <PrepDayCategorySection>
              {vcmParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="success"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>
      </PrepDayCategory>

      <PrepDayCategory>
        <H5>Canned</H5>
        <div>
          <b>Drain</b>
          {drainParts.length > 0 && (
            <PrepDayCategorySection>
              {drainParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="secondary"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>

        <div>
          <b>Open</b>
          {openParts.length > 0 && (
            <PrepDayCategorySection>
              {openParts.map((part, i) => (
                <PrepItem
                  key={i}
                  getClipboardText={getClipboardText}
                  part={part}
                  variant="secondary"
                />
              ))}
            </PrepDayCategorySection>
          )}
        </div>
      </PrepDayCategory>
    </div>
  )
}

export default PrepDay

const PrepDayCategory = ({ children }: { children: ReactNode }) => {
  return <div className="mb-5 border-b border-grey2 pb-3">{children}</div>
}

const PrepDayCategorySection = ({ children }: { children: ReactNode }) => {
  return (
    <div className="divide-y divide-grey2 rounded border border-grey2">
      {children}
    </div>
  )
}
