import { PDFViewer } from '@react-pdf/renderer'

import { EmployeeLabel } from 'types/internal'

import EmployeeDiscountPDF from './EmployeeDiscountPDF'

const EmployeeDiscountPDFViewer = ({
  data,
  onClickBack,
}: {
  data: EmployeeLabel[]
  onClickBack(): void
}): JSX.Element => {
  return (
    <>
      <button
        className="my-3 text-blue hover:underline"
        onClick={() => {
          onClickBack()
        }}
      >
        Go Back
      </button>
      <PDFViewer className="h-screen w-full">
        <EmployeeDiscountPDF data={data} />
      </PDFViewer>
    </>
  )
}

export default EmployeeDiscountPDFViewer
