import { round } from 'lodash-es'

export function formatDollars(value: number, decimalPlaces = 2): string {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
  }).format(round(value, decimalPlaces))
}

export function percentDifference(valueOne: number, valueTwo: number): number {
  return 100 * Math.abs((valueOne - valueTwo) / ((valueOne + valueTwo) / 2))
}
