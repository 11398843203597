import { InsulationTypeDetails, InsulationTypeName } from 'types/internal'

import image4plx5 from './images/4plx5.png'
import image5pl from './images/5pl.png'
import image6pl from './images/6pl.png'
import image8pl8 from './images/8pl8.png'
import image9pl from './images/9pl.png'
import image12pl8 from './images/12pl8.png'

const INSULATION_TYPES: Omit<
  {
    [insulationTypeName in InsulationTypeName]: InsulationTypeDetails
  },
  'invalid-insulation'
> = {
  '1in_white_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 9,
        boxesPerPallet: 63,
        layoutImage: image9pl,
      },
      6: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
      8: {
        boxesPerLayer: 5,
        boxesPerPallet: 30,
        layoutImage: image5pl,
      },
    },
    palletSheetAbbreviation: 'WH_B',
  },
  '1in_white_top_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 9,
        boxesPerPallet: 63,
        layoutImage: image9pl,
      },
      6: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
      8: {
        boxesPerLayer: 5,
        boxesPerPallet: 30,
        layoutImage: image5pl,
      },
    },
    palletSheetAbbreviation: 'WH_TB',
  },
  '1.5in_silver': {
    palletInfo: {
      4: {
        boxesPerLayer: 9,
        boxesPerPallet: 54,
        layoutImage: image9pl,
      },
      6: {
        boxesPerLayer: 6,
        boxesPerPallet: 36,
        layoutImage: image6pl,
      },
      8: {
        boxesPerLayer: 5,
        boxesPerPallet: 25,
        layoutImage: image5pl,
      },
    },
    palletSheetAbbreviation: 'IC',
  },
  // 2" top 2 bottom only used for 3/4 meal boxes
  '2in_top_2_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 6,
        boxesPerPallet: 30,
        layoutImage: image6pl,
      },
      6: {
        boxesPerLayer: 6,
        boxesPerPallet: 30,
        layoutImage: image6pl,
      },
      8: {
        boxesPerLayer: 4,
        boxesPerPallet: 20,
        layoutImage: image4plx5,
      },
    },
    palletSheetAbbreviation: 'Superhot',
  },
  // 2" top bottom only used for 6/8 meal boxes
  '2in_top_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 6,
        boxesPerPallet: 30,
        layoutImage: image6pl,
      },
      6: {
        boxesPerLayer: 6,
        boxesPerPallet: 30,
        layoutImage: image6pl,
      },
      8: {
        boxesPerLayer: 4,
        boxesPerPallet: 20,
        layoutImage: image4plx5,
      },
    },
    palletSheetAbbreviation: 'Superhot',
  },
  '700gsm_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 12,
        boxesPerPallet: 96,
        layoutImage: image12pl8,
      },
      6: {
        boxesPerLayer: 8,
        boxesPerPallet: 64,
        layoutImage: image8pl8,
      },
      8: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
    },
    palletSheetAbbreviation: 'Winter_B',
  },
  TGA8_bottom_ice: {
    palletInfo: {
      4: {
        boxesPerLayer: 12,
        boxesPerPallet: 96,
        layoutImage: image12pl8,
      },
      6: {
        boxesPerLayer: 8,
        boxesPerPallet: 64,
        layoutImage: image8pl8,
      },
      8: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
    },
    palletSheetAbbreviation: 'TG_B',
  },
  '0.5in_WK_top_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 12,
        boxesPerPallet: 96,
        layoutImage: image12pl8,
      },
      6: {
        boxesPerLayer: 8,
        boxesPerPallet: 64,
        layoutImage: image8pl8,
      },
      8: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
    },
    palletSheetAbbreviation: 'WK_TB',
  },
  '0.5in_WK_bottom_ice': {
    palletInfo: {
      4: {
        boxesPerLayer: 12,
        boxesPerPallet: 96,
        layoutImage: image12pl8,
      },
      6: {
        boxesPerLayer: 8,
        boxesPerPallet: 64,
        layoutImage: image8pl8,
      },
      8: {
        boxesPerLayer: 6,
        boxesPerPallet: 42,
        layoutImage: image6pl,
      },
    },
    palletSheetAbbreviation: 'WK_B',
  },
}

export function findInsulationTypeByName(
  insulationName: InsulationTypeName
): InsulationTypeDetails | undefined {
  if (insulationName === 'invalid-insulation') {
    return undefined
  }

  const insulationTypeDetails = INSULATION_TYPES[insulationName]

  if (!insulationTypeDetails) {
    return undefined
  }

  return insulationTypeDetails
}
