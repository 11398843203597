import { sortBy, sumBy, uniq } from 'lodash-es'

import { ComposedMeal, ComposedMealBOMItem } from 'types/internal'
import { getTagByCategory, getTagsByCategories } from './tags'
import { isGarnishContainer } from './containers'
import { lbsToGrams } from './general'
import {
  Meal,
  MealBOMItem,
  TagCategories,
} from 'types/combinedAPI/domainModels'

const TRAY_CONTRIBUTING_CONTAINERS: TagCategories['container'][] = [
  'tray 1',
  'tray 1 bag',
  'tray 1 dry sachet',
  'tray 1 sachet',
  'tray 2',
  'tray 2 bag',
  'tray 2 dry sachet',
  'tray 2 sachet',
]

export function numberOfTraysForMeal(meal: Meal): 1 | 2 | undefined {
  const containers = meal.billOfMaterials.map((bom) => {
    const containerTag = getTagByCategory(bom.tags, 'container')

    return containerTag?.title
  })

  // We could have duplicate containers when multiple BOMs are combined into a single
  // component.
  const uniqueContainers = uniq(containers)

  const trayCount = sumBy(uniqueContainers, (container) => {
    return container && TRAY_CONTRIBUTING_CONTAINERS.includes(container) ? 1 : 0
  })

  if (trayCount > 0) {
    return Math.min(trayCount, 2) as 1 | 2
  }
}

function composeBomsForMeal(
  boms: MealBOMItem[],
  mealAttrs: {
    count: number
    sleevingLocation: TagCategories['sleeving_location'] | ''
  },
  prepLocationTitle: string
): ComposedMealBOMItem[] {
  if (!boms) {
    return []
  }

  return boms.map((bom) => {
    let gas: 'N' | 'Y' = 'N'

    const [
      containerTag,
      filmTag,
      portionLocationTag,
      portionDateTag,
      frozenTag,
      spareTrayTag,
      mapTag,
    ] = getTagsByCategories(bom.tags, [
      'container',
      'film_type',
      'portion_location',
      'portion_date',
      'frozen',
      'spare_tray',
      'MAP',
    ])

    if (mapTag && mapTag.title === 'MAP') {
      gas = 'Y'
    }

    const gramsPerPortion = lbsToGrams(bom.qtyPounds)

    return {
      combined: bom.combined,
      container: containerTag ? containerTag.title : '',
      filmType: filmTag ? filmTag.title : '',
      frozen: frozenTag ? frozenTag.title : '',
      gas,
      id: bom.id,
      isPurchasedGood: !bom.productionPartID,
      title: bom.title,
      map: mapTag ? mapTag.title : '',
      productionPartID: bom.productionPartID,
      prepLocation: bom.productionPartID ? prepLocationTitle : '',
      portionDate: portionDateTag ? portionDateTag.title : '',
      portionLocation: portionLocationTag ? portionLocationTag.title : '',
      qtyGrams: gramsPerPortion,
      qtyPounds: bom.qtyPounds,
      spareTray: spareTrayTag ? spareTrayTag.title : '',
      tags: bom.tags,
      totalWeightRequiredPounds: bom.totalWeightRequiredPounds,
      ...mealAttrs,
    }
  })
}

function unassignedBomsForMeal(meal: Meal): MealBOMItem[] {
  return meal.billOfMaterials.filter((bom) => {
    return !bom.tags.find((t) => t.category === 'container')
  })
}

function naBomsForMeal(meal: Meal): MealBOMItem[] {
  return meal.billOfMaterials.filter((bom) => {
    return !!bom.tags.find(
      (t) => t.category === 'container' && t.title === 'N/A'
    )
  })
}

export function composeMealsForCycle(
  meals: Meal[],
  prepLocationTitle = ''
): ComposedMeal[] {
  const composedMeals: ComposedMeal[] = meals.map((meal) => {
    const [sleevingLocationTag, sleevingConfigurationTag] = getTagsByCategories(
      meal.tags,
      ['sleeving_location', 'sleeving_configuration']
    )

    const sleevingLocation = sleevingLocationTag
      ? sleevingLocationTag.title
      : ''
    const sleevingConfiguration = sleevingConfigurationTag
      ? sleevingConfigurationTag.title
      : ''

    const component1 = getTrayBomsForMeal({ meal, trayNumber: 1 })
    const component2 = getTrayBomsForMeal({ meal, trayNumber: 2 })
    const garnishes = getGarnishBomsForMeal(meal)
    const unassignedBoms = unassignedBomsForMeal(meal)
    const naBoms = naBomsForMeal(meal)

    return {
      component1: composeBomsForMeal(
        component1,
        { count: meal.totalMeals, sleevingLocation },
        prepLocationTitle
      ),
      component2: composeBomsForMeal(
        component2,
        { count: meal.totalMeals, sleevingLocation },
        prepLocationTitle
      ),
      garnishes: composeBomsForMeal(
        garnishes,
        { count: meal.totalMeals, sleevingLocation },
        prepLocationTitle
      ),
      id: meal.id,
      mealCode: meal.mealCode,
      naBoms: composeBomsForMeal(
        naBoms,
        { count: meal.totalMeals, sleevingLocation },
        prepLocationTitle
      ),
      numberOfGarnishes: garnishes.length,
      numberOfTrays: numberOfTraysForMeal(meal),
      partID: meal.partID,
      procedure: meal.procedure,
      shortTitle: meal.shortTitle,
      sleevingConfiguration,
      sleevingLocation,
      tags: meal.tags,
      totalMeals: meal.totalMeals,
      unassigned: composeBomsForMeal(
        unassignedBoms,
        { count: meal.totalMeals, sleevingLocation },
        prepLocationTitle
      ),
    }
  })

  return sortBy(composedMeals, 'mealCode')
}

export function colorsForTrayContainer(
  container: TagCategories['container'] | ''
): string {
  if (container === 'bag') {
    return '#90191a'
  }

  if (container.includes('9x14 bag')) {
    return '#F6EAC2'
  }

  if (container.includes('14x16 bag')) {
    return '#55CBCD'
  }

  // Make sure to keep unsealed above sealed below to keep colors conistent
  if (container.includes('unsealed body armor')) {
    return '#D2CCF1'
  }

  if (container.includes('sealed body armor')) {
    return '#FFCCF1'
  }

  if (container.includes('bagged meal extra')) {
    return '#DAA0F1'
  }

  if (container.includes('veggie bags')) {
    return '#9AB7D3'
  }

  if (container.includes('fish tray')) {
    return '#E4FE7E'
  }

  if (container.includes('mushroom bags')) {
    return '#C7DDAA'
  }

  if (container.includes('vac')) {
    return '#fff'
  }

  if (container === 'tray 1' || container === 'tray 2') {
    return '#97EE92'
  }

  if (container.includes('clamshell')) {
    return '#E4AE7E'
  }

  if (container === 'tray 1 sachet' || container === 'tray 2 sachet') {
    return '#89cff0'
  }

  if (container === 'tray 1 dry sachet' || container === 'tray 2 dry sachet') {
    return '#ead1dc'
  }

  return ''
}

export function getGarnishBomsForMeal(meal: Meal): MealBOMItem[] {
  return meal.billOfMaterials.filter((bom) => {
    const containerTag = getTagByCategory(bom.tags, 'container')

    return !!(containerTag && isGarnishContainer(containerTag.title))
  })
}

export function getTrayBomsForMeal({
  meal,
  trayNumber,
}: {
  meal: Meal
  trayNumber: 1 | 2
}): MealBOMItem[] {
  return meal.billOfMaterials.filter((bom) => {
    return !!bom.tags.find(
      (t) =>
        t.category === 'container' && t.title.includes(`tray ${trayNumber}`)
    )
  })
}
