import { ReactNode, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { FacilityNetwork } from 'types/combinedAPI/domainModels'
import { getEnvVar } from 'utils/env'
import { selectFacilityNetwork } from 'slices/production'

import { useFacilityNetworks } from 'hooks/combinedAPI/facilityNetworks'
import dashboardBgImg from './bnw-bg.svg'
import H3 from 'components/common/bs/H3'
import Modal, { ModalBody, ModalHeader } from 'components/common/Modal'
import Ul from 'components/common/bs/Ul'

type PendingPage = 'terms' | 'purchaseOrders' | ''

const CULINARY_TOOLS_URL = getEnvVar('CULINARY_TOOLS_URL')

const Dashboard = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Represents a page to navigate to after a facility network is selected.
  const pendingPage = useRef<PendingPage>('')
  const [showFacilityNetworksModal, setShowFacilityNetworksModal] =
    useState(false)

  const { data: facilityNetworks = [] } = useFacilityNetworks(true)

  function navigateToTermProduction() {
    pendingPage.current = 'terms'
    setShowFacilityNetworksModal(true)
  }

  function navigateToPurchaseOrder() {
    pendingPage.current = 'purchaseOrders'
    setShowFacilityNetworksModal(true)
  }

  function onNetworkSelect(network: FacilityNetwork) {
    dispatch(selectFacilityNetwork(network))

    if (pendingPage.current === 'terms') {
      navigate('/terms')
    } else if (pendingPage.current === 'purchaseOrders') {
      navigate(`/purchaseOrders?facilityNetworkID=${network.id}`)
    }
  }

  return (
    <div
      className="h-screen w-screen bg-cover"
      style={{ backgroundImage: `url(${dashboardBgImg})` }}
    >
      <div className="flex justify-center space-x-8 px-4 pt-10">
        <DashboardCard>
          <a className="text-bs-blue hover:underline" href={CULINARY_TOOLS_URL}>
            <i className="fas fa-pizza-slice" /> Culinary Tools
          </a>
        </DashboardCard>

        <DashboardCard>
          <a
            className="text-bs-blue hover:underline"
            href="#"
            onClick={navigateToTermProduction}
          >
            <i className="fas fa-cogs" /> Term Production
          </a>
        </DashboardCard>

        <DashboardCard>
          <a
            className="text-bs-blue hover:underline"
            href="#"
            onClick={navigateToPurchaseOrder}
          >
            <i className="fas fa-money-check" /> Purchase Orders
          </a>
        </DashboardCard>
      </div>

      {showFacilityNetworksModal && (
        <Modal onCloseModal={() => setShowFacilityNetworksModal(false)}>
          <ModalBody>
            <div className="w-[800px]">
              <div className="mb-4">
                <ModalHeader
                  onClickClose={() => setShowFacilityNetworksModal(false)}
                >
                  Facility Networks
                </ModalHeader>
              </div>

              <div className="flex space-x-4">
                {facilityNetworks.map((network) => (
                  <div key={network.title} className="w-1/2">
                    <div
                      className="rounded border border-light-grey"
                      data-testid={network.title}
                    >
                      <div className="border-b border-light-grey bg-bs-lightest-grey px-5 py-3">
                        {network.displayName}
                      </div>
                      <div className="p-5">
                        <div className="mb-2 text-xl font-semibold">
                          Available Facilities
                        </div>
                        <Ul>
                          {network.facilities.map((facility) => (
                            <li key={facility.title}>{facility.displayName}</li>
                          ))}
                        </Ul>
                        <button
                          className="px-4 py-2 text-blue hover:underline"
                          onClick={() => onNetworkSelect(network)}
                          type="button"
                        >
                          Select Network
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  )
}

export default Dashboard

const DashboardCard = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="w-80 rounded border-b border-bs-light-grey bg-white px-5 py-3">
      <H3 withMargin={false}>{children}</H3>
    </div>
  )
}
