import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { Login, login, LoginResponse } from 'services/combinedAPI/auth'

export function useLogin(
  opts?: Omit<
    UseMutationOptions<LoginResponse, Error, Login['data']>,
    'mutationFn'
  >
) {
  return useMutation({
    ...opts,
    mutationFn: (data) => {
      return login({ data })
    },
  })
}
