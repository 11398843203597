import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router-dom'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatDollars } from 'utils/currency'
import {
  getAPIFormData,
  getLineItemsTotal,
  SavePurchaseOrderFormData,
  SavePurchaseOrderFormDataValidated,
} from './helpers'

import { useSavePurchaseOrder } from 'hooks/combinedAPI/purchaseOrders'
import ConfirmationModal from 'components/common/ConfirmationModal'

const ConfirmPOSaveModal = ({
  isEdit,
  onClosePOConfirmation,
  purchaseOrderID,
  selectedFacilityNetworkID,
}: {
  isEdit: boolean
  onClosePOConfirmation(): void
  purchaseOrderID: string | undefined
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const navigate = useNavigate()

  const { values } = useFormikContext<SavePurchaseOrderFormData>()
  const { facility, freightCost, lineItems, requestedDeliveryDate, vendor } =
    values

  // We will have an empty line item for the user to keep adding line items but we only want
  // to consider full line items for the below code.
  const fullLineItems = lineItems.filter((lineItem) => {
    return !!lineItem.vendorSKU
  })

  const totalCostForLineItems = getLineItemsTotal({
    lineItems: fullLineItems,
    selectedFacilityNetworkID,
  })

  const totalCost = totalCostForLineItems + Number(freightCost)

  const {
    error: savePOError,
    isError: hasSavePOError,
    isLoading: isSavingPurchaseOrder,
    mutate: savePurchaseOrder,
  } = useSavePurchaseOrder({
    onSuccess: (data) => {
      navigate(
        `/purchaseOrders/${data.id}?facilityNetworkID=${selectedFacilityNetworkID}`
      )
    },
    purchaseOrderID,
  })

  return (
    <ConfirmationModal
      apiError={hasSavePOError ? savePOError : null}
      confirmText={isEdit ? 'Save' : 'Create'}
      isLoading={isSavingPurchaseOrder}
      onCancel={onClosePOConfirmation}
      onConfirm={() => {
        savePurchaseOrder({
          isEdit,
          ...getAPIFormData({
            formData: values as SavePurchaseOrderFormDataValidated,
            selectedFacilityNetworkID,
          }),
        })
      }}
      title={`Confirm ${isEdit ? 'Edit' : 'Create'} Purchase Order`}
    >
      <div className="space-y-4">
        {isEdit ? (
          <p>Are you sure you want to edit this purchase order?</p>
        ) : (
          <p>Are you sure you want to create a new purchase order?</p>
        )}

        <div className="space-y-1">
          <ConfirmationFormField label="Vendor" value={vendor?.value.name} />
          <ConfirmationFormField
            label="Facility"
            value={facility?.value.displayName}
          />
          <ConfirmationFormField
            label="Req. Delivery Date"
            value={formatDate(requestedDeliveryDate, {
              format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
            })}
          />
          <ConfirmationFormField
            label="# Line Items"
            value={fullLineItems.length}
          />
          <ConfirmationFormField
            label="Freight Cost"
            value={formatDollars(freightCost || 0)}
          />
          <ConfirmationFormField
            label="Total"
            value={formatDollars(totalCost)}
          />
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default ConfirmPOSaveModal

const ConfirmationFormField = ({
  label,
  value,
}: {
  label: string
  value: number | string | undefined
}): JSX.Element => {
  return (
    <div className="flex items-center">
      <div className="w-48 text-sm uppercase text-grey">{label}:</div>
      <div>{value}</div>
    </div>
  )
}
