import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CSSProperties } from 'react'
import { useDrag } from 'react-dnd'

import { ProductionPartWithCycle } from 'types/internal'
import { success } from 'slices/notifications'

import { useAppDispatch } from 'hooks'

const VARIANT_STYLES = {
  dark: {
    backgroundColor: '#c6c8ca',
    color: '#1b1e21',
  },
  primary: {
    backgroundColor: '#b8daff',
    color: '#004085',
  },
  secondary: {
    backgroundColor: '#d6d8db',
    color: '#383d41',
  },
  success: {
    backgroundColor: '#c3e6cb',
    color: '#155724',
  },
  warning: {
    backgroundColor: '#ffeeba',
    color: '#856404',
  },
} as const satisfies Record<Props['variant'], CSSProperties>

interface Props {
  getClipboardText(part: ProductionPartWithCycle): string
  part: ProductionPartWithCycle
  variant: 'dark' | 'primary' | 'secondary' | 'success' | 'warning'
}

const PrepItem = ({ getClipboardText, part, variant }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const [, drag] = useDrag({
    item: { part: { ...part } },
    type: 'prepItem',
  })

  return (
    <div
      key={`part${part.id}`}
      ref={drag}
      className="relative py-3 px-5 first:rounded-t last:rounded-b"
      style={VARIANT_STYLES[variant]}
    >
      {part.title} (#{part.apiMealCode}
      {Number(part.cycleNumber) > 1 ? '-a' : ''}) - {part.numBatches} batches
      <br />({(part.totalWeightPounds / part.numBatches).toFixed(2)} lbs per
      batch)
      <CopyToClipboard
        onCopy={() => dispatch(success('Copied!'))}
        text={getClipboardText(part)}
      >
        <i className="fas fa-copy absolute top-1 right-1 cursor-pointer"></i>
      </CopyToClipboard>
    </div>
  )
}

export default PrepItem
