import { TagCategories } from 'types/combinedAPI/domainModels'

import { useFloating } from 'hooks/floating'
import Button from 'components/common/bs/Button'
import ChevronDownIcon from 'components/common/icons/ChevronDownIcon'
import DropdownItem from './DropdownItem'
import Menu, { MenuButton, MenuItems } from 'components/common/Menu'

const DropdownCook = ({
  disabled = false,
  onChange,
}: {
  disabled?: boolean
  onChange(value: TagCategories['cook']): void
}): JSX.Element => {
  const { floating, reference, strategy, x, y } = useFloating()

  return (
    <Menu>
      <MenuButton ref={reference}>
        <Button buttonStyle="yellow" disabled={disabled}>
          <span className="flex items-center">
            Cook
            <div className="h-4 w-4">
              <ChevronDownIcon />
            </div>
          </span>
        </Button>
      </MenuButton>

      <MenuItems
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        <DropdownItem
          onClick={() => {
            onChange('skillet')
          }}
        >
          Skillet
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            onChange('kettle')
          }}
        >
          Kettle
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            onChange('oven')
          }}
        >
          Oven
        </DropdownItem>
      </MenuItems>
    </Menu>
  )
}

export default DropdownCook
