import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  CreatePickOrders,
  createPickOrders,
  CreatePickOrdersResponse,
  getPickOrders,
  GetPickOrders,
  GetPickOrdersResponse,
} from 'services/combinedAPI/pickOrders'

export function useCreatePickOrders(
  opts?: Omit<
    UseMutationOptions<CreatePickOrdersResponse, Error, CreatePickOrders>,
    'mutationFn'
  >
) {
  return useMutation<CreatePickOrdersResponse, Error, CreatePickOrders>({
    ...opts,
    mutationFn: (opts) => {
      return createPickOrders(opts)
    },
  })
}

export function usePickOrders({
  facilityNetworkID,
  productionTerm,
  ...rest
}: GetPickOrders &
  Omit<UseQueryOptions<GetPickOrdersResponse, Error>, 'queryFn' | 'queryKey'>) {
  return useQuery<GetPickOrdersResponse, Error>({
    ...rest,
    queryKey: ['pickOrders', facilityNetworkID, productionTerm],
    queryFn: () => {
      if (facilityNetworkID === '') {
        throw new Error(
          'Please refresh the page and try again. Missing facility network.'
        )
      }

      return getPickOrders({ facilityNetworkID, productionTerm })
    },
  })
}
