import { Document, Page, Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { groupBy, keys, times } from 'lodash-es'

import { commonPDFStyles } from 'utils/pdfs'
import {
  componentsForMeal,
  transferTagNumbersForComponent,
} from 'utils/transfer-tags'
import {
  Facility,
  FacilityNetwork,
  Meal,
  ProductionCycle,
  TagCategories,
} from 'types/combinedAPI/domainModels'
import { getTransferPartialBinUnit } from 'utils/shipping'

import {
  BigRedTitle,
  CodeColorContainer,
  CodeColorText,
  ColText,
  FullBins,
  FullBinsText,
  MealCol,
  MealRow,
  Signature,
  TermRow,
  TermText,
  ThickBorder,
  TitleContainer,
} from './styles'
import ApprovalContent from './ApprovalContent'
import LastTower from './LastTower'

const ComponentDocumentPDF = ({
  destinationFacility,
  facilityNetwork,
  productionCycle,
  term,
  transferComponents,
  type,
}: {
  destinationFacility: Facility | undefined
  facilityNetwork: FacilityNetwork
  productionCycle: ProductionCycle
  term: string
  transferComponents: Meal[]
  type: 'component' | 'frozen'
}): JSX.Element => {
  let label = ''
  let appendThis = ''

  if (type === 'frozen') {
    label = 'Frozen Components'
  } else if (type === 'component') {
    label = 'Meal Components'
  }

  if (productionCycle === '2') {
    appendThis = '-A'
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...commonPDFStyles.page,
          flexDirection: 'column',
          padding: '15px',
        }}
      >
        <View style={{ marginBottom: '10px' }}>
          <Text>
            Term {term} - {destinationFacility?.displayName} Transfer {label}
          </Text>
        </View>
        <View>
          {transferComponents.map((transferComponent) => {
            const components = componentsForMeal(
              type,
              transferComponent,
              destinationFacility?.title ?? '',
              facilityNetwork
            )
            const grouped = groupBy(components, (c) => {
              const containerTag = c.tags.find(
                (t) => t.category === 'container'
              )
              return containerTag?.title
            })

            return keys(grouped).map((container) => {
              const typedContainer = container as TagCategories['container']
              const component = grouped[container][0]
              const binAndTowerUsage = transferTagNumbersForComponent(
                transferComponent,
                component
              )
              const totalBins = binAndTowerUsage.totalBins
              const totalFullBins = binAndTowerUsage.totalFullBins
              const partialBinMeals = binAndTowerUsage.partialBinUnits
              const partialTowerBins = binAndTowerUsage.partialTowerBins
              const fullTowers = binAndTowerUsage.fullTowers
              const allTowers = binAndTowerUsage.allTowers
              const unit = getTransferPartialBinUnit(typedContainer)

              const commonProps = {
                allTowers,
                fullTowers,
                mealCode: transferComponent.mealCode,
                partialBinMeals,
                partialTowerBins,
                totalBins,
                totalFullBins,
                totalMeals: transferComponent.totalMeals,
                unit,
              }

              if (
                container.includes('tray 1') ||
                container.includes('tray 2') ||
                container.includes('bag') ||
                container.includes('clamshell')
              ) {
                return (
                  <SummaryRow
                    key={container}
                    {...commonProps}
                    title={grouped[container].map((g) => g.title).join(', ')}
                  />
                )
              }

              return (
                <Fragment key={container}>
                  {grouped[container].map((c, i) => (
                    <SummaryRow key={i} {...commonProps} title={c.title} />
                  ))}
                </Fragment>
              )
            })
          })}
        </View>
      </Page>
      {transferComponents.map((transferComponent) => {
        const components = componentsForMeal(
          type,
          transferComponent,
          destinationFacility?.title ?? '',
          facilityNetwork
        )
        const grouped = groupBy(components, (c) => {
          const containerTag = c.tags.find((t) => t.category === 'container')

          return containerTag?.title
        })

        return keys(grouped).map((container) => {
          const typedContainer = container as TagCategories['container']
          const component = grouped[container][0]

          const isSachet = !!component.tags
            .find((t) => t.category === 'container')
            ?.title.includes('sachet')

          const binAndTowerUsage = transferTagNumbersForComponent(
            transferComponent,
            component
          )
          const partialBinComponents = binAndTowerUsage.partialBinUnits
          const allTowers = binAndTowerUsage.allTowers
          const partialTowerBins = binAndTowerUsage.partialTowerBins
          const maxBinsPerTower = binAndTowerUsage.maxBinsPerTower
          const unit = getTransferPartialBinUnit(typedContainer)

          const commonProps = {
            allTowers,
            destinationFacility,
            isSachet,
            maxBinsPerTower,
            mealCode: transferComponent.mealCode,
            partialBinComponents,
            partialTowerBins,
            term,
            unit,
          }

          if (
            container.includes('tray 1') ||
            container.includes('tray 2') ||
            container.includes('bag') ||
            container.includes('clamshell')
          ) {
            return (
              <TransferSheet
                key={container}
                {...commonProps}
                title={grouped[container]
                  .map((g) => `${g.title}${appendThis}`)
                  .join(' / ')}
              />
            )
          }

          return (
            <Fragment key={container}>
              {grouped[container].map((g, i) => {
                return (
                  <TransferSheet
                    key={i}
                    {...commonProps}
                    title={`${g.title}${appendThis}`}
                  />
                )
              })}
            </Fragment>
          )
        })
      })}
    </Document>
  )
}

export default ComponentDocumentPDF

const SummaryRow = ({
  allTowers,
  fullTowers,
  mealCode,
  partialBinMeals,
  partialTowerBins,
  title,
  totalBins,
  totalFullBins,
  totalMeals,
  unit,
}: {
  allTowers: number
  fullTowers: number
  mealCode: number
  partialBinMeals: number
  partialTowerBins: number
  title: string
  totalBins: number
  totalFullBins: number
  totalMeals: number
  unit: string
}): JSX.Element => {
  return (
    <MealRow wrap={false}>
      <MealCol>
        <ColText>
          Meal #{mealCode} {title}
        </ColText>
      </MealCol>
      <MealCol>
        <ColText>Total Quantity: {totalMeals}</ColText>
      </MealCol>
      <MealCol>
        <ColText>Total Bins: {totalBins}</ColText>
        <ColText>
          ({totalFullBins} full
          {partialBinMeals > 0 ? ', 1 partial' : ''})
        </ColText>
      </MealCol>
      <MealCol>
        <ColText>Total Towers: {allTowers}</ColText>
        <ColText>
          ({fullTowers} full
          {partialTowerBins > 0 ? ', 1 partial' : ''})
        </ColText>
      </MealCol>
      <MealCol>
        <ColText>Partial Tower (bins): {partialTowerBins}</ColText>
        <ColText>
          {partialTowerBins && partialBinMeals
            ? `(${partialTowerBins - 1} full, 1 partial)`
            : ''}
        </ColText>
      </MealCol>
      <MealCol>
        <ColText>Partial Bin</ColText>
        <ColText>
          ({unit}): {partialBinMeals ? partialBinMeals : 'None'}
        </ColText>
      </MealCol>
    </MealRow>
  )
}

const TransferSheet = ({
  allTowers,
  destinationFacility,
  isSachet,
  maxBinsPerTower,
  mealCode,
  partialBinComponents,
  partialTowerBins,
  term,
  title,
  unit,
}: {
  allTowers: number
  destinationFacility: Facility | undefined
  isSachet: boolean
  maxBinsPerTower: number
  mealCode: number
  partialBinComponents: number
  partialTowerBins: number
  term: string
  title: string
  unit: string
}): JSX.Element => {
  const containerBackgroundColor = destinationFacility?.fulfillmentFacility
    ? 'yellow'
    : 'green'

  const componentText = `Meal ${mealCode} - ${
    isSachet ? 'Sachet ' : ''
  }${title}`

  return (
    <>
      {times(allTowers, (i) => {
        const towerNumber = i + 1

        return (
          <Page key={i} style={{ ...commonPDFStyles.page, padding: '20px' }}>
            <TitleContainer>
              <BigRedTitle>
                {destinationFacility?.displayName.toUpperCase()}
              </BigRedTitle>
            </TitleContainer>
            <CodeColorContainer
              style={{
                justifyContent: 'space-between',
                backgroundColor: containerBackgroundColor,
              }}
            >
              <CodeColorText small style={{ width: '30%' }}>
                Component:
              </CodeColorText>

              <CodeColorText small style={{ width: '68%' }}>
                {/* 53 is an arbitrary length to try to fit text on two lines but no more. */}
                {componentText.length > 53
                  ? `${componentText.substring(0, 53)}...`
                  : componentText}
              </CodeColorText>
            </CodeColorContainer>

            <CodeColorContainer
              style={{ backgroundColor: containerBackgroundColor }}
            >
              <CodeColorText>Tower:</CodeColorText>

              <CodeColorText style={{ marginLeft: '10px' }}>
                {towerNumber} of {allTowers}
              </CodeColorText>
            </CodeColorContainer>

            <TermRow>
              <TermText>Term: {term}</TermText>
            </TermRow>
            <ThickBorder />
            <ApprovalContent />
            <Signature />
            <FullBins>
              <FullBinsText>
                Total Bins:{' '}
                {towerNumber === allTowers && partialTowerBins
                  ? partialTowerBins
                  : maxBinsPerTower}
                &nbsp;
                {towerNumber === allTowers ? (
                  <LastTower
                    maxBinsPerTower={maxBinsPerTower}
                    partialBinComponents={partialBinComponents}
                    partialTowerBins={partialTowerBins}
                  />
                ) : null}
              </FullBinsText>
              {towerNumber === allTowers && partialBinComponents > 0 ? (
                <FullBinsText>
                  Partial Bin: {partialBinComponents} {unit}
                </FullBinsText>
              ) : null}
            </FullBins>
          </Page>
        )
      })}
    </>
  )
}
