import { ComposedMeal } from 'types/internal'
import { Meal, ProductionRecord } from 'types/combinedAPI/domainModels'

interface MealTotalsAcrossCycles {
  [mealCode: number]: number
}

export function getCycle2Meal({
  cycle1Meal,
  productionRecord,
}: {
  cycle1Meal: ComposedMeal
  productionRecord: ProductionRecord
}): Meal | undefined {
  return productionRecord.cycles['2'].meals.find(
    ({ mealCode }) => mealCode === cycle1Meal.mealCode
  )
}

export function getMealTotalsAcrossCycles({
  productionRecord,
}: {
  productionRecord: ProductionRecord
}): MealTotalsAcrossCycles {
  const mealTotalsAcrossCycles: MealTotalsAcrossCycles = {}

  const allMeals = [
    ...productionRecord.cycles['1'].meals,
    ...productionRecord.cycles['2'].meals,
  ]

  allMeals.forEach(({ mealCode, totalMeals }) => {
    const existingTotal = mealTotalsAcrossCycles[mealCode]

    mealTotalsAcrossCycles[mealCode] = existingTotal
      ? existingTotal + totalMeals
      : totalMeals
  })

  return mealTotalsAcrossCycles
}
