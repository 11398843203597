import {
  ClipPath,
  Defs,
  Document,
  G,
  Page,
  Path,
  Text,
  View,
  StyleSheet,
  Svg,
} from '@react-pdf/renderer'
import { ReactNode } from 'react'

import { commonPDFStyles, registerPOVendorPDFFonts } from 'utils/pdfs'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { formatCommas } from 'utils/general'
import { formatDollars } from 'utils/currency'
import {
  getFormattedUnitPrice,
  getMissingVendorSKUs,
  orderLineItems,
  VendorPurchaseOrderFormData,
} from './helpers'
import {
  PurchaseOrderFull,
  PurchaseOrderLineItemExpanded,
} from 'types/internal'

const borderStyle = '1px solid #000'
const styles = StyleSheet.create({
  cell: {
    padding: '12px 3px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

type FacilityAddress = {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
}

type FacilityAddresses = {
  [displayName in
    | 'McCook'
    | 'Pershing'
    | 'Tubeway'
    | 'Westvala']: FacilityAddress
}

const facilityAddresses: FacilityAddresses = {
  McCook: {
    addressLine1: '8801 W. 50th Street',
    addressLine2: '',
    city: 'Chicago',
    state: 'IL',
    zip: '60525',
  },
  Pershing: {
    addressLine1: '815 W Pershing Rd',
    addressLine2: 'Unit 2',
    city: 'Chicago',
    state: 'IL',
    zip: '60609',
  },
  Tubeway: {
    addressLine1: '248 Tubeway Dr',
    addressLine2: '',
    city: 'Carol Stream',
    state: 'IL',
    zip: '60188',
  },
  Westvala: {
    addressLine1: '5041 W 2400S',
    addressLine2: '',
    city: 'West Valley City',
    state: 'UT',
    zip: '84120',
  },
}

registerPOVendorPDFFonts()

const VendorPDF = ({
  formData,
  purchaseOrder,
}: {
  formData: VendorPurchaseOrderFormData
  purchaseOrder: PurchaseOrderFull
}): JSX.Element => {
  const missingVendorSKUs = getMissingVendorSKUs(purchaseOrder)
  const orderedLineItems = orderLineItems(purchaseOrder.lineItems)

  const { vendor } = purchaseOrder
  const facility = facilityAddresses[purchaseOrder.facility.displayName]

  return (
    <Document title={`purchase-order-${purchaseOrder.snapReceiptID}`}>
      <Page
        size="LETTER"
        style={{
          ...commonPDFStyles.page,
          width: '11in',
          height: '8.5in',
          fontSize: '10px',
          paddingBottom: '60px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '24px',
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <Svg style={{ width: '100%', height: '82px' }}>
              <G clip-path="url(#a)">
                <Path d="M0 0h650v82H0z" fill="#fff" />
                <Path
                  d="M0 82V0l650 .431579V2.5C579.711 3.7132 444.168 4 390.5 4 288 4 264.958 57.0613 261.5 82H0Z"
                  fill="#000"
                  stroke="#000"
                />
                <Path d="M33 17h170v48H33z" fill="#fff" />
                <G clip-path="url(#b)">
                  <Path
                    d="M57 38.3339h5.3205V33H57v5.3339ZM67.6428 33v5.3339h5.3222V33h-5.3222ZM57 49h5.3205v-5.3339H57V49Zm10.6428 0h5.3222v-5.3339h-5.3222V49Z"
                    fill="#FF6A1B"
                  />
                  <Path
                    d="M115.611 41c0-4.736-2.955-8-7.01-8s-7.033 3.264-7.033 8c0 4.736 2.978 8 7.033 8s7.01-3.264 7.01-8Zm-11.977 0c0-3.6729 2.054-6.1618 4.967-6.1618s4.967 2.4889 4.967 6.1618c0 3.6729-2.051 6.1618-4.967 6.1618-2.916 0-4.967-2.4889-4.967-6.1618Zm-10.8573 7.7617V35.0533h5.2436v-1.8151H85.4629v1.8151h5.2472v13.7084h2.0666Zm30.9653 0-5.891-15.5235h2.222l4.79 12.9298 4.789-12.9298h2.115l-5.854 15.5235h-2.171Zm27.525 0h9.795v-1.8151h-7.723V33.2382h-2.072v15.5235Zm21.335-15.5235h-2.289l-5.976 15.5235h2.072l1.206-3.1751.071-.1778h7.504l.209.5458 1.086 2.8142h2.091l-5.974-15.5306Zm-4.226 10.3466 3.046-8.0124 2.356 6.1191v.0107l.445 1.1573.277.7253h-6.124Zm-29.514-10.3466h2.287l5.971 15.5306h-2.086l-1.086-2.8142-.209-.5458h-7.504l-.071.1778-1.206 3.1751h-2.072l5.976-15.5235Zm-1.937 10.3466h6.115l-.277-.7253-.445-1.1573v-.0107l-2.356-6.1191-3.037 8.0124Z"
                    fill="#000"
                  />
                </G>
              </G>
              <Defs>
                <ClipPath id="a">
                  <Path d="M0 0h650v82H0z" fill="#fff" />
                </ClipPath>
                <ClipPath id="b">
                  <Path d="M57 33h122v16H57z" fill="#fff" />
                </ClipPath>
              </Defs>
            </Svg>
          </View>
          <Text
            style={{
              fontSize: '28px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            Purchase Order
          </Text>
        </View>

        <View style={{ padding: '0 24px' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <LabelGroup label="PO # :" width="120px">
                <Text>
                  {purchaseOrder.supplierRef || purchaseOrder.snapReceiptID}
                </Text>
              </LabelGroup>
              <LabelGroup label="PO Date :" width="120px">
                <Text>
                  {formatDate(purchaseOrder.created, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </Text>
              </LabelGroup>
              <LabelGroup label="Delivery Date :" width="120px">
                <Text style={{ backgroundColor: '#FFFF00' }}>
                  {formatDate(purchaseOrder.requestedDeliveryDate, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </Text>
              </LabelGroup>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              border: borderStyle,
              padding: '8px',
              margin: '16px 24px',
            }}
          >
            <View style={{ width: '50%' }}>
              <LabelGroup label="To :" width="35px">
                <View>
                  <Text style={{ fontWeight: 'bold' }}>{vendor.name}</Text>
                  <Text>
                    {vendor.addressLine1}
                    {vendor.addressLine2 && `, ${vendor.addressLine2}`}
                  </Text>
                  <Text>
                    {vendor.city && `${vendor.city},`} {vendor.state}{' '}
                    {vendor.zip}
                  </Text>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '12px',
                    }}
                  >
                    <Text style={{ width: '50px' }}>Contact:</Text>
                    <Text>{formData.contactName}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ width: '50px' }}>Email:</Text>
                    <Text>{formData.contactEmail}</Text>
                  </View>
                </View>
              </LabelGroup>
            </View>
            <View style={{ width: '50%' }}>
              <LabelGroup label="Ship To :" width="70px">
                <View>
                  <Text style={{ fontWeight: 'bold' }}>
                    {purchaseOrder.facility.displayName}
                  </Text>
                  <Text>
                    {facility.addressLine1}
                    {facility.addressLine2 && `, ${facility.addressLine2}`}
                  </Text>
                  <Text>
                    {facility.city}, {facility.state} {facility.zip}
                  </Text>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '12px',
                    }}
                  >
                    <Text style={{ width: '50px' }}>Buyer:</Text>
                    <Text>{formData.buyerName}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ width: '50px' }}>Email:</Text>
                    <Text>{formData.buyerEmail}</Text>
                  </View>
                </View>
              </LabelGroup>
            </View>
          </View>

          <View style={{ border: borderStyle }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: borderStyle,
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: '#e4e4e4',
              }}
            >
              <Text style={{ width: '20%' }}>Item No</Text>
              <Text style={{ width: '41%', borderLeft: borderStyle }}>
                Description
              </Text>
              <Text style={{ width: '13%', borderLeft: borderStyle }}>Qty</Text>
              <Text style={{ width: '13%', borderLeft: borderStyle }}>
                Unit Price
              </Text>
              <Text style={{ width: '13%', borderLeft: borderStyle }}>
                Total
              </Text>
            </View>

            {orderedLineItems.map((lineItem, i) => {
              const container = formData.containers[lineItem.vendorSKUID]

              return (
                <View key={i} wrap={false}>
                  <LineItemRow container={container} lineItem={lineItem} />
                </View>
              )
            })}
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              fontWeight: 'bold',
            }}
            wrap={false}
          >
            <Text
              style={{
                ...styles.cell,
                width: '13%',
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                textAlign: 'center',
              }}
            >
              {formData.totalLbs && `${formatCommas(formData.totalLbs)} lbs`}
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: '13%',
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                textTransform: 'uppercase',
              }}
            >
              Shipping
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: '13%',
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                borderRight: borderStyle,
                textAlign: 'center',
              }}
            >
              <Text>
                {formData.shipping && formatDollars(formData.shipping)}
              </Text>
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
            wrap={false}
          >
            <Text
              style={{
                ...styles.cell,
                width: '13%',
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                textTransform: 'uppercase',
              }}
            >
              Total
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: '13%',
                borderLeft: borderStyle,
                borderBottom: borderStyle,
                borderRight: borderStyle,
                textAlign: 'center',
              }}
            >
              {formData.total && formatDollars(formData.total)}
            </Text>
          </View>

          <View
            style={{ border: borderStyle, marginTop: '16px', width: '80%' }}
            wrap={false}
          >
            <View
              style={{
                borderBottom: borderStyle,
                padding: '6px 0',
                textAlign: 'center',
                fontWeight: 'bold',
                backgroundColor: '#e4e4e4',
              }}
            >
              <Text>Notes/Specifications</Text>
            </View>
            <Text style={{ padding: '12px' }}>{formData.notes}</Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '32px',
            }}
            wrap={false}
          >
            <View style={{ flexGrow: 0 }}>
              <Text
                style={{
                  borderBottom: borderStyle,
                  padding: '4px 6px',
                  fontSize: '18px',
                  ...commonPDFStyles.signature,
                }}
              >
                {formData.signature}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '2px',
                }}
              >
                <Text style={{ paddingRight: '30px' }}>
                  Authorized by {formData.authorizedBy}
                </Text>
                <Text>
                  {formatDate(formData.authorizationDate, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            position: 'absolute',
            bottom: 10,
            width: '100%',
            backgroundColor: '#000',
            color: '#FFF',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '12px',
          }}
        >
          <Text>Maestro Food Co. (DBA Tovala)</Text>
          <Text>
            1021 W. Adams St. Suite 100 Chicago, IL 60607 | Ph 312-818-4437
          </Text>
        </View>

        {missingVendorSKUs.length > 0 && (
          <View
            fixed={true}
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 0, 0, 0.75)',
            }}
          >
            <Text style={{ width: '75%', marginTop: '80px', color: '#fff' }}>
              Some SKUs in this purchase order are not marked as available for
              this facility network. Please see the purchase order details page
              for more details and check the Ingredient List or reach out in
              #help-ops-tech.
            </Text>
          </View>
        )}
      </Page>
    </Document>
  )
}

export default VendorPDF

const LabelGroup = ({
  children,
  label,
  width,
}: {
  children: ReactNode
  label: string
  width: string
}): JSX.Element => {
  return (
    <View
      style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}
    >
      <Text
        style={{
          paddingRight: '14px',
          color: '#696969',
          textAlign: 'right',
          textTransform: 'uppercase',
          width: `${width}`,
        }}
      >
        {label}
      </Text>
      {children}
    </View>
  )
}

const LineItemRow = ({
  lineItem,
  container,
}: {
  lineItem: PurchaseOrderLineItemExpanded
  container: string | undefined
}): JSX.Element => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        textAlign: 'center',
      }}
    >
      <View style={{ ...styles.cell, width: '20%' }}>
        <Text>{lineItem.vendorSKU?.sku}</Text>
      </View>

      <View
        style={{
          ...styles.cell,
          width: '41%',
          textAlign: 'left',
          borderLeft: borderStyle,
        }}
      >
        <Text>{lineItem.vendorSKU?.name}</Text>
      </View>

      <View style={{ ...styles.cell, width: '13%', borderLeft: borderStyle }}>
        <View>
          <Text>
            {lineItem.orderQty} {lineItem.orderUnit}
          </Text>
          <Text>{container && `(${container})`}</Text>
        </View>
      </View>

      <View style={{ ...styles.cell, width: '13%', borderLeft: borderStyle }}>
        <Text>{getFormattedUnitPrice(lineItem)}</Text>
      </View>

      <View style={{ ...styles.cell, width: '13%', borderLeft: borderStyle }}>
        <Text>{formatDollars(lineItem.total)}</Text>
      </View>
    </View>
  )
}
