import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  EditPrepLabel,
  editPrepLabel,
  EditPrepLabelResponse,
} from 'services/combinedAPI/prepLabels'
import { error } from 'slices/notifications'
import { isAxiosResponseError } from 'utils/api'

import { useAppDispatch } from 'hooks'

export function useEditPrepLabel(
  opts?: Omit<
    UseMutationOptions<EditPrepLabelResponse, Error, EditPrepLabel>,
    'mutationFn'
  >
) {
  const dispatch = useAppDispatch()

  return useMutation<EditPrepLabelResponse, Error, EditPrepLabel>({
    ...opts,
    onError: (...args) => {
      const apiError = args[0]

      const errorMessage = isAxiosResponseError(apiError)
        ? apiError.response?.data.message ?? 'Unknown error editing prep label'
        : apiError.message

      dispatch(error(errorMessage))

      opts?.onError?.(...args)
    },
    mutationFn: ({ data, prepLabelID, productionTerm }) => {
      return editPrepLabel({ data, prepLabelID, productionTerm })
    },
  })
}
