import { apiDelete, post, put } from './baseAPI'
import { Tag } from 'types/combinedAPI/domainModels'

export interface CombineProductionPart {
  productionPartID: string
  productionTerm: string
}

export type CombineProductionPartResponse = Record<string, unknown>

export interface EditProductionPart {
  data: { numBatches: number; tags: Tag[] }
  productionPartID: string
  productionTerm: string
}

export type EditProductionPartResponse = Record<string, unknown>

export interface UncombineProductionPart {
  productionPartID: string
  productionTerm: string
}

export type UncombineProductionPartResponse = Record<string, unknown>

export interface UpdateBatchNumberWithChildren {
  data: { numBatches: number }
  productionPartID: string
  productionTerm: string
}

export type UpdateBatchNumberWithChildrenResponse = Record<string, unknown>

export interface UpdateTagsForComponent {
  data: { bomIDs: string[]; tags: Tag[] }
  productionTerm: string
}

export type UpdateTagsForComponentResponse = 'okay'

export const ENDPOINTS = {
  COMBINE_PRODUCTION_PART: ({
    productionPartID,
    productionTerm,
  }: CombineProductionPart) => ({
    path: `/productionRecords/${productionTerm}/productionParts/${productionPartID}/combine`,
    version: 'v0' as const,
  }),
  EDIT_PRODUCTION_PART: ({
    productionPartID,
    productionTerm,
  }: Omit<EditProductionPart, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/productionParts/${productionPartID}`,
    version: 'v0' as const,
  }),
  UNCOMBINE_PRODUCTION_PART: ({
    productionPartID,
    productionTerm,
  }: UncombineProductionPart) => ({
    path: `/productionRecords/${productionTerm}/productionParts/${productionPartID}/combine`,
    version: 'v0' as const,
  }),
  UPDATE_BATCH_NUMBER_WITH_CHILDREN: ({
    productionPartID,
    productionTerm,
  }: Omit<UpdateBatchNumberWithChildren, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/productionParts/${productionPartID}/batchChildren`,
    version: 'v0' as const,
  }),
  UPDATE_TAGS_FOR_COMPONENT: ({
    productionTerm,
  }: Omit<UpdateTagsForComponent, 'data'>) => ({
    path: `/productionRecords/${productionTerm}/productionPartBOMs/bulkUpdateTags`,
    version: 'v0' as const,
  }),
}

export async function combineProductionPart(
  opts: CombineProductionPart
): Promise<CombineProductionPartResponse> {
  return post(ENDPOINTS.COMBINE_PRODUCTION_PART(opts))
}

export async function editProductionPart({
  data,
  productionPartID,
  productionTerm,
}: EditProductionPart): Promise<EditProductionPartResponse> {
  return put({
    data,
    ...ENDPOINTS.EDIT_PRODUCTION_PART({ productionPartID, productionTerm }),
  })
}

export async function uncombineProductionPart(
  opts: UncombineProductionPart
): Promise<UncombineProductionPartResponse> {
  return apiDelete(ENDPOINTS.UNCOMBINE_PRODUCTION_PART(opts))
}

export async function updateBatchNumberWithChildren({
  data,
  productionPartID,
  productionTerm,
}: UpdateBatchNumberWithChildren): Promise<UpdateBatchNumberWithChildrenResponse> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_BATCH_NUMBER_WITH_CHILDREN({
      productionPartID,
      productionTerm,
    }),
  })
}

export async function updateTagsForComponent({
  data,
  productionTerm,
}: UpdateTagsForComponent): Promise<UpdateTagsForComponentResponse> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_TAGS_FOR_COMPONENT({ productionTerm }),
  })
}
