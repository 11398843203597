import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { times } from 'lodash-es'

import { commonPDFStyles } from 'utils/pdfs'
import { CSVRow } from 'types/internal'
import { formatCommas } from 'utils/general'

import stockLabelBG from './stockLabelBG.png'

const StockLabelsPDF = ({
  barcodes,
  csvRow,
}: {
  barcodes: {
    batch: string
    caseWeight: string
    product: string
    stu: string
    vendor: string
  }
  csvRow: CSVRow
}): JSX.Element => {
  const labels = times(Math.min(20, Number(csvRow.Boxes)), () => {
    return csvRow
  })

  return (
    <Document title="stock-labels-pdf">
      {labels.map((label, i) => {
        const caseWeightStr = label['Box Qty']

        // The dates include time information and it's not something we can modify in the CSV we
        // upload (see comments on https://tovala.atlassian.net/browse/WAT-400). We just want to
        // drop the time information since there's no meaningful timezone here.
        const expirationDate = label['Date Expiry'].substring(0, 10)
        const receiptDate = label['Date Receipt'].substring(0, 10)

        return (
          <Page
            key={i}
            orientation="portrait"
            size="A6"
            style={{
              ...commonPDFStyles.page,
              width: '4in',
              height: '6in',
            }}
          >
            <View style={{ position: 'absolute', top: '5px', left: '15px' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontSize: '20px' }}>Product:</Text>
                <Text style={{ marginLeft: '15px', fontSize: '25px' }}>
                  {label.Product}
                </Text>
              </View>
              <Text
                style={{ fontSize: '17px', textOverflow: 'ellipsis' }}
                wrap={false}
              >
                {label.Description.length > 32
                  ? `${label.Description.substring(0, 32)}...`
                  : label.Description}
              </Text>
              <Image
                src={() => {
                  return barcodes.product
                }}
                style={{
                  width: '250px',
                  height: '55px',
                  textAlign: 'center',
                }}
              />
            </View>
            <View style={{ position: 'absolute', top: '120px', left: '15px' }}>
              <Text style={{ fontSize: '14px' }}>Batch: {label.Batch}</Text>
              <Image
                src={() => {
                  return barcodes.batch
                }}
                style={{
                  width: '250px',
                  height: '55px',
                  textAlign: 'center',
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '195px',
                right: '10px',
                left: '15px',
                height: '73px',
                padding: '2px 0',
              }}
            >
              <View style={{ marginBottom: '2px' }}>
                <Text style={{ fontSize: '13px' }}>
                  Vendor Info: {label.Vendor}
                </Text>
              </View>
              <View style={{ flexGrow: 1 }}>
                <Image
                  src={() => {
                    return barcodes.vendor
                  }}
                  style={{
                    width: '250px',
                    textAlign: 'center',
                  }}
                />
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                top: '272px',
                left: '15px',
              }}
            >
              <View style={{ position: 'relative', marginBottom: '5px' }}>
                <Text style={{ fontSize: '13px' }}>Case Weight:</Text>
                <View
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '-13px',
                    width: '40px',
                    fontSize: '7px',
                  }}
                >
                  <View>
                    <Text>*(Box Qty</Text>
                  </View>
                  <View>
                    <Text>in Snap):</Text>
                  </View>
                </View>
              </View>
              <Text style={{ fontSize: '15px' }}>
                {formatCommas(Number(caseWeightStr))} lbs/case
              </Text>
              <Image
                src={() => {
                  // We intentionially use whatever is in the uploaded CSV here. We don't want any rounding.
                  return barcodes.caseWeight
                }}
                style={{ width: '125px', height: '35px' }}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                top: '272px',
                left: '160px',
              }}
            >
              <Text style={{ fontSize: '12px' }}>Receipt Date:</Text>
              <Text style={{ fontSize: '12px' }}>{receiptDate}</Text>
              <Text style={{ fontSize: '22px' }}>Expiry Date:</Text>
              <Text style={{ fontSize: '22px' }}>{expirationDate}</Text>
            </View>
            <View
              style={{
                position: 'absolute',
                top: '355px',
                left: '15px',
              }}
            >
              <Text style={{ fontSize: '16px' }}>StU: {label.StU}</Text>
              {label.StU ? (
                <Image
                  src={() => {
                    return barcodes.stu
                  }}
                  style={{ width: '150px', height: '35px' }}
                />
              ) : (
                <Text />
              )}
            </View>
            <Image
              src={stockLabelBG}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Page>
        )
      })}
    </Document>
  )
}

export default StockLabelsPDF
