import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getVendors, GetVendorsResponse } from 'services/combinedAPI/vendors'

export function useVendors(
  opts?: Omit<
    UseQueryOptions<GetVendorsResponse, Error>,
    'queryFn' | 'queryKey'
  >
) {
  return useQuery<GetVendorsResponse, Error>({
    ...opts,
    queryFn: () => {
      return getVendors()
    },
    queryKey: ['vendors'],
  })
}
