import { Themes } from 'types/internal'

export const themes: Themes = {
  default: {
    headerColor: '#333741',
  },

  'chicago-01': {
    headerColor: '#1470D0',
    cycleButtonColor: '#28a745',
    wip: {
      'watermark-font-size': {
        pershing: '28px',
        tubeway: '32px',
      },
      'differentFacility-border': '3px dashed red',
      'differentFacility-watermark': '#ffbfbe',
      'sameFacility-border': {
        pershing: '1px dashed #ddd',
        tubeway: '3px dashed green',
      },
      'sameFacility-watermark': {
        pershing: '#00000000',
        tubeway: '#bfe0bd',
      },
    },
    checklist: {
      'row-background-color': {
        'no-selection': '#dc3545',
        pershing: '#708238',
        tubeway: '#98fb98',
      },
    },
  },

  'slc-01': {
    headerColor: '#9b1d20',
    cycleButtonColor: '#17a2b8',
    wip: {
      'watermark-font-size': {
        westvala: '12px',
      },
      'differentFacility-border': '3px dashed red',
      'sameFacility-border': {
        westvala: '1px dashed #ddd',
      },
      'differentFacility-watermark': '#ffbfbe',
      'sameFacility-watermark': {
        westvala: '#00000000',
      },
    },
    checklist: {
      'row-background-color': {
        'no-selection': '#dc3545',
        westvala: '#708238',
      },
    },
  },
}
