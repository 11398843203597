import { FacilityNetwork } from 'types/combinedAPI/domainModels'
import { Theme, Themes, WipBOM } from 'types/internal'

export function getTheme(
  themes: Themes,
  facilityNetwork: FacilityNetwork | '' | undefined
): Theme {
  if (facilityNetwork) {
    return themes[facilityNetwork.displayTheme] ?? themes.default
  }

  return themes.default
}

export function getDisplayNameForWatermark(
  wip: WipBOM,
  facilityNetwork: FacilityNetwork
): string {
  const sleevingLocation = facilityNetwork.facilities.find(
    (facility) => facility.title === wip.sleevingLocation
  )

  return sleevingLocation?.displayName ?? ''
}

export function getWatermarkColor(wip: WipBOM, theme: Theme): string {
  if (!theme.wip) {
    return ''
  }

  if (wip.portionLocation !== wip.sleevingLocation) {
    return theme.wip['differentFacility-watermark']
  }

  return theme.wip['sameFacility-watermark'][wip.sleevingLocation]
}

export function getBorderStyle(wip: WipBOM, theme: Theme): string {
  if (!theme.wip) {
    return ''
  }

  if (wip.portionLocation !== wip.sleevingLocation) {
    return theme.wip['differentFacility-border']
  }

  return theme.wip['sameFacility-border'][wip.sleevingLocation]
}

export function getWatermarkFontSize(wip: WipBOM, theme: Theme): string {
  return theme.wip?.['watermark-font-size'][wip.sleevingLocation] ?? ''
}

export function getLocationBackgroundColor(
  location: string,
  theme: Theme
): string {
  if (!theme.checklist) {
    return ''
  }

  if (location) {
    return theme.checklist['row-background-color'][location]
  }

  return theme.checklist['row-background-color']['no-selection']
}
