import { get } from './baseAPI'
import { LineHaul } from 'types/combinedAPI/domainModels'

export interface GetLineHauls {
  facilityNetworkID: string
  productionTerm: string
}

export type GetLineHaulsResponse = LineHaul[]

export const ENDPOINTS = {
  GET_LINE_HAULS: ({ facilityNetworkID, productionTerm }: GetLineHauls) => ({
    path: `/facilityNetworks/${facilityNetworkID}/terms/${productionTerm}/lineHauls`,
    version: 'v0' as const,
  }),
}

export async function getLineHauls(
  opts: GetLineHauls
): Promise<GetLineHaulsResponse> {
  const response = await get<{ data: LineHaul[] }>(
    ENDPOINTS.GET_LINE_HAULS(opts)
  )

  return response.data
}
