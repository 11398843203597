import { Fragment, useState } from 'react'
import { some } from 'lodash-es'

import { composeMealsForCycle } from 'utils/meal'
import { formatCommas } from 'utils/general'
import {
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import { useAppSelector } from 'hooks'
import {
  useCreatePickOrders,
  usePickOrders,
} from 'hooks/combinedAPI/pickOrders'
import { useIsReadOnly } from 'contexts/auth'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Button from 'components/common/Button'
import CheckIcon from 'components/common/icons/CheckIcon'
import ConfirmationModal from 'components/common/ConfirmationModal'
import CreateShortPickOrder from './CreateShortPickOrder'
import Tooltip from 'components/common/Tooltip'

const PickOrders = ({
  productionCycle,
  productionRecord,
  productionTerm,
}: {
  productionCycle: '1' | '2'
  productionRecord: ProductionRecord
  productionTerm: string
}): JSX.Element => {
  // We hardcode cycle "1" here because we show the same display regardless of which cycle the user has selected
  // since the actions (e.g. creating Snap pick orders) are relevant across both cycles.
  const composedMeals = composeMealsForCycle(productionRecord.cycles['1'].meals)

  return (
    <div className="w-3/4">
      <div className="my-4 flex items-center justify-between">
        <h1 className="text-2xl">Pick Orders</h1>

        <CreatePickOrders
          productionCycle={productionCycle}
          productionRecord={productionRecord}
          productionTerm={productionTerm}
        />
      </div>
      <p className="text-sm italic">
        Note: Pick orders in Snap are created for both cycles 1 and 2 at the
        same time so counts for both cycles are displayed below.
      </p>
      <div>
        <div className="mb-4 grid grid-cols-pick-order-counts gap-4 border-b border-light-grey pb-2 pt-4 text-sm font-normal uppercase text-grey">
          <div>Code</div>
          <div>Cycle 1 Count</div>
          <div>Cycle 2 Count</div>
          <div>Meal Title</div>
        </div>
        <div className="grid grid-cols-pick-order-counts gap-4">
          {composedMeals.map((meal) => {
            const cycle2Meal = productionRecord.cycles['2'].meals.find(
              ({ mealCode }) => mealCode === meal.mealCode
            )

            return (
              <Fragment key={meal.id}>
                <div>#{meal.mealCode}</div>
                <div>{formatCommas(meal.totalMeals)}</div>
                <div>{formatCommas(cycle2Meal?.totalMeals ?? 0)}</div>
                <div>{meal.shortTitle}</div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PickOrders

const CreatePickOrders = ({
  productionCycle,
  productionRecord,
  productionTerm,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
}): JSX.Element | null => {
  const isReadOnly = useIsReadOnly()

  const facilityNetwork = useAppSelector(
    (state) => state.production.facilityNetwork
  )

  const facilityNetworkID = facilityNetwork ? facilityNetwork.id : ''

  const [hasAttemptedToCreatePickOrders, setHasAttemptedToCreatePickOrders] =
    useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const {
    data: pickOrdersByFacility = {},
    error: loadPickOrdersError,
    isError: hasLoadPickOrdersError,
    isLoading: isLoadingPickOrders,
    isRefetching: isRefetchingPickOrders,
    refetch: refetchPickOrders,
  } = usePickOrders({
    facilityNetworkID,
    productionTerm,
    refetchOnWindowFocus: false,
  })

  if (isLoadingPickOrders || isRefetchingPickOrders) {
    return (
      <div
        className="h-4 w-56 animate-pulse bg-light-grey"
        data-testid="pick-orders-loading-indicator"
      />
    )
  }

  if (hasLoadPickOrdersError) {
    return <APIErrorDisplay error={loadPickOrdersError} />
  }

  let createSnapPickOrdersContent = (
    <Tooltip
      enabled={isReadOnly || !facilityNetworkID}
      trigger={
        <div className="w-48">
          <Button
            disabled={isReadOnly || !facilityNetworkID}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Create Snap Pick Orders
          </Button>
        </div>
      }
    >
      <p className="p-2 text-xs">
        You don't have permission to create Snap pick orders. The{' '}
        <strong>MISEVALA_PLANTMANAGER</strong> role is required.
      </p>
    </Tooltip>
  )

  const hasGeneratedPickOrders = some(pickOrdersByFacility, (pickOrders) => {
    return pickOrders.length > 0
  })

  if (hasGeneratedPickOrders) {
    createSnapPickOrdersContent = (
      <div className="flex items-center space-x-2 text-green">
        <div className="h-4 w-4">
          <CheckIcon />
        </div>
        <span>Snap pick orders created</span>
      </div>
    )
  } else if (hasAttemptedToCreatePickOrders) {
    // Creating Snap pick orders is an asynchronous process on the back-end but we don't currently have an endpoint
    // we can poll or other method for determining when the pick orders have finished processing (if the user
    // has refreshed the page) since there's no back-end indication that a user attempted to create pick orders.
    // Our trade-off for now is that we'll direct the user to a Slack channel until we have more bandwidth to
    // implement a better UX with back-end support.
    createSnapPickOrdersContent = (
      <div className="flex items-center space-x-2 text-sm text-green">
        <div className="h-4 w-4">
          <CheckIcon />
        </div>
        <span>
          Snap pick orders are being created. Please check the{' '}
          <span className="font-semibold">status-ops-tech</span> Slack channel
          for current status.
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center space-x-4">
        {createSnapPickOrdersContent}

        <CreateShortPickOrder
          hasGeneratedPickOrders={hasGeneratedPickOrders}
          productionCycle={productionCycle}
          productionRecord={productionRecord}
          productionTerm={productionTerm}
        />
      </div>

      {isOpen && (
        <ConfirmCreatePickOrdersModal
          facilityNetworkID={facilityNetworkID}
          onCloseModal={() => {
            setIsOpen(false)
          }}
          onPickOrdersCreated={() => {
            setIsOpen(false)
            setHasAttemptedToCreatePickOrders(true)
            refetchPickOrders()
          }}
          productionTerm={productionTerm}
        />
      )}
    </>
  )
}

const ConfirmCreatePickOrdersModal = ({
  facilityNetworkID,
  onCloseModal,
  onPickOrdersCreated,
  productionTerm,
}: {
  facilityNetworkID: string
  onCloseModal(): void
  onPickOrdersCreated(): void
  productionTerm: string
}): JSX.Element => {
  const {
    error: createPickOrdersError,
    isError: hasCreatePickOrdersError,
    isLoading: isCreatingPickOrders,
    mutate: createPickOrders,
  } = useCreatePickOrders({
    onSuccess: () => {
      onPickOrdersCreated()
    },
  })

  return (
    <ConfirmationModal
      apiError={hasCreatePickOrdersError ? createPickOrdersError : null}
      confirmText="Create"
      isLoading={isCreatingPickOrders}
      onCancel={onCloseModal}
      onConfirm={() => {
        createPickOrders({ facilityNetworkID, productionTerm })
      }}
      title="Create Snap Pick Orders"
    >
      <p className="my-8">
        Are you sure you want to create Snap pick orders? This cannot be undone.
      </p>
    </ConfirmationModal>
  )
}
