import { compact, flatMap, map, mapValues, sortBy, uniq } from 'lodash-es'
import { Link, useNavigate } from 'react-router-dom'
import { ReactNode, useState } from 'react'

import { DATE_FORMATS, formatDate, formatDateForAPI } from 'utils/dates'
import { PurchaseOrder } from 'types/combinedAPI/domainModels'
import { ReactSelectValue } from 'types/internal'

import { useFilters } from '../../contexts/filters'
import { useIsReadOnly } from 'contexts/auth'
import { usePurchaseOrders } from 'hooks/combinedAPI/purchaseOrders'
import { useQueryParams } from 'hooks/routes'
import {
  useFacilityNetworks,
  useSelectFacilityNetwork,
} from 'hooks/combinedAPI/facilityNetworks'
import { useVendors } from 'hooks/combinedAPI/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import Checkbox from 'components/common/Checkbox'
import CircleLoader from 'components/common/CircleLoader'
import DateInput from 'components/common/DateInput'
import DocumentIcon from 'components/common/icons/DocumentIcon'
import FilterIcon from 'components/common/icons/FilterIcon'
import FormGroup from 'components/common/FormGroup'
import InfiniteScrollTrigger from 'components/common/InfiniteScrollTrigger'
import PageLayout from './PageLayout'
import PrintReceivingPDF from './PrintReceivingPDF'
import Select from 'components/common/Select'
import XIcon from 'components/common/icons/XIcon'

export const LIST_PURCHASE_ORDERS_FILTER_KEYS = [
  'facilityID',
  'requestedDeliveryDateEnd',
  'requestedDeliveryDateStart',
  'vendorID',
] as const

type FilterKey = (typeof LIST_PURCHASE_ORDERS_FILTER_KEYS)[number]

const FILTER_NAME_MAP = {
  facilityID: 'facility',
  requestedDeliveryDateEnd: 'requested delivery date',
  requestedDeliveryDateStart: 'requested delivery date',
  vendorID: 'vendor',
} as const satisfies Record<FilterKey, string>

const ListPurchaseOrdersPage = (): JSX.Element => {
  const navigate = useNavigate()

  const { facilityNetworkID: selectedFacilityNetworkID } = useQueryParams([
    'facilityNetworkID',
  ] as const)

  useSelectFacilityNetwork(selectedFacilityNetworkID)

  const isReadOnlyProcurement = useIsReadOnly('procurement')

  const { filters, onChangeFilters, onClearFilters } = useFilters()

  const [selectedPurchaseOrderIDs, setSelectedPurchaseOrderIDs] = useState<
    Set<string>
  >(() => new Set())

  const addSelectedPurchaseOrderID = (id: string) => {
    setSelectedPurchaseOrderIDs((prev) => new Set(prev).add(id))
  }

  const removeSelectedPurchaseOrderID = (id: string) => {
    setSelectedPurchaseOrderIDs((prev) => {
      const updatedIDs = new Set(prev)
      updatedIDs.delete(id)
      return updatedIDs
    })
  }

  const clearSelectedPurchaseOrderIDs = () => {
    setSelectedPurchaseOrderIDs(() => new Set())
  }

  // Transform our filters into formatted dates or strings.
  const apiFilters = mapValues(filters, (filterValue, filterName) => {
    if (
      (filterName === 'requestedDeliveryDateEnd' ||
        filterName === 'requestedDeliveryDateStart') &&
      typeof filterValue === 'string'
    ) {
      return formatDateForAPI(filterValue) ?? ''
    }

    return filterValue ?? ''
  })

  const {
    data: getPurchaseOrdersResult,
    error: loadPOsError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadPOsError,
    isFetchingNextPage,
    isLoading: isLoadingPurchaseOrders,
  } = usePurchaseOrders({ filters: apiFilters })
  const purchaseOrders = flatMap(getPurchaseOrdersResult?.pages, (page) => {
    return page.results
  })

  return (
    <PageLayout>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl">Purchase Orders</h1>
        {!isReadOnlyProcurement && (
          <div className="w-20">
            <Button
              onClick={() => {
                navigate(
                  `create?facilityNetworkID=${selectedFacilityNetworkID}`
                )
              }}
            >
              + Create
            </Button>
          </div>
        )}
      </div>

      <Filters
        filters={filters}
        onChangeFilters={(...args) => {
          clearSelectedPurchaseOrderIDs()

          onChangeFilters(...args)
        }}
        selectedFacilityNetworkID={selectedFacilityNetworkID}
      />

      <AppliedFilters filters={filters} onClearFilters={onClearFilters} />

      <PurchaseOrdersTable
        handleChangeSelectedPurchaseOrderIDs={(purchaseOrderID) => {
          selectedPurchaseOrderIDs.has(purchaseOrderID)
            ? removeSelectedPurchaseOrderID(purchaseOrderID)
            : addSelectedPurchaseOrderID(purchaseOrderID)
        }}
        hasLoadPOsError={hasLoadPOsError}
        hasNextPage={!!hasNextPage}
        isLoadingNextPage={isFetchingNextPage}
        isLoadingPurchaseOrders={isLoadingPurchaseOrders}
        loadPOsError={loadPOsError}
        onLoadMorePOs={fetchNextPage}
        purchaseOrders={purchaseOrders}
        selectedFacilityNetworkID={selectedFacilityNetworkID}
        selectedPurchaseOrderIDs={selectedPurchaseOrderIDs}
      />

      <PrintSelectedActionsBar
        clearSelectedPurchaseOrderIDs={clearSelectedPurchaseOrderIDs}
        selectedFacilityNetworkID={selectedFacilityNetworkID}
        selectedPurchaseOrderIDs={selectedPurchaseOrderIDs}
      />
    </PageLayout>
  )
}

export default ListPurchaseOrdersPage

const Filters = ({
  filters,
  onChangeFilters,
  selectedFacilityNetworkID,
}: {
  filters: Record<FilterKey, string>
  onChangeFilters(filters: Partial<Record<FilterKey, string>>): void
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const { data: vendors = [] } = useVendors()

  const vendorOptions: ReactSelectValue<string>[] = sortBy(vendors, 'name').map(
    (vendor) => {
      return { label: vendor.name, value: vendor.id }
    }
  )

  const { data: facilityNetworks = [] } = useFacilityNetworks()

  const selectedFacilityNetwork = facilityNetworks.find(
    ({ id }) => id === selectedFacilityNetworkID
  )

  const facilityOptions: ReactSelectValue<string>[] = sortBy(
    selectedFacilityNetwork?.facilities,
    'displayName'
  ).map((facility) => {
    return { label: facility.displayName, value: facility.id }
  })

  const selectedFacility =
    facilityOptions.find((option) => {
      return filters.facilityID === option.value
    }) ?? null
  const selectedVendor =
    vendorOptions.find((option) => {
      return filters.vendorID === option.value
    }) ?? null

  return (
    <div className="mb-4 flex flex-wrap items-center space-x-4 sm:space-x-0 sm:space-y-4">
      <div className="w-1/4 sm:w-full">
        <FormGroup label="Vendor" labelFor="vendor">
          <Select<ReactSelectValue<string>, false>
            isClearable={true}
            name="vendor"
            onChange={(vendorID) => {
              onChangeFilters({ vendorID: vendorID?.value ?? '' })
            }}
            options={vendorOptions}
            value={selectedVendor}
          />
        </FormGroup>
      </div>
      <div className="w-1/4 sm:w-full">
        <FormGroup label="Facility" labelFor="facility">
          <Select<ReactSelectValue<string>, false>
            isClearable={true}
            name="facility"
            onChange={(facilityID) => {
              onChangeFilters({ facilityID: facilityID?.value ?? '' })
            }}
            options={facilityOptions}
            value={selectedFacility}
          />
        </FormGroup>
      </div>
      <div className="w-1/4 sm:w-full">
        <FormGroup label="Requested Delivery Date">
          <div className="flex items-center space-x-4">
            <DateInput
              name="requested-delivery-date-start"
              onChange={(requestedDeliveryDateStart) => {
                onChangeFilters({
                  requestedDeliveryDateStart,
                })
              }}
              value={filters.requestedDeliveryDateStart}
            />
            <span>-</span>
            <DateInput
              name="requested-delivery-date-end"
              onChange={(requestedDeliveryDateEnd) => {
                onChangeFilters({
                  requestedDeliveryDateEnd,
                })
              }}
              value={filters.requestedDeliveryDateEnd}
            />
            <a
              className="cursor-pointer text-xs uppercase text-blue"
              onClick={() => {
                const today = formatDate(new Date(), {
                  format: DATE_FORMATS.API_DATE_FORMAT,
                })
                onChangeFilters({
                  requestedDeliveryDateEnd: today,
                  requestedDeliveryDateStart: today,
                })
              }}
            >
              Today
            </a>
          </div>
        </FormGroup>
      </div>
    </div>
  )
}

const AppliedFilters = ({
  filters,
  onClearFilters,
}: {
  filters: Record<FilterKey, string>
  onClearFilters(): void
}): JSX.Element | null => {
  const appliedFilters = compact(
    map(filters, (value, filterKey) => {
      if (value) {
        return FILTER_NAME_MAP[filterKey as FilterKey]
      }
    })
  )
  const uniqueFilters = uniq(appliedFilters)

  if (uniqueFilters.length === 0) {
    return null
  }

  return (
    <div className="flex items-center space-x-2 bg-faded-gold p-2 text-sm">
      <div className="h-4 w-4">
        <FilterIcon />
      </div>
      <span>Filtered by {uniqueFilters.join(', ')}</span>
      <a
        className="cursor-pointer text-blue"
        onClick={() => {
          onClearFilters()
        }}
      >
        clear
      </a>
    </div>
  )
}

const PurchaseOrdersTable = ({
  handleChangeSelectedPurchaseOrderIDs,
  hasLoadPOsError,
  hasNextPage,
  isLoadingNextPage,
  isLoadingPurchaseOrders,
  loadPOsError,
  onLoadMorePOs,
  purchaseOrders,
  selectedFacilityNetworkID,
  selectedPurchaseOrderIDs,
}: {
  handleChangeSelectedPurchaseOrderIDs(purchaseOrderID: string): void
  hasLoadPOsError: boolean
  hasNextPage: boolean
  isLoadingNextPage: boolean
  isLoadingPurchaseOrders: boolean
  loadPOsError: Error | null
  onLoadMorePOs(): void
  purchaseOrders: PurchaseOrder[]
  selectedFacilityNetworkID: string | null
  selectedPurchaseOrderIDs: Set<string>
}): JSX.Element => {
  const poGridClasses =
    'grid grid-cols-purchase-order-table-list sm:grid-cols-purchase-order-table-list-mobile gap-4 items-center justify-items-center border-b border-light-grey'
  const poTableRowClasses = `${poGridClasses} py-4 sm:text-sm`

  return (
    <>
      <div
        className={`sticky top-[59px] ${poGridClasses} z-10 bg-white pb-2 pt-4 text-sm font-normal uppercase text-grey`}
      >
        <span className="sm:hidden"></span>
        <span className="justify-self-start">Snap Receipt ID</span>
        <span>Vendor</span>
        <span>Facility</span>
        <span>Requested Delivery Date</span>
        <span>Last Modified Date</span>
        <span className="sm:hidden">Actions</span>
      </div>
      <div>
        {purchaseOrders.length === 0 && (
          <>
            {isLoadingPurchaseOrders ? (
              <PurchaseOrdersTableLoadingRows
                poTableRowClasses={poTableRowClasses}
              />
            ) : (
              <>
                {hasLoadPOsError ? (
                  <div className="mt-2">
                    <APIErrorDisplay error={loadPOsError} />
                  </div>
                ) : (
                  <p className="py-2 text-sm">No purchase orders</p>
                )}
              </>
            )}
          </>
        )}

        {purchaseOrders.map(
          ({ facility, id, requestedDeliveryDate, snapReceiptID, vendor, updated }) => {
            return (
              <div
                key={id}
                className={poTableRowClasses}
                data-testid="purchase-order-table-row"
              >
                <div className="sm:hidden">
                  <label className="hidden" htmlFor={`select-${snapReceiptID}`}>
                    Select {snapReceiptID}
                  </label>
                  <Checkbox
                    checked={selectedPurchaseOrderIDs.has(id)}
                    id={`select-${snapReceiptID}`}
                    name="select-purchase-order-id"
                    onChange={() => {
                      handleChangeSelectedPurchaseOrderIDs(id)
                    }}
                  />
                </div>
                <div className="justify-self-start">
                  <Link
                    className="text-bs-blue hover:underline"
                    to={`/purchaseOrders/${id}?facilityNetworkID=${selectedFacilityNetworkID}`}
                  >
                    {snapReceiptID}
                  </Link>
                </div>
                <div>{vendor.name}</div>
                <div>{facility.displayName}</div>
                <div>
                  {formatDate(requestedDeliveryDate, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </div>
                <div>
                  {formatDate(updated, {
                    format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
                  })}
                </div>
                <div className="sm:hidden">
                  <PrintReceivingPDF
                    purchaseOrderIDs={[id]}
                    selectedFacilityNetworkID={selectedFacilityNetworkID}
                  >
                    {({ isLoading }) => {
                      if (isLoading) {
                        return <CircleLoader loaderStyle="colored" />
                      }

                      return (
                        <div
                          className="h-4 w-4 cursor-pointer"
                          title="Print Receiving Document"
                        >
                          <DocumentIcon />
                        </div>
                      )
                    }}
                  </PrintReceivingPDF>
                </div>
              </div>
            )
          }
        )}

        {isLoadingNextPage ? (
          <PurchaseOrdersTableLoadingRows
            poTableRowClasses={poTableRowClasses}
          />
        ) : hasNextPage ? (
          <InfiniteScrollTrigger onTriggered={onLoadMorePOs} />
        ) : null}
      </div>
    </>
  )
}

const PurchaseOrdersTableLoadingRows = ({
  poTableRowClasses,
}: {
  poTableRowClasses: string
}): JSX.Element => {
  return (
    <>
      {new Array(5).map((_unused, i) => {
        return (
          <div key={i} className={poTableRowClasses}>
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
            <div className="h-4 w-1/2 animate-pulse bg-light-grey" />
            <div className="h-4 w-1/2 animate-pulse bg-light-grey" />
            <div className="h-4 w-1/2 animate-pulse bg-light-grey sm:hidden" />
          </div>
        )
      })}
    </>
  )
}

const ActionsBar = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="sticky bottom-0 flex items-center space-x-4 border-t border-light-grey bg-white p-3">
      {children}
    </div>
  )
}

const PrintSelectedActionsBar = ({
  clearSelectedPurchaseOrderIDs,
  selectedFacilityNetworkID,
  selectedPurchaseOrderIDs,
}: {
  clearSelectedPurchaseOrderIDs: () => void
  selectedFacilityNetworkID: string | null
  selectedPurchaseOrderIDs: Set<string>
}): JSX.Element | null => {
  if (selectedPurchaseOrderIDs.size > 0) {
    return (
      <ActionsBar>
        <>
          <PrintReceivingPDF
            purchaseOrderIDs={Array.from(selectedPurchaseOrderIDs)}
            selectedFacilityNetworkID={selectedFacilityNetworkID}
          >
            {({ isLoading }) => {
              return (
                <div className="w-40">
                  <ButtonLoading isLoading={isLoading} type="button">
                    <div className="flex items-center justify-between space-x-2">
                      {!isLoading && (
                        <span
                          className="relative mr-1 block h-5 w-5"
                          title="Print Selected Receiving Documents"
                        >
                          <DocumentIcon />
                          <span className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform px-2 py-1 text-xs font-bold">
                            {selectedPurchaseOrderIDs.size}
                          </span>
                        </span>
                      )}
                      <span>Print Selected</span>
                    </div>
                  </ButtonLoading>
                </div>
              )
            }}
          </PrintReceivingPDF>
          <div className="w-40">
            <Button
              buttonStyle="primary-inverse"
              onClick={clearSelectedPurchaseOrderIDs}
            >
              <div className="flex cursor-pointer items-center justify-center">
                <span className="mr-1 block h-4 w-4">
                  <XIcon />
                </span>
                <span>Clear Selected</span>
              </div>
            </Button>
          </div>
        </>
      </ActionsBar>
    )
  }

  return null
}
