import { ReactNode } from 'react'
import { useField } from 'formik'

import DateInput, { Props as DateInputProps } from './DateInput'
import FormGroup from './FormGroup'

const FormDateInput = ({
  label,
  labelFor,
  name,
}: Pick<DateInputProps, 'name'> & {
  label?: ReactNode
  labelFor?: string
}): JSX.Element => {
  const [field, meta, fieldHelpers] = useField(name)
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <DateInput
        {...field}
        hasError={hasError}
        onChange={(value) => {
          fieldHelpers.setValue(value)
        }}
      />
    </FormGroup>
  )
}

export default FormDateInput
