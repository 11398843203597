import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarWeeks,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format as dateFnsFormat,
  isBefore,
  isMatch,
  isMonday,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  parseISO,
  previousMonday,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'

export enum DATE_FORMATS {
  API_DATE_FORMAT = 'yyyy-MM-dd',
  DEFAULT_DATE_FORMAT = 'M/d/yyyy',
  DEFAULT_DATETIME_FORMAT = 'M/d/yyyy h:mm:ss aaa',
  READABLE_DAY = 'EEEE',
  READABLE_MONTH_YEAR = 'MMMM yyyy',
  SINGLE_DAY = 'd',
  US_DATE_FORMAT_SHORT_YEAR = 'M/d/yy',
}

export type DateUnits = 'days' | 'months' | 'years'
export type WeekUnits = 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined

/**
 * A list of date formats that we support in parsing text a user has entered.
 */
export const MANUAL_ENTRY_DATE_FORMATS = [
  DATE_FORMATS.API_DATE_FORMAT,
  DATE_FORMATS.US_DATE_FORMAT_SHORT_YEAR,
  DATE_FORMATS.DEFAULT_DATE_FORMAT,
]

export function addToDate(
  date: Date,
  { quantity, units }: { quantity: number; units: DateUnits }
): Date {
  if (units === 'days') {
    return addDays(date, quantity)
  } else if (units === 'months') {
    return addMonths(date, quantity)
  }

  return addYears(date, quantity)
}

export function difference(
  firstDate: Date,
  secondDate: Date,
  units: DateUnits
): number {
  if (units === 'days') {
    return differenceInDays(firstDate, secondDate)
  } else if (units === 'months') {
    return differenceInMonths(firstDate, secondDate)
  }

  return differenceInYears(firstDate, secondDate)
}

export function formatDate(
  value: Date | number | string,
  {
    format,
    parseFormat,
  }: { format: DATE_FORMATS; parseFormat?: DATE_FORMATS | null }
): string {
  const valueAsDate =
    typeof value === 'string' ? parseToDate(value, { parseFormat }) : value

  // If we fail to parse the provided input, we can't try to format it.
  if (!isValid(valueAsDate)) {
    return typeof value === 'string' ? value : ''
  }

  return dateFnsFormat(valueAsDate, format)
}

export function formatDateForAPI(
  value: string | undefined
): string | undefined {
  if (value === undefined) {
    return undefined
  }

  const parseFormat = getDateFormat(value, MANUAL_ENTRY_DATE_FORMATS)
  if (parseFormat === null) {
    return undefined
  }

  return formatDate(value, {
    format: DATE_FORMATS.API_DATE_FORMAT,
    parseFormat,
  })
}

export function getCurrentDate(): Date {
  return new Date()
}

/**
 * Returns the date format that matches the supplied value if one is found.
 */
export function getDateFormat(
  date: string | null,
  parseFormats: DATE_FORMATS[]
): DATE_FORMATS | null {
  if (!date) {
    return null
  }

  for (let i = 0; i < parseFormats.length; i++) {
    if (isMatch(date, parseFormats[i])) {
      return parseFormats[i]
    }
  }

  return null
}

export function getDifferenceInCalendarWeeks(
  firstDate: Date,
  secondDate: Date,
  { weekStartsOn }: { weekStartsOn: WeekUnits }
): number {
  return differenceInCalendarWeeks(firstDate, secondDate, { weekStartsOn })
}

export function getPreviousMondayDate(date: Date): Date {
  return previousMonday(date)
}

export function isDateBefore(firstDate: Date, secondDate: Date): boolean {
  return isBefore(firstDate, secondDate)
}

export function isSame(
  firstDate: Date,
  secondDate: Date,
  units: DateUnits
): boolean {
  if (units === 'days') {
    return isSameDay(firstDate, secondDate)
  } else if (units === 'months') {
    return isSameMonth(firstDate, secondDate)
  }

  return isSameYear(firstDate, secondDate)
}

export function isDateMonday(date: Date): boolean {
  return isMonday(date)
}

/**
 * Parses the provided value to a Date. By default, this will try to parse the provided
 * value as an ISO date. If you provide a parseFormat, it will try to parse using that
 * format instead.
 */
export function parseToDate(
  value: string,
  { parseFormat = null }: { parseFormat?: DATE_FORMATS | null } = {}
): Date {
  return parseFormat ? parse(value, parseFormat, new Date()) : parseISO(value)
}

export function startOf(date: Date, units: DateUnits): Date {
  if (units === 'days') {
    return startOfDay(date)
  } else if (units === 'months') {
    return startOfMonth(date)
  }

  return startOfYear(date)
}
