import { Document, Page, Text, View } from '@react-pdf/renderer'
import { times } from 'lodash-es'

import { commonPDFStyles } from 'utils/pdfs'
import { Facility, Meal, ProductionCycle } from 'types/combinedAPI/domainModels'
import { transferTagNumbersForMeal } from 'utils/transfer-tags'

import {
  BigRedTitle,
  CodeColorContainer,
  CodeColorText,
  ColText,
  FullBins,
  FullBinsText,
  MealCol,
  MealRow,
  Signature,
  TermRow,
  TermText,
  ThickBorder,
  TitleContainer,
} from './styles'
import ApprovalContent from './ApprovalContent'
import LastTower from './LastTower'

const TransferTagDocumentPDF = ({
  destinationFacility,
  productionCycle,
  term,
  transferComponents,
}: {
  destinationFacility: Facility | undefined
  productionCycle: ProductionCycle
  term: string
  transferComponents: Meal[]
}): JSX.Element => {
  let appendThis = ''
  if (productionCycle === '2') {
    appendThis = '-A'
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...commonPDFStyles.page,
          flexDirection: 'column',
          padding: '15px',
        }}
      >
        <View style={{ marginBottom: '10px' }}>
          <Text>
            Term {term} - {destinationFacility?.displayName} Transfer Sleeved
            Meals
          </Text>
        </View>
        <View>
          {transferComponents.map((transferComponent, i) => {
            const binAndTowerUsage =
              transferTagNumbersForMeal(transferComponent)
            const totalBins = binAndTowerUsage.totalBins
            const partialBinMeals = binAndTowerUsage.partialBinUnits
            const partialTowerBins = binAndTowerUsage.partialTowerBins
            const fullTowers = binAndTowerUsage.fullTowers
            const allTowers = binAndTowerUsage.allTowers
            const totalFullBins = binAndTowerUsage.totalFullBins

            return (
              <MealRow key={i} wrap={false}>
                <MealCol>
                  <ColText>
                    Meal #{transferComponent.mealCode}
                    {appendThis}
                  </ColText>
                </MealCol>
                <MealCol>
                  <ColText>
                    Total Quantity: {transferComponent.totalMeals}
                  </ColText>
                </MealCol>
                <MealCol>
                  <ColText>Total Bins: {totalBins}</ColText>
                  <ColText>
                    ({totalFullBins} full
                    {partialBinMeals > 0 ? `, 1 partial` : null})
                  </ColText>
                </MealCol>
                <MealCol>
                  <ColText>Total Towers: {allTowers}</ColText>
                  <ColText>
                    ({fullTowers} full
                    {partialTowerBins > 0 ? ', 1 partial' : ''})
                  </ColText>
                </MealCol>
                <MealCol>
                  <ColText>Partial Tower (bins): {partialTowerBins}</ColText>
                  <ColText>
                    {partialTowerBins && partialBinMeals
                      ? `(${partialTowerBins - 1} full, 1 partial)`
                      : ''}
                  </ColText>
                </MealCol>
                <MealCol>
                  <ColText>
                    Partial Bin (meals):{' '}
                    {partialBinMeals ? partialBinMeals : '0'}
                  </ColText>
                </MealCol>
              </MealRow>
            )
          })}
        </View>
      </Page>
      {transferComponents.map((meal) => {
        const binAndTowerUsage = transferTagNumbersForMeal(meal)
        const partialBinMeals = binAndTowerUsage.partialBinUnits
        const allTowers = binAndTowerUsage.allTowers
        const partialTowerBins = binAndTowerUsage.partialTowerBins

        const containerBackgroundColor =
          destinationFacility?.fulfillmentFacility ? 'yellow' : 'green'

        return times(allTowers, (i) => {
          const towerNumber = i + 1

          return (
            <Page key={i} style={{ ...commonPDFStyles.page, padding: '20px' }}>
              <TitleContainer>
                <BigRedTitle>
                  {destinationFacility?.displayName.toUpperCase()}
                </BigRedTitle>
              </TitleContainer>
              <CodeColorContainer
                style={{ backgroundColor: containerBackgroundColor }}
              >
                <CodeColorText>Meal:</CodeColorText>

                <CodeColorText style={{ marginLeft: '20px' }}>
                  {meal.mealCode}
                  {appendThis}
                </CodeColorText>
              </CodeColorContainer>

              <CodeColorContainer>
                <CodeColorText>Tower:</CodeColorText>

                <CodeColorText style={{ marginLeft: '20px' }}>
                  {towerNumber} of {allTowers}
                </CodeColorText>
              </CodeColorContainer>

              <TermRow>
                <TermText>Term: {term}</TermText>
              </TermRow>
              <ThickBorder />
              <ApprovalContent />
              <Signature />
              <FullBins>
                <FullBinsText>
                  Total Bins:{' '}
                  {towerNumber === allTowers && partialTowerBins
                    ? partialTowerBins
                    : '10'}
                  &nbsp;
                  {/* if last tower */}
                  {towerNumber === allTowers ? (
                    <LastTower
                      maxBinsPerTower={10}
                      partialBinComponents={partialBinMeals}
                      partialTowerBins={partialTowerBins}
                    />
                  ) : null}
                </FullBinsText>
                {towerNumber === allTowers && partialBinMeals > 0 ? (
                  <FullBinsText style={{ marginTop: '30px' }}>
                    Partial Bin: {partialBinMeals} meals
                  </FullBinsText>
                ) : null}
              </FullBins>
            </Page>
          )
        })
      })}
    </Document>
  )
}

export default TransferTagDocumentPDF
