import { ReactNode } from 'react'

const LineItemsTableCols = ({
  actionCol,
  purchasedGoodCol,
  qtyCol,
  totalPriceCol,
  totalWtCol,
  unitPriceCol,
  unitWtCol,
}: {
  actionCol?: ReactNode
  purchasedGoodCol: ReactNode
  qtyCol?: ReactNode
  totalPriceCol: ReactNode
  totalWtCol?: ReactNode
  unitPriceCol?: ReactNode
  unitWtCol?: ReactNode
}) => {
  return (
    <>
      <div className="justify-self-stretch">{purchasedGoodCol}</div>
      <div className="justify-self-stretch">{qtyCol}</div>
      <div className="sm:hidden">{unitWtCol}</div>
      <div className="sm:hidden">{totalWtCol}</div>
      <div className="sm:hidden">{unitPriceCol}</div>
      <div>{totalPriceCol}</div>
      {actionCol !== undefined ? <div>{actionCol}</div> : null}
    </>
  )
}

export default LineItemsTableCols
