import { error } from 'slices/notifications'
import { ProductionCycle, ProductionPart } from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import {
  useCombineProductionPart,
  useUncombineProductionPart,
} from 'hooks/combinedAPI/productionParts'
import { useIsReadOnly } from 'contexts/auth'
import CircleLoader from 'components/common/CircleLoader'

const PartCombination = ({
  part,
  productionCycle,
  productionTerm,
  refetchProductionRecord,
}: {
  part: ProductionPart
  productionCycle: ProductionCycle
  productionTerm: string
  refetchProductionRecord(): void
}): JSX.Element => {
  const isReadOnly = useIsReadOnly()

  const dispatch = useAppDispatch()

  const canMutate = productionCycle === '1'

  const { isLoading: isCombining, mutate: combineProductionPart } =
    useCombineProductionPart({
      onSettled: () => {
        refetchProductionRecord()
      },
    })
  const { isLoading: isUncombining, mutate: uncombineProductionPart } =
    useUncombineProductionPart({
      onSettled: () => {
        refetchProductionRecord()
      },
    })

  const uncombinePart = () => {
    if (isReadOnly) {
      return dispatch(error('You do not have permission to uncombine parts.'))
    }

    uncombineProductionPart({
      productionPartID: part.id,
      productionTerm,
    })
  }

  const combinePart = () => {
    if (isReadOnly) {
      return dispatch(error('You do not have permission to combine parts.'))
    }

    combineProductionPart({
      productionPartID: part.id,
      productionTerm,
    })
  }

  if (canMutate) {
    return (
      <div
        className="flex w-[220px] cursor-pointer items-center justify-center space-x-2"
        onClick={() => {
          if (part.combined) {
            uncombinePart()
          } else {
            combinePart()
          }
        }}
        style={{
          backgroundColor: part.combined ? '#57C4E5' : '#ececec',
        }}
      >
        {part.combined ? (
          <>
            <span>Uncombine Part</span>
            <div className="flex h-4 w-4 items-center justify-center">
              {isUncombining ? (
                <CircleLoader />
              ) : (
                <i className="fas fa-unlink text-sm" />
              )}
            </div>
          </>
        ) : (
          <>
            <span>Combine Part</span>
            <div className="flex h-4 w-4 items-center justify-center">
              {isCombining ? (
                <CircleLoader loaderStyle="colored" />
              ) : (
                <i className="fas fa-link text-sm" />
              )}
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <div
      className="flex w-[220px] items-center justify-center"
      style={{
        backgroundColor: part.combined ? '#57C4E5' : '#ececec',
      }}
    >
      {part.combined ? 'Combined' : 'Not Combined'}
    </div>
  )
}

export default PartCombination
