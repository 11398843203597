import { AnchorHTMLAttributes } from 'react'

const Hyperlink = ({
  ...rest
}: AnchorHTMLAttributes<HTMLAnchorElement>): JSX.Element => {
  return (
    <a
      {...rest}
      className="cursor-pointer text-bs-blue transition-colors hover:text-bs-blue-hover hover:underline"
    />
  )
}

export default Hyperlink
