import {
  FacilityNetwork,
  ProductionCycle,
  ProductionPart,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import { useCachedTags } from 'hooks/tags'
import { useEditPartTags } from 'hooks/combinedAPI/productionParts'
import { useUpdateOnValueChange } from 'hooks/general'
import PartCombination from './PartCombination'
import Procedure from './Procedure'
import ProductionPartBatches from './ProductionPartBatches'
import ProductionPartBOMs from './ProductionPartBOMs'
import ProductionPartTagDropdowns from './ProductionPartTagDropdowns'
import ProductionPartTags from './ProductionPartTags'
import Table, { TBody, Th, Thead, Tr } from 'components/common/Table'

export interface Props {
  facilityNetwork: FacilityNetwork
  part: ProductionPart
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}

const ProductionPartTable = ({
  facilityNetwork,
  part,
  productionCycle,
  productionRecord,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: Props): JSX.Element => {
  const initialNumBatches = part.combined
    ? part.combinedNumBatches
    : part.numBatches

  // Modifying tags for a part that is combined should not happen if the user is viewing cycle 2
  // because the whole point of a combined part is that modifications happen in cycle 1.
  const canModifyTags = !readOnly && (productionCycle === '1' || !part.combined)

  const [numBatches, setNumBatches] = useUpdateOnValueChange(initialNumBatches)

  const { mutateAsync: editPartTags } = useEditPartTags({
    onSettled: () => {
      refetchProductionRecord()
    },
  })

  const { onTagsChanged, tags } = useCachedTags({
    initialTags: part.tags,
    updateTagsFn: ({ tags, updateCycle2 }) => {
      return editPartTags({
        numBatches,
        part,
        productionCycle,
        productionRecord,
        productionTerm,
        tags,
        updateCycle2,
      })
    },
  })

  const backgroundColor = part.combined ? 'bg-[#57c4e5]' : 'bg-[#ececec]'

  return (
    <div className="flex space-x-4">
      <div className="w-1/4">
        <Procedure part={part} />
      </div>
      <div className="w-3/4">
        <div className="flex text-xl font-bold">
          <div
            className="w-[115px] py-1 text-center"
            style={{
              backgroundColor: 'yellow',
            }}
          >
            #{part.apiMealCode}
            {Number(productionCycle) > 1 ? ' - a' : ''}
          </div>
          <PartCombination
            part={part}
            productionCycle={productionCycle}
            productionTerm={productionTerm}
            refetchProductionRecord={refetchProductionRecord}
          />
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th backgroundColor={backgroundColor} width="40%">
                <div style={{ fontSize: '135%' }}>
                  {part.title}{' '}
                  <ProductionPartTags
                    onTagsChanged={onTagsChanged}
                    readOnly={!canModifyTags}
                    tags={tags}
                  />
                  <div className="text-base font-normal">
                    {part.parentPartTitle} (<i>{part.mealPartTitle}</i>)
                  </div>
                </div>
              </Th>
              <Th backgroundColor={backgroundColor} width="15%"></Th>
              <Th backgroundColor={backgroundColor} width="15%"></Th>
              <Th backgroundColor={backgroundColor} width="30%">
                <ProductionPartBatches
                  initialNumBatches={numBatches}
                  onNumBatchesUpdated={setNumBatches}
                  part={part}
                  productionCycle={productionCycle}
                  productionTerm={productionTerm}
                  readOnly={readOnly}
                  refetchProductionRecord={refetchProductionRecord}
                  tags={tags}
                />
              </Th>
            </Tr>
          </Thead>
          <TBody>
            <ProductionPartBOMs part={part} />
            <ProductionPartTagDropdowns
              facilityNetwork={facilityNetwork}
              onTagsChanged={onTagsChanged}
              readOnly={!canModifyTags}
              tags={tags}
            />
          </TBody>
        </Table>
      </div>
    </div>
  )
}

export default ProductionPartTable
