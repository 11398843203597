import { useState } from 'react'
import { Formik, FormikErrors, Form } from 'formik'

import { DATE_FORMATS, formatDate } from 'utils/dates'

import Modal, { ModalBody, ModalHeader } from 'components/common/Modal'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import FormInput from 'components/common/FormInput'
import FormTextarea from 'components/common/FormTextarea'
import PrinterIcon from 'components/common/icons/PrinterIcon'

import { PurchaseOrderFull } from 'types/internal'
import PrintVendorPDF from './PrintVendorPDF'
import {
  VendorPurchaseOrderFormData,
  VendorPurchaseOrderContainers,
} from './helpers'

const VendorPurchaseOrder = ({
  purchaseOrder,
  selectedFacilityNetworkID,
}: {
  purchaseOrder: PurchaseOrderFull
  selectedFacilityNetworkID: string | null
}): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="w-44">
        <Button
          buttonStyle="primary"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <span>Generate Vendor PDF</span>
        </Button>
      </div>
      {isOpen && (
        <VendorPurchaseOrderModal
          onCloseModal={() => {
            setIsOpen(false)
          }}
          purchaseOrder={purchaseOrder}
          selectedFacilityNetworkID={selectedFacilityNetworkID}
        />
      )}
    </>
  )
}

export default VendorPurchaseOrder

const VendorPurchaseOrderModal = ({
  onCloseModal,
  purchaseOrder,
  selectedFacilityNetworkID,
}: {
  onCloseModal(): void
  purchaseOrder: PurchaseOrderFull
  selectedFacilityNetworkID: string | null
}): JSX.Element => {
  const containers: VendorPurchaseOrderContainers = {}
  purchaseOrder.lineItems.forEach((lineItem) => {
    containers[lineItem.vendorSKUID] = ''
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-vendor-purchase-order-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Generate Vendor Purchase Order PDF
            </ModalHeader>
          </div>

          <p className="my-8">
            <span className="font-semibold">
              PO #: {purchaseOrder.supplierRef || purchaseOrder.snapReceiptID}
            </span>
          </p>

          <div className="mt-8">
            <Formik<VendorPurchaseOrderFormData>
              initialValues={{
                authorizationDate: formatDate(purchaseOrder.created, {
                  format: DATE_FORMATS.API_DATE_FORMAT,
                }),
                authorizedBy: '',
                buyerName: '',
                buyerEmail: '',
                contactEmail: purchaseOrder.vendor.contactEmail || '',
                contactName: purchaseOrder.vendor.contactName || '',
                containers,
                notes: `Delivery Hours:\nInstructions on requesting delivery:\nEmail Final Invoice to ap@tovala.com\nPlease provide BOL to receiver upon delivery`,
                shipping: '',
                signature: '',
                total: '',
                totalLbs: '',
              }}
              /* eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function --
                We're using the click event in PrintVendorPDF to request print as the submit function and
                getting the values via useFormikContext in the AutoPrintVendorDoc component to supply to the VendorPDF
              */
              onSubmit={() => {}}
              validate={(formData) => {
                return validateFormData({ formData })
              }}
            >
              <Form className="space-y-4">
                <div className="flex space-x-4">
                  <div className="flex-grow">
                    <FormInput
                      id="contactName"
                      label="Vendor Contact Name"
                      labelFor="contactName"
                      name="contactName"
                    />
                  </div>
                  <div className="flex-grow">
                    <FormInput
                      id="contactEmail"
                      label="Vendor Contact Email"
                      labelFor="contactEmail"
                      name="contactEmail"
                    />
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-grow">
                    <FormInput
                      id="buyerName"
                      label="Buyer"
                      labelFor="buyerName"
                      name="buyerName"
                    />
                  </div>
                  <div className="flex-grow">
                    <FormInput
                      id="buyerEmail"
                      label="Buyer Email"
                      labelFor="buyerEmail"
                      name="buyerEmail"
                      type="email"
                    />
                  </div>
                </div>

                <div className="mb-3 grid grid-cols-4 gap-4 text-sm uppercase text-grey">
                  <div>Item No</div>
                  <div>Description</div>
                  <div>Qty</div>
                  <div>
                    Containers <span className="lowercase">(optional)</span>
                  </div>
                </div>

                {purchaseOrder.lineItems.map((lineItem) => {
                  return (
                    <div
                      key={lineItem.vendorSKUID}
                      className="grid grid-cols-4 gap-4"
                    >
                      <div>{lineItem.vendorSKU?.sku}</div>
                      <div>{lineItem.vendorSKU?.name}</div>
                      <div>
                        {lineItem.orderQty} {lineItem.orderUnit}
                      </div>
                      <FormInput name={`containers.${lineItem.vendorSKUID}`} />
                    </div>
                  )
                })}

                <div className="grid grid-cols-3 gap-4">
                  <FormInput
                    id="totalLbs"
                    label="Total LBS"
                    labelFor="totalLbs"
                    name="totalLbs"
                    type="number"
                  />
                  <FormInput
                    id="shipping"
                    label="Shipping"
                    labelFor="shipping"
                    name="shipping"
                    type="number"
                  />
                  <FormInput
                    id="total"
                    label="Total"
                    labelFor="total"
                    name="total"
                    type="number"
                  />
                </div>

                <FormTextarea
                  id="notes"
                  label="Notes/Specifications"
                  labelFor="notes"
                  name="notes"
                  rows={4}
                />

                <div className="grid grid-cols-2 gap-4">
                  <FormInput
                    id="authorizedBy"
                    label="Authorized By"
                    labelFor="authorizedBy"
                    name="authorizedBy"
                  />
                  <FormInput
                    id="authorizationDate"
                    label="Authorization Date"
                    labelFor="authorizationDate"
                    name="authorizationDate"
                    type="date"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <FormInput
                    id="signature"
                    label="Signature"
                    labelFor="signature"
                    name="signature"
                  />
                </div>
                <div className="flex w-full items-center justify-end pt-6">
                  <div className="w-24">
                    <Button buttonStyle="cancel" onClick={onCloseModal}>
                      Done
                    </Button>
                  </div>
                  <PrintVendorPDF
                    purchaseOrderID={purchaseOrder.id}
                    selectedFacilityNetworkID={selectedFacilityNetworkID}
                  >
                    {({ isLoading }) => {
                      return (
                        <div className="w-48 sm:w-20">
                          <ButtonLoading isLoading={isLoading} type="submit">
                            <div className="flex items-center justify-between space-x-2">
                              {!isLoading && (
                                <div className="h-4 w-4">
                                  <PrinterIcon />
                                </div>
                              )}
                              <span>Print</span>
                            </div>
                          </ButtonLoading>
                        </div>
                      )
                    }}
                  </PrintVendorPDF>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export function validateFormData({
  formData,
}: {
  formData: VendorPurchaseOrderFormData
}): FormikErrors<VendorPurchaseOrderFormData> {
  const errors: FormikErrors<VendorPurchaseOrderFormData> = {}

  if (!formData.authorizationDate) {
    errors.authorizationDate = 'Please enter authorization date.'
  }

  if (!formData.authorizedBy) {
    errors.authorizedBy = 'Please enter authorized by.'
  }

  if (!formData.buyerEmail) {
    errors.buyerEmail = 'Please enter a buyer email.'
  }

  if (!formData.buyerName) {
    errors.buyerName = 'Please enter a buyer name.'
  }

  if (!formData.notes) {
    errors.notes = 'Please enter notes.'
  }

  if (!formData.total) {
    errors.total = 'Please enter a total.'
  } else if (formData.total <= 0) {
    errors.total = 'Total must be greater than 0.'
  }

  if (!formData.signature) {
    errors.signature = 'Please enter a signature.'
  }

  return errors
}
