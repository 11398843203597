import { uniq } from 'lodash-es'

import { ComposedMealBOMItem } from 'types/internal'
import { Meal, ProductionRecord } from 'types/combinedAPI/domainModels'

export function getBOMIDsByTitlesAndQuantities({
  meal,
  sourceBOMs,
}: {
  meal: Meal
  sourceBOMs: ComposedMealBOMItem[]
}): string[] {
  let bomIDs: string[] = []

  for (const sourceBOM of sourceBOMs) {
    const matchingMealBOMs = meal.billOfMaterials.filter((mealBOM) => {
      return (
        sourceBOM.title === mealBOM.title &&
        sourceBOM.qtyPounds === mealBOM.qtyPounds
      )
    })

    bomIDs = [...bomIDs, ...matchingMealBOMs.map((bom) => bom.id)]
  }

  return bomIDs
}

export function getCycle2BOMIDs({
  boms,
  productionMealCycle2,
  productionRecord,
}: {
  boms: ComposedMealBOMItem[]
  productionMealCycle2: Meal | undefined
  productionRecord: ProductionRecord
}): string[] {
  if (!productionMealCycle2) {
    return []
  }

  let cycle2BOMIDs: string[] = []

  for (const bom of boms) {
    let cycle2BOMIDsForCurrentBOM: string[] = []

    const isPurchasedGood = !bom.productionPartID

    if (isPurchasedGood) {
      // Purchased good BOMs don't show up in the cyclePartMatches array of a production
      // record (since they by definition don't have production part IDs). So we have to
      // resort to finding cycle 2 matches by title and we add in a matching quantity to
      // cut down on false positives.
      cycle2BOMIDsForCurrentBOM = getBOMIDsByTitlesAndQuantities({
        meal: productionMealCycle2,
        sourceBOMs: [bom],
      })
    } else {
      // The cyclePartMatches of the production record contains production part IDs, not
      // BOM IDs, so we have to go productionPartID of cycle 1 => productionPartID of cycle 2
      // => BOM ID of cycle 2.
      const cycle2ProductionPartIDs = productionRecord.cyclePartMatches
        .filter((cyclePartMatch) => {
          return bom.productionPartID === cyclePartMatch.id
        })
        .map((cyclePartMatch) => {
          return cyclePartMatch.c2ID
        })

      cycle2BOMIDsForCurrentBOM =
        productionMealCycle2.billOfMaterials
          .filter((bom) => {
            return cycle2ProductionPartIDs.includes(bom.productionPartID)
          })
          .map((bom) => {
            return bom.id
          }) ?? []
    }

    cycle2BOMIDs = [...cycle2BOMIDs, ...cycle2BOMIDsForCurrentBOM]
  }

  return uniq(cycle2BOMIDs)
}
