import PageLayout from './PageLayout'

const PurchaseOrderPageLoading = (): JSX.Element => {
  return (
    <PageLayout>
      <div className="h-8 w-60 animate-pulse bg-light-grey" />

      <div className="my-8 flex w-1/2 items-center justify-between space-x-4">
        {new Array(4).map((_unused, i) => {
          return (
            <div key={i} className="h-4 w-full animate-pulse bg-light-grey" />
          )
        })}
      </div>

      <div className="space-y-8 border-t border-light-grey pt-2">
        {new Array(4).map((_unused, i) => {
          return (
            <div
              key={i}
              className="mt-4 grid grid-cols-purchase-order-table-single items-center justify-items-center gap-4 pt-2"
            >
              {new Array(6).map((_unused, j) => {
                return (
                  <div
                    key={j}
                    className="h-4 w-full animate-pulse bg-light-grey"
                  />
                )
              })}
            </div>
          )
        })}
      </div>
    </PageLayout>
  )
}

export default PurchaseOrderPageLoading
