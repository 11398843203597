import { PDFViewer } from '@react-pdf/renderer'

import LabelPDF, { LabelPDFProps } from './LabelPDF'

const LabelPDFViewer = ({
  showLabels,
  ...rest
}: LabelPDFProps & {
  showLabels(): void
}): JSX.Element => {
  return (
    <>
      <button
        className="my-8 text-blue hover:underline"
        onClick={() => showLabels()}
      >
        Go Back to Labels
      </button>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <LabelPDF {...rest} />
      </PDFViewer>
    </>
  )
}

export default LabelPDFViewer
