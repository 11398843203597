import { clsx } from 'clsx'
import { ReactNode } from 'react'

const H3 = ({
  children,
  withMargin = true,
}: {
  children: ReactNode
  withMargin?: boolean
}): JSX.Element => {
  return (
    <h3
      className={clsx('text-2.5xl font-medium', {
        'mb-2': withMargin,
      })}
    >
      {children}
    </h3>
  )
}

export default H3
