import { ReactNode } from 'react'

import { isAxiosResponseError } from 'utils/api'

import ErrorDisplay from './ErrorDisplay'

const APIErrorDisplay = ({
  error,
  fallbackError = '',
}: {
  error: Error | null
  fallbackError?: string
}): JSX.Element => {
  let errorMessage: ReactNode = fallbackError
  if (isAxiosResponseError(error)) {
    const { message, requestid } = error.response?.data || {}

    errorMessage = (
      <div>
        <p>{message}</p>
        <p className="mt-1 text-xs">
          <span className="font-semibold uppercase text-grey">Request ID:</span>{' '}
          {requestid}
        </p>
      </div>
    )
  } else if (error instanceof Error) {
    errorMessage = error.message
  }

  return <ErrorDisplay>{errorMessage}</ErrorDisplay>
}

export default APIErrorDisplay
