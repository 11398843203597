import axios, { RawAxiosRequestConfig } from 'axios'

import { COOKIE_NAMES, getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'

export type APIVersion = '' | 'v0'

export function getAPIUrl({
  path,
  version,
}: {
  path: string
  version: APIVersion
}) {
  return `${getEnvVar('MISEVALA_API_URL')}${
    version ? `/${version}` : ''
  }${path}`
}

function getDefaultHeaders({ isPublic = false }: { isPublic?: boolean }) {
  const headers: Record<string, string> = {
    // This AppID is used is back-end logs so we can identify the application that is making a request.
    'X-Tovala-AppID': 'misevala-web',
  }

  const jwt = getCookie(COOKIE_NAMES.JWT_TOKEN)
  if (!isPublic && jwt) {
    headers.Authorization = `Bearer ${jwt}`
  }

  return headers
}

export async function apiDelete<ResponseType>({
  config = {},
  isPublic = false,
  path,
  version,
}: {
  config?: RawAxiosRequestConfig<ResponseType>
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const response = await axios.delete<ResponseType>(
    getAPIUrl({ path, version }),
    {
      headers: getDefaultHeaders({ isPublic }),
      ...config,
    }
  )

  return response.data
}

export async function get<ResponseType>({
  config = {},
  isPublic = false,
  path,
  version,
}: {
  config?: RawAxiosRequestConfig<ResponseType>
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const response = await axios.get<ResponseType>(getAPIUrl({ path, version }), {
    headers: getDefaultHeaders({ isPublic }),
    ...config,
  })

  return response.data
}

export async function post<ResponseType>({
  config = {},
  data = undefined,
  isPublic = false,
  path,
  version,
}: {
  config?: RawAxiosRequestConfig<ResponseType>
  data?: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const response = await axios.post<ResponseType>(
    getAPIUrl({ path, version }),
    data,
    {
      headers: getDefaultHeaders({ isPublic }),
      ...config,
    }
  )

  return response.data
}

export async function put<ResponseType>({
  config = {},
  data,
  isPublic = false,
  path,
  version,
}: {
  config?: RawAxiosRequestConfig<ResponseType>
  data: unknown
  isPublic?: boolean
  path: string
  version: APIVersion
}) {
  const response = await axios.put<ResponseType>(
    getAPIUrl({ path, version }),
    data,
    {
      headers: getDefaultHeaders({ isPublic }),
      ...config,
    }
  )

  return response.data
}
