import { clsx } from 'clsx'
import { Fragment } from 'react'
import { groupBy, keys, orderBy } from 'lodash-es'

import H3 from 'components/common/bs/H3'
import H4 from 'components/common/bs/H4'
import QRCode from 'qrcode.react'

import {
  getPartsFromProductionRecord,
  getProductionPartWeights,
} from 'utils/production'
import {
  ProductionCycle,
  ProductionPart,
  ProductionRecord,
  TagCategories,
} from 'types/combinedAPI/domainModels'

import Table, { TBody, Td, Th, Thead, Tr } from 'components/common/Table'

const TAG_COLORS: Partial<Record<keyof TagCategories, string>> = {
  cook: 'bg-bs-yellow',
  mix: 'bg-black text-white',
  prep_labels: 'bg-red text-white',
  prepare: 'bg-blue text-white',
}

const MealCompendium = ({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): JSX.Element => {
  const workOrdersByMeal = groupBy(
    getPartsFromProductionRecord({ productionCycle, productionRecord }),
    (part) => part.mealPartTitle
  )
  const mealNames = keys(workOrdersByMeal)

  return (
    <div>
      {mealNames.map((mealName, mealNameIdx) => {
        const meal = productionRecord.cycles[productionCycle].meals.find(
          (m) => m.partTitle === mealName
        )
        const workOrdersForMeal = workOrdersByMeal[mealName]

        if (!meal) {
          return null
        }

        return (
          <Fragment key={mealNameIdx}>
            <div className="print:break-after-page">
              <div className="border-b border-light-grey">
                <H3>
                  {mealName} - #{meal.mealCode}
                  {Number(productionCycle) > 1 ? ' - a' : ''}
                </H3>
              </div>
              <div className="my-4 flex">
                <div className="w-1/2">
                  <H4>Parts</H4>
                  <ul className="list-disc pl-8">
                    {meal.billOfMaterials.map((bom, i) => (
                      <li key={i}>
                        {bom.title} (portion: {bom.qtyPounds.toFixed(2)} lbs)
                        (total: {bom.totalWeightRequiredPounds.toFixed(2)} lbs)
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-1/2">
                  <H4>Procedure</H4>
                  <ul className="list-disc pl-8">
                    {meal.procedure.map((step, i) => (
                      <li key={i}>{step.instructions}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                size={128}
                value={`133A254|${meal.id}|5E34BF80`}
              />
            </div>
            {workOrdersForMeal.map((productionPart, i) => {
              return (
                <CompendiumWorkOrder
                  key={i}
                  productionCycle={productionCycle}
                  productionPart={productionPart}
                />
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export default MealCompendium

const CompendiumWorkOrder = ({
  productionCycle,
  productionPart,
}: {
  productionCycle: ProductionCycle
  productionPart: ProductionPart
}): JSX.Element | null => {
  if (productionPart.combined && productionCycle === '2') {
    return null
  }

  const { batchWt, totalWt, useYieldWeight, yieldWt } =
    getProductionPartWeights(productionPart)

  const prepDay =
    productionPart.tags.find((t) => t.category === 'day_of_week')?.title ??
    'NO PREP DAY'

  return (
    <div className="relative mt-10 print:break-after-page">
      <div className="absolute left-0 top-0 flex -translate-y-full space-x-1 text-center text-xl font-bold">
        <div
          className="w-[115px]"
          style={{
            backgroundColor: 'yellow',
          }}
        >
          #{productionPart.apiMealCode}
          {Number(productionCycle) > 1 ? ' - a' : ''}
        </div>

        {productionPart.combined && (
          <div
            className="w-[115px] text-white"
            style={{
              backgroundColor: 'blue',
            }}
          >
            combined
          </div>
        )}
      </div>

      <Table>
        <Thead>
          <Tr>
            <Th width="40%">
              <div className="text-xl">
                {productionPart.title}&nbsp;
                {productionPart.tags.map((tag, i) => (
                  <div
                    key={i}
                    className={clsx(
                      'inline-flex cursor-pointer rounded border border-black p-1 text-xs leading-3',
                      TAG_COLORS[tag.category] ?? 'bg-bs-lighter-grey'
                    )}
                  >
                    {tag.title}
                  </div>
                ))}
                <br />
                <small className="font-normal">
                  {' '}
                  {productionPart.parentPartTitle} (
                  <span>{productionPart.mealPartTitle}</span>)
                </small>
              </div>
            </Th>
            <Th width="20%">Total Weight</Th>
            <Th width="20%">Total Yield</Th>
            <Th>
              <b>Number of Batches</b>
              <br />
              <div>{productionPart.numBatches}</div>
            </Th>
          </Tr>
        </Thead>
        <TBody>
          {orderBy(productionPart.billOfMaterials, (mat) =>
            mat.title.toLowerCase()
          ).map((mat, i) => {
            return (
              <Tr key={i}>
                <Td>
                  <div>{mat.title}</div>
                  {mat.baseSku && (
                    <div className="text-sm text-dark-grey">
                      Tovala Base SKU: {mat.baseSku}
                    </div>
                  )}
                </Td>
                <Td>{mat.totalWeightRequiredPounds.toFixed(2)}lbs total</Td>
                <Td>{mat.finalYieldPounds.toFixed(2)}lbs yield</Td>
                <Td>
                  {productionPart.numBatches &&
                    (useYieldWeight
                      ? mat.finalYieldPounds
                      : mat.totalWeightRequiredPounds /
                        productionPart.numBatches
                    ).toFixed(2)}{' '}
                  lbs / batch
                </Td>
              </Tr>
            )
          })}
          <Tr withBlackTopBorder>
            <Td></Td>
            <Td>
              <b>{totalWt} lbs total</b>
            </Td>
            <Td>
              <b>{yieldWt} lbs yield</b>
            </Td>
            <Td>
              {batchWt} {useYieldWeight ? 'yield' : 'total'} lbs / batch
            </Td>
          </Tr>

          <Tr>
            <Td>
              <b>Prep Day: {prepDay}</b>
            </Td>
          </Tr>
        </TBody>
      </Table>
      <div className="mt-2">
        <H4>Procedure</H4>
        {productionPart.procedure.map((step, i) => (
          <span key={i} className="block">
            {step.stepNumber}.) {step.instructions}
          </span>
        ))}
      </div>
    </div>
  )
}
