import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { Tab as HeadlessUITab } from '@headlessui/react'

type TabStyle = 'default' | 'pills'

const TabGroup = (props: {
  children: ReactNode
  onChange?: (index: number) => void
  selectedIndex?: number
}) => {
  return <HeadlessUITab.Group {...props} />
}

export default TabGroup

export const TabList = ({
  children,
  tabStyle = 'default',
}: {
  children: ReactNode
  tabStyle?: TabStyle
}) => {
  return (
    <HeadlessUITab.List
      className={clsx({
        'border-grey-3 flex border-b': tabStyle === 'default',
      })}
    >
      {children}
    </HeadlessUITab.List>
  )
}

export const Tab = ({
  children,
  disabled = false,
  tabStyle = 'default',
}: {
  children: ReactNode
  disabled?: boolean
  tabStyle?: TabStyle
}) => {
  return (
    // We need both the "ui-selected" and "ui-not-selected" because otherwise for nested tabs
    // the individual tabs will all be selected (since they'll inherit from the parent tabs).
    // Specifying both variants is a workaround.
    // See https://github.com/tailwindlabs/headlessui/issues/1857
    <HeadlessUITab
      className={clsx('px-4 py-2', {
        'ui-selected:border-grey-3 -mb-px rounded-t border px-4 py-2 ui-selected:border-b-white ui-not-selected:border-transparent ui-not-selected:text-blue ui-not-selected:disabled:text-grey':
          tabStyle === 'default',
        'text-left ui-selected:rounded ui-selected:bg-blue ui-selected:text-white ui-not-selected:bg-white ui-not-selected:text-blue':
          tabStyle === 'pills',
      })}
      disabled={disabled}
    >
      {children}
    </HeadlessUITab>
  )
}

export const TabPanels = ({ children }: { children: ReactNode }) => {
  return <HeadlessUITab.Panels>{children}</HeadlessUITab.Panels>
}

export const TabPanel = ({ children }: { children: ReactNode }) => {
  return <HeadlessUITab.Panel>{children}</HeadlessUITab.Panel>
}
