import { partition } from 'lodash-es'

import { Facility, FacilityNetwork } from 'types/combinedAPI/domainModels'

interface FacilityManyOfType {
  notOfType: Facility[]
  ofType: Facility[]
}

interface FacilityOneOfType {
  notOfType: Facility | undefined
  ofType: Facility | undefined
}

function filterByFacilityType(
  facilityNetwork: FacilityNetwork,
  facilitySelector: (facility: Facility) => boolean
): FacilityManyOfType {
  const [ofType, notOfType] = partition(
    facilityNetwork.facilities,
    (facility) => {
      return facilitySelector(facility)
    }
  )

  return { ofType, notOfType }
}

function filterByFacilityTypeWhenOnlyOneOfEachType(
  facilityNetwork: FacilityNetwork,
  facilitySelector: (facility: Facility) => boolean
): FacilityOneOfType {
  const facilityIndex = facilityNetwork.facilities.findIndex(facilitySelector)
  const facility = facilityNetwork.facilities[facilityIndex]
  const nonfacility = facilityNetwork.facilities[facilityIndex === 0 ? 1 : 0]

  return { ofType: facility, notOfType: nonfacility }
}

export function filterPortionFacilities(
  facilityNetwork: FacilityNetwork
): FacilityManyOfType {
  return filterByFacilityType(facilityNetwork, (f) => f.portionFacility)
}

export function filterSleevingFacilities(
  facilityNetwork: FacilityNetwork
): FacilityManyOfType {
  return filterByFacilityType(facilityNetwork, (f) => f.sleevingFacility)
}

export function filterPrepFacilities(
  facilityNetwork: FacilityNetwork
): FacilityManyOfType {
  return filterByFacilityType(facilityNetwork, (f) => f.prepFacility)
}

export function filterFulfillmentFacilities(
  facilityNetwork: FacilityNetwork
): FacilityOneOfType {
  return filterByFacilityTypeWhenOnlyOneOfEachType(
    facilityNetwork,
    (f) => f.fulfillmentFacility
  )
}
