import { post } from './baseAPI'
import { PurchasedGood } from 'types/combinedAPI/domainModels'

export interface SearchPurchasedGoods {
  vendorID: string
}

export type SearchPurchasedGoodsResponse = PurchasedGood[]

export const ENDPOINTS = {
  SEARCH_PURCHASED_GOODS: () => ({
    path: '/purchasedGoods/search',
    version: 'v0' as const,
  }),
}

export async function searchPurchasedGoods({
  vendorID,
}: SearchPurchasedGoods): Promise<SearchPurchasedGoodsResponse> {
  const response = await post<{ data: PurchasedGood[] }>({
    data: { property: 'vendorID', value: vendorID },
    ...ENDPOINTS.SEARCH_PURCHASED_GOODS(),
  })

  return response.data
}
