import { Cookies } from 'react-cookie'

import { getEnvVar } from './env'

const cookies = new Cookies()

export const COOKIE_NAMES = {
  JWT_TOKEN: getEnvVar('JWT_COOKIE_NAME'),
}

export function getCookie(cookieName: string): string {
  return cookies.get(cookieName)
}

export function removeCookie(cookieName: string): void {
  cookies.remove(cookieName)
}

export function setCookie({
  content,
  cookieName,
  expires,
}: {
  content: string
  cookieName: string
  expires: Date
}): void {
  cookies.set(cookieName, content, {
    path: '/',
    expires,
    sameSite: 'strict',
  })
}
