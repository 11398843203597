import { ProductionPart } from 'types/combinedAPI/domainModels'

import H3 from 'components/common/bs/H3'

const Procedure = ({ part }: { part: ProductionPart }): JSX.Element => {
  return (
    <>
      <H3>Procedure</H3>
      <ul>
        {part.procedure &&
          part.procedure.map((step, i) => (
            <li key={i}>
              {step.stepNumber}.) {step.instructions}
            </li>
          ))}
      </ul>
    </>
  )
}

export default Procedure
