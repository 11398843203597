import { CSSProperties, forwardRef, ReactNode } from 'react'
import { Menu as HeadlessUIMenu } from '@headlessui/react'

const Menu = ({ children, ...rest }: { children: ReactNode }) => {
  return (
    <HeadlessUIMenu as="div" className="relative" {...rest}>
      {children}
    </HeadlessUIMenu>
  )
}

export default Menu

export const MenuButton = forwardRef<
  HTMLButtonElement,
  { children: ReactNode; style?: CSSProperties }
>(function MenuButton({ children, ...rest }, ref) {
  return (
    <HeadlessUIMenu.Button ref={ref} as="div" {...rest}>
      {children}
    </HeadlessUIMenu.Button>
  )
})

export const MenuItem = HeadlessUIMenu.Item

export const MenuItems = forwardRef<
  HTMLDivElement,
  { children: ReactNode; style?: CSSProperties }
>(function MenuItems({ children, ...rest }, ref) {
  return (
    <HeadlessUIMenu.Items
      ref={ref}
      className="z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md border border-light-grey bg-white shadow-lg"
      {...rest}
    >
      {children}
    </HeadlessUIMenu.Items>
  )
})
