import { qaParts } from 'utils/qa'

import { useAppSelector } from 'hooks'
import H3 from 'components/common/bs/H3'
import Table, { TBody } from 'components/common/Table'
import WeightCheckTableHeader from './WeightCheckTableHeader'
import WeightCheckTableRow from './WeightCheckTableRow'

const WeightCheck = (): JSX.Element => {
  const productionCycle = useAppSelector(
    (state) => state.production.productionCycle
  )
  const productionRecord = useAppSelector(
    (state) => state.production.productionRecord
  )
  const weightCheckParts = productionRecord
    ? qaParts('weight_check', productionRecord)
    : null

  return (
    <>
      <div className="py-5 print:hidden">
        <H3>Weight Check Log</H3>
      </div>
      <Table>
        <WeightCheckTableHeader />
        <TBody>
          {weightCheckParts &&
            weightCheckParts.cycles[productionCycle].map((part, i) => {
              return (
                <WeightCheckTableRow
                  key={i}
                  part={part}
                  productionCycle={productionCycle}
                />
              )
            })}
        </TBody>
      </Table>
    </>
  )
}

export default WeightCheck
