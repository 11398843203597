import { BoxSize } from 'types/combinedAPI/domainModels'
import { ShippingCarrierCSVRow } from 'types/internal'

import { findInsulationTypeByName } from './insulation-types'

export function boxesPerPallet(
  boxesForCarrierAndInsulationType: ShippingCarrierCSVRow,
  boxSize: BoxSize
):
  | {
      boxesOnLastPallet: number
      boxesPerPallet: number
      displayText: string
      totalPallets: number
    }
  | undefined {
  const insulationType = findInsulationTypeByName(
    boxesForCarrierAndInsulationType.insulation
  )
  if (!insulationType) {
    return undefined
  }

  const displayText = `${boxSize}${insulationType.palletSheetAbbreviation}`
  const totalBoxes = boxesForCarrierAndInsulationType[`${boxSize}`]
  const boxesPerPallet = insulationType.palletInfo[boxSize].boxesPerPallet
  const totalPallets = Math.ceil(totalBoxes / boxesPerPallet)

  const leftoverBoxes =
    boxesForCarrierAndInsulationType[boxSize] % boxesPerPallet
  const boxesOnLastPallet = leftoverBoxes ? leftoverBoxes : boxesPerPallet

  return { boxesOnLastPallet, boxesPerPallet, displayText, totalPallets }
}
