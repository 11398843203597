import { keys, pickBy, sortBy } from 'lodash-es'

import { boxesPerPallet } from './boxes-per-pallet'
import { ShippingCarrierCSVRow } from 'types/internal'

import Table, { TBody, Td, Th, Thead, Tr } from 'components/common/Table'

const SummaryTable = ({
  carriers,
}: {
  carriers: Record<string, ShippingCarrierCSVRow[]>
}): JSX.Element => {
  const carrierNamesWithBoxes = keys(
    pickBy(carriers, (csvRows) => {
      return csvRows.length > 0
    })
  )

  return (
    <div className="space-y-4">
      {sortBy(carrierNamesWithBoxes).map((carrier) => (
        <Table key={`${carrier}-table`}>
          <Thead>
            <Tr>
              <Th>Carrier Name</Th>
              <Th>Insulation</Th>
              <Th>4 Box</Th>
              <Th>6 Box</Th>
              <Th>8 Box</Th>
            </Tr>
          </Thead>
          <TBody>
            {carriers[carrier].map((c) => {
              const palletBoxes4 = boxesPerPallet(c, 4)
              const palletBoxes6 = boxesPerPallet(c, 6)
              const palletBoxes8 = boxesPerPallet(c, 8)

              return (
                <Tr key={`${c.carrier}-${c.insulation}`}>
                  <Td>{c.carrier}</Td>
                  <Td>{c.insulation}</Td>
                  <Td>
                    {c['4']} total boxes
                    <br />
                    {palletBoxes4?.boxesPerPallet} boxes per pallet
                    <br />
                    {palletBoxes4?.totalPallets} total pallets
                  </Td>
                  <Td>
                    {c['6']} total boxes
                    <br />
                    {palletBoxes6?.boxesPerPallet} boxes per pallet
                    <br />
                    {palletBoxes6?.totalPallets} total pallets
                  </Td>
                  <Td>
                    {c['8']} total boxes
                    <br />
                    {palletBoxes8?.boxesPerPallet} boxes per pallet
                    <br />
                    {palletBoxes8?.totalPallets} total pallets
                  </Td>
                </Tr>
              )
            })}
          </TBody>
        </Table>
      ))}
    </div>
  )
}

export default SummaryTable
