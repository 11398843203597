import { compact, isEmpty, orderBy, round } from 'lodash-es'
import { FormikErrors } from 'formik'

import {
  Facility,
  PurchasedGood,
  PurchaseOrderLineItem,
  Vendor,
} from 'types/combinedAPI/domainModels'
import { formatDateForAPI } from 'utils/dates'
import { formatDollars } from 'utils/currency'
import {
  PurchaseOrderFull,
  PurchaseOrderLineItemExpanded,
  ReactSelectValue,
  VendorSKUsInPO,
  VendorSKUWithPurchasedGood,
} from 'types/internal'
import { SavePurchaseOrderBody } from 'services/combinedAPI/purchaseOrders'

type AbbrFacility = Pick<Facility, 'displayName' | 'id'>

export interface SavePurchaseOrderFormData {
  facility: ReactSelectValue<AbbrFacility> | null
  freightCost: number | ''
  lineItems: LineItem[]
  requestedDeliveryDate: string
  supplierReference: string
  vendor: ReactSelectValue<Vendor> | null
}

export interface SavePurchaseOrderFormDataValidated {
  facility: ReactSelectValue<AbbrFacility>
  freightCost: number | ''
  lineItems: LineItem[]
  requestedDeliveryDate: string
  supplierReference: string
  vendor: ReactSelectValue<Vendor>
}

interface LineItem {
  // The user is allowed to override the cost we have stored in the DB. If not provided, we'll use the
  // cost per pound from the API.
  overrideCasePrice: number | null
  overridedSku?: number | null
  qty: number | ''
  vendorSKU: ReactSelectValue<VendorSKUWithPurchasedGood> | null
}

interface LineItemValidated {
  overrideCasePrice: number | null
  qty: number
  vendorSKU: ReactSelectValue<VendorSKUWithPurchasedGood>
}

export interface VendorPurchaseOrderContainers {
  [vendorSKUID: number]: string | undefined
}

export interface VendorPurchaseOrderFormData {
  authorizationDate: string
  authorizedBy: string
  buyerEmail: string
  buyerName: string
  contactEmail: string
  contactName: string
  containers: VendorPurchaseOrderContainers
  notes: string
  shipping: number | ''
  signature: string
  total: number | ''
  totalLbs: number | ''
}

const CVS_CONFIGS: Record<
  Vendor['name'],
  {
    lineItemsEndOffset: number
    lineItemsStartIndex: number
    overrideCasePriceIndex: number
    qtyIndex: number
    requestedDeliveryDateIndices: number[]
    supplierReferenceIndices: number[]
    vendorSKUIndex: number
  }
> = {
  Sysco: {
    lineItemsEndOffset: 0,
    // The first two rows are headers.
    lineItemsStartIndex: 2,
    overrideCasePriceIndex: 10,
    qtyIndex: 2,
    requestedDeliveryDateIndices: [0, 5],
    supplierReferenceIndices: [0, 7],
    vendorSKUIndex: 1,
  },
  'US Foods': {
    // The last row is a totals row.
    lineItemsEndOffset: 1,
    // The first row is a header.
    lineItemsStartIndex: 1,
    overrideCasePriceIndex: 10,
    qtyIndex: 12,
    requestedDeliveryDateIndices: [1, 3],
    supplierReferenceIndices: [1, 4],
    vendorSKUIndex: 5,
  },
}

export function csvParser({
  csvData,
  purchasedGoodsForVendor,
  vendor,
}: {
  csvData: string[][]
  purchasedGoodsForVendor: PurchasedGood[]
  vendor: Vendor
}): {
  formData: Partial<SavePurchaseOrderFormData>
  missingCaseWeights: string[]
  missingConfig: boolean
  missingSKUs: string[]
} {
  const config = CVS_CONFIGS[vendor.name]

  if (!config || csvData.length === 0) {
    return {
      formData: {},
      missingCaseWeights: [],
      missingConfig: true,
      missingSKUs: [],
    }
  }

  const lineItems: SavePurchaseOrderFormData['lineItems'] = []
  const missingCaseWeights: string[] = []
  const missingSKUs: string[] = []

  for (
    let i = config.lineItemsStartIndex;
    i <= csvData.length - 1 - config.lineItemsEndOffset;
    i++
  ) {
    let vendorSKUCSV = csvData[i][config.vendorSKUIndex]

    if (!vendorSKUCSV) {
      continue
    }

    // See https://tovala.atlassian.net/browse/WAT-610, "US Foods" CSVs have an extra space in
    // the vendor SKU column due to a recent update to their system.
    vendorSKUCSV = vendorSKUCSV.trim()

    const purchasedGood = purchasedGoodsForVendor.find(({ vendorSkus }) => {
      return vendorSkus.some(({ sku }) => sku === vendorSKUCSV)
    })
    const vendorSKU = purchasedGood?.vendorSkus.find(
      ({ sku }) => sku === vendorSKUCSV
    )

    if (!purchasedGood || !vendorSKU) {
      missingSKUs.push(vendorSKUCSV)
      continue
    }

    if (Number(vendorSKU.caseWeightPounds)) {
      lineItems.push({
        overrideCasePrice:
          Number(csvData[i][config.overrideCasePriceIndex].substring(1)) ||
          null,
        qty: Number(csvData[i][config.qtyIndex]),
        vendorSKU: getVendorSKUOption({ ...vendorSKU, purchasedGood }),
      })
    } else {
      missingCaseWeights.push(vendorSKUCSV)
    }
  }

  const [deliveryDateStart, deliveryDateEnd] =
    config.requestedDeliveryDateIndices
  const [supplierRefStart, supplierRefEnd] = config.supplierReferenceIndices

  return {
    formData: {
      // The user still has to pick a facility for the purchase order.
      facility: null,
      lineItems,
      requestedDeliveryDate: formatDateForAPI(
        csvData[deliveryDateStart][deliveryDateEnd]
      ),
      supplierReference: csvData[supplierRefStart][supplierRefEnd],
      vendor: getVendorOption(vendor),
    },
    missingCaseWeights,
    missingConfig: false,
    missingSKUs,
  }
}

export function getAPIFormData({
  formData,
  selectedFacilityNetworkID,
}: {
  formData: SavePurchaseOrderFormDataValidated
  selectedFacilityNetworkID: string | null
}): SavePurchaseOrderBody {
  return {
    facilityID: formData.facility.value.id,
    freightCost: Number(formData.freightCost),
    lineItems: formData.lineItems
      .filter((lineItem): lineItem is LineItemValidated => {
        return lineItem.vendorSKU !== null
      })
      .map((lineItem) => {
        const { costPerLb, orderUnit, unitWt } = getLineItemInfo({
          lineItem,
          selectedFacilityNetworkID,
        })

        return {
          costPerLb,
          quantity: Number(lineItem.qty),
          quantityUnit: orderUnit,
          skuID: lineItem.vendorSKU.value.id,
          unitWeightLbs: unitWt,
        }
      }),
    requestedDeliveryDate: formData.requestedDeliveryDate,
    supplierRef: formData.supplierReference,
    vendorID: formData.vendor.value.id,
  }
}

export function getExpandedLineItem({
  lineItem,
  vendorSKUsInPO,
}: {
  lineItem: PurchaseOrderLineItem
  vendorSKUsInPO: VendorSKUsInPO
}): PurchaseOrderLineItemExpanded {
  const { costPerLb, quantity, quantityUnit, skuID, unitWeightLbs } = lineItem
  const { isAvailable, vendorSKUWithPurchasedGood } =
    vendorSKUsInPO[skuID] || {}

  const orderUnit = quantityUnit

  const receivingUnit = vendorSKUWithPurchasedGood?.receivingUnit
  const caseWeightPounds = Number(vendorSKUWithPurchasedGood?.caseWeightPounds)
  const packWeightPounds = vendorSKUWithPurchasedGood?.packWeightPounds

  const totalWt = quantity * unitWeightLbs
  const unitPrice =
    orderUnit === 'cases' ? unitWeightLbs * costPerLb : costPerLb
  const receivingUnitWt =
    receivingUnit === 'packs' ? packWeightPounds : caseWeightPounds

  return {
    baseSku: vendorSKUWithPurchasedGood?.purchasedGood.baseSku,
    isAvailable,
    orderQty: quantity,
    orderUnit,
    orderUnitWt: unitWeightLbs,
    receivingQty: receivingUnitWt ? totalWt / receivingUnitWt : receivingUnitWt,
    receivingUnit,
    receivingUnitWt,
    total: quantity * unitPrice,
    totalWt,
    unitPrice,
    vendorSKU: vendorSKUWithPurchasedGood
      ? {
          name: vendorSKUWithPurchasedGood.purchasedGood.name,
          sku: vendorSKUWithPurchasedGood.sku,
        }
      : undefined,
    vendorSKUID: skuID,
    vendorSKUWithPurchasedGood,
  }
}

export function getFacilityOption(
  facility: AbbrFacility
): ReactSelectValue<AbbrFacility> {
  return { label: facility.displayName, value: facility }
}

export function getFormattedUnitPrice(
  {
    orderUnit,
    unitPrice,
  }: {
    orderUnit: 'cases' | 'lbs'
    unitPrice: number
  },
  decimalPlaces = 2
) {
  const singularOrderUnit = orderUnit === 'cases' ? 'case' : 'lb'

  return `${formatDollars(unitPrice, decimalPlaces)} / ${singularOrderUnit}`
}

export function getInitialFormData({
  purchaseOrder,
}: {
  purchaseOrder?: PurchaseOrderFull
} = {}): SavePurchaseOrderFormData {
  const lineItems = purchaseOrder?.lineItems ?? []

  return {
    facility: purchaseOrder ? getFacilityOption(purchaseOrder.facility) : null,
    freightCost: purchaseOrder?.freightCost ?? '',
    lineItems:
      lineItems.length > 0
        ? lineItems.map((lineItem) => {
            return {
              overrideCasePrice: lineItem.unitPrice,
              qty: lineItem.orderQty,
              vendorSKU: lineItem.vendorSKUWithPurchasedGood
                ? getVendorSKUOption(lineItem.vendorSKUWithPurchasedGood)
                : null,
            }
          })
        : [
            {
              overrideCasePrice: null,
              qty: '',
              vendorSKU: null,
            },
          ],
    requestedDeliveryDate:
      formatDateForAPI(purchaseOrder?.requestedDeliveryDate) ?? '',
    supplierReference: purchaseOrder?.supplierRef ?? '',
    vendor: purchaseOrder ? getVendorOption(purchaseOrder.vendor) : null,
  }
}

export function getLineItemInfo({
  lineItem,
  selectedFacilityNetworkID,
}: {
  lineItem: LineItem
  selectedFacilityNetworkID: string | null
}): {
  caseWtLbs: number
  costPerLb: number
  orderUnit: 'cases' | 'lbs'
  orderUnitSingle: 'case' | 'lb'
  total: number
  totalWt: number
  unitPrice: number
  originalUnitPrice: number
  unitWt: number
} {
  const caseWtLbs = Number(lineItem.vendorSKU?.value.caseWeightPounds)

  const orderUnitSingle = caseWtLbs ? 'case' : 'lb'
  const orderUnit = caseWtLbs ? 'cases' : 'lbs'
  const unitWt = caseWtLbs ? caseWtLbs : 1
  const costInfo = selectedFacilityNetworkID
    ? lineItem.vendorSKU?.value.facilityNetworkInfo[selectedFacilityNetworkID]
    : undefined
  let unitPrice: number
  let costPerLb: number

  const hasPrevious = lineItem.overridedSku

  // If it's the same sku and there's already an overrided price, that's the unit price.
  // "has previous" -> If there's no previous sku but there's an overrided price, we should use it.
  if (
    lineItem.overrideCasePrice &&
    (!hasPrevious || lineItem.overridedSku === lineItem.vendorSKU?.value.id)
  ) {
    unitPrice = lineItem.overrideCasePrice
    costPerLb = round(unitPrice / unitWt, 3)
  } else {
    costPerLb = round(Number(costInfo?.costPerPound ?? 0), 3)
    unitPrice = costPerLb * unitWt
  }
  const originalUnitPrice = round(
    Number(costInfo?.costPerPound ?? 0) * unitWt,
    3
  )

  // Set the precision on unit price to 3
  unitPrice = round(unitPrice, 3)
  const totalWt = Number(lineItem.qty) * unitWt
  const total = Number(lineItem.qty) * unitPrice

  return {
    caseWtLbs,
    costPerLb,
    orderUnit,
    orderUnitSingle,
    total,
    totalWt,
    unitPrice,
    originalUnitPrice,
    unitWt,
  }
}

export function getLineItemLotNumber({
  lineItem,
  snapReceiptID,
}: {
  lineItem: PurchaseOrderLineItemExpanded
  snapReceiptID: string
}) {
  return lineItem.baseSku ? `${lineItem.baseSku}-${snapReceiptID}` : ''
}

export function getLineItemsTotal({
  lineItems,
  selectedFacilityNetworkID,
}: {
  lineItems: SavePurchaseOrderFormData['lineItems']
  selectedFacilityNetworkID: string | null
}): number {
  return lineItems.reduce((total, lineItem) => {
    return (
      total + getLineItemInfo({ lineItem, selectedFacilityNetworkID }).total
    )
  }, 0)
}

export function getMissingVendorSKUs(
  purchaseOrder: PurchaseOrderFull
): (VendorSKUWithPurchasedGood | number)[] {
  return compact(
    purchaseOrder.lineItems.map(
      ({ isAvailable, vendorSKU, vendorSKUID, vendorSKUWithPurchasedGood }) => {
        if (!vendorSKU) {
          return vendorSKUID
        } else if (!isAvailable) {
          return vendorSKUWithPurchasedGood
        }
      }
    )
  )
}

export function getVendorOption(vendor: Vendor): ReactSelectValue<Vendor> {
  return { label: vendor.name, value: vendor }
}

export function getVendorSKUOption(
  vendorSKU: VendorSKUWithPurchasedGood
): ReactSelectValue<VendorSKUWithPurchasedGood> {
  return { label: vendorSKU.purchasedGood.name, value: vendorSKU }
}

export function orderLineItems(
  lineItems: PurchaseOrderLineItemExpanded[]
): PurchaseOrderLineItemExpanded[] {
  return orderBy(lineItems, ({ vendorSKU }) => {
    return vendorSKU?.name.toLowerCase()
  })
}

export function validateFormData({
  formData,
  selectedFacilityNetworkID,
}: {
  formData: SavePurchaseOrderFormData
  selectedFacilityNetworkID: string | null
}): FormikErrors<SavePurchaseOrderFormData> {
  const errors: FormikErrors<SavePurchaseOrderFormData> = {}

  if (!formData.facility) {
    errors.facility = 'Please choose a facility.'
  }

  if (!formData.requestedDeliveryDate) {
    errors.requestedDeliveryDate = 'Please enter a requested delivery date.'
  }

  if (formData.supplierReference.length > 20) {
    errors.supplierReference = `Max length is 20 characters (${formData.supplierReference.length} entered)`
  }

  if (!formData.vendor) {
    errors.vendor = 'Please choose a vendor.'
  }

  if (formData.lineItems.length <= 0) {
    errors.lineItems = [{ vendorSKU: 'Required' }]
  } else if (
    formData.lineItems.every((lineItem) => {
      return !lineItem.vendorSKU && !lineItem.qty
    })
  ) {
    errors.lineItems = [{ vendorSKU: 'Required' }]
  } else {
    const lineItemErrors: FormikErrors<LineItem>[] = []

    formData.lineItems.forEach((lineItem) => {
      const errors: FormikErrors<LineItem> = {}

      if (lineItem.qty && !lineItem.vendorSKU) {
        errors.vendorSKU = 'Required'
      } else if (lineItem.vendorSKU) {
        if (
          !selectedFacilityNetworkID ||
          !lineItem.vendorSKU.value.facilityNetworkInfo[
            selectedFacilityNetworkID
          ]?.isAvailable
        ) {
          errors.vendorSKU = 'Purchased good is not available'
        }

        if (lineItem.qty === '') {
          errors.qty = 'Required'
        } else if (Number(lineItem.qty) <= 0) {
          errors.qty = 'Must be greater than 0'
        }
      }

      lineItemErrors.push(errors)
    })

    if (lineItemErrors.some((errors) => !isEmpty(errors))) {
      errors.lineItems = lineItemErrors
    }
  }

  return errors
}
