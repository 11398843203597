import { omitBy, round } from 'lodash-es'

const GRAMS_IN_LB = 453.59237

export function formatCommas(value: number): string {
  return new Intl.NumberFormat().format(value)
}

export function formatPercent(value: number): string {
  return `${round(value * 100, 2)}%`
}

export function gramsToLbs(grams: number): number {
  return grams / GRAMS_IN_LB
}

export function lbsToGrams(lbs: number): number {
  return lbs * GRAMS_IN_LB
}

export function removeQueryParamsEmptyKeys(
  obj: Record<string, boolean | string | null | undefined | ''>
) {
  return omitBy(obj, (value) => {
    return value === undefined || value === ''
  })
}
