import { useCallback } from 'react'
import { assign, createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Tag } from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'
import { error } from 'slices/notifications'

interface Context {
  tags: Tag[]
}

type Events =
  | {
      tags: Tag[]
      type: 'EDIT'
      updateCycle2?: boolean
    }
  | {
      tags: Tag[]
      type: 'INITIAL_TAGS_CHANGED'
    }

type Services = {
  editTags: {
    data: unknown
  }
}

export function createTagsMachine(initialTags: Tag[]) {
  return createMachine<Context, Events>(
    {
      id: 'tagsMachine',
      initial: 'idle',
      context: {
        tags: initialTags,
      },
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as Events,
        services: {} as Services,
      },
      states: {
        idle: {
          on: {
            EDIT: {
              actions: ['updateTags'],
              target: 'saving',
            },
            INITIAL_TAGS_CHANGED: {
              actions: ['updateTags'],
            },
          },
        },
        saving: {
          on: { EDIT: { actions: ['updateTags'], target: 'saving' } },
          invoke: {
            src: 'editTags',
            onDone: {
              target: 'idle',
            },
            onError: {
              actions: ['showFailedTagUpdate'],
              target: 'idle',
            },
          },
        },
      },
    },
    {
      actions: {
        updateTags: assign((context, event) => {
          return {
            tags: event.tags,
          }
        }),
      },
    }
  )
}

export function useCachedTags({
  initialTags,
  updateTagsFn,
}: {
  initialTags: Tag[]
  updateTagsFn({
    tags,
    updateCycle2,
  }: {
    tags: Tag[]
    updateCycle2?: boolean
  }): Promise<unknown>
}) {
  const dispatch = useAppDispatch()

  const [state, send] = useMachine(() => createTagsMachine(initialTags), {
    actions: {
      showFailedTagUpdate: () => {
        dispatch(error('Failed to update tags'))
      },
    },
    services: {
      editTags: (_context, event) => {
        if (event.type !== 'EDIT') {
          return Promise.resolve()
        }

        return updateTagsFn({
          tags: event.tags,
          updateCycle2: event.updateCycle2,
        })
      },
    },
  })
  const { tags } = state.context

  const onTagsChanged = useCallback(
    ({ tags, updateCycle2 }: { tags: Tag[]; updateCycle2?: boolean }) => {
      send({ tags, type: 'EDIT', updateCycle2 })
    },
    [send]
  )

  useDeepCompareEffect(() => {
    send({ tags: initialTags, type: 'INITIAL_TAGS_CHANGED' })
  }, [initialTags, send])

  return { onTagsChanged, tags }
}
