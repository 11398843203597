import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  searchPurchasedGoods,
  SearchPurchasedGoodsResponse,
} from 'services/combinedAPI/purchasedGoods'

function getPurchasedGoodsQueryConfig({
  vendorID,
  ...rest
}: { vendorID: string | undefined } & Omit<
  UseQueryOptions<SearchPurchasedGoodsResponse, Error>,
  'enabled' | 'queryFn' | 'queryKey'
>) {
  return {
    ...rest,
    enabled: !!vendorID,
    queryFn: () => {
      if (!vendorID) {
        throw new Error('No vendorID provided for searching purchased goods')
      }

      return searchPurchasedGoods({ vendorID })
    },
    queryKey: ['purchasedGoods', vendorID],
  }
}

export function useBulkPurchasedGoods({
  vendorIDs,
  ...rest
}: { vendorIDs: string[] } & Omit<
  UseQueryOptions<SearchPurchasedGoodsResponse, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQueries({
    queries: vendorIDs.map<
      UseQueryOptions<SearchPurchasedGoodsResponse, Error>
    >((vendorID) => {
      return getPurchasedGoodsQueryConfig({ vendorID, ...rest })
    }),
  })
}

export function usePurchasedGoods(
  opts: Parameters<typeof getPurchasedGoodsQueryConfig>[0]
) {
  return useQuery<SearchPurchasedGoodsResponse, Error>(
    getPurchasedGoodsQueryConfig(opts)
  )
}
