import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  FacilityNetwork,
  LineHaul,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

interface ProductionState {
  facilityNetwork: FacilityNetwork | ''
  lineHauls: LineHaul[]
  planningTab: number
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord | ''
}

export const initialState: ProductionState = {
  facilityNetwork: '',
  lineHauls: [],
  planningTab: 0,
  productionCycle: '1',
  productionRecord: '',
}

export const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    selectFacilityNetwork: (
      state,
      action: PayloadAction<ProductionState['facilityNetwork']>
    ) => {
      state.facilityNetwork = action.payload
    },
    setPlanningTab: (
      state,
      action: PayloadAction<ProductionState['planningTab']>
    ) => {
      state.planningTab = action.payload
    },
    setLineHauls: (
      state,
      action: PayloadAction<ProductionState['lineHauls']>
    ) => {
      state.lineHauls = action.payload
    },
    setProductionCycle: (
      state,
      action: PayloadAction<ProductionState['productionCycle']>
    ) => {
      state.productionCycle = action.payload
    },
    setProductionRecord: (
      state,
      action: PayloadAction<ProductionState['productionRecord']>
    ) => {
      state.productionRecord = action.payload
    },
  },
})

export const {
  reset,
  selectFacilityNetwork,
  setLineHauls,
  setPlanningTab,
  setProductionCycle,
  setProductionRecord,
} = productionSlice.actions

export default productionSlice.reducer
