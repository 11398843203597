import { compact } from 'lodash-es'
import { ReactNode, useCallback, useState } from 'react'
import { usePopper } from 'react-popper'

import { useTouchOutside } from 'hooks/dom'

const Popover = ({
  canFlip = true,
  children,
  disabled = false,
  name,
  isOpen,
  setIsOpen,
  trigger,
}: {
  canFlip?: boolean
  children: ReactNode
  disabled?: boolean
  name: string
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  trigger(opts: {
    ref: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    className: string
    onClick(): void
  }): ReactNode
}): JSX.Element => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [internalIsOpen, setInternalIsOpen] = useState(false)

  const showPopover = isOpen === undefined ? internalIsOpen : isOpen
  const setShowPopover = setIsOpen === undefined ? setInternalIsOpen : setIsOpen

  const popoverTriggerClassName = `popover-trigger-${name}`
  const triggerProps = {
    ref: setReferenceElement,
    className: popoverTriggerClassName,
    onClick: () => {
      if (!disabled) {
        setShowPopover((isShown) => !isShown)
      }
    },
  }

  return (
    <>
      {trigger(triggerProps)}

      {showPopover && (
        <PopoverBody
          canFlip={canFlip}
          name={name}
          popoverTriggerClassName={popoverTriggerClassName}
          referenceElement={referenceElement}
          setShowPopover={setShowPopover}
        >
          {children}
        </PopoverBody>
      )}
    </>
  )
}

export default Popover

const PopoverBody = ({
  canFlip = true,
  children,
  name,
  popoverTriggerClassName,
  referenceElement,
  setShowPopover,
}: {
  canFlip?: boolean
  children: ReactNode
  name: string
  referenceElement: HTMLElement | null
  popoverTriggerClassName: string
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    modifiers: compact([
      canFlip
        ? undefined
        : {
            name: 'flip',
            options: { fallbackPlacements: ['bottom-start'] },
          },
      {
        name: 'offset',
        options: {
          offset: [-8, 0],
        },
      },
    ]),
    placement: 'bottom-start',
  })

  const closePopover = useCallback(() => {
    setShowPopover(false)
  }, [setShowPopover])

  useTouchOutside({
    el: popperElement,
    enabled: true,
    // We don't consider a click on the trigger to be an outside click. The trigger is really
    // part of the popover.
    exceptionClassName: popoverTriggerClassName,
    onTouchOutside: closePopover,
  })

  return (
    <div
      ref={setPopperElement}
      className="z-10 max-w-xs rounded border border-light-grey shadow-md"
      data-testid={`${name}-popover-body`}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="rounded bg-white">{children}</div>
    </div>
  )
}
