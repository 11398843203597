import { every, orderBy, some } from 'lodash-es'
import { FieldArray, Form, Formik, useField } from 'formik'
import { useCallback, useState } from 'react'
import { useMachine } from '@xstate/react'
import { v4 as uuidV4 } from 'uuid'

import {
  FacilityNetwork,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { filterShortPurchasedGoods } from 'utils/selects'
import {
  FormData,
  FormDataValidated,
  getShortPickOrderOptions,
  validateData,
} from './helpers'

import { shortPickOrderMachine } from './shortPickOrderMachine'
import { useAppSelector } from 'hooks'
import { useSubmitValidation } from 'hooks/forms'
import Button from 'components/common/Button'
import CreateShortPickOrderCreation from './CreateShortPickOrderCreation'
import ErrorDisplay from 'components/common/ErrorDisplay'
import FormFieldError from 'components/common/FormFieldError'
import FormFieldLabel from 'components/common/FormFieldLabel'
import FormGroup from 'components/common/FormGroup'
import FormInput from 'components/common/FormInput'
import FormSelect from 'components/common/FormSelect'
import MinusCircleIcon from 'components/common/icons/MinusCircleIcon'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'
import Select from 'components/common/Select'
import Tooltip from 'components/common/Tooltip'

interface Props {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
}

const CreateShortPickOrder = ({
  hasGeneratedPickOrders,
  ...rest
}: Props & { hasGeneratedPickOrders: boolean }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <Tooltip
        enabled={!hasGeneratedPickOrders}
        trigger={
          <div className="w-48">
            <Button
              disabled={!hasGeneratedPickOrders}
              onClick={() => {
                setIsOpen(true)
              }}
            >
              Create Short Pick Order
            </Button>
          </div>
        }
      >
        <p className="p-2 text-xs">
          Snap pick orders must be generated before short pick orders can be
          created.
        </p>
      </Tooltip>

      {isOpen && (
        <CreateShortPickOrderModal {...rest} onCloseModal={closeModal} />
      )}
    </>
  )
}

export default CreateShortPickOrder

const CreateShortPickOrderModal = ({
  onCloseModal,
  productionCycle,
  productionRecord,
  productionTerm,
}: Props & {
  onCloseModal(): void
}): JSX.Element => {
  const facilityNetwork = useAppSelector(
    (state) => state.production.facilityNetwork
  )

  const [state, send] = useMachine(shortPickOrderMachine)
  const { purchasedGoods, stages } = state.context
  const isDone = state.matches('created')
  const isEditing = state.matches('editing')
  const isCreating =
    state.matches('creating') ||
    state.matches('checkingForMoreItems') ||
    state.matches('delayCreate')
  const hasError = some(stages, ({ stage }) => stage === 'error')
  const isSuccess = every(stages, ({ stage }) => stage === 'success')

  return (
    <Modal onCloseModal={isEditing || isDone ? onCloseModal : undefined}>
      <ModalBody>
        <div className="w-create-short-pick-order-modal">
          <div className="mb-4">
            <ModalHeader
              onClickClose={isEditing || isDone ? onCloseModal : undefined}
            >
              Create Short Pick Order
            </ModalHeader>
          </div>
          <Formik<FormData>
            initialValues={{ facility: null, purchasedGoods: [] }}
            onSubmit={(formData) => {
              const validatedFormData = formData as FormDataValidated

              send({
                facilityID: validatedFormData.facility.value,
                facilityNetworkID: facilityNetwork ? facilityNetwork.id : '',
                productionCycle,
                productionTerm,
                purchasedGoods: validatedFormData.purchasedGoods,
                type: 'CREATE',
              })
            }}
            validate={(formData) => {
              return validateData(formData)
            }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form>
              {isEditing ? (
                <CreateShortPickOrderForm
                  facilityNetwork={facilityNetwork}
                  onCloseModal={onCloseModal}
                  productionCycle={productionCycle}
                  productionRecord={productionRecord}
                />
              ) : (
                <CreateShortPickOrderCreation
                  hasError={hasError}
                  isCreating={isCreating}
                  isSuccess={isSuccess}
                  onClickDone={onCloseModal}
                  purchasedGoods={purchasedGoods}
                  stages={stages}
                />
              )}
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

const CreateShortPickOrderForm = ({
  facilityNetwork,
  onCloseModal,
  productionCycle,
  productionRecord,
}: {
  facilityNetwork: FacilityNetwork | ''
  onCloseModal(): void
  productionCycle: '1' | '2'
  productionRecord: ProductionRecord
}): JSX.Element => {
  const [{ value: purchasedGoods }] =
    useField<FormData['purchasedGoods']>('purchasedGoods')

  const { errors, onClickSubmit } = useSubmitValidation<FormData>({
    isSaving: false,
  })

  const facilityOptions = facilityNetwork
    ? facilityNetwork.facilities.map((facility) => {
        return { label: facility.displayName, value: facility.id }
      })
    : []

  return (
    <>
      <div className="space-y-4">
        <div className="grid grid-cols-3 gap-4">
          <FormSelect
            inputId="facility"
            label="Facility"
            labelFor="facility"
            name="facility"
            options={facilityOptions}
          />
        </div>
        <FieldArray
          name="purchasedGoods"
          render={(purchasedGoodsHelpers) => {
            return (
              <>
                <p className="text-sm">
                  Use the below dropdown to select purchased goods to create
                  short pick orders for.
                </p>
                <CreateShortPickOrderSelects
                  onSelect={(value) => {
                    const itemToAdd: FormData['purchasedGoods'][number] = {
                      clientSideID: uuidV4(),
                      count: '',
                      item: value,
                    }

                    purchasedGoodsHelpers.push(itemToAdd)
                  }}
                  productionCycle={productionCycle}
                  productionRecord={productionRecord}
                />
                <hr />
                {purchasedGoods.length === 0 ? (
                  <>
                    <p className="text-sm text-dark-grey">No items selected</p>
                    {typeof errors?.purchasedGoods === 'string' && (
                      <FormFieldError error={errors.purchasedGoods} />
                    )}
                  </>
                ) : (
                  <div>
                    <div className="mb-4 flex items-center justify-between">
                      <p className="text-sm">
                        Selected items to create pick orders for:
                      </p>
                      <div className="w-40">
                        <FormFieldLabel label="Counts" />
                      </div>
                    </div>
                    <div className="space-y-4">
                      {purchasedGoods.map((purchasedGood, i) => {
                        return (
                          <CreateShortPickOrderRow
                            key={purchasedGood.clientSideID}
                            index={i}
                            onClickRemove={() => {
                              purchasedGoodsHelpers.remove(i)
                            }}
                            purchasedGood={purchasedGood}
                          />
                        )
                      })}
                    </div>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>

      <div className="mt-8">
        {!!errors && (
          <ErrorDisplay>Please fix form errors and try again</ErrorDisplay>
        )}

        <ModalButtons
          mainActionBtnText="Create"
          onCancel={onCloseModal}
          onMainAction={onClickSubmit}
        />
      </div>
    </>
  )
}

const CreateShortPickOrderSelects = ({
  onSelect,
  productionCycle,
  productionRecord,
}: {
  onSelect(item: FormData['purchasedGoods'][number]['item']): void
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): JSX.Element => {
  const { purchasedGoods } = getShortPickOrderOptions({
    productionCycle,
    productionRecord,
  })

  return (
    <div className="grid grid-cols-3 gap-4">
      <FormGroup
        label="Purchased Good"
        labelFor="short-pick-order-purchased-good"
      >
        <Select
          filterOption={filterShortPurchasedGoods}
          formatOptionLabel={(option) => {
            if (!option) {
              return null
            }

            return (
              <div>
                <div>{option.label}</div>
                <div className="text-xs text-grey">{option.value.baseSku}</div>
              </div>
            )
          }}
          inputId="short-pick-order-purchased-good"
          name="short-pick-order-purchased-good"
          onChange={(value) => {
            if (value) {
              onSelect(value)
            }
          }}
          options={orderBy(purchasedGoods, ({ label }) => label.toLowerCase())}
          value={null}
        />
      </FormGroup>
    </div>
  )
}

const CreateShortPickOrderRow = ({
  index,
  onClickRemove,
  purchasedGood,
}: {
  index: number
  onClickRemove(): void
  purchasedGood: FormData['purchasedGoods'][number]
}): JSX.Element => {
  return (
    <div key={purchasedGood.item.value.id} className="flex items-center">
      <div
        className="mr-4 h-5 w-5 cursor-pointer text-red"
        onClick={() => {
          onClickRemove()
        }}
      >
        <MinusCircleIcon strokeWidth={1} />
      </div>
      <div className="flex-grow">
        <FormGroup label="Purchased Good">
          <p className="text-sm">
            <span>
              {purchasedGood.item.label}{' '}
              <span className="text-xs text-grey">
                ({purchasedGood.item.value.baseSku})
              </span>
            </span>
          </p>
        </FormGroup>
      </div>
      <div className="w-40 flex-shrink-0">
        <div className="flex items-center space-x-2">
          <FormInput
            aria-label={`Purchased Good ${index + 1} Count`}
            name={`purchasedGoods.${index}.count`}
            type="number"
          />
          <div className="w-12">lbs</div>
        </div>
      </div>
    </div>
  )
}
