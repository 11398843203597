import { Fragment } from 'react'
import { keys } from 'lodash-es'

import { composeMealsForCycle } from 'utils/meal'
import { ComposedMeal } from 'types/internal'
import { getMaxAllowableWeightVariation } from 'utils/wip'
import {
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

import H3 from 'components/common/bs/H3'
import Table, { Td, Thead, Th, Tr, TBody } from 'components/common/Table'

const WeightVerificationChecklist = ({
  productionCycle,
  productionRecord,
  productionTerm,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
  productionTerm: string
}): JSX.Element => {
  const meals = composeMealsForCycle(
    productionRecord.cycles[productionCycle].meals
  )

  return (
    <>
      <div className="py-5 print:hidden">
        <H3>Weight Verification Checklist</H3>
      </div>
      <div className="print:text-2xs">
        <Table>
          <Thead>
            <Tr>
              <Th width="5%">Date Prepared</Th>
              <Th width="5%">Meal#</Th>
              <Th width="5%">Term</Th>
              <Th width="5%">Cycle</Th>
              <Th width="15%">Component</Th>
              <Th width="10%">Target weight (g)</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">Wt.</Th>
              <Th width="5%">QA Initials</Th>
            </Tr>
          </Thead>
          {meals && (
            <TBody>
              {meals.map((meal, i) => {
                return (
                  <Fragment key={i}>
                    <TrayComponentRow
                      meal={meal}
                      productionCycle={productionCycle}
                      productionTerm={productionTerm}
                      trayNumber={1}
                    />
                    <TrayComponentRow
                      meal={meal}
                      productionCycle={productionCycle}
                      productionTerm={productionTerm}
                      trayNumber={2}
                    />
                    <GarnishRows
                      meal={meal}
                      productionCycle={productionCycle}
                      productionTerm={productionTerm}
                    />
                  </Fragment>
                )
              })}
            </TBody>
          )}
        </Table>
      </div>
    </>
  )
}

export default WeightVerificationChecklist

const TrayComponentRow = ({
  meal,
  productionCycle,
  productionTerm,
  trayNumber,
}: {
  meal: ComposedMeal
  productionCycle: ProductionCycle
  productionTerm: string
  trayNumber: 1 | 2
}): JSX.Element | null => {
  const components = meal[`component${trayNumber}`]

  if (!components.length) {
    return null
  }

  const title = components.map((c) => c.title).join(' / ')
  const targetWeights: Record<string, string> = {}

  components.forEach((c) => {
    const gramsPerPortion = c.qtyGrams
    const allowedWeightOffset = getMaxAllowableWeightVariation(gramsPerPortion)

    targetWeights[
      c.title
    ] = `${gramsPerPortion.toFixed()} +/- ${allowedWeightOffset.toFixed(1)}`
  })

  const component = {
    cycle: productionCycle,
    mealCode: meal.mealCode,
    targetWeights,
    term: productionTerm,
    title,
  }

  return <WeightVerificationTableRow component={component} />
}

const GarnishRows = ({
  meal,
  productionCycle,
  productionTerm,
}: {
  meal: ComposedMeal
  productionCycle: ProductionCycle
  productionTerm: string
}): JSX.Element => {
  const garnishes = meal.garnishes

  return (
    <>
      {garnishes.map((c, i) => {
        const targetWeights: Record<string, string> = {}
        const gramsPerPortion = c.qtyGrams
        const allowedWeightOffset =
          getMaxAllowableWeightVariation(gramsPerPortion)

        targetWeights[
          c.title
        ] = `${gramsPerPortion.toFixed()} +/- ${allowedWeightOffset.toFixed(1)}`

        const component = {
          cycle: productionCycle,
          mealCode: meal.mealCode,
          targetWeights,
          term: productionTerm,
          title: c.title,
        }

        return <WeightVerificationTableRow key={i} component={component} />
      })}
    </>
  )
}

const WeightVerificationTableRow = ({
  component,
}: {
  component: {
    cycle: string
    mealCode: number
    targetWeights: Record<string, string>
    term: string
    title: string
  }
}): JSX.Element => {
  return (
    <Tr withHover>
      <Td></Td>
      <Td>{component.mealCode}</Td>
      <Td>
        {component.term}
        {component.cycle === '2' ? 'A' : ''}
      </Td>
      <Td>{component.cycle}</Td>
      <Td>{component.title}</Td>
      <Td>
        {keys(component.targetWeights).map((c, i) => {
          return (
            <span key={i}>
              {component.targetWeights[c]} <br />
            </span>
          )
        })}
      </Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
    </Tr>
  )
}
