import { FacilityNetwork } from 'types/combinedAPI/domainModels'
import { get } from './baseAPI'

export type GetFacilityNetworksResponse = FacilityNetwork[]

export const ENDPOINTS = {
  GET_FACILITY_NETWORKS: (omitOffsiteStorage: boolean) => ({
    path: `/facilityNetworks?omitOffsiteStorage=${omitOffsiteStorage}`,
    version: 'v0' as const,
  }),
}

export async function getFacilityNetworks(
  omitOffsiteStorage: boolean
): Promise<GetFacilityNetworksResponse> {
  return get(ENDPOINTS.GET_FACILITY_NETWORKS(omitOffsiteStorage))
}
