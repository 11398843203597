import { useState } from 'react'

import { ComposedMeal } from 'types/internal'
import { success } from 'slices/notifications'

import { useAppDispatch } from 'hooks'
import { useDeleteProductionMeal } from 'hooks/combinedAPI/productionMeals'
import ConfirmationModal from 'components/common/ConfirmationModal'

export interface Props {
  productionMeal: ComposedMeal
  productionTerm: string
  readOnly: boolean
  refetchProductionRecord(): void
}

const DeleteMeal = ({
  productionMeal,
  productionTerm,
  readOnly,
  refetchProductionRecord,
}: Props) => {
  const [showDeleteMealModal, setShowDeleteMealModal] = useState(false)

  return (
    <>
      {readOnly ? null : (
        <div
          className="flex cursor-pointer items-center justify-center text-base"
          data-testid="delete-meal-icon"
          onClick={() => {
            setShowDeleteMealModal(true)
          }}
          title="Delete Meal"
        >
          <i className="fas fa-minus-circle" />
        </div>
      )}
      <ConfirmDeleteMealModal
        isOpen={showDeleteMealModal}
        onCloseModal={() => {
          setShowDeleteMealModal(false)
        }}
        productionMeal={productionMeal}
        productionTerm={productionTerm}
        refetchProductionRecord={refetchProductionRecord}
      />
    </>
  )
}

export default DeleteMeal

const ConfirmDeleteMealModal = ({
  isOpen,
  onCloseModal,
  productionMeal,
  productionTerm,
  refetchProductionRecord,
}: {
  isOpen: boolean
  onCloseModal(): void
  productionMeal: ComposedMeal
  productionTerm: string
  refetchProductionRecord(): void
}) => {
  const dispatch = useAppDispatch()

  const { isLoading: isDeletingMeal, mutate: deleteMeal } =
    useDeleteProductionMeal({
      onSuccess: () => {
        refetchProductionRecord()

        onCloseModal()

        dispatch(
          success(
            `Meal #${productionMeal.mealCode} was successfully deleted from term ${productionTerm}.`
          )
        )
      },
    })

  if (!isOpen) {
    return null
  }

  return (
    <ConfirmationModal
      confirmText="Delete"
      isLoading={isDeletingMeal}
      onCancel={() => {
        onCloseModal()
      }}
      onConfirm={() => {
        deleteMeal({ mealID: productionMeal.id, productionTerm })
      }}
      title="Confirm Delete Meal"
    >
      <div>
        <p>Are you sure you want to delete meal #{productionMeal.mealCode}?</p>
      </div>
    </ConfirmationModal>
  )
}
