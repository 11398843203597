import { Td, Tr } from 'components/common/Table'
import { ProductionCycle } from 'types/combinedAPI/domainModels'

const SensoryTableRow = ({
  part,
  productionCycle,
}: {
  part: { mealCodes: string; partTitle: string }
  productionCycle: ProductionCycle
}): JSX.Element => {
  let appendThis = ''
  if (productionCycle === '2') {
    appendThis = '-A'
  }

  return (
    <Tr height="80px" withHover>
      <Td>
        {part.partTitle}
        {appendThis}
      </Td>
      <Td>{part.mealCodes}</Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
    </Tr>
  )
}

export default SensoryTableRow
