import { addToDate, DATE_FORMATS, formatDate, parseToDate } from 'utils/dates'
import { TagCategories } from 'types/combinedAPI/domainModels'

export function getCycle2DayOfWeek(
  cycle1DayOfWeek: TagCategories['day_of_week']
): TagCategories['day_of_week'] {
  const cycle1Date = parseToDate(cycle1DayOfWeek, {
    parseFormat: DATE_FORMATS.READABLE_DAY,
  })
  const cycle2Date = addToDate(cycle1Date, { quantity: 2, units: 'days' })

  return formatDate(cycle2Date, {
    format: DATE_FORMATS.READABLE_DAY,
  }).toLowerCase() as TagCategories['day_of_week']
}
