import { groupBy, keys, sum } from 'lodash-es'

import {
  ProductionPart,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'

interface QaPartsResponse {
  cycles: {
    [cycleNum: string]: {
      combined: boolean
      cookType?: string
      expectedWeight?: number
      mealCodes: string
      partTitle: string
      totalWeight?: number
    }[]
  }
}

export const qaParts = (
  type: 'sensory' | 'weight_check',
  productionRecord: ProductionRecord
): QaPartsResponse => {
  const payload: QaPartsResponse = { cycles: {} }

  keys(productionRecord.cycles).map((cycleNumber) => {
    const parts: ProductionPart[] = []
    const filteredParts = productionRecord.cycles[cycleNumber].parts.filter(
      (p) => {
        return !!(
          p.tags.find((t) => t.category === 'mix') ||
          p.tags.find((t) => t.category === 'cook')
        )
      }
    )

    filteredParts.map((part) => {
      return parts.push(
        part.combined ? productionRecord.combinedParts[part.id] : part
      )
    })

    const grouped = groupBy(parts, 'title')

    payload.cycles[cycleNumber] = keys(grouped).map((partName) => {
      const combined = grouped[partName][0].combined
      const newObject = {
        combined,
        mealCodes: grouped[partName].map((x) => x.apiMealCode).join(', '),
        partTitle: partName,
      }

      if (type === 'weight_check') {
        const cookTag = grouped[partName][0].tags.find(
          (t) => t.category === 'cook'
        )
        const mixTag = grouped[partName][0].tags.find(
          (t) => t.category === 'mix'
        )
        const tag = cookTag || mixTag

        return {
          ...newObject,
          cookType: tag?.title ?? '',
          expectedWeight: sum(
            grouped[partName].map((x) => x.finalWeightPounds)
          ),
          totalWeight: sum(grouped[partName].map((x) => x.totalWeightPounds)),
        }
      }

      return newObject
    })
  })

  return payload
}
