import { clsx } from 'clsx'
import { orderBy, reject } from 'lodash-es'

import { error } from 'slices/notifications'
import { Tag, TagCategories } from 'types/combinedAPI/domainModels'

import { useAppDispatch } from 'hooks'

const TAG_CATEGORY_ORDER: (keyof TagCategories)[] = [
  'prepare',
  'cook',
  'mix',
  'canned',
  'manual',
  'prep_labels',
  'day_of_week',
  'prep_location',
]

const TAG_COLORS: Partial<Record<keyof TagCategories, string>> = {
  cook: 'bg-bs-yellow',
  manual: 'bg-green text-white',
  mix: 'bg-black text-white',
  prep_labels: 'bg-red text-white',
  prepare: 'bg-blue text-white',
}

const ProductionPartTags = ({
  onTagsChanged,
  readOnly,
  tags,
}: {
  onTagsChanged(opts: { tags: Tag[]; updateCycle2?: boolean }): void
  readOnly: boolean
  tags: Tag[]
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const orderedTags = orderBy(tags, ({ category }) => {
    return TAG_CATEGORY_ORDER.indexOf(category)
  })

  const removeTagFromPart = (tag: Tag) => {
    if (readOnly) {
      return dispatch(error('You do not have permission to remove tags.'))
    }

    const newTags = reject(tags, ({ category, title }) => {
      return category === tag.category && title === tag.title
    })

    // We do not update cycle 2 here because we don't want to remove cycle 2's "day_of_week" tag. We need back-end support
    // to do this - until we have that, we'll just not remove any cycle 2 tag when cycle 1 is modified.
    onTagsChanged({ tags: newTags, updateCycle2: false })
  }

  return (
    <>
      {orderedTags.map((tag) => (
        <div
          key={tag.category}
          className={clsx(
            'inline-flex cursor-pointer space-x-1 rounded p-1 text-xs leading-3',
            TAG_COLORS[tag.category] ?? 'bg-bs-lighter-grey'
          )}
        >
          <span>{tag.title}</span>
          {!readOnly && (
            <i
              aria-label={`Remove tag ${tag.category}`}
              className="fas fa-times"
              onClick={() => {
                removeTagFromPart(tag)
              }}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default ProductionPartTags
