import CSVReader from 'react-csv-reader'
import { useState } from 'react'

import EmployeeDiscountPDFViewer from './EmployeeDiscountPDFViewer'
import H3 from 'components/common/bs/H3'

import { EmployeeLabel } from 'types/internal'

const papaparseOptions = {
  dynamicTyping: true,
  header: true,
  skipEmptyLines: true,
}

const EmployeeLabels = () => {
  const [data, setData] = useState<EmployeeLabel[]>([])
  const [showPDF, setShowPDF] = useState(false)

  function parseDataFromCsv(data: EmployeeLabel[]) {
    setShowPDF(true)
    setData(data)
  }

  return (
    <div className="pt-6">
      {showPDF && data.length ? (
        <EmployeeDiscountPDFViewer
          data={data}
          onClickBack={() => {
            setShowPDF(false)
          }}
        />
      ) : (
        <div>
          <H3>Upload Employee Label CSV</H3>
          <CSVReader
            onFileLoaded={(data: EmployeeLabel[]) => parseDataFromCsv(data)}
            parserOptions={papaparseOptions}
          />
        </div>
      )}
    </div>
  )
}

export default EmployeeLabels
