import { PropsWithChildren } from 'react'
import ReactPDF, { Text, View } from '@react-pdf/renderer'

function ApprovalContent() {
  return (
    <View style={{ marginTop: '40px' }}>
      <Text style={{ fontSize: '30px' }}>Approvals</Text>
      <View
        style={{
          marginTop: '10px',
          marginBottom: '20px',
        }}
      >
        <Approval>
          <Box />
          <ApprovalText>
            Each strap is taut enough (slight bending on top red bin sides
            should be present)
          </ApprovalText>
        </Approval>

        <Approval>
          <Box />
          <ApprovalText>Top red bin is empty</ApprovalText>
        </Approval>

        <Approval>
          <Box />
          <ApprovalText>2 straps on each tower</ApprovalText>
        </Approval>

        <Approval>
          <Box />
          <ApprovalText>Meals/Components in tower match tag</ApprovalText>
        </Approval>
      </View>

      <Text style={{ fontSize: '13px' }}>MOD/Supervisor Initials</Text>
    </View>
  )
}

export default ApprovalContent

const Approval = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
      }}
      {...props}
    />
  )
}

const ApprovalText = (props: PropsWithChildren<ReactPDF.TextProps>) => {
  return (
    <Text
      style={{
        lineHeight: '1',
        fontSize: '13px',
        marginLeft: '10px',
      }}
      {...props}
    />
  )
}

const Box = (props: PropsWithChildren<ReactPDF.ViewProps>) => {
  return (
    <View
      style={{
        height: '14px',
        width: '14px',
        border: '1px solid black',
      }}
      {...props}
    />
  )
}
