import { apiDelete, get, post, put } from './baseAPI'
import {
  PurchaseOrder,
  PurchaseOrderWithLineItems,
} from 'types/combinedAPI/domainModels'

export interface SavePurchaseOrderBody {
  facilityID: string
  freightCost: number
  lineItems: {
    costPerLb: number
    quantity: number
    quantityUnit: 'cases' | 'lbs'
    skuID: number
    unitWeightLbs: number
  }[]
  requestedDeliveryDate: string
  supplierRef: string
  vendorID: string
}

export interface CreatePurchaseOrder {
  data: SavePurchaseOrderBody
}

export interface DeletePurchaseOrder {
  purchaseOrderID: string
}

export type DeletePurchaseOrderResponse = void

export interface EditPurchaseOrder {
  data: SavePurchaseOrderBody
  purchaseOrderID: string
}

export interface GetPurchaseOrder {
  purchaseOrderID: string
}

export type GetPurchaseOrderResponse = PurchaseOrderWithLineItems

export interface GetPurchaseOrders {
  filters?: {
    facilityID?: string
    requestedDeliveryDateEnd?: string
    requestedDeliveryDateStart?: string
    vendorID?: string
  }
  nextCursor: string | undefined
}

export interface GetPurchaseOrdersResponse {
  nextCursor: string | null
  results: PurchaseOrder[]
}

export const ENDPOINTS = {
  CREATE_PURCHASE_ORDER: () => ({
    path: '/purchaseOrders',
    version: 'v0' as const,
  }),
  DELETE_PURCHASE_ORDER: ({ purchaseOrderID }: DeletePurchaseOrder) => ({
    path: `/purchaseOrders/${purchaseOrderID}`,
    version: 'v0' as const,
  }),
  EDIT_PURCHASE_ORDER: ({
    purchaseOrderID,
  }: Omit<EditPurchaseOrder, 'data'>) => ({
    path: `/purchaseOrders/${purchaseOrderID}`,
    version: 'v0' as const,
  }),
  GET_PURCHASE_ORDER: ({ purchaseOrderID }: GetPurchaseOrder) => ({
    path: `/purchaseOrders/${purchaseOrderID}`,
    version: 'v0' as const,
  }),
  GET_PURCHASE_ORDERS: () => ({
    path: '/purchaseOrders',
    version: 'v0' as const,
  }),
}

export async function createPurchaseOrder({
  data,
}: CreatePurchaseOrder): Promise<PurchaseOrderWithLineItems> {
  return post({ data, ...ENDPOINTS.CREATE_PURCHASE_ORDER() })
}

export function deletePurchaseOrder(
  opts: DeletePurchaseOrder
): Promise<DeletePurchaseOrderResponse> {
  return apiDelete(ENDPOINTS.DELETE_PURCHASE_ORDER(opts))
}

export async function editPurchaseOrder({
  data,
  purchaseOrderID,
}: EditPurchaseOrder): Promise<PurchaseOrderWithLineItems> {
  return put({
    data,
    ...ENDPOINTS.EDIT_PURCHASE_ORDER({ purchaseOrderID }),
  })
}

export async function getPurchaseOrder(
  opts: GetPurchaseOrder
): Promise<GetPurchaseOrderResponse> {
  return get(ENDPOINTS.GET_PURCHASE_ORDER(opts))
}

export async function getPurchaseOrders({
  filters,
  nextCursor,
}: GetPurchaseOrders): Promise<GetPurchaseOrdersResponse> {
  return get({
    config: {
      params: {
        cursor: nextCursor,
        facility_id: filters?.facilityID,
        limit: 50,
        requested_delivery_date_end: filters?.requestedDeliveryDateEnd,
        requested_delivery_date_start: filters?.requestedDeliveryDateStart,
        vendor_id: filters?.vendorID,
      },
    },
    ...ENDPOINTS.GET_PURCHASE_ORDERS(),
  })
}
